import { Component } from 'react'

import IconArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import IconArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { IconButton, Paper } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

type Props = {
    showPreviousResults(..._args: unknown[]): unknown
    showNextResults(..._args: unknown[]): unknown
    searchState: {
        paging?: {
            itemsTotalCount?: number
            nextOffset?: number
        }
    }
    offsetToShow: number
    pagingSize: number
    disableControls?: boolean
}

class BasicPagingControl extends Component<Props> {
    renderPreviousButton() {
        const { offsetToShow, showPreviousResults, disableControls } = this.props
        if (offsetToShow === 0) return null
        return (
            <IconButton
                onClick={showPreviousResults}
                disabled={disableControls}
                color="primary"
                size="large"
            >
                <IconArrowLeft />
            </IconButton>
        )
    }

    renderNextButton() {
        const {
            offsetToShow,
            pagingSize,
            showNextResults,
            disableControls,
            searchState: { paging },
        } = this.props
        if (offsetToShow + pagingSize >= paging.itemsTotalCount) return null
        return (
            <IconButton
                onClick={showNextResults}
                disabled={disableControls}
                color="primary"
                size="large"
            >
                <IconArrowRight />
            </IconButton>
        )
    }

    render() {
        const {
            offsetToShow,
            pagingSize,
            searchState: { paging },
        } = this.props
        if (!paging || paging.itemsTotalCount <= pagingSize) return null
        return (
            <Paper sx={{ display: 'flex', displayPrint: 'none' }}>
                <Grid container spacing={0} xs={12} justifyContent={'space-around'}>
                    <Grid xs={1} display="flex" justifyContent="center" alignItems="center">
                        {this.renderPreviousButton()}
                    </Grid>
                    <Grid xs={10} display="flex" justifyContent="center" alignItems="center">
                        {`Result ${offsetToShow + 1} to ${Math.min(
                            offsetToShow + pagingSize,
                            paging.itemsTotalCount,
                        )}`}
                    </Grid>
                    <Grid xs={1} display="flex" justifyContent="center" alignItems="center">
                        {this.renderNextButton()}
                    </Grid>
                </Grid>
            </Paper>
        )
    }
}

export default BasicPagingControl
