import { isEmpty } from 'lodash/fp'

import { SearchConfig, SearchOptions } from './searchConfig'
import { serializeSearchInput } from './searchInputSerializers'

export const getSearchUrl = (
    searchState: {
        searchOptions: SearchOptions
    } = { searchOptions: undefined },
    searchConfig: SearchConfig,
) => {
    const queryString = serializeSearchInput(searchState.searchOptions, searchConfig)
    if (!searchConfig.searchLocation) return
    return window.encodeURI(
        `${searchConfig.searchLocation}${isEmpty(queryString) ? '' : '?'}${queryString}`,
    )
}
