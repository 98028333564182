import { sortBy } from 'lodash/fp'
import { Component } from 'react'

import { Divider, FormControl, MenuItem, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { Dialog, SaveButton } from 'Common/core'

const NEW_VIEW_ID = 'OPUS::DEFAULT::NEW'

type Props = {
    saveView(..._args: unknown[]): unknown
    closeModal(): void
    savedViews: unknown[]
    viewName?: string
}

type State = {
    selectedView?: any
    newViewName?: any
    errorMessage?: string
}

class ViewSaving extends Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            selectedView: props.viewName || NEW_VIEW_ID,
            newViewName: '',
        }
    }

    onSaveView = () => {
        const { saveView } = this.props
        const { selectedView, newViewName } = this.state

        if (selectedView === NEW_VIEW_ID && !newViewName) {
            this.setState({
                errorMessage: 'You must give the view a name',
            })
            return
        }

        saveView(selectedView === NEW_VIEW_ID ? newViewName : selectedView)
    }

    setSelectedView = (event) =>
        this.setState({
            selectedView: event.target.value,
        })

    render() {
        const { savedViews, closeModal } = this.props
        const { selectedView, newViewName, errorMessage } = this.state
        return (
            <Dialog
                title="Create a new search view or update an already saved view."
                actions={[<SaveButton key="save" onClick={this.onSaveView} />]}
                onClose={closeModal}
            >
                <Grid xs={12}>
                    <FormControl variant="standard" fullWidth>
                        <TextField
                            variant="standard"
                            label="Select view"
                            select
                            onChange={this.setSelectedView}
                            value={selectedView}
                        >
                            <MenuItem value={NEW_VIEW_ID}>Create new</MenuItem>
                            <Divider />
                            {sortBy((view: any) => view.name, savedViews).map(
                                (view: any, index) => (
                                    <MenuItem key={index} value={view.name}>
                                        {view.name}
                                    </MenuItem>
                                ),
                            )}
                        </TextField>
                    </FormControl>
                </Grid>

                {selectedView === NEW_VIEW_ID ? (
                    <Grid xs={12}>
                        <FormControl variant="standard" fullWidth>
                            <TextField
                                variant="standard"
                                label="Name your view *"
                                onChange={(event) =>
                                    this.setState({
                                        newViewName: event.target.value,
                                        errorMessage: null,
                                    })
                                }
                                error={!!errorMessage}
                                value={newViewName}
                                helperText={errorMessage}
                            />
                        </FormControl>
                    </Grid>
                ) : null}
            </Dialog>
        )
    }
}

export default ViewSaving
