import oDataQueryBuilder, { QueryOptions } from 'odata-query'

export const standardOdataToAureSearchProps = (url: string): string =>
    url.replace('$top', 'limit').replace('$skip', 'offset').replace('$search', 'q')

export const buildQuery = ({
    oDataQuery,
    searchFields,
}: {
    searchFields?: string
    oDataQuery: QueryOptions<any>
}) => {
    return `${standardOdataToAureSearchProps(oDataQueryBuilder(oDataQuery))}${
        searchFields ? '&$searchFields=' + searchFields : ''
    }`
}
