import { Component } from 'react'

import Grid from '@mui/material/Unstable_Grid2'

import DisplayField from 'Common/components/displayFields/DisplayField'
import Section from 'Common/components/section/Section'
import SectionHeader from 'Common/components/section/SectionHeader'

import CustomPropertiesTypeList from './CustomPropertiesTypeList'
import MetaDataItemsList from './MetaDataItemsList'

type Props = {
    metaDataType: any
}

class MetaDataTypeOverview extends Component<Props> {
    state = {
        showEditModal: false,
    }

    render() {
        const { metaDataType } = this.props
        return (
            <Grid container spacing={2} xs={12}>
                <Grid xs={12}>
                    <SectionHeader title={metaDataType.name} />
                </Grid>
                <Section title="Details">
                    <Grid container spacing={2} xs={12}>
                        <Grid xs={12} sm={6}>
                            <DisplayField label="ID" value={metaDataType.id} />
                        </Grid>
                        <Grid xs={12} sm={6}>
                            <DisplayField label="ID type" value={metaDataType.idType} />
                        </Grid>
                        <Grid xs={12} sm={6}>
                            <DisplayField label="Name" value={metaDataType.name} />
                        </Grid>
                        <Grid xs={12} sm={6}>
                            <DisplayField
                                label="Use show external"
                                value={metaDataType.useShowExternal ? 'True' : 'False'}
                            />
                        </Grid>
                    </Grid>
                </Section>
                <Section title="Custom property types">
                    <CustomPropertiesTypeList
                        customPropertyTypes={metaDataType.customPropertyTypes}
                        typeId={metaDataType.id}
                    />
                </Section>
                <Section title="Items">
                    <MetaDataItemsList
                        typeId={metaDataType.id}
                        items={metaDataType.items}
                        customPropertyTypes={metaDataType.customPropertyTypes}
                    />
                </Section>
            </Grid>
        )
    }
}

export default MetaDataTypeOverview
