import Grid from '@mui/material/Unstable_Grid2'

import { Dialog } from 'Common/core'

const RightsDialog = () => (
    <Dialog title="Welcome to Mockingbird!">
        <Grid container spacing={2}>
            <p>
                Nice of you to swing by! In order to make sure everything works perfectly the first
                time you log in we will need to connect your access rights manually.
            </p>
            <p>Please contact your Mockingbird Support.</p>
            <p>Best regards, The Mockingbird Team</p>
        </Grid>
    </Dialog>
)

export default RightsDialog
