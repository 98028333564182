import { Component } from 'react'

import { FormControl, MenuItem, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { MetaData } from 'Common/metadata'

import FilterSelectionList from './FilterSelectionList'

type Props = {
    updateFilter(..._args: unknown[]): unknown
    filter: {
        id: string
        name: string
    }
    active: unknown[]
    metaData: MetaData
    chipColor?: string
}

class BooleanFilter extends Component<Props> {
    static defaultProps = {
        active: [],
    }

    onToggleOption = (option) => {
        const parsedOption = option === 'true' || option === true ? true : false
        const { filter, active, updateFilter } = this.props
        const updatedActive = active.includes(parsedOption)
            ? active.filter((a) => a !== parsedOption)
            : [...active, parsedOption]
        updateFilter(filter.id, updatedActive)
    }

    render() {
        const { filter, active, metaData, chipColor } = this.props
        return (
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <FormControl variant="standard" fullWidth>
                        <TextField
                            variant="standard"
                            value={active}
                            label={filter.name}
                            select
                            onChange={(event) => this.onToggleOption(event.target.value)}
                        >
                            <MenuItem value="">-</MenuItem>
                            <MenuItem value={'true'}>Yes</MenuItem>
                            <MenuItem value={'false'}>No</MenuItem>
                        </TextField>
                    </FormControl>
                </Grid>
                <Grid xs={12}>
                    <FilterSelectionList
                        onRemoveOption={this.onToggleOption}
                        {...{
                            filter,
                            active,
                            metaData,
                            chipColor,
                        }}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default BooleanFilter
