import { requestStatus } from 'Common/utils/net/statuses'

import { GET_PRODUCTION_REQUEST } from '../actions/productionActions'
import {
    GET_PRODUCTION_ESTIMATED_SALES_FAILURE,
    GET_PRODUCTION_ESTIMATED_SALES_REQUEST,
    GET_PRODUCTION_ESTIMATED_SALES_SUCCESS,
} from '../actions/productionEstimatedSalesActions'

const initialState = {
    status: {
        getStatus: undefined,
        getError: undefined,
    },
    data: undefined,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCTION_REQUEST:
            return { ...initialState }

        case GET_PRODUCTION_ESTIMATED_SALES_REQUEST:
            return {
                ...state,
                workId: action.payload.workId,
                productionId: action.payload.productionId,
                status: { ...state.status, getStatus: requestStatus.request },
            }

        case GET_PRODUCTION_ESTIMATED_SALES_SUCCESS:
            return {
                ...state,
                data: action.payload.json,
                status: { ...state.status, getStatus: requestStatus.success },
            }

        case GET_PRODUCTION_ESTIMATED_SALES_FAILURE:
            return {
                ...state,
                status: {
                    ...state.status,
                    getStatus: requestStatus.failure,
                    getError: action.payload,
                },
            }

        default:
            return state
    }
}
