import { get, isNil } from 'lodash/fp'

export type MetaDataKey = string
export type PublishingHouse = {
    id: string
    name: string
    isActive: boolean
    customProperties?: any
    publishingHouseGroupId: string
    publishingHouseGroupIds: string[]
    publishingHouses?: any
}

export type AssetType = {
    id: string
    name: string
    isActive: boolean
    customProperties?: any
    publishingHouseGroupId: string
    publishingHouseGroupIds: string[]
    publishingHouses?: any
}

export type Brand = {
    id: string
    name: string
    isActive: boolean
    customProperties?: any
    publishingHouseGroupId: string
    publishingHouseGroupIds: string[]
    publishingHouses?: any
}

export type MetaDataItem =
    | {
        id: string
        name: string
        isActive: boolean
        customProperties: any
        publishingHouseGroupId?: string
        publishingHouseGroupIds: string[]
        publishingHouses?: any
    }
    | PublishingHouse
export type MetaData = Record<MetaDataKey, MetaDataItem[]>

const filterSpecificMetaData = (metaData: MetaDataItem[], publishingHouseGroups: string[]) =>
    metaData
        .filter((metaDataItem) => {
            return (
                metaDataItem.publishingHouseGroupId &&
                publishingHouseGroups.includes(metaDataItem.publishingHouseGroupId)
            )
        })
        .filter(Boolean)

const filterSpecificHouseMetaData = (metaData: MetaDataItem[], publishingHouse: string) =>
    metaData
        .filter((metaDataItem) => {
            return (
                metaDataItem.customProperties &&
                metaDataItem.customProperties.publishingHouses &&
                metaDataItem.customProperties.publishingHouses.includes(publishingHouse)
            )
        })
        .filter(Boolean)

const filterGeneralMetaData = (metaData: MetaDataItem[]) =>
    metaData
        .filter((metaDataItem) => {
            return !metaDataItem.publishingHouseGroupId
        })
        .filter(Boolean)

const filterGeneralHouseMetaData = (metaData: MetaDataItem[]) =>
    metaData
        .filter((metaDataItem) => {
            return isNil(get('customProperties.publishingHouses', metaDataItem))
        })
        .filter(Boolean)

export const filterMetadataByPublishingGroup = (
    metaData: MetaData,
    publishingHouseGroup: string,
): MetaData =>
    Object.keys(metaData).reduce((acc, cur) => {
        const publishingHouseSpecificMetadata = filterSpecificMetaData(metaData[cur], [
            publishingHouseGroup,
        ])
        const generalMetadata = filterGeneralMetaData(metaData[cur])
        return {
            ...acc,
            [cur]:
                publishingHouseSpecificMetadata.length === 0
                    ? generalMetadata
                    : publishingHouseSpecificMetadata,
        }
    }, {})
export const filterMetadataByPublishingHouse = (metaData: MetaData, publishingHouse: string) =>
    Object.keys(metaData).reduce((acc, cur) => {
        const publishingHouseSpecificMetadata = filterSpecificHouseMetaData(
            metaData[cur],
            publishingHouse,
        )
        const generalMetadata = filterGeneralHouseMetaData(metaData[cur])
        return {
            ...acc,
            [cur]:
                publishingHouseSpecificMetadata.length === 0
                    ? generalMetadata
                    : publishingHouseSpecificMetadata,
        }
    }, {})
export const filterMetadataByPublishingGroups = (
    metaData: MetaData,
    publishingHouseGroups: string[],
) =>
    Object.keys(metaData).reduce((acc, cur) => {
        const publishingHouseSpecificMetadata = filterSpecificMetaData(
            metaData[cur],
            publishingHouseGroups,
        )
        const generalMetadata = filterGeneralMetaData(metaData[cur])
        return {
            ...acc,
            [cur]:
                publishingHouseSpecificMetadata.length === 0
                    ? generalMetadata
                    : publishingHouseSpecificMetadata,
        }
    }, {})
