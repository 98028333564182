// import { getOr } from 'lodash/fp'
import { Component } from 'react'

import { FormControl, MenuItem, TextField } from '@mui/material'
import { Chip } from '@mui/material'
import { lightGreen } from '@mui/material/colors'

type Props = {
    updateFilter(..._args: unknown[]): unknown
    filter: {
        id: string
        name: string
        displayFormat?(..._args: unknown[]): unknown
        type?: {
            displayFormat?(..._args: unknown[]): unknown
        }
        metaDataId: string
        options: unknown[]
    }
    active: unknown[]
    chipColor?: string
}

class OptionFilter extends Component<Props> {
    static defaultProps = {
        active: [],
        chipColor: lightGreen[300],
    }

    onToggleOption = (option) => {
        const { filter, active, updateFilter } = this.props

        if (option === 'DEFAULT-TOGGLE-ALL') {
            updateFilter(
                filter.id,
                filter.options.map((_?: any) => _.id),
            )
            return
        }

        if (option === 'DEFAULT-TOGGLE-NONE') {
            updateFilter(filter.id, [])
            return
        }

        const updatedActive = active.includes(option)
            ? active.filter((_) => _ !== option)
            : active.concat(option)
        updateFilter(filter.id, updatedActive)
    }

    render() {
        const { filter, active, chipColor } = this.props
        const { options } = filter
        const displayFormat = filter.displayFormat || filter.type.displayFormat
        const availableOptions = options
        return (
            <FormControl variant="standard" fullWidth>
                <TextField
                    variant="standard"
                    fullWidth
                    select
                    value={active || ''}
                    onChange={(event) => this.onToggleOption(event.target.value)}
                >
                    {availableOptions.map((element: any, index) => (
                        <MenuItem key={index} value={element.id}>
                            {element.name}
                        </MenuItem>
                    ))}
                </TextField>
                <div
                    style={{
                        paddingBottom: 0,
                        paddingTop: 10,
                    }}
                >
                    <div>
                        {active.map((activeOption, index) => (
                            <Chip
                                key={index}
                                onDelete={() => this.onToggleOption(activeOption)}
                                style={{
                                    backgroundColor: chipColor,
                                    margin: '5px 5px 0 0',
                                }}
                                label={displayFormat(activeOption, filter, availableOptions)}
                            />
                        ))}
                    </div>
                </div>
            </FormControl>
        )
    }
}

export default OptionFilter
