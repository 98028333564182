import { getOr, isEqual, isNil } from 'lodash/fp'
import { Component } from 'react'

import { FormControl, MenuItem, Select } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { AddButton } from 'Common/components/buttons'
import { MetaData } from 'Common/metadata'

import ContactPicker from '../../components/contacts/ContactPicker'
import commonMessages from '../../messages'
import { getContactNames } from '../../utils/contacts'
import FilterSelectionList from './FilterSelectionList'

type Props = {
    updateFilter(..._args: unknown[]): unknown
    filter: {
        id: string
        typeConfig?: {
            hideContact?: boolean
        }
    }
    active: unknown[]
    metaData: MetaData
    chipColor?: string
}

type State = {
    newOption?: any
}

class ContactFilter extends Component<Props, State> {
    static defaultProps = {
        active: [],
    }

    state = {
        newOption: {
            roleId: null,
            contactId: null,
        },
    }

    onAddOption = () => {
        const { filter, active, updateFilter } = this.props
        const { newOption } = this.state
        updateFilter(filter.id, active.concat(newOption))
    }

    onRemoveOption = (option) => {
        const { filter, active, updateFilter } = this.props
        const updatedActive = active.filter((activeOption) => !isEqual(activeOption, option))
        updateFilter(filter.id, updatedActive)
    }

    render() {
        const { filter, active, chipColor, metaData } = this.props
        const { newOption } = this.state
        const hideContact = getOr(false, 'typeConfig.hideContact', filter)
        return (
            <Grid container spacing={2}>
                {!hideContact ? (
                    <Grid xs={12} key="contact-picker">
                        <ContactPicker
                            searchId="productionSearchFilterContact"
                            onChange={(contact) =>
                                this.setState((state) => ({
                                    newOption: {
                                        ...state.newOption,
                                        contactId: contact.id,
                                        contactName: getContactNames(contact).fullName,
                                    },
                                }))
                            }
                        />
                    </Grid>
                ) : null}
                <Grid xs={12} key="roll-picker">
                    <FormControl variant="standard" fullWidth>
                        <Select
                            variant="standard"
                            label="Select role"
                            displayEmpty={true}
                            value={this.state.newOption.roleId || ''}
                            onChange={(event) =>
                                this.setState((state) => ({
                                    newOption: {
                                        ...state.newOption,
                                        roleId:
                                            event.target.value === 'DEFAULT-NONE'
                                                ? null
                                                : event.target.value,
                                    },
                                }))
                            }
                        >
                            <MenuItem value="DEFAULT-NONE">
                                <strong
                                    style={{
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    {commonMessages.action.remove}
                                </strong>
                            </MenuItem>
                            {metaData.contactRoles.map((contactRole: any, index) => (
                                <MenuItem key={index} value={contactRole.id}>
                                    {contactRole.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={12} key="button">
                    <AddButton
                        onClick={this.onAddOption}
                        disabled={isNil(newOption.contactId) && isNil(newOption.roleId)}
                    />
                </Grid>
                <FilterSelectionList
                    onRemoveOption={this.onRemoveOption}
                    {...{
                        filter,
                        active,
                        metaData,
                        chipColor,
                    }}
                />
            </Grid>
        )
    }
}

export default ContactFilter
