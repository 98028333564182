import { Route, RouteProps } from 'react-router-dom'

import { Authentication as AuthState } from 'Common/auth/authReducer'
import ModuleUnathorizedPage from 'Common/components/auth/ModuleUnathorizedPage'
import { hasClaim } from 'Common/utils/user/auth'

type Props = {
    component?: any
    restrictToClaims?: any[]
    auth?: AuthState
}

function PrivateRoute({ component, restrictToClaims = [], auth, ...rest }: Props & RouteProps) {
    const PrivateComponent = component
    const accessDenied =
        Array.isArray(restrictToClaims) &&
        restrictToClaims.length > 0 &&
        restrictToClaims.find((claim) => hasClaim(auth, claim)) === undefined
    return (
        <Route
            {...rest}
            render={(props) =>
                accessDenied ? <ModuleUnathorizedPage /> : <PrivateComponent {...props} />
            }
        />
    )
}

export default PrivateRoute
