import { getOr } from 'lodash/fp'

import request from '../utils/net/request'
import { serializeSearchOutput } from './searchOutputSerializers'

export const GET_SEARCH = 'GET_SEARCH'
export const GET_SEARCH_REQUEST = 'GET_SEARCH_REQUEST'
export const GET_SEARCH_SUCCESS = 'GET_SEARCH_SUCCESS'
export const GET_SEARCH_FAILURE = 'GET_SEARCH_FAILURE'
export const UPDATE_SEARCH_OPTIONS = 'UPDATE_SEARCH_OPTIONS'
export const CLEAR_SEARCH = 'CLEAR_SEARCH'
export const getSearch = (searchConfig) => {
    return (dispatch, getState) => {
        const limit = getOr(25, 'pagingSize', searchConfig)
        const searchOptions = getOr({}, `search[${searchConfig.id}].searchOptions`, getState())
        const searchQuery = serializeSearchOutput(searchOptions, searchConfig, limit)
        return dispatch({
            type: GET_SEARCH,
            meta: {
                searchConfig,
                startTime: Date.now(),
            },
            payload: {
                promise: request({
                    method: 'GET',
                    url: `${searchConfig.searchService()}?${searchQuery}`,
                }),
            },
        })
    }
}
export const updateSearchOptions = (searchConfig, searchOptions) => {
    return {
        type: UPDATE_SEARCH_OPTIONS,
        meta: {
            searchConfig,
            searchOptions,
        },
    }
}
export const clearSearch = (searchConfig) => {
    return {
        type: CLEAR_SEARCH,
        meta: {
            searchConfig,
        },
    }
}
