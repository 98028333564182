import { Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { Variant } from '@mui/material/styles/createTypography'

type Props = {
    noGrid?: boolean
    label?: string
    value?: string | number | Date | React.ReactNode
    required?: boolean
    variant?: Variant
    disabled?: boolean
}

const DisplayField = (props: Props) => {
    const { label, value, variant = 'body1', required = false, noGrid, disabled = false } = props
    return (
        <>
            {noGrid ? (
                <>
                    <Typography
                        variant="caption"
                        color={disabled ? 'text.disabled' : 'text.secondary'}
                        style={{ display: 'block' }}
                    >
                        {label} {required ? '*' : ''}
                    </Typography>
                    <Typography
                        sx={{ whiteSpace: 'pre-line' }}
                        variant={variant}
                        color={disabled ? 'text.disabled' : 'text.secondary'}
                    >
                        {value ?? '-'}
                    </Typography>
                </>
            ) : (
                <Grid xs={12}>
                    <Typography
                        variant="body2"
                        color={disabled ? 'text.disabled' : 'text.secondary'}
                        sx={{ marginBottom: 0.5 }}
                    >
                        {label} {required ? '*' : ''}
                    </Typography>
                    <Typography
                        sx={{ whiteSpace: 'pre-line' }}
                        variant={variant}
                        color={disabled ? 'text.disabled' : 'text.secondary'}
                    >
                        {value ?? '-'}
                    </Typography>
                </Grid>
            )}
        </>
    )
}

export default DisplayField
