import { postProductionsBatchExportRules as postProductionsBatchExportRulesAction } from 'Work/actions/productionExportRulesActions'
import { searchConfig as productionsSearchConfig } from 'Work/config/productionsConfig'
import messages from 'Work/messages'
import { getOr } from 'lodash/fp'
import { Component } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { InjectedFormProps, formValueSelector, reduxForm } from 'redux-form'

import { Button, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import Spinner from 'Common/components/loader/Spinner'
import InfoNotice from 'Common/components/notice/InfoNotice'
import { Dialog, Field, SaveButton } from 'Common/core'
import { Form } from 'Common/form'
import FormCheckbox from 'Common/form/FormCheckbox'
import FormSelectField from 'Common/form/FormSelectField'
import { notEmpty, quickValidation } from 'Common/form/formvalidation'
import {
    getSearch as getSearchAction,
    updateSearchOptions as updateSearchOptionsAction,
} from 'Common/search/searchActions'
import { RootState } from 'Common/types'
import { formSubmit } from 'Common/utils/net/submit'

import BatchErrorResult from './BatchErrorResult'

const FORM_NAME = 'batch_edit_prodution_export_rules'
const selector = formValueSelector(FORM_NAME)
type Props = {
    closeModal: () => void
    selectedEditions: []
    error: string
}
type State = {
    selectedContact: Record<string, unknown> | null
    selectedRole: Record<string, unknown> | null
    warningAccepted: boolean
    successUpdating: boolean
    productionWarnings: any[]
}

const mapState = (state: RootState) => {
    const { metaData, search } = state
    const productionsSearch = getOr([], productionsSearchConfig.id, search)
    const salesTypes = getOr([], 'salesTypes', metaData.data)
    const salesChannels = getOr([], 'salesChannels', metaData.data)
    const { itemsTotalCount } = productionsSearch.paging
    const selectedSalesType = selector(state, 'salesTypeId')
    const selectedHasRights = selector(state, 'hasRights')
    const initialValues = {
        hasRights: false,
        hasDistribution: false,
        isBlocked: false,
    }
    return {
        initialValues,
        salesTypes,
        salesChannels,
        itemsTotalCount,
        selectedSalesType,
        selectedHasRights,
    }
}

const form = reduxForm<any, any>({
    form: FORM_NAME,
    validate: quickValidation({
        salesTypeId: [notEmpty()],
        salesChannelId: [notEmpty()],
        hasRights: [notEmpty()],
        hasDistribution: [notEmpty()],
        isBlocked: [notEmpty()],
    }),
})
const connector = connect(mapState, {
    postProductionsBatchExportRules: postProductionsBatchExportRulesAction,
    getSearch: getSearchAction,
    updateSearchOptions: updateSearchOptionsAction,
})
type PropsFromRedux = ConnectedProps<typeof connector>

class BatchEditExportRules extends Component<Props & InjectedFormProps & PropsFromRedux, State> {
    state = {
        selectedContact: null,
        selectedRole: null,
        warningAccepted: false,
        successUpdating: false,
        productionWarnings: [],
    }

    acceptWarning = () =>
        this.setState({
            warningAccepted: true,
        })

    submit = (values) => {
        const { postProductionsBatchExportRules } = this.props
        return formSubmit(() => postProductionsBatchExportRules(productionsSearchConfig, values))
            .then(({ value }) => value.json)
            .then((result) => {
                const productionWarnings = result.filter((r) => !r.isSuccess)

                if (productionWarnings.length > 0) {
                    this.setState({
                        productionWarnings,
                    })
                } else {
                    this.setState({
                        successUpdating: true,
                    })
                }
            })
    }

    render() {
        const {
            closeModal,
            getSearch,
            error,
            handleSubmit,
            submitting,
            valid,
            submitSucceeded,
            salesTypes,
            salesChannels,
            selectedSalesType,
            selectedHasRights,
            itemsTotalCount,
            updateSearchOptions,
        } = this.props
        const { productionWarnings, warningAccepted, successUpdating } = this.state

        const closeAndSearchAction = () => {
            getSearch(productionsSearchConfig)
            closeModal()
        }

        const selectedCloseAction = () => {
            submitSucceeded ? closeAndSearchAction() : closeModal()
        }

        const doSubmit = handleSubmit(this.submit)
        const actions = [
            <Button
                variant="outlined"
                key="cancel"
                onClick={selectedCloseAction}
                color="secondary"
                disabled={submitting}
            >
                {submitSucceeded ? messages.common.action.close : messages.common.action.cancel}
            </Button>,
            <SaveButton
                key="save"
                onClick={doSubmit}
                submitting={submitting}
                disabled={!valid || submitting || submitSucceeded || !warningAccepted}
            />,
        ]
        const title = (
            <Typography>
                {itemsTotalCount > 100 ? (
                    <span style={{ color: 'red' }}>
                        {' '}
                        WARNING - {itemsTotalCount} editions affected
                    </span>
                ) : null}{' '}
                Edit export rules
            </Typography>
        )
        return (
            <Dialog
                {...{
                    actions,
                    title,
                }}
            >
                <Form onSubmit={doSubmit}>
                    {!warningAccepted ? (
                        <InfoNotice
                            title="Are you sure you want to proceed with a batch operation?"
                            content={
                                <div>
                                    <Typography variant="body2">
                                        {`You are about to perform a batch operation that will affect all entities in your search result (${itemsTotalCount} editions). The operation is irreversible.`}
                                    </Typography>
                                    <Button
                                        style={{
                                            float: 'left',
                                            marginTop: 20,
                                        }}
                                        color="primary"
                                        variant="outlined"
                                        key="accept-warning"
                                        onClick={this.acceptWarning}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            }
                        />
                    ) : (
                        !submitting &&
                        warningAccepted &&
                        !successUpdating &&
                        (productionWarnings.length === 0 ? (
                            <>
                                <Field
                                    name="salesTypeId"
                                    component={FormSelectField}
                                    label="Sales type"
                                    metaData={salesTypes}
                                />
                                {selectedSalesType ? (
                                    <>
                                        <Field
                                            name="salesChannelId"
                                            component={FormSelectField}
                                            label="Sales channel"
                                            metaData={salesChannels.filter((salesChannel) =>
                                                salesChannel.customProperties.salesTypes.includes(
                                                    selectedSalesType,
                                                ),
                                            )}
                                        />
                                        <Grid xs={6}>
                                            <Field
                                                name="hasRights"
                                                component={FormCheckbox}
                                                label="Rights"
                                            />
                                        </Grid>
                                        <Grid xs={6}>
                                            <Field
                                                disabled={!selectedHasRights}
                                                name="hasDistribution"
                                                component={FormCheckbox}
                                                label="Distribution"
                                            />
                                        </Grid>
                                        <Grid xs={6}>
                                            <Field
                                                disabled={!selectedHasRights}
                                                name="isBlocked"
                                                component={FormCheckbox}
                                                label="Blocked"
                                            />
                                        </Grid>
                                    </>
                                ) : null}
                            </>
                        ) : null)
                    )}
                    {productionWarnings.length > 0 ? (
                        <BatchErrorResult
                            {...{
                                productionWarnings,
                                updateSearchOptions,
                            }}
                        />
                    ) : null}
                    {!submitting && successUpdating ? (
                        <Typography variant="body2">Success updating editions.</Typography>
                    ) : null}
                    {submitting ? (
                        <Grid xs={12}>
                            <div
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                <Spinner />
                                <span>Saving... </span>
                            </div>
                        </Grid>
                    ) : null}
                    {error ? (
                        <Grid xs={12}>
                            <div
                                style={{
                                    textAlign: 'center',
                                    color: '#f44336',
                                }}
                            >
                                {error}
                            </div>
                        </Grid>
                    ) : null}
                </Form>
            </Dialog>
        )
    }
}

export default connector(form(BatchEditExportRules))
