import { getOr } from 'lodash/fp'

import { requestStatus } from 'Common/utils/net/statuses'

import { GET_PRODUCTION_REQUEST } from '../actions/productionActions'
import {
    GET_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_FAILURE,
    GET_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_REQUEST,
    GET_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_SUCCESS,
    POST_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_FAILURE,
    POST_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_REQUEST,
    POST_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_SUCCESS,
    PUT_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_FAILURE,
    PUT_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_REQUEST,
    PUT_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_SUCCESS,
} from '../actions/productionPrintDeliveryspecificationsActions'

const initialState = {
    status: {
        getStatus: undefined,
        getError: undefined,
    },
}
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCTION_REQUEST:
            return { ...initialState }

        case GET_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_REQUEST:
            return {
                ...state,
                [action.meta.printNumber]: {
                    workId: action.meta.workId,
                    productionId: action.meta.productionId,
                    printNumber: action.meta.printNumber,
                    data: null,
                    status: {
                        ...getOr(null, `${action.meta.printNumber}.status`, state),
                        getStatus: requestStatus.request,
                        getError: null,
                    },
                },
            }

        case GET_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_SUCCESS:
            return {
                ...state,
                [action.meta.printNumber]: {
                    data: action.payload.json,
                    status: {
                        ...getOr(null, `${action.meta.printNumber}.status`, state),
                        getStatus: requestStatus.success,
                    },
                },
            }

        case GET_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_FAILURE:
            return {
                ...state,
                [action.meta.printNumber]: {
                    status: {
                        ...getOr(null, `${action.meta.printNumber}.status`, state),
                        getStatus: requestStatus.failure,
                        getError: action.payload,
                    },
                },
            }

        case POST_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_REQUEST:
        case PUT_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_REQUEST:
            return {
                ...state,
                workId: action.meta.workId,
                productionId: action.meta.productionId,
                [action.meta.printNumber]: {
                    ...state[action.meta.printNumber],
                    status: {
                        ...getOr(null, `${action.meta.printNumber}.status`, state),
                        getStatus: requestStatus.request,
                        getError: null,
                    },
                },
            }

        case POST_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_SUCCESS:
        case PUT_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_SUCCESS:
            return {
                ...state,
                [action.meta.printNumber]: {
                    data: action.payload.json,
                    status: {
                        ...getOr(null, `${action.meta.printNumber}.status`, state),
                        getStatus: requestStatus.success,
                    },
                },
            }

        case POST_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_FAILURE:
        case PUT_PRODUCTION_PRINT_DELIVERY_SPECIFICATION_FAILURE:
            return {
                ...state,
                [action.meta.printNumber]: {
                    ...state[action.meta.printNumber],
                    status: {
                        ...getOr(null, `${action.meta.printNumber}.status`, state),
                        getStatus: requestStatus.failure,
                        getError: action.payload,
                    },
                },
            }

        default:
            return state
    }
}
