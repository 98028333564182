import { requestStatus } from 'Common/utils/net/statuses'

import {
    DELETE_ACTIVITY_FAILURE,
    DELETE_ACTIVITY_FROM_WORK_FAILURE,
    DELETE_ACTIVITY_FROM_WORK_REQUEST,
    DELETE_ACTIVITY_FROM_WORK_SUCCESS,
    DELETE_ACTIVITY_REQUEST,
    DELETE_ACTIVITY_SUCCESS,
    GET_ACTIVITY_FAILURE,
    GET_ACTIVITY_REQUEST,
    GET_ACTIVITY_SUCCESS,
    PATCH_ACTIVITY_FAILURE,
    PATCH_ACTIVITY_REQUEST,
    PATCH_ACTIVITY_SUCCESS,
    POST_ACTIVITY_FAILURE,
    POST_ACTIVITY_REQUEST,
    POST_ACTIVITY_SUCCESS,
    POST_ACTIVITY_TO_WORK_FAILURE,
    POST_ACTIVITY_TO_WORK_REQUEST,
    POST_ACTIVITY_TO_WORK_SUCCESS,
} from '../actions/activityActions'

export type State = any
const initialState = {
    status: {},
}
export default (state: State = initialState, action: any) => {
    switch (action.type) {
        case GET_ACTIVITY_REQUEST:
            return {
                ...state,
                data: null,
                status: { ...state.status, getStatus: requestStatus.request, getError: null },
            }

        case GET_ACTIVITY_SUCCESS:
            return {
                ...state,
                data: action.payload.json,
                status: { ...state.status, getStatus: requestStatus.success },
            }

        case GET_ACTIVITY_FAILURE:
            return {
                ...state,
                status: {
                    ...state.status,
                    getStatus: requestStatus.failure,
                    getError: action.payload,
                },
            }

        case POST_ACTIVITY_REQUEST:
            return {
                ...state,
                status: { ...state.status, postStatus: requestStatus.request, postError: null },
            }

        case POST_ACTIVITY_SUCCESS:
            return {
                ...state,
                data: action.payload.json,
                status: { ...state.status, postStatus: requestStatus.success, postError: null },
            }

        case POST_ACTIVITY_FAILURE:
            return {
                ...state,
                status: {
                    ...state.status,
                    postStatus: requestStatus.failure,
                    postError: action.payload,
                },
            }

        case PATCH_ACTIVITY_REQUEST:
            return {
                ...state,
                status: { ...state.status, patchStatus: requestStatus.request, patchError: null },
            }

        case PATCH_ACTIVITY_SUCCESS:
            return {
                ...state,
                data: action.payload.json,
                status: { ...state.status, patchStatus: requestStatus.success, patchError: null },
            }

        case PATCH_ACTIVITY_FAILURE:
            return {
                ...state,
                status: {
                    ...state.status,
                    patchStatus: requestStatus.failure,
                    patchError: action.payload,
                },
            }

        case DELETE_ACTIVITY_REQUEST:
            return {
                ...state,
                status: { ...state.status, deleteStatus: requestStatus.request, deleteError: null },
            }

        case DELETE_ACTIVITY_SUCCESS:
            return {
                ...state,
                data: null,
                status: { ...state.status, deleteStatus: requestStatus.success, deleteError: null },
            }

        case DELETE_ACTIVITY_FAILURE:
            return {
                ...state,
                status: {
                    ...state.status,
                    deleteStatus: requestStatus.failure,
                    deleteError: action.payload,
                },
            }

        case POST_ACTIVITY_TO_WORK_REQUEST:
            return {
                ...state,
                status: {
                    ...state.status,
                    postToWorkStatus: requestStatus.request,
                    postToWorkError: null,
                },
            }

        case POST_ACTIVITY_TO_WORK_SUCCESS:
            return {
                ...state,
                data: action.payload.json,
                status: { ...state.status, postToWorkStatus: requestStatus.success },
            }

        case POST_ACTIVITY_TO_WORK_FAILURE:
            return {
                ...state,
                status: {
                    ...state.status,
                    postToWorkStatus: requestStatus.failure,
                    postToWorkError: action.payload,
                },
            }

        case DELETE_ACTIVITY_FROM_WORK_REQUEST:
            return {
                ...state,
                status: {
                    ...state.status,
                    deleteFromWorkStatus: requestStatus.request,
                    deleteFromWorkError: null,
                },
            }

        case DELETE_ACTIVITY_FROM_WORK_SUCCESS:
            return {
                ...state,
                data: action.payload.json,
                status: { ...state.status, deleteFromWorkStatus: requestStatus.success },
            }

        case DELETE_ACTIVITY_FROM_WORK_FAILURE:
            return {
                ...state,
                status: {
                    ...state.status,
                    deleteFromWorkStatus: requestStatus.failure,
                    deleteFromWorkError: action.payload,
                },
            }

        default:
            return state
    }
}
