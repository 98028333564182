export default {
    CONTACT_NOT_FOUND: 'Contact not found.',
    CONTACT_FORBIDDEN: 'You do not have access rights for this contact.',
    CONTACT_ZIP_CODE_INVALID:
        'One or more addresses are missing valid postal numbers. Update the addresses and try again.',
    CONTACT_ERP_SUPPLIER_NOT_FOUND: 'The specified supplier ID could not be found.',
    CONTACT_ERP_CUSTOMER_ALREADY_CONNECTED: 'The customer ID is already in use in another contact.',
    CONTACT_ERP_CUSTOMER_NOT_FOUND: 'The specified customer ID could not be found.',
    CONTACT_ERP_CUSTOMER_GROUP_NOT_FOUND: 'The specified customer group could not be found.',
    CONTACT_ERP_CUSTOMER_SUB_GROUP_NOT_FOUND: 'The specified customer subgroup could not be found.',
    MAXIMUM_NUMBER_OF_ASSET_TYPE_FOR_CONTACT: 'You cannot connect more assets of this type.',
    CONTACT_ASSET_NOT_CONNECTED: 'The asset is not connected to this contact.',
    CONTACT_ASSET_ALREADY_CONNECTED: 'The asset is already connected to this contact.',
    ASSET_NOT_FOUND: 'The asset was not found.',
    ASSET_TYPE_DOES_NOT_EXIST: 'The specified asset type does not exist.',
    LIST_NOT_FOUND: 'The specified list could not be found.',
    LIST_IS_NOT_SHARED_AND_USER_IS_NOT_OWNER: 'Only the owner can change non-shared lists.',
    LIST_ONLY_OWNER_CAN_DELETE: 'Only the owner can remove a list.',
    LIST_CONTACT_DOES_NOT_EXIST_IN_LIST: 'The contact does not exist in this list.',
    USER_NOT_FOUND: 'Your user was not found. Contact support.',
    USER_MISSING_AD_USERNAME: 'Your user is not connected to AD. Contact support.',
}
