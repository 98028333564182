import { getOr, uniqBy } from 'lodash/fp'

import { IdNameObject } from 'Common/types'

export type RoleId = string
export type RoleKey = string
export type Role = {
    id: RoleId
    name: string
    isActive?: boolean
    showExternal?: boolean
    customProperties?: {
        isCreator?: boolean
        isPrinter?: boolean
        isAuthor?: boolean
    }
}
export type MemberContact = {
    firstName: string
    id: number
    imageAssetId: number
    lastName: string
    schillingLevNo: string
    isUser: boolean
}
export type AccessLevelKey = string
export type AccessLevel = {
    id: AccessLevelKey
}
export type SearchContact = {
    id: string
    firstName: string
    lastName: string
    schillingLevNo: string
    hasAuthorPhotographDownload: boolean
    hasAuthorPhotographStandard: boolean
    hasContactPresentation: boolean
    isCompanyContact: boolean
    isSecret: boolean
    isUser: boolean
    productionByRole: []
    productionIds: []
    productionRoles: []
}
export type Member = {
    id: number
    sortOrder?: number
    showExternal?: false
    accessLevel: AccessLevel
    contact: MemberContact
    role: Role
}

export type MemberContract = {
    contact: MemberContact
    id: number
    prioLevel: IdNameObject
    numberOfFreeCopies: number
    sortOrder?: number
    showExternal?: false
    role: Role
}

export const filterMemberByCreator = (member) =>
    getOr(false, 'role.customProperties.isCreator', member)
export const filterMemberByAuthor = (member) =>
    getOr(false, 'role.customProperties.isAuthor', member)
export const filterMemberByProductionLeader = (member) =>
    getOr(false, 'role.customProperties.isProductionLeader', member)
export const filterMemberByEditor = (member) =>
    getOr(false, 'role.customProperties.isEditor', member)
export const filterMemberByDesigner = (member) =>
    getOr(false, 'role.customProperties.isDesigner', member)
export const filterMemberByTranslator = (member) =>
    getOr(false, 'role.customProperties.isTranslator', member)
export const filterMemberByReciter = (member) =>
    getOr(false, 'role.customProperties.isReciter', member)
export const filterMemberByIllustrator = (member) =>
    getOr(false, 'role.customProperties.isIllustrator', member)
export const filterMemberByPhotographer = (member) =>
    getOr(false, 'role.customProperties.isPhotographer', member)
export const filterMemberByCoverDesigner = (member) =>
    getOr(false, 'role.customProperties.isCoverDesigner', member)
export const filterMemberByPublisher = (member) =>
    getOr(false, 'role.customProperties.isPublisher', member)
export const filterMemberByMediaProjectLead = (member) =>
    getOr(false, 'role.customProperties.isMediaProjectLead', member)
export const filterMemberByPrinter = (member) =>
    getOr(false, 'role.customProperties.isPrinter', member)
export const filterMemberByCommunicationStrategist = (member) =>
    getOr(false, 'role.customProperties.isCommunicationStrategist', member)
export const getContactAuthorRoleIds = (contactRoles: Role[]) =>
    contactRoles
        .filter((cr) => cr.customProperties && cr.customProperties.isAuthor)
        .map((authorRole) => authorRole.id)
export const getPrimaryRoleIds = (contactRoles: Role[]) =>
    contactRoles
        .filter((cr: any) => cr.customProperties && cr.customProperties.isPrimaryRole)
        .map((role) => role.id)
export const filterMembersByRoleId = (roleId: RoleId, members: Member[] = []): Member[] =>
    members.filter((member) => member.role.id === roleId)

export const hasMemberWithRole = (roleId: RoleId, members: Member[] = []): boolean =>
    members.some((member) => member.role.id === roleId)

export const searchRoles = (roleIds: RoleId[], members: Member[] = []): RoleId[] =>
    roleIds.filter((role) => hasMemberWithRole(role, members))

export const searchMembersByRoleId = (roleIds: RoleId[], members: Member[] = []): Member[] =>
    members.filter((member) => roleIds.includes(member.role.id))

export const getMembersColumns = (category, cellType) => (roles) => {
    const authorRole = roles.find((role) => getOr(false, 'customProperties.isAuthor', role))
    return roles.reduce((acc, role) => {
        return {
            ...acc,
            [`membersByRole_${role.id}`]: {
                id: `membersByRole_${role.id}`,
                selectField: `membersByRole_${role.id}`,
                label: role.name,
                width: 150,
                sortField: authorRole && role.id === authorRole.id ? 'authors' : null,
                sortable: authorRole && role.id === authorRole.id,
                category,
                cellType,
                cellConfig: {
                    dataField: 'membersByRole',
                    roleId: role.id,
                    lastNameFirst: true,
                    transform: (members) => uniqBy('id', members),
                },
            },
        }
    }, {})
}
