import { requestStatus } from 'Common/utils/net/statuses'

import {
    GET_PRODUCTION_REQUEST,
    POST_PRODUCTION_NEWPRINT_SUCCESS,
} from '../actions/productionActions'
import {
    GET_PRODUCTION_PRINT_CHANGES_FAILURE,
    GET_PRODUCTION_PRINT_CHANGES_REQUEST,
    GET_PRODUCTION_PRINT_CHANGES_SUCCESS,
} from '../actions/productionPrintChangesActions'

const initialState = {
    status: {
        getStatus: undefined,
        getError: undefined,
    },
    data: {},
}
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCTION_REQUEST:
        case POST_PRODUCTION_NEWPRINT_SUCCESS:
            return { ...initialState }

        case GET_PRODUCTION_PRINT_CHANGES_REQUEST:
            return {
                ...state,
                workId: action.meta.workId,
                productionId: action.meta.productionId,
                data: null,
                status: { ...state.status, getStatus: requestStatus.request, getError: null },
            }

        case GET_PRODUCTION_PRINT_CHANGES_SUCCESS:
            return {
                ...state,
                data: action.payload.json,
                status: { ...state.status, getStatus: requestStatus.success },
            }

        case GET_PRODUCTION_PRINT_CHANGES_FAILURE:
            return {
                ...state,
                status: {
                    ...state.status,
                    getStatus: requestStatus.failure,
                    getError: action.payload,
                },
            }

        default:
            return state
    }
}
