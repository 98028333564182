import { getOr } from 'lodash/fp'
import { Component } from 'react'

import IconEdit from '@mui/icons-material/Edit'
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { styled } from '@mui/material/styles'

import SortableTableHeader from 'Common/components/table/SortableTableHeader'
import { getDefaultState, getSortedList, updateSorting } from 'Common/components/table/tableHelpers'
import { AddButton } from 'Common/core'

import AddCustomPropertyItemModal from './AddCustomPropertyItemModal'
import EditCustomPropertyItemModal from './EditCustomPropertyItemModal'

const PREFIX = 'CustomPropertiesItemsList'

const classes = {
    root: `${PREFIX}-root`,
}

const StyledGrid = styled(Grid)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}))

const StyledTableRow = TableRow

const getCustomPropertyTypeName = (typeId, customPropertyTypes) =>
    getOr(
        '-',
        'name',
        customPropertyTypes.find((_) => _.id === typeId),
    )

const getNameSorter =
    (customPropertyTypes) =>
    (item: any = {}) =>
        getCustomPropertyTypeName(item.customPropertyTypeId, customPropertyTypes)

type Props = {
    typeId: number
    metadataItemId: number
    items?: unknown[]
    customPropertyTypes?: unknown[]
}

type State = {
    showAddModal: boolean
    showEditModal: boolean
    editItem: any
    sorting: any
}

class CustomPropertiesItemsList extends Component<Props, State> {
    static defaultProps = {
        items: [],
        customPropertyTypes: [],
    }

    updateSorting: any

    nameSorter: (item?: any) => any

    constructor(props: Props) {
        super(props)
        this.updateSorting = updateSorting.bind(this)
        this.nameSorter = getNameSorter(props.customPropertyTypes)
        this.state = {
            ...getDefaultState(),
            showAddModal: false,
            showEditModal: false,
            editItem: null,
        }
    }

    toggleAddModal = () =>
        this.setState((state) => ({
            showAddModal: !state.showAddModal,
        }))

    toggleEditModal = (item = null) =>
        this.setState({
            showEditModal: item !== null,
            editItem: item,
        })

    render() {
        const { items, customPropertyTypes, typeId, metadataItemId } = this.props

        if (customPropertyTypes.length === 0) {
            return null
        }

        const { sorting, showAddModal, showEditModal, editItem } = this.state
        const sortedItems = getSortedList(items, sorting)
        return (
            <StyledGrid container spacing={2}>
                {showAddModal ? (
                    <AddCustomPropertyItemModal
                        onCloseModal={this.toggleAddModal}
                        {...{
                            metadataItemId,
                            typeId,
                            customPropertyTypes,
                        }}
                    />
                ) : null}
                {showEditModal && editItem ? (
                    <EditCustomPropertyItemModal
                        item={editItem}
                        onCloseModal={this.toggleEditModal}
                        {...{
                            metadataItemId,
                            typeId,
                            customPropertyTypes,
                        }}
                    />
                ) : null}
                <Grid xs={12} container spacing={2} justifyContent="space-around">
                    <Grid xs={12}>
                        <Typography variant="h6">Custom properties</Typography>
                    </Grid>
                </Grid>
                <Grid xs={12}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <SortableTableHeader
                                        label="Id"
                                        field="id"
                                        sorting={sorting}
                                        updateSorting={this.updateSorting}
                                    />
                                </TableCell>
                                <TableCell>
                                    <SortableTableHeader
                                        label="Name"
                                        field={this.nameSorter}
                                        sorting={sorting}
                                        updateSorting={this.updateSorting}
                                    />
                                </TableCell>

                                <TableCell>
                                    <SortableTableHeader
                                        label="Type ID"
                                        field="customPropertyTypeId"
                                        sorting={sorting}
                                        updateSorting={this.updateSorting}
                                    />
                                </TableCell>

                                <TableCell>
                                    <SortableTableHeader
                                        label="Value"
                                        field="value"
                                        sorting={sorting}
                                        updateSorting={this.updateSorting}
                                    />
                                </TableCell>

                                <TableCell>
                                    <SortableTableHeader
                                        label="Sort order"
                                        field="sortOrder"
                                        sorting={sorting}
                                        updateSorting={this.updateSorting}
                                    />
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedItems.map((item) => (
                                <StyledTableRow
                                    key={item.id}
                                    classes={{
                                        root: classes.root,
                                    }}
                                >
                                    <TableCell
                                        className={'linkPrimary'}
                                        onClick={() => this.toggleEditModal(item)}
                                    >
                                        {item.id}
                                    </TableCell>
                                    <TableCell
                                        className={'linkPrimary'}
                                        onClick={() => this.toggleEditModal(item)}
                                    >
                                        {getCustomPropertyTypeName(
                                            item.customPropertyTypeId,
                                            customPropertyTypes,
                                        )}
                                    </TableCell>
                                    <TableCell>{item.customPropertyTypeId}</TableCell>
                                    <TableCell>{item.value}</TableCell>
                                    <TableCell>{item.sortOrder}</TableCell>
                                    <TableCell align="right">
                                        <IconButton
                                            size="small"
                                            color="primary"
                                            onClick={() => this.toggleEditModal(item)}
                                        >
                                            <IconEdit />
                                        </IconButton>
                                    </TableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
                <Grid xs={12}>
                    {items.length === 0 ? <Typography>No items added.</Typography> : null}
                </Grid>
                <Grid xs={12} container spacing={2} justifyContent="space-around">
                    <Grid xs={4}>
                        <AddButton fullWidth onClick={this.toggleAddModal}>
                            Add custom property
                        </AddButton>
                    </Grid>
                </Grid>
            </StyledGrid>
        )
    }
}

export default CustomPropertiesItemsList
