import { DateTime } from 'luxon'
import { Component } from 'react'
import { NavLink } from 'react-router-dom'

import { styled } from '@mui/material'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Unstable_Grid2'

import { getMimeTypeIcon } from 'Common/utils/files'

const HiddenDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    [theme.breakpoints.down('md')]: {
        display: 'none',
    },
}))

type Props = {
    result: any
}

class AssetViewItem extends Component<Props> {
    render() {
        const { result } = this.props
        return (
            <Grid xs={12}>
                <Grid container spacing={2} xs={12} sx={{ borderTop: '1px solid #e0e0e0' }}>
                    <Grid xs={2} sm={1} sx={{ display: { xs: 'none', md: 'flex' } }}>
                        {getMimeTypeIcon(result.mimeType)}
                    </Grid>
                    <Grid xs={8} sm={4}>
                        {result.canRead ? (
                            <Link
                                underline="none"
                                component={NavLink}
                                to={`/assets/${result.id}`}
                                style={{
                                    fontWeight: 'bold',
                                }}
                            >
                                {result.assetTypeName}
                            </Link>
                        ) : null}
                        {!result.canRead ? <div>{result.assetTypeName}</div> : null}
                        <HiddenDiv>{result.description}</HiddenDiv>
                    </Grid>
                    <Grid xs={8} sm={3}>
                        {result.dateUploaded
                            ? DateTime.fromISO(result.dateUploaded).toISODate()
                            : '-'}
                    </Grid>
                    <Grid xs={8} sm={4}>
                        {result.publishingHouseName ? result.publishingHouseName : '-'}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default AssetViewItem
