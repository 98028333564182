import { getOr, isEmpty, isString } from 'lodash/fp'
import { Component } from 'react'

import { TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import ContactPickerDialog from '../components/contacts/ContactPickerDialog'
import { getContactNames } from '../utils/contacts'
import FormFieldInfo from './fieldinfo/FormFieldInfo'
import { getFieldInfo, getFormInfoStyleWidth, getLabelText } from './formFieldHelpers'

const defaultStyle = {
    display: 'inline-block',
    width: '100%',
}

const getErrorText = (touched, error) => {
    return touched && error ? error : null
}

type Props = {
    input: any
    style: any
    meta: any
    searchId: string
    label: string
    required?: boolean
    id?: string
    disabled?: boolean
    infoText?: string
    propertyRules?: Record<string, unknown>
    showMetaDataAdmin?: boolean
    searchFilters?: unknown[]
    searchOptions?: Record<string, unknown>
    contactLabel?: string
    title?: string
}

export class FormContactPicker extends Component<Props> {
    static defaultProps = {
        disabled: false,
        propertyRules: {},
        contactLabel: 'contact',
        required: false,
        showMetaDataAdmin: false,
    }

    state = {
        showDialog: false,
    }

    onChangeContact = (contact = {}) => {
        this.props.input.onChange(isEmpty(contact) ? null : contact)
        this.closeDialog()
    }

    openDialog = () => {
        this.setState({
            showDialog: true,
        })
    }

    closeDialog = () => {
        this.setState({
            showDialog: false,
        })
    }

    render() {
        const {
            input,
            meta,
            id,
            infoText,
            propertyRules,
            searchId,
            searchFilters,
            searchOptions,
            contactLabel,
            showMetaDataAdmin,
            title,
            ...otherProps
        } = this.props
        const { showDialog } = this.state
        const { required, label, style, disabled } = otherProps
        const { name } = input
        const mergedStyle = { ...defaultStyle, ...style }
        const fieldInfo = getFieldInfo(propertyRules, infoText)
        const isDisabled = disabled || fieldInfo.isReadOnly
        const { error, touched } = meta
        const infoButtonStyle = {
            width: getFormInfoStyleWidth([fieldInfo.showInfoButton], mergedStyle.width),
        }
        const nestedProps = {
            ...otherProps,
            value: getContactNames(getOr({}, 'value', input)).fullName,
            required,
            label: getLabelText(label, propertyRules),
            helperText: getErrorText(touched, error),
            error: !!getErrorText(touched, error),
            style: { ...mergedStyle, ...infoButtonStyle },
            disabled: isDisabled,
        }
        const inputValue = getOr({}, 'value', input)
        const currentContact = isString(inputValue) ? {} : inputValue
        return (
            <Grid spacing={2} justifyContent="space-between">
                <TextField
                    variant="standard"
                    fullWidth
                    id={
                        id ||
                        `FormContactPickerTextField-${name}-${label}`.replace(/[^A-Za-z0-9-]/gi, '')
                    }
                    {...nestedProps}
                    onClick={this.openDialog}
                />
                <FormFieldInfo
                    {...{
                        fieldInfo,
                        propertyRules,
                        infoText,
                    }}
                />
                {showDialog ? (
                    <ContactPickerDialog
                        onClose={this.closeDialog}
                        onChange={this.onChangeContact}
                        {...{
                            currentContact,
                            searchId,
                            searchFilters,
                            searchOptions,
                            contactLabel,
                            title,
                        }}
                    />
                ) : null}
            </Grid>
        )
    }
}
export default FormContactPicker
