import { MetaDataItem } from '../metadata'
import { Claim, ClaimType, ClaimValue } from '../types'

export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGOUT_USER = 'LOGOUT_USER'
export const REFRESHED_TOKEN = 'REFRESHED_TOKEN'
export const REFRESHING_TOKEN = 'REFRESHING_TOKEN'
export const SET_PUBLISHING_HOUSE_SUCCESS = 'SET_PUBLISHING_HOUSE_SUCCESS'
export const SET_PUBLISHING_HOUSE_FAILURE = 'SET_PUBLISHING_HOUSE_FAILURE'
export type ActionType =
    | 'LOGIN_USER'
    | 'LOGIN_USER_FAILURE'
    | 'LOGIN_USER_SUCCESS'
    | 'LOGOUT_USER'
    | 'REFRESHED_TOKEN'
    | 'REFRESHING_TOKEN'
    | 'SET_PUBLISHING_HOUSE_SUCCESS'
    | 'SET_PUBLISHING_HOUSE_FAILURE'
export type AuthData = {
    amr: string[]
    auth_time: number
    family_name: string
    given_name: string
    idp: string
    name: string
    opus_contact_id: any[]
    opus_user: {
        brands?: MetaDataItem[]
        access?: Record<ClaimType, Record<string, ClaimValue[]>>
        id: string
        firstName?: string
        lastName?: string
        email?: string
        claims?: Claim[]
        publishingHouseGroups?: MetaDataItem[]
    }
    opus_user_id: string
    publishinghousegroup_id: string
    sid: string
    sub: string
    token: string
}
export type Action = {
    type: ActionType
    authPayload: AuthData
}
export const login = () => {
    return {
        type: LOGIN_USER,
    }
}
export const loginSuccess = (data: AuthData) => {
    return {
        type: LOGIN_USER_SUCCESS,
        authPayload: data,
    }
}
export const loginFailure = () => {
    return {
        type: LOGIN_USER_FAILURE,
    }
}
export const logout = () => {
    return {
        type: LOGOUT_USER,
    }
}
