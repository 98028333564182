import { Cell } from 'fixed-data-table-2'
import { getOr } from 'lodash/fp'
import { PureComponent } from 'react'

import Contact from 'Common/components/contacts/Contact'

type Props = {
    column: {
        cellConfig: {
            dataField: string
            roleId: string
            lastNameFirst?: boolean
        }
    }
    resultStatus: string
    result?: Record<string, unknown>
    viewProps?: Record<string, unknown>
}

class MemberCell extends PureComponent<Props> {
    getMembers() {
        const { column, result } = this.props
        const {
            cellConfig: { dataField, roleId, lastNameFirst },
        } = column
        const members = getOr([], `document.${dataField}.${roleId}`, result)
        const transform = getOr((_) => _, 'cellConfig.transform', column)
        const transformedMembers = transform(members)
        return transformedMembers.length > 0 ? (
            transformedMembers.map((member, index) => (
                <span
                    key={member.id}
                    style={{
                        marginRight: 5,
                    }}
                >
                    <Contact {...member.contact} lastNameFirst={lastNameFirst} />
                    {index + 1 !== transformedMembers.length && ' /'}
                </span>
            ))
        ) : (
            <span>-</span>
        )
    }

    render() {
        const { column, result, resultStatus, viewProps, ...otherProps } = this.props
        return (
            <Cell {...otherProps}>
                <span
                    style={{
                        whiteSpace: 'nowrap',
                    }}
                >
                    {resultStatus === 'pending' ? 'Loading...' : this.getMembers()}
                </span>
            </Cell>
        )
    }
}

export default MemberCell
