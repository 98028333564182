import { getOr } from 'lodash/fp'
import { CSSProperties, Component } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Unstable_Grid2'

import { Dialog } from 'Common/core'
import { RootState } from 'Common/store/createStore'

import { getAsset as getAssetAction } from '../../assets/AssetsActions'
import { requestStatus } from '../../utils/net/statuses'
import DisplayField from '../displayFields/DisplayField'
import Spinner, { SPINNER_SIZES } from '../loader/Spinner'
import AssetDownload from './AssetDownload'
import AssetThumbnail from './AssetThumbnail'
import AssetThumbnailDownload from './AssetThumbnailDownload'

const styles = {
    loadingOrError: {
        textAlign: 'center',
        marginTop: 30,
    } as CSSProperties,
}
const defaultSizeValue = 'Original'

type Props = {
    toggleModal: (any) => void
    assetId?: number
}

const mapState = (state: RootState) => {
    const { assets, metaData } = state
    const imgSize: any[] = metaData.data.thumbnailWidthSizes
    const imgSizeList = imgSize.sort((a, b) => (a.id > b.id ? 1 : -1))
    return {
        asset: assets.active,
        assetStatus: assets.status,
        imgSizeList,
    }
}

const mapDisp = {
    getAsset: getAssetAction,
}
const connector = connect(mapState, mapDisp)

type PropsFromRedux = ConnectedProps<typeof connector>

class AssetDetailsDialog extends Component<Props & PropsFromRedux> {
    state = {
        downloadableFile: defaultSizeValue,
    }

    UNSAFE_componentWillMount() {
        const { assetId, getAsset } = this.props

        if (assetId) {
            getAsset(assetId)
        }
    }

    onHandleChangeDownloadFile = (event) => {
        this.setState({
            downloadableFile: event.target.value,
        })
    }

    renderModalContent = () => {
        const { asset, assetStatus, imgSizeList } = this.props

        if (assetStatus.getStatus !== requestStatus.success) {
            return (
                <div style={styles.loadingOrError}>
                    {assetStatus.getStatus === requestStatus.failure ? (
                        'An error occured'
                    ) : (
                        <Spinner size={SPINNER_SIZES.LARGE} />
                    )}
                </div>
            )
        }

        if (!asset) return null
        const selectedAssetIsImage =
            asset !== null && getOr('', 'mimeType', asset).includes('image/')
        return (
            <Grid container spacing={2}>
                {selectedAssetIsImage ? (
                    <Grid xs={12} sm={4}>
                        <Grid
                            xs={12}
                            style={{
                                marginTop: 20,
                            }}
                        >
                            <AssetThumbnail id={asset.id} maxWidth={200} />
                        </Grid>
                    </Grid>
                ) : null}
                <Grid xs={12} sm={8}>
                    <Grid container spacing={2}>
                        <Grid
                            xs={12}
                            style={{
                                wordWrap: 'break-word',
                            }}
                        >
                            <DisplayField value={asset.originalFilename} label="Filnamn" />
                        </Grid>
                        <Grid xs={12}>
                            <DisplayField
                                value={asset.publishingHouse ? asset.publishingHouse.name : '-'}
                                label="Publishing house"
                            />
                        </Grid>
                        <Grid xs={12}>
                            <DisplayField value={asset.description} label="Description" />
                        </Grid>
                        <Grid xs={12}>
                            <DisplayField value={asset.keywords} label="Keywords" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12}>
                    <p>
                        Read more or edit this asset by clicking{' '}
                        <Link underline="none" component={NavLink} to={`/assets/${asset.id}`}>
                            here
                        </Link>
                    </p>
                </Grid>
                {selectedAssetIsImage ? (
                    <Grid container spacing={2} xs={12}>
                        <Grid xs={6}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel>Select a size to show/download</InputLabel>
                                <Select
                                    variant="standard"
                                    value={this.state.downloadableFile}
                                    onChange={this.onHandleChangeDownloadFile}
                                >
                                    {imgSizeList
                                        .filter(
                                            (element) =>
                                                element.name <= asset.imageWidth ||
                                                element.name === 'Original',
                                        )
                                        .map((e) => (
                                            <MenuItem key={e.id} value={e.name}>
                                                {e.name !== 'Original'
                                                    ? `Download with width ${e.name}px`
                                                    : 'Download original'}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid container spacing={0} xs={12} justifyContent="flex-end">
                            <Grid xs={2}>
                                <AssetThumbnailDownload
                                    id={asset.id}
                                    size={this.state.downloadableFile}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}
                {!selectedAssetIsImage ? (
                    <Grid container spacing={0} xs={12} justifyContent="flex-end">
                        <Grid xs={2}>
                            <AssetDownload id={asset.id} label="Download" color="primary" />
                        </Grid>
                    </Grid>
                ) : null}
            </Grid>
        )
    }

    render() {
        const { asset, toggleModal } = this.props
        return (
            <Dialog
                title={`Asset: ${asset && asset.assetType ? asset.assetType.name : ''}`}
                onClose={toggleModal}
            >
                {this.renderModalContent()}
            </Dialog>
        )
    }
}

export default connector(AssetDetailsDialog)
