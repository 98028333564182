import { create } from 'zustand'

type AppbarTitleStore = {
    appTitle: string
    setTitle: (title: string) => void
}

export const useAppbarTitleStore = create<AppbarTitleStore>((set) => ({
    appTitle: 'Mockingbird',
    setTitle: (title) => set(() => ({ appTitle: title })),
}))
