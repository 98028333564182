import { Cell } from 'fixed-data-table-2'
import { getOr } from 'lodash/fp'
import { PureComponent } from 'react'

type Props = {
    column: {
        selectField: string
    }
    resultStatus: string
    result?: Record<string, unknown>
    viewProps?: Record<string, unknown>
}

class BooleanCell extends PureComponent<Props> {
    render() {
        const { column, result, resultStatus, viewProps, ...otherProps } = this.props
        const value = getOr(false, `document.${column.selectField}`, result)
        const displayValue = value ? 'Yes' : 'No'
        return (
            <Cell {...otherProps}>
                <span
                    style={{
                        whiteSpace: 'nowrap',
                    }}
                >
                    {resultStatus === 'pending' ? 'Loading...' : displayValue}
                </span>
            </Cell>
        )
    }
}

export default BooleanCell
