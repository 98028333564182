import { isNil, isUndefined, omitBy } from 'lodash/fp'
import { UserManager } from 'oidc-client'
import * as React from 'react'
import { ConnectedProps, connect } from 'react-redux'

import { browserHistory } from 'Common/base/history'
import { loadEnvironment } from 'Common/environment'
import { RootState } from 'Common/store/createStore'

import {
    login as loginAction,
    loginFailure as loginFailureAction,
    loginSuccess as loginSuccessAction,
    logout as logoutAction,
} from './authActions'

const convertUserDataStringsToNumberArray = (value) => {
    if (isNil(value)) return []
    if (!Array.isArray(value)) return [parseInt(value, 10)]
    return value.map((v) => parseInt(v, 10))
}

const getUserData: any = (user) => {
    const { profile, access_token } = user
    const opusUserObject = profile.opus_user_object
    return omitBy(isUndefined, {
        ...profile,
        token: access_token,
        opus_contact_id: convertUserDataStringsToNumberArray(profile.opus_contact_id),
        opus_user: opusUserObject ? JSON.parse(opusUserObject) : undefined,
        opus_user_object: undefined,
    })
}

const mapState = (state: RootState) => {
    const { token, userName, isAuthenticated } = state.auth
    return {
        token,
        userName,
        isAuthenticated,
    }
}

const mapDisp = {
    login: loginAction,
    loginSuccess: loginSuccessAction,
    loginFailure: loginFailureAction,
    logout: logoutAction,
}

const connector = connect(mapState, mapDisp)

type PropsFromRedux = ConnectedProps<typeof connector>

export function requireAuthentication(Component) {
    class AuthenticatedComponent extends React.Component<PropsFromRedux> {
        userManager: UserManager

        componentDidMount() {
            const { loginSuccess } = this.props
            const { isAuthenticated } = this.props
            loadEnvironment().then((environment) => {
                const authSettings = {
                    authority: environment.endpoints.identityserver,
                    client_id: 'opus',
                    redirect_uri: window.location.origin,
                    silent_redirect_uri: window.location.origin + '/silent_renew.html',
                    post_logout_redirect_uri: '/services/auth/manage',
                    response_type: 'id_token token',
                    scope: 'openid profile opus opus_info',
                    automaticSilentRenew: true,
                    filter_protocol_claims: true,
                    loadUserInfo: true,
                }
                this.userManager = new UserManager(authSettings)
                this.userManager.events.addSilentRenewError(() => {
                    this.signinRedirect()
                })
                this.userManager.events.addAccessTokenExpired(() => {
                    console.info('token expired')
                    this.signinRedirect()
                })
                this.userManager.events.addUserSignedOut(() => {
                    console.info('user signed out in another place') //Commenting out this as this seems to throw one user in a loop if using chrome.
                    // this.signinRedirect()
                })
                this.userManager.events.addUserLoaded((user) => {
                    console.info('user loaded')
                    loginSuccess(getUserData(user))
                })

                window.onmessage = (e) => {
                    if (
                        e.origin === `${window.location.protocol}//${window.location.host}` &&
                        e.data === 'changed'
                    ) {
                        this.userManager.removeUser()
                        this.userManager
                            .signinSilent()
                            .then(() => {
                                // Session state changed but we managed to silently get a new identity token, everything's fine
                                console.info('renewTokenSilentAsync success')
                            })
                            .catch((err) => {
                                // Here we couldn't get a new identity token, we have to ask the user to log in again
                                console.warn('renewTokenSilentAsync failed', err.message)
                                this.signinRedirect()
                            })
                    }
                }

                if (
                    browserHistory.location.hash &&
                    browserHistory.location.hash.includes('#id_token=')
                ) {
                    // validate the callback from the server and give the user the access if valid
                    this.validateUser()
                } else if (!isAuthenticated) {
                    // redirect user to login page
                    this.signinSilent()
                }
            })
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            if (!nextProps.isAuthenticated) {
                this.signinSilent()
            }
        }

        logout = () => {
            this.userManager.signoutRedirect()
        }

        signinRedirect = () => {
            this.userManager.signinRedirect({
                data: `${window.location.pathname}${window.location.search || ''}`,
            })
        }

        signinSilent = () => {
            this.userManager.signinSilent().catch((err) => {
                console.warn('Error on signin silent', err.message)
                this.userManager.signinRedirect({
                    data: `${window.location.pathname}${window.location.search || ''}`,
                    redirect_uri: `${window.location.origin}`,
                })
            })
        }

        validateUser = () => {
            const { login, loginSuccess, loginFailure } = this.props
            login()
            this.userManager.signinRedirectCallback().then(
                (user) => {
                    loginSuccess(getUserData(user))

                    if (user.state) {
                        browserHistory.push(user.state)
                    } else {
                        browserHistory.push(`/`)
                    }
                },
                (error) => {
                    console.warn('Problem Getting Token', error.message || error)
                    loginFailure()
                    browserHistory.push(`/`)
                },
            )
        }

        render() {
            return (
                <div>
                    {this.props.isAuthenticated === true ? (
                        <Component {...this.props} logout={this.logout} />
                    ) : null}
                </div>
            )
        }
    }

    return connector(AuthenticatedComponent)
}
