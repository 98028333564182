import { Component } from 'react'

import { Typography } from '@mui/material'

const getSortingDisplay = (field, sorting) => {
    if (field !== sorting.field) return ''
    if (sorting.desc) return '\u2193'
    return '\u2191'
}

type FieldFunction = (..._args: unknown[]) => unknown

type SortFieldFunction = (..._args: unknown[]) => unknown

type Props = {
    updateSorting(..._args: unknown[]): unknown
    field: string | FieldFunction
    label: string
    sorting?: {
        desc: boolean
        field?: string | SortFieldFunction
    }
    defaultValue?: any
    style?: any
}

class SortableTableHeader extends Component<Props> {
    static defaultProps = {
        defaultValue: '',
    }

    handleSort = () => {
        const { field, defaultValue, updateSorting } = this.props
        updateSorting(field, defaultValue)
    }

    render() {
        const { field, label, sorting } = this.props
        return (
            <Typography
                variant="body1"
                className="linkPrimary"
                onClick={this.handleSort}
                style={{
                    userSelect: 'none',
                }}
            >
                {label} {getSortingDisplay(field, sorting)}
            </Typography>
        )
    }
}

export default SortableTableHeader
