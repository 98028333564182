import { getOr } from 'lodash/fp'
import { DateTime } from 'luxon'
import { CSSProperties } from 'react'

import Grid from '@mui/material/Unstable_Grid2'

import AssetDetailsThumbnail from 'Common/components/assets/AssetDetailsThumbnail'
import Contact from 'Common/components/contacts/Contact'
import {
    filterMemberByDesigner,
    filterMemberByEditor,
    filterMemberByPrinter,
    filterMemberByProductionLeader,
    filterMemberByPublisher,
    filterMemberByTranslator,
} from 'Common/utils/roles'

export const getTimePlanValue = (id, result) => {
    const entries = getOr([], 'activePrint.timePlan.entries', result)
    const entry = entries.find((_) => _.type.id === id)
    if (!entry) return '-'
    const date = entry.actual || entry.planned
    if (!date) return '-'
    return DateTime.fromISO(date).toISODate()
}
export const getContactValues = (contacts, lastNameFirst = false) => (
    <div>
        {contacts.length > 0 ? (
            contacts.map((author, index) => (
                <span
                    key={author.id}
                    style={{
                        marginRight: 5,
                    }}
                >
                    <Contact {...author.contact} lastNameFirst={lastNameFirst} />
                    {index + 1 !== contacts.length && ' /'}
                </span>
            ))
        ) : (
            <span>-</span>
        )}
    </div>
)
const styles = {
    gridContainer: {
        padding: 10,
        backgroundColor: '#eeeeee',
        marginBottom: 10,
    } as CSSProperties,
    itemListHeader: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
    } as CSSProperties,
    itemList: {
        paddingLeft: 0,
        listStyleType: 'none',
    } as CSSProperties,
    item: {
        paddingBottom: 10,
    } as CSSProperties,
    itemHeader: {
        color: 'rgba(0, 0, 0, 0.45098)',
        fontWeight: 'bold',
        marginBottom: 3,
    } as CSSProperties,
}

type Props = {
    result: any
}

const PublishingListViewItemDetails = ({ result }: Props) => {
    return (
        <Grid container spacing={2} xs={12} style={styles.gridContainer}>
            <Grid xs={6} sm={3}>
                <span style={styles.itemListHeader}>Edition</span>
                <br />
                <ul style={styles.itemList}>
                    <li style={styles.item}>
                        <div style={styles.itemHeader}>ISBN</div>
                        {getOr('-', 'isbn', result)}
                    </li>
                    <li style={styles.item}>
                        <div style={styles.itemHeader}>Colors</div>
                        {getOr('-', 'activePrint.insideColors', result)}
                    </li>
                    <li style={styles.item}>
                        <div style={styles.itemHeader}>Print</div>
                        {getOr('-', 'activePrint.insidePrinting.name', result)}
                    </li>
                    <li style={styles.item}>
                        <div style={styles.itemHeader}>Last numbered page</div>
                        {getOr('-', 'pages', result)}
                    </li>
                    <li style={styles.item}>
                        <div style={styles.itemHeader}>Definitive print run, this print</div>
                        {getOr('-', 'activePrint.quantityOrdered', result)}
                    </li>
                    <li style={styles.item}>
                        <div style={styles.itemHeader}>Budgeted sales</div>
                        {getOr('-', 'activePrint.quantityPlanned', result)}
                    </li>
                </ul>
            </Grid>
            <Grid xs={6} sm={3}>
                <span style={styles.itemListHeader}>Contacts</span>
                <br />
                <ul style={styles.itemList}>
                    <li style={styles.item}>
                        <div style={styles.itemHeader}>Publisher</div>
                        {getContactValues(result.members.filter(filterMemberByPublisher))}
                    </li>
                    <li style={styles.item}>
                        <div style={styles.itemHeader}>Translator</div>
                        {getContactValues(result.members.filter(filterMemberByTranslator))}
                    </li>
                    <li style={styles.item}>
                        <div style={styles.itemHeader}>Editor</div>
                        {getContactValues(result.members.filter(filterMemberByEditor))}
                    </li>
                    <li style={styles.item}>
                        <div style={styles.itemHeader}>Designer</div>
                        {getContactValues(result.members.filter(filterMemberByDesigner))}
                    </li>
                    <li style={styles.item}>
                        <div style={styles.itemHeader}>Production manager</div>
                        {getContactValues(result.members.filter(filterMemberByProductionLeader))}
                    </li>
                    <li style={styles.item}>
                        <div style={styles.itemHeader}>Printer</div>
                        {getContactValues(result.members.filter(filterMemberByPrinter))}
                    </li>
                </ul>
            </Grid>
            <Grid xs={6} sm={3}>
                <span style={styles.itemListHeader}>Time plan</span>
                <br />
                <ul style={styles.itemList}>
                    <li style={styles.item}>
                        <div style={styles.itemHeader}>Manuscript to editor</div>
                        {getTimePlanValue('1', result)}
                    </li>
                    <li style={styles.item}>
                        <div style={styles.itemHeader}>To typesetting</div>
                        {getTimePlanValue('2', result)}
                    </li>
                    <li style={styles.item}>
                        <div style={styles.itemHeader}>To printer</div>
                        {getTimePlanValue('7', result)}
                    </li>
                    <li style={styles.item}>
                        <div style={styles.itemHeader}>Delivery</div>
                        {getTimePlanValue('11', result)}
                    </li>
                </ul>
            </Grid>
            <Grid xs={6} sm={3}>
                <span style={styles.itemListHeader}>Cover</span>
                <div>
                    <AssetDetailsThumbnail
                        assetId={result.coverImageAssetId}
                        maxWidth={120}
                        alt="bookcover"
                    />
                </div>
            </Grid>
        </Grid>
    )
}

export default PublishingListViewItemDetails
