import { getOr } from 'lodash/fp'

import { services } from '../../Common/environment'
import { serializeSearchOutput } from '../../Common/search/searchOutputSerializers'
import request from '../../Common/utils/net/request'

export const POST_PRODUCTIONS_ACTIVE_WEB_PERIOD = 'POST_PRODUCTIONS_ACTIVE_WEB_PERIOD'

export const postProductionsBatchActiveWebPeriod =
    (searchConfig, op, path, value) => (dispatch, getState) => {
        const searchOptions = getOr({}, `search[${searchConfig.id}].searchOptions`, getState())
        const itemsTotalCount = getOr(
            {},
            `search[${searchConfig.id}].paging.itemsTotalCount`,
            getState(),
        )
        const searchQuery = serializeSearchOutput(searchOptions, searchConfig)
        const url = services.work(`/v1/productions/activewebperiods`)
        const body = {
            search: decodeURIComponent(searchQuery),
            patchdocument: [{ op, path, value }],
            itemsTotalCount,
        }
        return dispatch({
            type: POST_PRODUCTIONS_ACTIVE_WEB_PERIOD,
            meta: {
                searchConfig,
                startTime: Date.now(),
            },
            payload: {
                promise: request({
                    method: 'POST',
                    url,
                    body,
                }),
            },
        })
    }
