/* parses url input to a searchOptions object */
import { Base64 } from 'js-base64'
import { fromPairs, zipObject } from 'lodash/fp'

export const getQueryInputParser =
    (defaultQuery = '') =>
    (queryParams: any = {}) =>
        window.decodeURIComponent((queryParams.query || defaultQuery).trim())
export const getQueryFieldInputParser =
    (defaultField = 'all') =>
    (queryParams: any = {}) =>
        queryParams.queryField || defaultField
export const getOrderByInputParser =
    (defaultOrderBy = 'relevance') =>
    (queryParams: any = {}) =>
        queryParams.orderBy || defaultOrderBy
export const getCombinatorInputParser =
    (defaultCombinator = 'and') =>
    (queryParams: any = {}) =>
        queryParams.combinator || defaultCombinator
export const getViewInputParser =
    (defaultView = '') =>
    (queryParams: any = {}) =>
        queryParams.view || defaultView
export const getOptionsInputParser =
    (id) =>
    (queryParams: any = {}) =>
        queryParams[id] ? queryParams[id].split(',') : null
export const getCombinationsInputParser =
    (id, typeConfig) =>
    (queryParams: any = {}) => {
        const params = queryParams[id] ? queryParams[id].split(',') : []
        return params.length === 0
            ? null
            : params.map((param) => zipObject(typeConfig.keys, param.split(':::')))
    }
export const getBooleansInputParser =
    (id) =>
    (queryParams: any = {}) =>
        queryParams[id] ? queryParams[id].split(',').map((param) => param === 'true') : null
export const getDatesInputParser = (id) =>
    getCombinationsInputParser(id, {
        keys: ['min', 'max'],
    })
export const getObjectsInputParser =
    (id) =>
    (queryParams: any = {}) => {
        const params = queryParams[id] ? queryParams[id].split(',') : []
        return params.length === 0 ? null : params.map((param) => JSON.parse(Base64.decode(param)))
    }
export const parseSearchInput = (queryParams = {}, searchConfig) => {
    const parsers = [
        ['query', searchConfig.queryInputParser || getQueryInputParser(searchConfig.defaultQuery)],
        [
            'queryField',
            searchConfig.queryFieldInputParser ||
                getQueryFieldInputParser(searchConfig.defaultQueryField),
        ],
        [
            'orderBy',
            searchConfig.orderByInputParser || getOrderByInputParser(searchConfig.defaultOrderBy),
        ],
        [
            'combinator',
            searchConfig.combinatorInputParser ||
                getCombinatorInputParser(searchConfig.defaultCombinator),
        ],
        ['view', searchConfig.viewInputParser || getViewInputParser(searchConfig.defaultView)],
    ]
        .concat(
            (searchConfig.filters || []).map((filter) => [
                filter.id,
                filter.inputParser ||
                    filter.type.getInputParser(filter.id, {
                        ...filter.type.typeConfig,
                        ...filter.typeConfig,
                    }),
            ]),
        )
        .filter((parser) => parser && parser[1])
    const result = fromPairs(parsers.map((parser) => [parser[0], parser[1](queryParams)]))
    return searchConfig.defaultSelectFields
        ? { ...result, selectFields: searchConfig.defaultSelectFields }
        : result
}
