import { requestStatus } from 'Common/utils/net/statuses'

import {
    DELETE_USER_FAILURE,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    GET_USER_FAILURE,
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    PATCH_USER_FAILURE,
    PATCH_USER_REQUEST,
    PATCH_USER_SUCCESS,
    POST_USER_PASSWORD_FAILURE,
    POST_USER_PASSWORD_REQUEST,
    POST_USER_PASSWORD_SUCCESS,
} from '../actions/userActions'

const initialState = {
    status: {
        getStatus: undefined,
        getError: undefined,
    },
    data: undefined,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_REQUEST:
            return {
                ...state,
                data: null,
                status: { ...state.status, getStatus: requestStatus.request, getError: null },
            }

        case GET_USER_SUCCESS:
            return {
                ...state,
                data: action.payload.json,
                status: { ...state.status, getStatus: requestStatus.success },
            }

        case GET_USER_FAILURE:
            return {
                ...state,
                status: {
                    ...state.status,
                    getStatus: requestStatus.failure,
                    getError: action.payload,
                },
            }

        case PATCH_USER_REQUEST:
            return {
                ...state,
                status: { ...state.status, patchStatus: requestStatus.request, patchError: null },
            }

        case PATCH_USER_SUCCESS:
            return {
                ...state,
                data: action.payload.json,
                status: { ...state.status, patchStatus: requestStatus.success },
            }

        case PATCH_USER_FAILURE:
            return {
                ...state,
                status: {
                    ...state.status,
                    patchStatus: requestStatus.failure,
                    patchError: action.payload,
                },
            }

        case DELETE_USER_REQUEST:
            return {
                ...state,
                status: { ...state.status, deleteStatus: requestStatus.request, deleteError: null },
            }

        case DELETE_USER_SUCCESS:
            return {
                ...state,
                data: null,
                status: { ...state.status, deleteStatus: requestStatus.success },
            }

        case DELETE_USER_FAILURE:
            return {
                ...state,
                status: {
                    ...state.status,
                    deleteStatus: requestStatus.failure,
                    deleteError: action.payload,
                },
            }

        case POST_USER_PASSWORD_REQUEST:
            return {
                ...state,
                status: {
                    ...state.status,
                    postPasswordStatus: requestStatus.request,
                    postPasswordError: null,
                },
            }

        case POST_USER_PASSWORD_SUCCESS:
            return {
                ...state,
                status: { ...state.status, postPasswordStatus: requestStatus.success },
            }

        case POST_USER_PASSWORD_FAILURE:
            return {
                ...state,
                status: {
                    ...state.status,
                    postPasswordStatus: requestStatus.failure,
                    postPasswordError: action.payload,
                },
            }

        default:
            return state
    }
}
