import { services } from 'Common/environment'
import createErrorMessages from 'Common/messages/createErrorMessages'
import request from 'Common/utils/net/request'

import messages from '../messages'

export const GET_CONTACT_DETAILS_LINKS = 'GET_CONTACT_DETAILS_LINKS'
export const GET_CONTACT_DETAILS_LINKS_REQUEST = 'GET_CONTACT_DETAILS_LINKS_REQUEST'
export const GET_CONTACT_DETAILS_LINKS_SUCCESS = 'GET_CONTACT_DETAILS_LINKS_SUCCESS'
export const GET_CONTACT_DETAILS_LINKS_FAILURE = 'GET_CONTACT_DETAILS_LINKS_FAILURE'
export const PUT_CONTACT_DETAILS_LINKS = 'PUT_CONTACT_DETAILS_LINKS'
export const PUT_CONTACT_DETAILS_LINKS_REQUEST = 'PUT_CONTACT_DETAILS_LINKS_REQUEST'
export const PUT_CONTACT_DETAILS_LINKS_SUCCESS = 'PUT_CONTACT_DETAILS_LINKS_SUCCESS'
export const PUT_CONTACT_DETAILS_LINKS_FAILURE = 'PUT_CONTACT_DETAILS_LINKS_FAILURE'
const GET_CONTACT_DETAILS_LINKS_ERROR_MESSAGES = createErrorMessages(
    ['CONTACT_LINK_NOT_FOUND', 'CONTACT_LINK_FORBIDDEN'],
    messages,
)
export const getContactDetailsLinks = (id) => (dispatch) =>
    dispatch({
        type: GET_CONTACT_DETAILS_LINKS,
        meta: {
            id,
        },
        payload: {
            promise: request({
                method: 'GET',
                url: services.contact(`/v2/contacts/${id}/links`),
                errorMessages: GET_CONTACT_DETAILS_LINKS_ERROR_MESSAGES,
            }),
        },
    })
const PUT_CONTACT_DETAILS_LINKS_ERROR_MESSAGES = createErrorMessages(
    ['CONTACT_NOT_FOUND', 'CONTACT_FORBIDDEN'],
    messages,
)
export const putContactDetailsLinks = (id, contactLinks) => (dispatch) =>
    dispatch({
        type: PUT_CONTACT_DETAILS_LINKS,
        meta: {
            id,
        },
        payload: {
            promise: request({
                method: 'PUT',
                url: services.contact(`/v2/contacts/${id}/links`),
                body: contactLinks,
                errorMessages: PUT_CONTACT_DETAILS_LINKS_ERROR_MESSAGES,
            }),
        },
    })
