export const UPDATE_SUBMENU_OPEN = 'UPDATE_SUBMENU_OPEN'
export const UPDATE_SUBMENU_HAS_ITEMS = 'UPDATE_SUBMENU_HAS_ITEMS'

export const updateSubmenuOpen = (isOpen) => ({
    type: UPDATE_SUBMENU_OPEN,
    payload: {
        isOpen,
    },
})
export const updateSubmenuHasItems = (hasItems) => ({
    type: UPDATE_SUBMENU_HAS_ITEMS,
    payload: {
        hasItems,
    },
})
