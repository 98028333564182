export const texts = {
    shortText: '1',
    longText: '2',
    frontText: '3',
    backText: '4',
    spineText: '5',
    authorPortrait: '8',
    internalSalesPitch: '9',
    pressTitleInformation: '15',
    pressQuotesMultiple: '16',
    colophon: '17',
    pressQuotesSingle: '18',
    headline: '19',
    illustratorPortrait: '24',
    freeCopyComment: '28',
    internalSalesPitchFlyer: '103',
    longTextBGA: '105',
    reviewsBGA: '107',
    descriptionCore: '30',
    descriptionExtra: '31',
}
// this should ONLY be used when textTypes from metaData can't be used, ie if it is needed before we have metaData

// export const textLabels = {
//   shortText: 'Copy 1',
//   longText: 'Copy 2',
//   frontText: 'Cover text, front',
//   backText: 'Cover text, back',
//   spineText: 'Text on spine',
//   authorPortrait: 'Author presentation',
//   internalSalesPitch: 'USP (several)',
//   pressTitleInformation: 'Advance information',
//   pressQuotesMultiple: 'Review quotes (several)',
//   colophon: 'Colophon',
//   pressQuotesSingle: 'Review quote (one)',
//   headline: 'Headline',
//   illustratorPortrait: 'Illustrator presentation',
//   freeCopyComment: 'Free copy comment',
//   internalSalesPitchFlyer: 'USP (one)',
//   longTextBGA: 'Copy, long (Agency Rights)',
//   reviewsBGA: 'Reviews (Agency Rights)',
//   descriptionCore: 'Description (core) - for booksellers and consumers',
//   descriptionExtra: 'Description (extra) - additional and optional',
// }

export const getTextColumns =
    (category, prefix = '') =>
    (texts) => {
        return texts.reduce((acc, text) => {
            return {
                ...acc,
                [`text-${text.id}`]: {
                    id: `text-${text.id}`,
                    selectField: `${prefix}textFullByTypes_${text.id}`,
                    label: text.name,
                    width: 150,
                    sortable: false,
                    category: category,
                    cellConfig: {
                        dataField: `${prefix}textFullByTypes.${text.id}.text`,
                    },
                },
            }
        }, {})
    }
