import { sortBy } from 'lodash/fp'
import { Component } from 'react'

import { FormControl, MenuItem, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { Dialog, SaveButton } from 'Common/core'

const NEW_SEARCH_ID = 'OPUS::DEFAULT::NEW'

type Props = {
    saveSearch(..._args: unknown[]): unknown
    closeModal(): void
    savedSearches: unknown[]
    searchName?: string
}

type State = {
    selectedSearch?: any
    newSearchName?: any
    errorMessage?: any
}

class SearchSaving extends Component<Props, State> {
    initialFocus: any

    constructor(props) {
        super(props)
        this.state = {
            selectedSearch: props.searchName || NEW_SEARCH_ID,
            newSearchName: '',
        }
    }

    componentDidMount() {
        this.initialFocus && this.initialFocus.focus()
    }

    onSaveSearch = () => {
        const { saveSearch } = this.props
        const { selectedSearch, newSearchName } = this.state

        if (selectedSearch === NEW_SEARCH_ID && !newSearchName) {
            this.setState({
                errorMessage: 'You need to give the search a name',
            })
            return
        }

        saveSearch(selectedSearch === NEW_SEARCH_ID ? newSearchName : selectedSearch)
    }

    setSelectedSearch = (event) => {
        this.setState({
            selectedSearch: event.target.value,
        })
    }

    render() {
        const { savedSearches, closeModal } = this.props
        const { selectedSearch, newSearchName, errorMessage } = this.state
        return (
            <Dialog
                actions={[<SaveButton key="save" onClick={this.onSaveSearch} />]}
                title="Create a new saved search or update an already saved search"
                onClose={closeModal}
            >
                <Grid container spacing={2} xs={12}>
                    <Grid xs={12}>
                        <FormControl variant="standard" fullWidth>
                            <TextField
                                variant="standard"
                                select
                                label="Select search"
                                onChange={this.setSelectedSearch}
                                value={selectedSearch}
                            >
                                <MenuItem value={NEW_SEARCH_ID}>Create new</MenuItem>
                                {sortBy((search: any) => search.name, savedSearches).map(
                                    (search: any, index) => (
                                        <MenuItem key={index} value={search.name}>
                                            {search.name}
                                        </MenuItem>
                                    ),
                                )}
                            </TextField>
                        </FormControl>
                    </Grid>

                    {selectedSearch === NEW_SEARCH_ID ? (
                        <Grid xs={12}>
                            <FormControl variant="standard" fullWidth>
                                <TextField
                                    variant="standard"
                                    label="Name your search *"
                                    error={!!errorMessage}
                                    onChange={(event) =>
                                        this.setState({
                                            newSearchName: event.target.value,
                                            errorMessage: null,
                                        })
                                    }
                                    value={newSearchName}
                                    helperText={errorMessage}
                                    style={{
                                        width: '100%',
                                    }}
                                    autoFocus
                                />
                            </FormControl>
                        </Grid>
                    ) : null}
                </Grid>
            </Dialog>
        )
    }
}

export default SearchSaving
