import { useState } from 'react'

import AssetDetailsDialog from './AssetDetailsDialog'
import AssetThumbnail from './AssetThumbnail'

type Props = {
    assetId?: number
    maxWidth: number
    alt: string
    reload?: any
}

const AssetDetailsThumbnail = (props: Props) => {
    const { assetId, ...rest } = props
    const [showDialog, setShowDialog] = useState(false)

    const toggleDialog = (e?: any) => {
        e.stopPropagation()
        setShowDialog(!showDialog)
    }

    return (
        <div
            style={{
                cursor: 'pointer',
            }}
        >
            {showDialog ? (
                <AssetDetailsDialog toggleModal={toggleDialog} assetId={assetId} />
            ) : null}
            <AssetThumbnail {...rest} id={assetId} onClick={(e: any) => toggleDialog(e)} />
        </div>
    )
}

export default AssetDetailsThumbnail
