import { Box, Container } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

type PageWrapperProps = {
    children: React.ReactNode
}

export const PageWrapper = (props: PageWrapperProps) => {
    const { children } = props
    return (
        <Container sx={{ marginTop: 4, marginBottom: 2 }} maxWidth={false} className="shrink-print">
            {children}
        </Container>
    )
}

export const TabsWrapper = (props: PageWrapperProps) => {
    const { children } = props
    return (
        <Container maxWidth={false} className="shrink-print">
            {children}
        </Container>
    )
}

export const TabsPageWrapper = (props: PageWrapperProps) => {
    const { children } = props
    return (
        <Box sx={{ witdh: '100%', marginTop: 2, marginBottom: 2 }} className="shrink-print">
            {children}
        </Box>
    )
}

export const MainWrapper = ({ children }: { children: React.ReactNode }) => (
    <Grid container spacing={0} xs={12} className="shrink-print">
        {children}
    </Grid>
)
