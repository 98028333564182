export const GENERIC_ERROR = 'GENERIC_ERROR'
export const GENERIC_ERROR_CLEAR = 'GENERIC_ERROR_CLEAR'
export const setGenericError = ({ header, paragraph }: { header: string; paragraph?: any }) => {
    return {
        type: GENERIC_ERROR,
        header,
        paragraph,
    }
}
export const clearGenericError = () => {
    return {
        type: GENERIC_ERROR_CLEAR,
    }
}
