import { convertPercentagesToFactors } from 'Calculation/utils/calculation'

import { services } from 'Common/environment'
import createErrorMessages from 'Common/messages/createErrorMessages'
import { AppDispatch, Concept, PreliminaryEdition } from 'Common/types'
import request from 'Common/utils/net/request'

import messages from '../messages'

export const POST_CONCEPT_PRODUCTION = 'POST_CONCEPT_PRODUCTION'
export const POST_CONCEPT_PRODUCTION_REQUEST = 'POST_CONCEPT_PRODUCTION_REQUEST'
export const POST_CONCEPT_PRODUCTION_SUCCESS = 'POST_CONCEPT_PRODUCTION_SUCCESS'
export const POST_CONCEPT_PRODUCTION_FAILURE = 'POST_CONCEPT_PRODUCTION_FAILURE'
export const POST_CONCEPT_PRODUCTION_SILENT = 'POST_CONCEPT_PRODUCTION_SILENT'
export const POST_CONCEPT_PRODUCTION_SILENT_REQUEST = 'POST_CONCEPT_PRODUCTION_SILENT_REQUEST'
export const POST_CONCEPT_PRODUCTION_SILENT_SUCCESS = 'POST_CONCEPT_PRODUCTION_SILENT_SUCCESS'
export const POST_CONCEPT_PRODUCTION_SILENT_FAILURE = 'POST_CONCEPT_PRODUCTION_SILENT_FAILURE'
export const PUT_CONCEPT_PRODUCTION = 'PUT_CONCEPT_PRODUCTION'
export const PUT_CONCEPT_PRODUCTION_REQUEST = 'PUT_CONCEPT_PRODUCTION_REQUEST'
export const PUT_CONCEPT_PRODUCTION_SUCCESS = 'PUT_CONCEPT_PRODUCTION_SUCCESS'
export const PUT_CONCEPT_PRODUCTION_FAILURE = 'PUT_CONCEPT_PRODUCTION_FAILURE'
export const DELETE_CONCEPT_PRODUCTION = 'DELETE_CONCEPT_PRODUCTION'
export const DELETE_CONCEPT_PRODUCTION_REQUEST = 'DELETE_CONCEPT_PRODUCTION_REQUEST'
export const DELETE_CONCEPT_PRODUCTION_SUCCESS = 'DELETE_CONCEPT_PRODUCTION_SUCCESS'
export const DELETE_CONCEPT_PRODUCTION_FAILURE = 'DELETE_PRODUCTION_FAILURE'
export const DELETE_CONCEPT_PRODUCTION_SILENT = 'DELETE_CONCEPT_PRODUCTION_SILENT'
export const DELETE_CONCEPT_PRODUCTION_SILENT_REQUEST = 'DELETE_CONCEPT_PRODUCTION_SILENT_REQUEST'
export const DELETE_CONCEPT_PRODUCTION_SILENT_SUCCESS = 'DELETE_CONCEPT_PRODUCTION_SILENT_SUCCESS'
export const DELETE_CONCEPT_PRODUCTION_SILENT_FAILURE = 'DELETE_CONCEPT_PRODUCTION_SILENT_FAILURE'
export const GET_CONCEPT_PRODUCTION_COSTS = 'GET_CONCEPT_PRODUCTION_COSTS'
export const GET_CONCEPT_PRODUCTION_COSTS_REQUEST = 'GET_CONCEPT_PRODUCTION_COSTS_REQUEST'
export const GET_CONCEPT_PRODUCTION_COSTS_SUCCESS = 'GET_CONCEPT_PRODUCTION_COSTS_SUCCESS'
export const GET_CONCEPT_PRODUCTION_COSTS_FAILURE = 'GET_CONCEPT_PRODUCTION_COSTS_FAILURE'
export const PUT_CONCEPT_PRODUCTION_COSTS = 'PUT_CONCEPT_PRODUCTION_COSTS'
export const PUT_CONCEPT_PRODUCTION_COSTS_REQUEST = 'PUT_CONCEPT_PRODUCTION_COSTS_REQUEST'
export const PUT_CONCEPT_PRODUCTION_COSTS_SUCCESS = 'PUT_CONCEPT_PRODUCTION_COSTS_SUCCESS'
export const PUT_CONCEPT_PRODUCTION_COSTS_FAILURE = 'PUT_CONCEPT_PRODUCTION_COSTS_FAILURE'

const POST_PRODUCTION_ERROR_MESSAGES = createErrorMessages(
    ['CONCEPT_NOT_FOUND', 'CONCEPT_FORBIDDEN'],
    messages,
)
export const postProduction =
    (conceptId: number, production: PreliminaryEdition) => (dispatch: AppDispatch) => {
        return dispatch({
            type: POST_CONCEPT_PRODUCTION,
            payload: {
                data: production,
                promise: request({
                    url: services.concept(`/v1/concepts/${conceptId}/productions`),
                    method: 'POST',
                    body: {
                        ...production,
                        royaltyLevels: convertPercentagesToFactors(production.royaltyLevels),
                    },
                    errorMessages: POST_PRODUCTION_ERROR_MESSAGES,
                }),
            },
        })
    }
export const getProductionCosts =
    (conceptId: number, productionId: number) => (dispatch: AppDispatch) => {
        const url = services.concept(`/v1/concepts/${conceptId}/productions/${productionId}/costs`)
        return dispatch({
            type: GET_CONCEPT_PRODUCTION_COSTS,
            payload: {
                promise: request({
                    method: 'GET',
                    url,
                }),
            },
            meta: {
                productionId,
            },
        })
    }
export const getProductionsCosts =
    (conceptId: number, productionIds: number[]) => (dispatch: AppDispatch) => {
        if (!productionIds || productionIds.length === 0) return
        return Promise.all(
            productionIds.map((productionId) =>
                dispatch({
                    type: GET_CONCEPT_PRODUCTION_COSTS,
                    payload: {
                        promise: request({
                            method: 'GET',
                            url: services.concept(
                                `/v1/concepts/${conceptId}/productions/${productionId}/costs`,
                            ),
                        }),
                    },
                    meta: {
                        productionId,
                    },
                }),
            ),
        )
    }
export const putProductionCosts =
    (conceptId: number, productionId: number, body: any) => (dispatch: AppDispatch) => {
        const url = services.concept(`/v1/concepts/${conceptId}/productions/${productionId}/costs`)
        return dispatch({
            type: PUT_CONCEPT_PRODUCTION_COSTS,
            payload: {
                promise: request({
                    method: 'PUT',
                    url,
                    body,
                }),
            },
            meta: {
                productionId,
            },
        })
    }
export const postProductionSilent =
    (conceptId: number, production: PreliminaryEdition, concept: Concept) =>
    (dispatch: AppDispatch) => {
        return dispatch({
            type: POST_CONCEPT_PRODUCTION_SILENT,
            meta: {
                concept,
            },
            payload: {
                data: production,
                promise: request({
                    url: services.concept(`/v1/concepts/${conceptId}/productions`),
                    method: 'POST',
                    body: {
                        ...production,
                        royaltyLevels: convertPercentagesToFactors(production.royaltyLevels),
                    },
                    errorMessages: POST_PRODUCTION_ERROR_MESSAGES,
                }),
            },
        })
    }
const PUT_PRODUCTION_ERROR_MESSAGES = createErrorMessages(
    ['CONCEPT_NOT_FOUND', 'CONCEPT_FORBIDDEN', 'CONCEPT_PRODUCTION_NOT_FOUND'],
    messages,
)
export const putProduction =
    (conceptId: number, production: PreliminaryEdition) => (dispatch: AppDispatch) => {
        const calculatedProduction = {
            ...production,
            audioTotalRunningTimeMinutes:
                +production.audioRunningTimeHours * 60 + +production.audioRunningTimeMinutes,
            royaltyLevels: convertPercentagesToFactors(production.royaltyLevels),
        }
        return dispatch({
            type: PUT_CONCEPT_PRODUCTION,
            payload: {
                data: { ...calculatedProduction },
                promise: request({
                    url: services.concept(`/v1/concepts/${conceptId}/productions/${production.id}`),
                    method: 'PUT',
                    body: { ...calculatedProduction },
                    errorMessages: PUT_PRODUCTION_ERROR_MESSAGES,
                }),
            },
        })
    }
const DELETE_PRODUCTION_ERROR_MESSAGES = createErrorMessages(
    ['CONCEPT_NOT_FOUND', 'CONCEPT_FORBIDDEN', 'CONCEPT_PRODUCTION_NOT_FOUND'],
    messages,
)
export const deleteProduction =
    (conceptId: number, production: PreliminaryEdition) => (dispatch: AppDispatch) => {
        return dispatch({
            type: DELETE_CONCEPT_PRODUCTION,
            payload: {
                data: {
                    ...production,
                    royaltyLevels: convertPercentagesToFactors(production.royaltyLevels),
                },
                promise: request({
                    url: services.concept(`/v1/concepts/${conceptId}/productions/${production.id}`),
                    method: 'DELETE',
                    errorMessages: DELETE_PRODUCTION_ERROR_MESSAGES,
                }),
            },
        })
    }
export const deleteProductionSilent =
    (conceptId: number, production: PreliminaryEdition, concept: Concept) =>
    (dispatch: AppDispatch) => {
        return dispatch({
            type: DELETE_CONCEPT_PRODUCTION_SILENT,
            meta: {
                concept,
            },
            payload: {
                data: {
                    ...production,
                    royaltyLevels: convertPercentagesToFactors(production.royaltyLevels),
                },
                promise: request({
                    url: services.concept(`/v1/concepts/${conceptId}/productions/${production.id}`),
                    method: 'DELETE',
                    errorMessages: DELETE_PRODUCTION_ERROR_MESSAGES,
                }),
            },
        })
    }
