import IconShare from '@mui/icons-material/Share'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

const FormFieldInfoSharedTo = () => (
    <Grid
        xs={12}
        sx={{
            borderTop: '1px dashed black',
            backgroundColor: 'warning.main',
        }}
    >
        <Grid xs={12}>
            <Grid container spacing={1} xs={12}>
                <Grid xs={2} display="flex" justifyContent="center">
                    <IconShare />
                </Grid>
                <Grid xs={10}>
                    <Typography fontWeight="bold">SHARING</Typography>
                </Grid>
                <Grid xsOffset={2} xs={10}>
                    <Typography variant="body2">
                        The information in this field is shared to one or more editions.
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
)

export default FormFieldInfoSharedTo
