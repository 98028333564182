import { difference } from 'lodash/fp'

import { services } from 'Common/environment'

import request from '../utils/net/request'

export const GET_METADATA = 'GET_METADATA'
export const GET_METADATA_REQUEST = 'GET_METADATA_REQUEST'
export const GET_METADATA_SUCCESS = 'GET_METADATA_SUCCESS'
export const GET_METADATA_FAILURE = 'GET_METADATA_FAILURE'

const getMetaDataBase =
    (metaDataFields, silent = false) =>
    (dispatch) =>
        dispatch({
            type: GET_METADATA,
            meta: {
                silent,
                metaDataFields,
            },
            payload: {
                promise: request({
                    method: 'GET',
                    url: services.metadata(`/v2/merge?filter=${metaDataFields.join(',')}`),
                }).then((value) => {
                    const diff = difference(metaDataFields, Object.keys(value.json || {}))

                    if (diff.length > 0) {
                        console.warn('***** WE HAVE A DIFF IN THE METADATA FIELDS', diff)
                    }

                    return value
                }),
            },
        })

export const getMetaData = (metaDataFields) => getMetaDataBase(metaDataFields, false)
export const getMetaDataSilent = (metaDataFields) => getMetaDataBase(metaDataFields, true)
