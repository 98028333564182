import { first, get, getOr } from 'lodash/fp'
import { DateTime } from 'luxon'
import { Component, Fragment } from 'react'
import { NumericFormat } from 'react-number-format'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { Divider, Typography } from '@mui/material'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Unstable_Grid2'

import AssetDetailsThumbnail from 'Common/components/assets/AssetDetailsThumbnail'
import Contact from 'Common/components/contacts/Contact'
import { getLabel, services } from 'Common/environment'
import { MetaData } from 'Common/metadata'
import { RootState } from 'Common/types'
import { UseDescriptionCore } from 'Common/utils/featureConfig'
import {
    filterMemberByAuthor,
    filterMemberByCommunicationStrategist,
    filterMemberByCoverDesigner,
    filterMemberByEditor,
    filterMemberByIllustrator,
    filterMemberByMediaProjectLead,
    filterMemberByPhotographer,
    filterMemberByPublisher,
    filterMemberByReciter,
} from 'Common/utils/roles'
import { sanitizer } from 'Common/utils/sanitizer'
import { texts } from 'Common/utils/texts'

const newLineToHtml = (text) =>
    `<p style="margin: 0px;">${text
        .replace(/\r\n\r\n/g, '</p><p style="margin: 0px;">')
        .replace(/\n\n/g, '</p><p style="margin: 0px;">')
        .replace(/\n/g, '</p><p style="margin: 0px;">')}</p>`

const translateRole = (role) => ({
    ...role,
    name: getLabel(`work.launchbrief.role.${role.id}`) ?? role.name,
})

type Props = {
    language?: string
    logoError: boolean
    result: any
    metaData: MetaData
    publishingHouseGroupIds: string[]
}

class LaunchBriefViewItem extends Component<Props> {
    state = {
        logoError: false,
        language: '',
    }

    infoDisplay(value, header) {
        return (Array.isArray(value) ? value.length > 0 : value) ? (
            <Grid xs={12}>
                {header ? (
                    <Typography variant="body1" fontWeight="bold">
                        {header}
                    </Typography>
                ) : null}
                <Typography variant="body2">{value}</Typography>
            </Grid>
        ) : null
    }

    renderHeader = () => {
        const { result } = this.props
        const { logoError } = this.state
        const logoUrl =
            services.storage('/logos/publishinghouse-') + result.mainEditionPublishingHouseId
        const authors = result.members.filter(filterMemberByAuthor)
        const showLogo = !logoError && logoUrl
        const illustrators = result.members.filter(filterMemberByIllustrator)
        const authorsAndIllustrators = [...authors, ...illustrators]
        const title = getOr('-', 'title', result)
        const subtitle = getOr(false, 'subtitle', result)
        const displayTitle = `${title}${subtitle ? ' : ' + subtitle : ''}`
        return (
            <Grid container spacing={0} xs={12} flexDirection={'row-reverse'}>
                <Grid container spacing={0} xs={5}>
                    <Grid xs={12} display="flex" justifyContent="flex-end">
                        <Typography variant="h5" fontWeight="bold">
                            {showLogo ? (
                                <img
                                    src={logoUrl}
                                    alt="logo"
                                    style={{
                                        maxHeight: '50px',
                                        maxWidth: '210px',
                                        width: '210px',
                                        height: '50px',
                                        objectFit: 'contain',
                                    }}
                                    onError={() =>
                                        this.setState({
                                            logoError: true,
                                        })
                                    }
                                />
                            ) : (
                                <span>
                                    {getOr(
                                        getOr('-', 'publishingHouseName', result),
                                        'brandName',
                                        result,
                                    )}
                                </span>
                            )}
                        </Typography>
                    </Grid>
                    <Grid xs={12} display="flex" justifyContent="flex-end">
                        {result.mainEditionSeasonName && result.mainEditionSeasonName}
                    </Grid>
                </Grid>
                <Grid container spacing={1} xs={7}>
                    <Grid xs={12}>
                        <Typography variant="body1" fontWeight="bold">
                            {getLabel('work.launchbrief.launchbrief') ?? 'Launch brief'}
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Link
                            underline="none"
                            variant="h4"
                            fontWeight="bold"
                            component={NavLink}
                            to={`/book/${result.id}`}
                        >
                            {displayTitle}
                        </Link>
                    </Grid>
                    <Grid xs={12}>
                        {authorsAndIllustrators.length > 0 ? (
                            authorsAndIllustrators.map((person, index) => (
                                <span key={person.id}>
                                    {`${person.contact.firstName} ${person.contact.lastName}`.trim()}
                                    {index + 1 !== authorsAndIllustrators.length && '/ '}
                                </span>
                            ))
                        ) : (
                            <Typography variant="body1">-</Typography>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    renderSideBar = () => {
        const { result, language } = this.props
        const isAudio = result.bindingCodeType === 'audio'
        const isDigitalDelivery = ['FIL', 'EBOK'].includes(getOr(false, `bindingCode`, result))

        const members = getOr([], `members`, result).map((member) => ({
            ...member,
            role: translateRole(member.role),
        }))
        const illustrators = members.filter(filterMemberByIllustrator)
        const photographers = members.filter(filterMemberByPhotographer)
        const reciters = members.filter(filterMemberByReciter)
        const coverDesigners = members.filter(filterMemberByCoverDesigner)
        const themas = getOr([], 'themas', result).filter(
            (thema) => thema.languageCode === language,
        )
        const salesTargetProductions = getOr([], `productions`, result)
        const workSalesTargetTotal = salesTargetProductions.reduce(
            (acc, production) => production.totalSalesTarget + acc,
            0,
        )
        const mainEdition: any = first(
            getOr([], `productions`, result).filter((p) => p.isMainEdition),
        )
        const publishers = members.filter(filterMemberByPublisher)
        const editors = members.filter(filterMemberByEditor)
        const communicationStrategists = members.filter(filterMemberByCommunicationStrategist)
        const mediaProjectLeaders = members.filter(filterMemberByMediaProjectLead)
        const otherMembers = [
            ...publishers,
            ...editors,
            ...communicationStrategists,
            ...mediaProjectLeaders,
        ]
        const playtimeHours = getOr(0, 'audioPlaytimeHours', result)
        const playtimeMinutes = getOr(0, 'audioPlaytimeMinutes', result)
        const publishingDate = result.mainEditionPublishingDate
            ? DateTime.fromISO(result.mainEditionPublishingDate).toISODate()
            : '-'
        const reviewDate = result.mainEditionReviewDate
            ? DateTime.fromISO(result.mainEditionReviewDate).toISODate()
            : '-'
        const hasSameReviewAndPublishingDate = publishingDate === reviewDate
        const creatorInvolvements = [
            result.willAuthorParticipate &&
                (getLabel('work.launchbrief.willauthorparticipate') ?? 'Author will participate'),
            result.isAuthorAvailableForEvents &&
                (getLabel('work.launchbrief.isauthoravailableforevents') ??
                    'Author is available for events'),
            result.isAuthorAvailableForPress &&
                (getLabel('work.launchbrief.isauthoravailableforpress') ??
                    'Author is available for press'),
        ].filter(Boolean)

        const displayFormatedNumber = (totalSalesTarget) => (
            <NumericFormat
                value={totalSalesTarget}
                displayType={'text'}
                decimalSeparator=","
                decimalScale={0}
                thousandSeparator=" "
            />
        )

        return (
            <Grid container spacing={0} rowSpacing={0.5} xs={3}>
                <Grid xs={12}>
                    <AssetDetailsThumbnail
                        assetId={result.mainEditionCoverImageAssetId}
                        maxWidth={150}
                        alt="bokomslag"
                    />
                </Grid>
                {illustrators.length > 0
                    ? this.infoDisplay(
                          illustrators.map((illustrator, index) => (
                              <span key={`illustrator-${illustrator.id}`}>
                                  {`${illustrator.contact.firstName} ${illustrator.contact.lastName}`.trim()}
                                  {index + 1 !== illustrators.length && ','}
                              </span>
                          )),
                          getLabel('work.launchbrief.illustrator') ?? 'Illustrator',
                      )
                    : null}
                {photographers.length > 0
                    ? this.infoDisplay(
                          photographers.map((illustrator, index) => (
                              <span key={`photographer-${illustrator.id}`}>
                                  {`${illustrator.contact.firstName} ${illustrator.contact.lastName}`.trim()}
                                  {index + 1 !== illustrators.length && ','}
                              </span>
                          )),
                          getLabel('work.launchbrief.photographer') ?? 'Photographer',
                      )
                    : null}
                {this.infoDisplay(
                    result.mainEditionInterestAgeName,
                    getLabel('work.launchbrief.interestage') ?? 'Age group',
                )}
                {this.infoDisplay(
                    result.mainEditionEffortTypeName,
                    getLabel('work.launchbrief.efforttype') ?? 'List category',
                )}
                {isAudio && reciters.length > 0
                    ? this.infoDisplay(
                          reciters.map((reciter, index) => (
                              <span key={`reciter-${reciter.id}`}>
                                  {`${reciter.contact.firstName} ${reciter.contact.lastName}`.trim()}
                                  {index + 1 !== reciters.length && ','}
                              </span>
                          )),
                          getLabel('work.launchbrief.reciter') ?? 'Reciter',
                      )
                    : null}
                {this.infoDisplay(
                    result.mainEditionSeriesName &&
                        result.mainEditionNumberInSeries &&
                        `${result.mainEditionSeriesName} ${result.mainEditionNumberInSeries}`,
                    getLabel('work.launchbrief.numberinseries') ?? 'Order in series',
                )}
                {this.infoDisplay(
                    themas.map((thema, index) => (
                        <span key={`thema-${thema.codeValue}`}>
                            {`${thema.codeDescription}`}
                            {index + 1 !== themas.length && ','}
                        </span>
                    )),
                    getLabel('work.launchbrief.thema') ?? 'Thema',
                )}
                {isAudio &&
                    (playtimeHours !== 0 || playtimeMinutes !== 0) &&
                    this.infoDisplay(
                        <span>
                            {playtimeHours && playtimeHours !== 0
                                ? ` ${playtimeHours} ${getLabel('work.launchbrief.hours') ?? 'hours'}`
                                : null}
                            {playtimeMinutes && playtimeMinutes !== 0
                                ? ` ${playtimeMinutes} ${getLabel('work.launchbrief.minutes') ?? 'min'}`
                                : null}
                        </span>,
                        getLabel('work.launchbrief.runningtime') ?? 'Running time',
                    )}
                {this.infoDisplay(result.isbn, getLabel('work.launchbrief.isbn') ?? 'ISBN')}
                {this.infoDisplay(
                    result.bindingCode,
                    getLabel('work.launchbrief.format') ?? 'Format',
                )}

                {!isDigitalDelivery && hasSameReviewAndPublishingDate ? (
                    this.infoDisplay(
                        publishingDate,
                        getLabel('work.launchbrief.publicationandreviewdate') ??
                            'Publication date/review date',
                    )
                ) : (
                    <Grid>
                        {this.infoDisplay(
                            publishingDate,
                            getLabel('work.launchbrief.publicationdate') ?? 'Publication date',
                        )}
                        {!isDigitalDelivery &&
                            this.infoDisplay(
                                reviewDate,
                                getLabel('work.launchbrief.reviewdate') ?? 'Review date',
                            )}
                    </Grid>
                )}
                {coverDesigners.length > 0
                    ? this.infoDisplay(
                          coverDesigners.map((coverDesigner, index) => (
                              <span key={`coverDesigner-${coverDesigner.id}`}>
                                  {`${coverDesigner.contact.firstName} ${coverDesigner.contact.lastName}`.trim()}
                                  {index + 1 !== coverDesigners.length && ','}
                              </span>
                          )),
                          getLabel('work.launchbrief.coverdesigner') ?? 'Cover designer',
                      )
                    : null}
                {result.mainEditionPages
                    ? this.infoDisplay(
                          result.mainEditionPages,
                          getLabel('work.launchbrief.pages') ?? 'Pages',
                      )
                    : result.mainEditionEstimatedNumberOfPages
                      ? this.infoDisplay(
                            result.mainEditionEstimatedNumberOfPages,
                            getLabel('work.launchbrief.estimatednumberofpages') ?? 'Pages',
                        )
                      : null}
                {creatorInvolvements.length > 0
                    ? this.infoDisplay(
                          <Fragment>
                              {creatorInvolvements.map((text, index) => (
                                  <Typography key={index}>{text}</Typography>
                              ))}
                          </Fragment>,
                          getLabel('work.launchbrief.creatorinvolvement') ?? 'Creator involvement',
                      )
                    : null}
                {otherMembers.length > 0
                    ? this.infoDisplay(
                          <Fragment>
                              {otherMembers.map((member) => (
                                  <Typography variant="body2" key={member.id}>
                                      <Contact
                                          roleFirst={true}
                                          {...member.contact}
                                          role={member.role}
                                      />
                                  </Typography>
                              ))}
                          </Fragment>,
                          getLabel('work.launchbrief.pointofcontacts') ?? 'Point of contacts',
                      )
                    : null}
                {mainEdition
                    ? this.infoDisplay(
                          <Fragment>
                              <Typography variant="body2" key="mainEdition">
                                  {mainEdition.bindingCodeName}
                              </Typography>
                              <Typography variant="body2" key="resellerPrice">
                                  {getLabel('work.launchbrief.retailerprice') ?? 'Price excl. VAT:'}
                                  {mainEdition.resellerPrice
                                      ? ` ${mainEdition.resellerPrice}`
                                      : ' -'}
                              </Typography>
                          </Fragment>,
                          getLabel('work.launchbrief.mainedition') ?? 'Main edition',
                      )
                    : null}
                {salesTargetProductions.length > 0
                    ? this.infoDisplay(
                          <Fragment>
                              <Typography variant="body2" fontWeight="bold" key="work">
                                  {getLabel('work.launchbrief.worksalestotal') ?? 'Total'}
                                  {': '}
                                  {displayFormatedNumber(workSalesTargetTotal)}
                              </Typography>
                              {salesTargetProductions.map((production) => (
                                  <Typography variant="body2" key={production.id}>
                                      {production.bindingCodeName}
                                      {': '}
                                      {production.totalSalesTarget
                                          ? displayFormatedNumber(production.totalSalesTarget)
                                          : displayFormatedNumber(0)}
                                  </Typography>
                              ))}
                          </Fragment>,
                          getLabel('work.launchbrief.salestargeteditions') ??
                              'Editions (sales target)',
                      )
                    : null}
            </Grid>
        )
    }

    renderMain = () => {
        const { result, publishingHouseGroupIds, metaData } = this.props
        const description = get(`description`, result)
        const authorText = get(`authorText`, result)
        const purpose = get(`purpose`, result)
        const opportunities = get(`opportunities`, result)
        const challenges = get(`challenges`, result)
        const overallGoals = get(`overallGoals`, result)
        const descriptionCore = UseDescriptionCore(publishingHouseGroupIds)
            ? getOr('', `textFullByTypes.${texts.descriptionCore}.text`, result)
            : getOr('', `textFullByTypes.1.text`, result)

        if (metaData === null) return <div></div>
        const internalSalesPitchId = metaData.textTypes
            .filter((textType) =>
                getOr([], 'customProperties.textTypeUsage', textType).includes('salesArgument'),
            )
            .find((tt) => getOr('', 'customProperties.textTypeUsage', tt)).id

        const internalSalesPitch = getOr(
            '',
            `mainEditionTextFullByTypes.${internalSalesPitchId}.text`,
            result,
        )
        const targetGroup: any = first(getOr(false, `targetGroups`, result))
        return (
            <Grid container spacing={1} xs={8} direction="column">
                {description ? (
                    <Grid xs={11}>
                        <Typography variant="body1" fontWeight="bold">
                            {getLabel('work.launchbrief.aboutbook') ?? 'About the book'}
                        </Typography>
                        <Typography variant="body2">{description}</Typography>
                    </Grid>
                ) : null}
                {authorText ? (
                    <Grid xs={11}>
                        <Typography variant="body1" fontWeight="bold">
                            {getLabel('work.launchbrief.aboutcreator') ?? 'About the creator'}
                        </Typography>
                        <Typography variant="body2">{authorText}</Typography>
                    </Grid>
                ) : null}
                {purpose ? (
                    <Grid xs={11}>
                        <Typography variant="body1" fontWeight="bold">
                            {getLabel('work.launchbrief.purpose') ??
                                'Reasons for publishing the book'}
                        </Typography>
                        <Typography variant="body2">{purpose}</Typography>
                    </Grid>
                ) : null}
                {targetGroup ? (
                    <Grid xs={11}>
                        {targetGroup.name ? (
                            <Typography variant="body1" fontWeight="bold">
                                {`${getLabel('work.launchbrief.targetpersona') ?? 'Target persona'} ${
                                    targetGroup.brief === '' || !targetGroup.brief
                                        ? ''
                                        : ' / ' + getLabel('work.launchbrief.targetpersonabrief') ||
                                          'The fight'
                                } `}
                            </Typography>
                        ) : null}
                        <Typography variant="body2">
                            {`${targetGroup.name} ${
                                targetGroup.brief === '' || !targetGroup.brief
                                    ? ''
                                    : ' / ' + targetGroup.brief
                            } `}
                        </Typography>
                    </Grid>
                ) : null}
                {opportunities ? (
                    <Grid xs={11}>
                        <Typography fontWeight="bold">
                            {getLabel('work.launchbrief.opportunitites') ?? 'Opportunitites'}
                        </Typography>
                        <Typography variant="body2">{opportunities}</Typography>
                    </Grid>
                ) : null}
                {challenges ? (
                    <Grid xs={11}>
                        <Typography variant="body1" fontWeight="bold">
                            {getLabel('work.launchbrief.challenges') ?? 'Challenges'}
                        </Typography>
                        <Typography variant="body2">{challenges}</Typography>
                    </Grid>
                ) : null}
                {overallGoals ? (
                    <Grid xs={11}>
                        <Typography variant="body1" fontWeight="bold">
                            {getLabel('work.launchbrief.overallgoals') ??
                                'General communications goals'}
                        </Typography>
                        <Typography variant="body2">{overallGoals}</Typography>
                    </Grid>
                ) : null}
                {internalSalesPitch ? (
                    <Grid xs={11}>
                        <Typography variant="body1" fontWeight="bold">
                            {getLabel('work.launchbrief.internalsalespitch') ?? 'USP (several)'}
                        </Typography>
                        <Typography
                            variant="body2"
                            dangerouslySetInnerHTML={{
                                __html: sanitizer(newLineToHtml(internalSalesPitch)),
                            }}
                        />
                    </Grid>
                ) : null}
                {descriptionCore ? (
                    <Grid xs={11}>
                        <Typography variant="body1" fontWeight="bold">
                            {getLabel('work.launchbrief.descriptioncore') ?? 'Description (core)'}
                        </Typography>
                        <Typography
                            variant="body2"
                            dangerouslySetInnerHTML={{
                                __html: sanitizer(newLineToHtml(descriptionCore)),
                            }}
                        />
                    </Grid>
                ) : null}
            </Grid>
        )
    }

    render() {
        return (
            <Grid container spacing={2} xs={12}>
                <Grid xs={12} sx={{ display: { displayPrint: 'none' } }}>
                    <Divider sx={{ borderBottomWidth: 4, background: 'black' }} />
                </Grid>
                <Grid container spacing={1} xs={12} className="pageBreak">
                    {this.renderHeader()}
                    <Grid xs={12}>
                        <Divider />
                    </Grid>
                    <Grid container spacing={2} xs={12}>
                        {this.renderSideBar()}
                        <Divider orientation="vertical" />
                        {this.renderMain()}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

const connector = connect((state: RootState) => {
    const { auth } = state
    const publishingHouseGroupIds = getOr([], 'data.opus_user.publishingHouseGroups', auth).map(
        ({ id }) => id,
    )
    return {
        publishingHouseGroupIds,
    }
})

export default connector(LaunchBriefViewItem)
