import { getOr } from 'lodash/fp'
import { DateTime } from 'luxon'
import { Component } from 'react'
import { NavLink } from 'react-router-dom'

import { styled } from '@mui/material'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Unstable_Grid2'

import Contact from 'Common/components/contacts/Contact'
import { getLabel } from 'Common/environment'
import { filterMemberByAuthor } from 'Common/utils/roles'

const HiddenDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    [theme.breakpoints.down('md')]: {
        display: 'none',
    },
}))

const styles = {
    productionRow: {
        borderTop: '1px solid #e0e0e0',
    },
}

type Props = {
    result: any
}

class LaunchViewItem extends Component<Props> {
    render() {
        const { result } = this.props
        const authors = result.members.filter(filterMemberByAuthor)
        return (
            <Grid xs={12}>
                <Grid container spacing={2} xs={12} style={styles.productionRow}>
                    <Grid xs={8} sm={4}>
                        <Link underline="none" component={NavLink} to={`/book/${result.workId}`}>
                            {result.title}
                        </Link>
                        <HiddenDiv>{getOr('', 'publishingHouseName', result)}</HiddenDiv>
                    </Grid>
                    <Grid xs={8} sm={2}>
                        {authors.length > 0 ? (
                            authors.map((author, index) => (
                                <span key={author.id}>
                                    <Contact {...author.contact} lastNameFirst />
                                    {index + 1 !== authors.length && ' /'}
                                </span>
                            ))
                        ) : (
                            <span>-</span>
                        )}
                    </Grid>
                    <Grid xs={8} sm={2}>
                        {result.publishingDate
                            ? DateTime.fromISO(result.publishingDate).toISODate()
                            : '-'}
                    </Grid>
                    <Grid xs={8} sm={2}>
                        <Link
                            underline="none"
                            component={NavLink}
                            to={`/book/${result.workId}/launch-brief`}
                        >
                            {getLabel('work.productions.search.launchview.launchbrief') ??
                                'Launch brief'}
                        </Link>
                    </Grid>
                    <Grid xs={8} sm={2}>
                        <Link
                            underline="none"
                            component={NavLink}
                            to={`/book/${result.workId}/launch-plan`}
                        >
                            {getLabel('work.productions.search.launchview.launchplan') ??
                                'Launch plan'}
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default LaunchViewItem
