import { services } from 'Common/environment'
import createErrorMessages from 'Common/messages/createErrorMessages'
import request from 'Common/utils/net/request'

import messages from '../messages'

export const GET_CONTACT_DETAILS = 'GET_CONTACT_DETAILS'
export const GET_CONTACT_DETAILS_REQUEST = 'GET_CONTACT_DETAILS_REQUEST'
export const GET_CONTACT_DETAILS_SUCCESS = 'GET_CONTACT_DETAILS_SUCCESS'
export const GET_CONTACT_DETAILS_FAILURE = 'GET_CONTACT_DETAILS_FAILURE'
export const PATCH_CONTACT_DETAILS = 'PATCH_CONTACT_DETAILS'
export const PATCH_CONTACT_DETAILS_REQUEST = 'PATCH_CONTACT_DETAILS_REQUEST'
export const PATCH_CONTACT_DETAILS_SUCCESS = 'PATCH_CONTACT_DETAILS_SUCCESS'
export const PATCH_CONTACT_DETAILS_FAILURE = 'PATCH_CONTACT_DETAILS_FAILURE'
const GET_CONTACT_DETAILS_ERROR_MESSAGES = createErrorMessages(
    ['CONTACT_NOT_FOUND', 'CONTACT_FORBIDDEN'],
    messages,
)
export const getContactDetails = (id) => (dispatch) =>
    dispatch({
        type: GET_CONTACT_DETAILS,
        meta: {
            id,
        },
        payload: {
            promise: request({
                method: 'GET',
                url: services.contact(`/v2/contacts/${id}`),
                errorMessages: GET_CONTACT_DETAILS_ERROR_MESSAGES,
            }),
        },
    })
const PATCH_CONTACT_DETAILS_ERROR_MESSAGES = createErrorMessages(
    [
        'CONTACT_NOT_FOUND',
        'CONTACT_FORBIDDEN',
        'CONTACT_ZIP_CODE_INVALID',
        'CONTACT_ERP_SUPPLIER_NOT_FOUND',
        'CONTACT_ERP_CUSTOMER_ALREADY_CONNECTED',
        'CONTACT_ERP_CUSTOMER_NOT_FOUND',
    ],
    messages,
)
export const patchContactDetails = (id, contact) => (dispatch) =>
    dispatch({
        type: PATCH_CONTACT_DETAILS,
        meta: {
            id,
        },
        payload: {
            promise: request({
                method: 'PATCH',
                url: services.contact(`/v2/contacts/${id}`),
                body: contact,
                errorMessages: PATCH_CONTACT_DETAILS_ERROR_MESSAGES,
            }),
        },
    })
