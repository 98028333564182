import { Component } from 'react'
import { ConnectedProps, connect } from 'react-redux'

import { Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { MainWrapper, PageWrapper } from 'Common/core'
import { RootState } from 'Common/store/createStore'

import { clearGenericError as clearGenericErrorAction } from './errorActions'

const mapState = (state: RootState) => {
    const { error } = state
    return {
        error,
    }
}

const connector = connect(mapState, {
    clearGenericError: clearGenericErrorAction,
})

type PropsFromRedux = ConnectedProps<typeof connector>

class ErrorPage extends Component<PropsFromRedux> {
    componentWillUnmount() {
        const { clearGenericError } = this.props
        clearGenericError()
    }

    render() {
        const { error } = this.props
        return (
            <MainWrapper>
                <PageWrapper>
                    <Grid xs={12}>
                        <Typography variant="h4" gutterBottom>
                            {error.header ||
                                'Unfortunately something went wrong with the page you were trying to visit.'}
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography variant="body1">
                            {error.paragraph ||
                                'Either the page does not exist or something went wrong while loading the page.'}
                        </Typography>
                    </Grid>
                </PageWrapper>
            </MainWrapper>
        )
    }
}
export default connector(ErrorPage)
