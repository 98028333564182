export const getTimePlanPlannedColumns = (category, cellType) => (timePlanEntryTypes) =>
    timePlanEntryTypes.reduce((acc, timePlanEntryType) => {
        if (timePlanEntryType.id > 40) {
            return acc
        }
        return {
            ...acc,
            [`timeplan-planned-${timePlanEntryType.id}`]: {
                id: `timeplan-planned-${timePlanEntryType.id}`,
                selectField: `activePrintTimeplan${timePlanEntryType.id}Planned`,
                label: `${timePlanEntryType.name || timePlanEntryType.id}, planned`,
                width: 180,
                category,
                cellType,
                sortable: true,
            },
        }
    }, {})

export const getTimePlanActualColumns = (category, cellType) => (timePlanEntryTypes) =>
    timePlanEntryTypes.reduce((acc, timePlanEntryType) => {
        if (timePlanEntryType.id > 40) {
            return acc
        }
        return {
            ...acc,
            [`timeplan-actual-${timePlanEntryType.id}`]: {
                id: `timeplan-actual-${timePlanEntryType.id}`,
                selectField: `activePrintTimeplan${timePlanEntryType.id}Actual`,
                label: `${timePlanEntryType.name || timePlanEntryType.id}, actual`,
                width: 180,
                category,
                cellType,
                sortable: true,
            },
        }
    }, {})
