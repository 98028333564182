import { requestStatus } from 'Common/utils/net/statuses'

import { GET_PRODUCTION_REQUEST } from '../actions/productionActions'
import {
    GET_PRODUCTION_TEXTS_FAILURE,
    GET_PRODUCTION_TEXTS_REQUEST,
    GET_PRODUCTION_TEXTS_SUCCESS,
    PATCH_PRODUCTION_TEXTS_FAILURE,
    PATCH_PRODUCTION_TEXTS_REQUEST,
    PATCH_PRODUCTION_TEXTS_SUCCESS,
} from '../actions/productionTextsActions'

const initialState = {
    status: {
        getStatus: undefined,
        getError: undefined,
    },
    data: undefined,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCTION_REQUEST:
            return { ...initialState }

        case GET_PRODUCTION_TEXTS_REQUEST:
            return {
                ...state,
                workId: action.payload.workId,
                productionId: action.payload.productionId,
                data: null,
                status: { ...state.status, getStatus: requestStatus.request, getError: null },
            }

        case GET_PRODUCTION_TEXTS_SUCCESS:
            return {
                ...state,
                data: action.payload.json,
                status: { ...state.status, getStatus: requestStatus.success },
            }

        case GET_PRODUCTION_TEXTS_FAILURE:
            return {
                ...state,
                status: {
                    ...state.status,
                    getStatus: requestStatus.failure,
                    getError: action.payload,
                },
            }

        case PATCH_PRODUCTION_TEXTS_REQUEST:
            return {
                ...state,
                status: { ...state.status, patchStatus: requestStatus.request, patchError: null },
            }

        case PATCH_PRODUCTION_TEXTS_SUCCESS:
            return {
                ...state,
                data: action.payload.json,
                status: { ...state.status, patchStatus: requestStatus.success },
            }

        case PATCH_PRODUCTION_TEXTS_FAILURE:
            return {
                ...state,
                status: {
                    ...state.status,
                    patchStatus: requestStatus.failure,
                    patchError: action.payload,
                },
            }

        default:
            return state
    }
}
