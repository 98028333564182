export const getContactNames = (contact: any = {}) => {
    const { isCompanyContact = false, company = {}, person = {} } = contact
    const firstName = (isCompanyContact ? company.name1 : person.firstName) || ''
    const lastName = (isCompanyContact ? company.name2 : person.lastName) || ''
    const fullName = `${firstName} ${lastName}`.trim()
    return {
        firstName,
        lastName,
        fullName,
    }
}
export const getInitialPhoneNumbers = (phoneNumbers = []) => {
    if (phoneNumbers.length > 1) return phoneNumbers
    const phoneNumber = {
        type: 'phoneNumber',
        value: '',
    }
    const mobileNumber = {
        type: 'mobileNumber',
        value: '',
    }
    if (phoneNumbers.length === 0) return [phoneNumber, mobileNumber]
    if (!phoneNumbers.find((nr) => nr.type === 'phoneNumber')) return [phoneNumber, ...phoneNumbers]
    return [...phoneNumbers, mobileNumber]
}
export const getInitialEmailAddresses = (emailAddresses = []) => {
    if (emailAddresses.length > 0) return emailAddresses
    return [
        {
            type: 'workEmail',
            value: '',
        },
    ]
}
