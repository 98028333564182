import { services } from 'Common/environment'
import { AppDispatch } from 'Common/types'
import request from 'Common/utils/net/request'

export const GET_PRODUCTION_SALES_TARGET = 'GET_PRODUCTION_SALES_TARGET'
export const GET_PRODUCTION_SALES_TARGET_REQUEST = 'GET_PRODUCTION_SALES_TARGET_REQUEST'
export const GET_PRODUCTION_SALES_TARGET_SUCCESS = 'GET_PRODUCTION_SALES_TARGET_SUCCESS'
export const GET_PRODUCTION_SALES_TARGET_FAILURE = 'GET_PRODUCTION_SALES_TARGET_FAILURE'
export const PUT_PRODUCTION_SALES_TARGET = 'PUT_PRODUCTION_SALES_TARGET'
export const PUT_PRODUCTION_SALES_TARGET_REQUEST = 'PUT_PRODUCTION_SALES_TARGET_REQUEST'
export const PUT_PRODUCTION_SALES_TARGET_SUCCESS = 'PUT_PRODUCTION_SALES_TARGET_SUCCESS'
export const PUT_PRODUCTION_SALES_TARGET_FAILURE = 'PUT_PRODUCTION_SALES_TARGET_FAILURE'

export const getProductionSalesTarget =
    (workId: number, productionId: number) => (dispatch: AppDispatch) =>
        dispatch({
            type: GET_PRODUCTION_SALES_TARGET,
            payload: {
                data: {
                    workId,
                    productionId,
                },
                promise: request({
                    method: 'GET',
                    url: services.work(
                        `/v1/works/${workId}/productions/${productionId}/salestarget`,
                    ),
                }),
            },
        })
export const getProductionsSalesTarget =
    (workId: number, productionIds: number[]) => (dispatch: AppDispatch) => {
        if (!productionIds || productionIds.length === 0) return
        return Promise.all(
            productionIds.map((productionId) =>
                dispatch({
                    type: GET_PRODUCTION_SALES_TARGET,
                    payload: {
                        data: {
                            workId,
                            productionId,
                        },
                        promise: request({
                            method: 'GET',
                            url: services.work(
                                `/v1/works/${workId}/productions/${productionId}/salestarget`,
                            ),
                        }),
                    },
                }),
            ),
        )
    }
export const putProductionSalesTarget =
    (workId: number, productionId: number, putBody: any) => (dispatch: AppDispatch) =>
        dispatch({
            type: PUT_PRODUCTION_SALES_TARGET,
            payload: {
                data: {
                    workId,
                    productionId,
                },
                promise: request({
                    method: 'PUT',
                    url: services.work(
                        `/v1/works/${workId}/productions/${productionId}/salestarget`,
                    ),
                    body: putBody,
                }),
            },
        })
