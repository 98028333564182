import { getOr } from 'lodash/fp'
import { DateTime } from 'luxon'
import { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { Divider, Typography } from '@mui/material'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Unstable_Grid2'

import AssetDetailsThumbnail from 'Common/components/assets/AssetDetailsThumbnail'
import { getLabel, services } from 'Common/environment'
import { MetaData } from 'Common/metadata'
import { RootState } from 'Common/types'
import { UseDescriptionCore } from 'Common/utils/featureConfig'
import {
    filterMemberByAuthor,
    filterMemberByCoverDesigner,
    filterMemberByIllustrator,
    filterMemberByMediaProjectLead,
    filterMemberByReciter,
    filterMemberByTranslator,
} from 'Common/utils/roles'
import { sanitizer } from 'Common/utils/sanitizer'
import { texts } from 'Common/utils/texts'

const newLineToHtml = (text) =>
    `<p>${text
        .replace(/\r\n\r\n/g, '</p><p>')
        .replace(/\n\n/g, '</p><p>')
        .replace(/\n/g, '</p><p>')}</p>`

type Props = {
    result: any
    language?: string
    metaData: MetaData
    publishingHouseGroupIds: string[]
}

export type State = {
    logoError: boolean
}

class SalesPitchRetailerViewItem extends Component<Props, State> {
    state = {
        logoError: false,
    }

    renderHeader = () => {
        const { result } = this.props
        const { logoError } = this.state
        const logoUrl = services.storage('/logos/publishinghouse-') + result.publishingHouse
        const showLogo = !logoError && logoUrl
        const authors = result.members.filter(filterMemberByAuthor)
        const illustrators = result.members.filter(filterMemberByIllustrator)
        const authorsAndIllustrators = [...authors, ...illustrators]
        const title = getOr('-', 'title', result)
        const subtitle = getOr(false, 'subtitle', result)
        const displayTitle = `${title}${subtitle ? ' : ' + subtitle : ''}`
        return (
            <Grid container spacing={0} xs={12} flexDirection={'row-reverse'}>
                <Grid xs={12} sm={5} display="flex" justifyContent="flex-end">
                    <Typography variant="h5" fontWeight="bold">
                        {showLogo ? (
                            <img
                                src={logoUrl}
                                alt="logo"
                                style={{
                                    maxHeight: '50px',
                                    maxWidth: '210px',
                                    width: '210px',
                                    height: '50px',
                                    objectFit: 'contain',
                                }}
                                onError={() =>
                                    this.setState({
                                        logoError: true,
                                    })
                                }
                            />
                        ) : (
                            <span>
                                {getOr(
                                    getOr('-', 'publishingHouseName', result),
                                    'brandName',
                                    result,
                                )}
                            </span>
                        )}
                    </Typography>
                </Grid>
                <Grid xs={12} sm={7}>
                    <Link
                        variant="h4"
                        underline="none"
                        component={NavLink}
                        to={`/book/${result.workId}/edition/${result.id}`}
                    >
                        {displayTitle}
                    </Link>
                </Grid>
                <Grid xs={12}>
                    <Grid xs={12} sm={7}>
                        {authorsAndIllustrators.length > 0 ? (
                            authorsAndIllustrators.map((person, index) => (
                                <span key={person.id}>
                                    {`${person.contact.firstName} ${person.contact.lastName}`.trim()}
                                    {index + 1 !== authorsAndIllustrators.length && '/ '}
                                </span>
                            ))
                        ) : (
                            <Typography>-</Typography>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    infoDisplay(value, header) {
        return (Array.isArray(value) ? value.length > 0 : value) ? (
            <Grid xs={12}>
                {header && <Typography variant="body2">{header}</Typography>}
                <Typography variant="body2">{value}</Typography>
            </Grid>
        ) : null
    }

    renderSideBar = () => {
        const { result, language } = this.props
        const isAudio = result.bindingCodeType === 'audio'
        const isDigitalDelivery = ['FIL', 'EBOK'].includes(getOr(false, `bindingCode`, result))

        const authors = result.members.filter(filterMemberByAuthor)
        const illustrators = result.members.filter(filterMemberByIllustrator)
        const reciters = result.members.filter(filterMemberByReciter)
        const translators = result.members.filter(filterMemberByTranslator)
        const coverDesigners = result.members.filter(filterMemberByCoverDesigner)
        const mediaProjectLeads = result.members.filter(filterMemberByMediaProjectLead)
        const playtimeHours = getOr(0, 'audioPlaytimeHours', result)
        const playtimeMinutes = getOr(0, 'audioPlaytimeMinutes', result)
        const themas = getOr([], 'themas', result).filter(
            (thema) => thema.languageCode === language,
        )
        const hasSameReviewAndPublishingDate = result.publishingDate === result.reviewDate
        return (
            <Grid container spacing={0} rowSpacing={0.5} xs={12} sm={3}>
                <Grid xs={12}>
                    <Grid xs={12}>
                        <AssetDetailsThumbnail
                            assetId={result.coverImageAssetId}
                            maxWidth={150}
                            alt="bokomslag"
                        />
                    </Grid>
                    {authors.length > 0
                        ? this.infoDisplay(
                              authors.map((author, index) => (
                                  <span key={`author-${author.id}`}>
                                      {`${author.contact.firstName} ${author.contact.lastName}`.trim()}
                                      {index + 1 !== authors.length && ','}
                                  </span>
                              )),
                              getLabel('work.productions.search.salespitch.author') ?? 'Author',
                          )
                        : null}
                    {illustrators.length > 0
                        ? this.infoDisplay(
                              illustrators.map((illustrator, index) => (
                                  <span key={`illustrator-${illustrator.id}`}>
                                      {`${illustrator.contact.firstName} ${illustrator.contact.lastName}`.trim()}
                                      {index + 1 !== illustrators.length && ','}
                                  </span>
                              )),
                              getLabel('work.productions.search.salespitch.illustrator') ??
                                  'Illustrator',
                          )
                        : null}
                    {this.infoDisplay(
                        result.originalTitle,
                        getLabel('work.productions.search.salespitch.originaltitle') ??
                            'Original title',
                    )}
                    {translators.length > 0
                        ? this.infoDisplay(
                              translators.map((translator, index) => (
                                  <span key={`translators-${translator.id}`}>
                                      {`${translator.contact.firstName} ${translator.contact.lastName}`.trim()}
                                      {index + 1 !== translators.length && ','}
                                  </span>
                              )),
                              getLabel('work.productions.search.salespitch.translator') ??
                                  'Translator',
                          )
                        : null}
                    {isAudio && reciters.length > 0
                        ? this.infoDisplay(
                              reciters.map((reciter, index) => (
                                  <span key={`reciter-${reciter.id}`}>
                                      {`${reciter.contact.firstName} ${reciter.contact.lastName}`.trim()}
                                      {index + 1 !== reciters.length && ','}
                                  </span>
                              )),
                              getLabel('work.productions.search.salespitch.reciter') ?? 'Reciter',
                          )
                        : null}
                    {this.infoDisplay(
                        result.seriesName && `${result.seriesName} ${result.numberInSeries ?? ''}`,
                        getLabel('work.productions.search.salespitch.series') ?? 'Series',
                    )}
                    {this.infoDisplay(
                        themas.map((thema, index) => (
                            <span key={`thema-${thema.codeValue}`}>
                                {`${thema.codeDescription}`}
                                {index + 1 !== themas.length && ','}
                            </span>
                        )),
                        getLabel('work.productions.search.salespitch.thema') ?? 'Thema',
                    )}
                    {isAudio &&
                        (playtimeHours !== 0 || playtimeMinutes !== 0) &&
                        this.infoDisplay(
                            <span>
                                {playtimeHours && playtimeHours !== 0
                                    ? ` ${playtimeHours} ${
                                          getLabel('work.productions.search.salespitch.hours') ??
                                          'hours'
                                      }`
                                    : null}
                                {playtimeMinutes && playtimeMinutes !== 0
                                    ? ` ${playtimeMinutes} ${
                                          getLabel('work.productions.search.salespitch.minutes') ??
                                          'min'
                                      }`
                                    : null}
                            </span>,
                            getLabel('work.productions.search.salespitch.runningtime') ??
                                'Running time',
                        )}
                    {this.infoDisplay(
                        result.isbn,
                        getLabel('work.productions.search.salespitch.isbn') ?? 'ISBN',
                    )}
                    {this.infoDisplay(
                        result.bindingCodeName,
                        getLabel('work.productions.search.salespitch.format') ?? 'Format',
                    )}

                    {!isDigitalDelivery && hasSameReviewAndPublishingDate ? (
                        result.publishingDate ? (
                            this.infoDisplay(
                                DateTime.fromISO(result.publishingDate).toISODate(),
                                getLabel(
                                    'work.productions.search.salespitch.publicationandreviewdate',
                                ) ?? 'Publication date/review date',
                            )
                        ) : null
                    ) : (
                        <Grid xs={12}>
                            {result.publishingDate
                                ? this.infoDisplay(
                                      DateTime.fromISO(result.publishingDate).toISODate(),
                                      getLabel(
                                          'work.productions.search.salespitch.publicationdate',
                                      ) ?? 'Publication date',
                                  )
                                : null}
                            {!isDigitalDelivery && result.reviewDate
                                ? this.infoDisplay(
                                      DateTime.fromISO(result.reviewDate).toISODate(),
                                      getLabel('work.productions.search.salespitch.reviewdate') ??
                                          'Review date',
                                  )
                                : null}
                        </Grid>
                    )}
                    {coverDesigners.length > 0
                        ? this.infoDisplay(
                              coverDesigners.map((coverDesigner, index) => (
                                  <span key={`coverDesigner-${coverDesigner.id}`}>
                                      {`${coverDesigner.contact.firstName} ${coverDesigner.contact.lastName}`.trim()}
                                      {index + 1 !== coverDesigners.length && ','}
                                  </span>
                              )),
                              getLabel('work.productions.search.salespitch.coverdesigner') ??
                                  'Cover designer',
                          )
                        : null}
                    {result.pages
                        ? this.infoDisplay(
                              result.pages,
                              getLabel('work.productions.search.salespitch.pages') ?? 'Pages',
                          )
                        : result.estimatedNumberOfPages
                          ? this.infoDisplay(
                                result.estimatedNumberOfPages,
                                getLabel(
                                    'work.productions.search.salespitch.estimatednumberofpages',
                                ) ?? 'Estimated number of pages',
                            )
                          : null}
                    {this.infoDisplay(
                        result.interestAgeName,
                        getLabel('work.productions.search.salespitch.targetpersona') ??
                            'Target persona',
                    )}
                    {!isDigitalDelivery && mediaProjectLeads.length > 0
                        ? this.infoDisplay(
                              mediaProjectLeads.map((mediaProjectLead, index) => (
                                  <span key={`mediaProjectLead-${mediaProjectLead.id}`}>
                                      {`${mediaProjectLead.contact.firstName} ${mediaProjectLead.contact.lastName}`.trim()}
                                      {index + 1 !== mediaProjectLeads.length && ','}
                                  </span>
                              )),
                              getLabel('work.productions.search.salespitch.mediacontact') ??
                                  'Media contact',
                          )
                        : null}
                </Grid>
            </Grid>
        )
    }

    renderMain = () => {
        const { result, publishingHouseGroupIds } = this.props

        const headline = getOr('', `textFullByTypes.${texts.headline}.text`, result)
        const descriptionCore = UseDescriptionCore(publishingHouseGroupIds)
            ? getOr('', `textFullByTypes.${texts.descriptionCore}.text`, result)
            : getOr('', `textFullByTypes.1.text`, result)
        const authorPortrait = getOr('', `textFullByTypes.${texts.authorPortrait}.text`, result)
        const illustratorPortrait = getOr(
            '',
            `textFullByTypes.${texts.illustratorPortrait}.text`,
            result,
        )
        return (
            <Grid container spacing={0} rowSpacing={0.5} xs={12} sm={8}>
                <Grid xs={12}>
                    {headline ? (
                        <Grid xs={12}>
                            <Typography
                                variant="body2"
                                dangerouslySetInnerHTML={{
                                    __html: sanitizer(newLineToHtml(headline)),
                                }}
                            />
                        </Grid>
                    ) : null}
                    {descriptionCore ? (
                        <Grid xs={12}>
                            <Typography variant="body2">
                                {getLabel('work.productions.search.salespitch.aboutbook.header') ??
                                    'About the book'}
                            </Typography>
                            <Typography
                                variant="body2"
                                dangerouslySetInnerHTML={{
                                    __html: sanitizer(newLineToHtml(descriptionCore)),
                                }}
                            />
                        </Grid>
                    ) : null}
                    {authorPortrait ? (
                        <Grid xs={12}>
                            <Typography variant="body2">
                                {getLabel(
                                    'work.productions.search.salespitch.aboutauthor.header',
                                ) ?? 'About the author'}
                            </Typography>
                            <Typography
                                variant="body2"
                                dangerouslySetInnerHTML={{
                                    __html: sanitizer(newLineToHtml(authorPortrait)),
                                }}
                            />
                        </Grid>
                    ) : null}
                    {illustratorPortrait ? (
                        <Grid xs={12}>
                            <Typography variant="body2">
                                {getLabel('work.productions.search.salespitch.aboutillustrator') ??
                                    'About the illustrator'}
                            </Typography>
                            <Typography
                                variant="body2"
                                dangerouslySetInnerHTML={{
                                    __html: sanitizer(newLineToHtml(illustratorPortrait)),
                                }}
                            />
                        </Grid>
                    ) : null}
                </Grid>
            </Grid>
        )
    }

    render() {
        return (
            <Grid container spacing={2} xs={12}>
                <Grid xs={12} sx={{ display: { displayPrint: 'none' } }}>
                    <Divider sx={{ borderBottomWidth: 2, background: 'black' }} />
                </Grid>
                <Grid container spacing={1} xs={12} className="pageBreak">
                    {this.renderHeader()}
                    <Grid xs={12}>
                        <Divider />
                    </Grid>
                    <Grid container spacing={1} xs={12}>
                        {this.renderSideBar()}
                        <Divider orientation="vertical" />
                        {this.renderMain()}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

const connector = connect((state: RootState) => {
    const { auth } = state
    const publishingHouseGroupIds = getOr([], 'data.opus_user.publishingHouseGroups', auth).map(
        ({ id }) => id,
    )
    return {
        publishingHouseGroupIds,
    }
})

export default connector(SalesPitchRetailerViewItem)
