import { services } from 'Common/environment'
import createErrorMessages from 'Common/messages/createErrorMessages'
import request from 'Common/utils/net/request'

import messages from '../messages'

export const POST_PRODUCTION_TIMEPLAN_ADD_PACKAGE = 'POST_PRODUCTION_TIMEPLAN_ADD_PACKAGE'
export const POST_PRODUCTION_TIMEPLAN_ADD_PACKAGE_REQUEST =
    'POST_PRODUCTION_TIMEPLAN_ADD_PACKAGE_REQUEST'
export const POST_PRODUCTION_TIMEPLAN_ADD_PACKAGE_SUCCESS =
    'POST_PRODUCTION_TIMEPLAN_ADD_PACKAGE_SUCCESS'
export const POST_PRODUCTION_TIMEPLAN_ADD_PACKAGE_FAILURE =
    'POST_PRODUCTION_TIMEPLAN_ADD_PACKAGE_FAILURE'
export const POST_PRODUCTION_TIMEPLAN_ENTRY = 'POST_PRODUCTION_TIMEPLAN_ENTRY'
export const POST_PRODUCTION_TIMEPLAN_ENTRY_REQUEST = 'POST_PRODUCTION_TIMEPLAN_ENTRY_REQUEST'
export const POST_PRODUCTION_TIMEPLAN_ENTRY_SUCCESS = 'POST_PRODUCTION_TIMEPLAN_ENTRY_SUCCESS'
export const POST_PRODUCTION_TIMEPLAN_ENTRY_FAILURE = 'POST_PRODUCTION_TIMEPLAN_ENTRY_FAILURE'
export const PUT_PRODUCTION_TIMEPLAN_ENTRY = 'PUT_PRODUCTION_TIMEPLAN_ENTRY'
export const PUT_PRODUCTION_TIMEPLAN_ENTRY_REQUEST = 'PUT_PRODUCTION_TIMEPLAN_ENTRY_REQUEST'
export const PUT_PRODUCTION_TIMEPLAN_ENTRY_SUCCESS = 'PUT_PRODUCTION_TIMEPLAN_ENTRY_SUCCESS'
export const PUT_PRODUCTION_TIMEPLAN_ENTRY_FAILURE = 'PUT_PRODUCTION_TIMEPLAN_ENTRY_FAILURE'
export const DELETE_PRODUCTION_TIMEPLAN_ENTRY = 'DELETE_PRODUCTION_TIMEPLAN_ENTRY'
export const DELETE_PRODUCTION_TIMEPLAN_ENTRY_REQUEST = 'DELETE_PRODUCTION_TIMEPLAN_ENTRY_REQUEST'
export const DELETE_PRODUCTION_TIMEPLAN_ENTRY_SUCCESS = 'DELETE_PRODUCTION_TIMEPLAN_ENTRY_SUCCESS'
export const DELETE_PRODUCTION_TIMEPLAN_ENTRY_FAILURE = 'DELETE_PRODUCTION_TIMEPLAN_ENTRY_FAILURE'
const POST_PRODUCTION_TIMEPLAN_ADD_PACKAGE_ERROR_MESSAGES = createErrorMessages(
    ['PRODUCTION_NOT_FOUND', 'PRODUCTION_FORBIDDEN'],
    messages,
)
export const postProductionTimePlanPackage = (workId, productionId, body) => (dispatch) =>
    dispatch({
        type: POST_PRODUCTION_TIMEPLAN_ADD_PACKAGE,
        meta: {
            workId,
            productionId,
        },
        payload: {
            promise: request({
                method: 'POST',
                body,
                url: services.work(
                    `/v1/works/${workId}/productions/${productionId}/activeprint/timePlan/addpackage`,
                ),
                errorMessages: POST_PRODUCTION_TIMEPLAN_ADD_PACKAGE_ERROR_MESSAGES,
            }),
        },
    })
const POST_PRODUCTION_TIMEPLAN_ENTRY_ERROR_MESSAGES = createErrorMessages(
    ['PRODUCTION_NOT_FOUND', 'PRODUCTION_FORBIDDEN'],
    messages,
)
export const postProductionTimePlanEntry = (workId, productionId, body) => (dispatch) =>
    dispatch({
        type: POST_PRODUCTION_TIMEPLAN_ENTRY,
        meta: {
            workId,
            productionId,
        },
        payload: {
            promise: request({
                method: 'POST',
                body,
                url: services.work(
                    `/v1/works/${workId}/productions/${productionId}/activeprint/timePlan/entries`,
                ),
                errorMessages: POST_PRODUCTION_TIMEPLAN_ENTRY_ERROR_MESSAGES,
            }),
        },
    })
const PUT_PRODUCTION_TIMEPLAN_ENTRY_ERROR_MESSAGES = createErrorMessages(
    ['PRODUCTION_NOT_FOUND', 'PRODUCTION_FORBIDDEN'],
    messages,
)
export const putProductionTimePlanEntry = (workId, productionId, body, entryId) => (dispatch) =>
    dispatch({
        type: PUT_PRODUCTION_TIMEPLAN_ENTRY,
        meta: {
            workId,
            productionId,
            body,
        },
        payload: {
            promise: request({
                method: 'PUT',
                body,
                url: services.work(
                    `/v1/works/${workId}/productions/${productionId}/activeprint/timePlan/entries/${entryId}`,
                ),
                errorMessages: PUT_PRODUCTION_TIMEPLAN_ENTRY_ERROR_MESSAGES,
            }),
        },
    })
const DELETE_PRODUCTION_TIMEPLAN_ENTRY_ERROR_MESSAGES = createErrorMessages(
    ['PRODUCTION_NOT_FOUND', 'PRODUCTION_FORBIDDEN'],
    messages,
)
export const deleteProductionTimePlanEntry = (workId, productionId, entryId) => (dispatch) =>
    dispatch({
        type: DELETE_PRODUCTION_TIMEPLAN_ENTRY,
        meta: {
            workId,
            productionId,
            entryId,
        },
        payload: {
            promise: request({
                url: services.work(
                    `/v1/works/${workId}/productions/${productionId}/activeprint/timePlan/entries/${entryId}`,
                ),
                method: 'DELETE',
                errorMessages: DELETE_PRODUCTION_TIMEPLAN_ENTRY_ERROR_MESSAGES,
            }),
        },
    })
