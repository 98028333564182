import { requestStatus } from '../utils/net/statuses'
import {
    CLEAR_ASSET,
    GET_ASSETS_FAILURE,
    GET_ASSETS_REQUEST,
    GET_ASSETS_SUCCESS,
    GET_ASSET_FAILURE,
    GET_ASSET_REQUEST,
    GET_ASSET_SUCCESS,
    PATCH_ASSET_FAILURE,
    PATCH_ASSET_SUCCESS,
} from './AssetsActions'

const initialState = {
    list: [],
    searchOptions: {
        query: '',
        orderBy: 'result',
    },
    status: {
        getStatus: undefined,
        getError: undefined,
    },
    active: undefined,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_ASSET:
            return { ...initialState }

        case GET_ASSETS_REQUEST:
            return {
                ...state,
                active: null,
                list: [],
                listStatus: requestStatus.request,
                searchOptions: action.payload.searchOptions,
            }

        case GET_ASSETS_SUCCESS: {
            const { json } = action.payload
            const list = json.results.map((result) => {
                return { ...result.document }
            })
            return { ...state, list, status: { ...state.status, getStatus: requestStatus.success } }
        }

        case PATCH_ASSET_FAILURE:
        case GET_ASSET_FAILURE:
        case GET_ASSETS_FAILURE:
            return {
                ...state,
                active: null,
                status: {
                    ...state.status,
                    getStatus: requestStatus.failure,
                    getError: action.payload,
                },
            }

        case GET_ASSET_REQUEST:
            return {
                ...state,
                active: null,
                status: { ...state.status, getStatus: requestStatus.request, getError: null },
            }

        case PATCH_ASSET_SUCCESS:
        case GET_ASSET_SUCCESS: {
            const { json } = action.payload
            return {
                ...state,
                active: { ...state.active, ...json },
                status: { ...state.status, getStatus: requestStatus.success },
            }
        }

        default:
            return state
    }
}
