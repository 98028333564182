import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'

import Grid from '@mui/material/Unstable_Grid2'

import { postUserFeedback } from 'Common/api/UserFeedbackApi'
import { AuthData } from 'Common/auth/authActions'
import { Dialog, PlainButton } from 'Common/core'
import { Form } from 'Common/form'
import CheckboxInputController from 'Common/form/hookForm/CheckboxInputController'
import TextInputController from 'Common/form/hookForm/TextInputController'
import { ErrorResponse, ProblemDetails, UserFeedbackForm } from 'Common/types'
import { useHandleError } from 'Common/utils/query/handleError'

import Spinner from '../loader/Spinner'
import { useSnackBar } from '../snackbars/SnackbarToast'

type Props = {
    closeDialog: () => void
    userInfo: AuthData
}
const UserFeedbackDialog = (props: Props) => {
    const { userInfo } = props
    const snackBar = useSnackBar()
    const handleError = useHandleError()

    const form = useForm<UserFeedbackForm>({
        defaultValues: {
            firstname: userInfo.opus_user?.firstName,
            lastname: userInfo.opus_user?.lastName,
            email: userInfo.opus_user?.email,
            url: window.location.href,
            feedback: '',
            includeScreenshot: true,
            canBeContacted: true,
        },
    })
    const {
        handleSubmit,
        formState: { isDirty, isSubmitting },
        control,
        watch,
    } = form

    const feedbackMutation = useMutation(postUserFeedback, {
        onError: (error: ProblemDetails & ErrorResponse) => {
            handleError(error)
        },
        onSuccess: () => {
            snackBar.show({
                severity: 'success',
                title: 'Your feedback was sent to Mockingbird',
            })
        },
    })

    const screenshot = window.sessionStorage.getItem('feedbackScreenshot')

    const doSubmit = async (data: any) => {
        const formData: UserFeedbackForm = {
            ...data,
            screenshot: data.includeScreenshot ? screenshot : null,
        }
        await feedbackMutation.mutateAsync(formData)

        closeDialog()
    }

    const { closeDialog } = props

    return (
        <>
            <Dialog
                title="Send us feedback"
                actions={[
                    <PlainButton
                        key="send-feedback"
                        onClick={handleSubmit(doSubmit)}
                        submitting={isSubmitting}
                        disabled={!isDirty || isSubmitting}
                    >
                        {'Send feedback!'}
                    </PlainButton>,
                ]}
                onClose={closeDialog}
            >
                <Form onSubmit={doSubmit}>
                    <Grid container spacing={2} xs={12}>
                        <Grid container xs={6}>
                            <Grid xs={12}>
                                <Grid xs={12}>
                                    <TextInputController
                                        name="firstname"
                                        label="Firstname"
                                        control={control}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <TextInputController
                                        name="lastname"
                                        label="Lastname"
                                        control={control}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <TextInputController
                                        name="email"
                                        label="E-mail"
                                        control={control}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <TextInputController
                                        name="feedback"
                                        label="Enter feedback here"
                                        control={control}
                                        fullWidth
                                        multiline
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <CheckboxInputController
                                        name="canBeContacted"
                                        label="If we need any further information, may we contact you?"
                                        control={control}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <p>
                                        Mockingbird Publishing Software AB values your feedback.
                                        Please let us know what you like, dislike, and new features
                                        that would make processes easier. Your input will be read
                                        and taken into consideration as we continuously enhance the
                                        system and improve our work practices.
                                    </p>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={6}>
                            <CheckboxInputController
                                name="includeScreenshot"
                                label="Include screenshot"
                                control={control}
                            />
                            {watch('includeScreenshot') ? (
                                <img
                                    src={screenshot}
                                    alt="screenshot"
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                    }}
                                />
                            ) : null}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        {isSubmitting ? <Spinner /> : null}
                    </Grid>
                </Form>
            </Dialog>
        </>
    )
}
export default UserFeedbackDialog
