import { searchConfig as productionsSearchConfig } from 'Work/config/productionsConfig'
import { Component } from 'react'

import { Button, MenuItem, Popover } from '@mui/material'

import AddContactToEditions from '../BatchEdits/BatchAddContactToEditions'
import BatchAddListingCode from '../BatchEdits/BatchAddListingCode'
import BatchEditActiveWebPeriod from '../BatchEdits/BatchEditActiveWebPeriod'
import BatchEditExportRules from '../BatchEdits/BatchEditExportRules'
import PublishEditions from '../BatchEdits/BatchPublishEditions'

type State = {
    showMenu: boolean
    showAddContactToEditionDialog: boolean
    showPublishEditionsDialog: boolean
    showExportRulesDialog: boolean
    showListingCodeDialog: boolean
    showActiveWebPeriodDialog: boolean
}

class ProductionSearchOperations extends Component<State> {
    state = {
        showMenu: false,
        showAddContactToEditionDialog: false,
        showPublishEditionsDialog: false,
        showExportRulesDialog: false,
        showListingCodeDialog: false,
        showActiveWebPeriodDialog: false,
    }

    private _isMounted: boolean

    actionMenuAnchor: HTMLSpanElement

    componentDidMount() {
        this._isMounted = true
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    getMenuItems = () => {
        const items = [
            {
                title: 'Add member to editions',
                action: this.togglAddContactToEditionsDialog,
                isAvailable: true,
            },
            {
                title: 'Make public',
                action: this.togglePublishEditionsDialog,
                isAvailable: true,
            },
            {
                title: 'Edit governing code',
                action: this.toggleListingCodeDialog,
                isAvailable: true,
            },
            {
                title: 'Edit export rules',
                action: this.toggleExportRulesDialog,
                isAvailable: true,
            },
            {
                title: 'Edit active web period',
                action: this.toggleActiveWebPeriodDialog,
                isAvailable: true,
            },
        ]
        return items.filter((_) => _.isAvailable)
    }

    toggleMenu = (reason) =>
        this.setState((state) => ({
            showMenu: reason === 'clickAway' ? false : !state.showMenu,
        }))

    togglAddContactToEditionsDialog = () => {
        if (!this._isMounted) return
        this.setState((state) => ({
            showAddContactToEditionDialog: !state.showAddContactToEditionDialog,
            showMenu: false,
        }))
    }

    togglePublishEditionsDialog = () => {
        if (!this._isMounted) return
        this.setState((state) => ({
            showPublishEditionsDialog: !state.showPublishEditionsDialog,
            showMenu: false,
        }))
    }

    toggleListingCodeDialog = () => {
        if (!this._isMounted) return
        this.setState((state) => ({
            showListingCodeDialog: !state.showListingCodeDialog,
            showMenu: false,
        }))
    }

    toggleExportRulesDialog = () => {
        if (!this._isMounted) return
        this.setState((state) => ({
            showExportRulesDialog: !state.showExportRulesDialog,
            showMenu: false,
        }))
    }

    toggleActiveWebPeriodDialog = () => {
        if (!this._isMounted) return
        this.setState((state) => ({
            showActiveWebPeriodDialog: !state.showActiveWebPeriodDialog,
            showMenu: false,
        }))
    }

    render() {
        const {
            showMenu,
            showAddContactToEditionDialog,
            showPublishEditionsDialog,
            showListingCodeDialog,
            showExportRulesDialog,
            showActiveWebPeriodDialog,
        } = this.state
        const menuItems = this.getMenuItems()
        if (menuItems.length === 0) return null
        const WrappedPublishEditions: any = PublishEditions
        return (
            <div className="col">
                {showAddContactToEditionDialog ? (
                    <AddContactToEditions closeModal={this.togglAddContactToEditionsDialog} />
                ) : null}
                {showPublishEditionsDialog ? (
                    <WrappedPublishEditions closeModal={this.togglePublishEditionsDialog} />
                ) : null}
                {showListingCodeDialog ? (
                    <BatchAddListingCode closeModal={this.toggleListingCodeDialog} />
                ) : null}
                {showExportRulesDialog ? (
                    <BatchEditExportRules
                        searchConfig={productionsSearchConfig}
                        closeModal={this.toggleExportRulesDialog}
                    />
                ) : null}
                {showActiveWebPeriodDialog ? (
                    <BatchEditActiveWebPeriod
                        searchConfig={productionsSearchConfig}
                        closeModal={this.toggleActiveWebPeriodDialog}
                    />
                ) : null}
                <span
                    ref={(el) => {
                        this.actionMenuAnchor = el
                    }}
                >
                    <Button variant="outlined" onClick={this.toggleMenu} color="primary">
                        Actions
                    </Button>
                </span>
                <Popover open={showMenu} anchorEl={this.actionMenuAnchor} onClose={this.toggleMenu}>
                    {menuItems.map((menuItem) => (
                        <MenuItem key={menuItem.title} onClick={menuItem.action}>
                            {menuItem.title}
                        </MenuItem>
                    ))}
                </Popover>
            </div>
        )
    }
}

export default ProductionSearchOperations
