import { requestStatus } from 'Common/utils/net/statuses'

import { POST_CONCEPT_FAILURE, POST_CONCEPT_SUCCESS } from '../actions/ConceptActions'

const initialState = {
    results: [],
    searchOptions: {
        query: '',
        orderBy: 'created',
        status: 'ongoing',
    },
    offset: 0,
    paging: null,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case POST_CONCEPT_SUCCESS: {
            const { json } = action.payload
            let concepts = null

            if (typeof state.results !== 'undefined') {
                state.results.unshift({
                    ...{
                        document: json,
                    },
                })
                concepts = state.results
            } else {
                concepts = [
                    {
                        ...{
                            document: json,
                        },
                    },
                ]
            }

            return { ...state, status: requestStatus.success, results: concepts }
        }

        case POST_CONCEPT_FAILURE:
            return {
                ...state,
                status: requestStatus.failure,
                errorStatus: action.payload.statusText,
            }

        default:
            return state
    }
}
