import { requestStatus } from 'Common/utils/net/statuses'

import {
    GET_MY_RELATED_CONCEPTS_FAILURE,
    GET_MY_RELATED_CONCEPTS_REQUEST,
    GET_MY_RELATED_CONCEPTS_SUCCESS,
} from '../actions/ConceptActions'

const initialState = {
    results: [],
    status: undefined,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_MY_RELATED_CONCEPTS_REQUEST: {
            return { ...state, results: [], status: requestStatus.request }
        }

        case GET_MY_RELATED_CONCEPTS_SUCCESS: {
            const { json } = action.payload
            const list = json.results.map((result) => {
                return { ...result.document }
            })
            return { ...state, results: list, status: requestStatus.success }
        }

        case GET_MY_RELATED_CONCEPTS_FAILURE:
            return {
                ...state,
                status: requestStatus.failure,
                statusText: action.payload.statusText,
                statusCode: action.payload.status,
            }

        default:
            return state
    }
}
