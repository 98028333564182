import { Column, Table } from 'fixed-data-table-2'
import { getOr } from 'lodash/fp'

import Grid from '@mui/material/Unstable_Grid2'

import { requestStatus } from '../../utils/net/statuses'
import { DEFAULT_COLUMN_WIDTH } from './AdvancedSearchView'
import CheckboxCell from './cells/CheckboxCell'
import DefaultCell from './cells/DefaultCell'
import HeaderCell from './cells/HeaderCell'
import HeaderCheckboxCell from './cells/HeaderCheckboxCell'

type Props = {
    getResult(..._args: unknown[]): any
    updateSorting(..._args: unknown[]): unknown
    onColumnReorderEndCallback(..._args: unknown[]): unknown
    onColumnResizeEndCallback(..._args: unknown[]): unknown
    viewConfig: {
        columns: Record<string, unknown>
    }
    searchState: {
        searchOptions: {
            orderBy: string
        }
        results: unknown[]
        status?: string
        globalSelectionStatus?: string
    }
    workingView: {
        activeColumns: unknown[]
        columnWidths: Record<string, unknown>
    }
    resultsCount: number
    tableWidth: number
    viewProps?: Record<string, unknown>
    hasActions?: boolean
    toggleSingle(..._args: unknown[]): unknown
    toggleAll(..._args: unknown[]): unknown
    language?: string
}

const AdvancedViewTable = (props: Props) => {
    const {
        viewConfig,
        searchState,
        viewProps,
        workingView,
        resultsCount,
        tableWidth,
        getResult,
        updateSorting,
        onColumnReorderEndCallback,
        onColumnResizeEndCallback,
        hasActions = false,
        toggleSingle,
        toggleAll,
        language,
    } = props
    const { columns } = viewConfig
    const { searchOptions, results, status, globalSelectionStatus = 'none' } = searchState
    const { activeColumns, columnWidths } = workingView
    const disableSelectAll =
        getOr(false, 'selectedResult.length', searchState) >= 50 ||
        getOr(false, 'paging.itemsTotalCount', searchState) > 50
    const fakeResultsCount = Math.max(
        Math.min(results.length + 25, resultsCount),
        status === requestStatus.request && results.length === 0 ? 1 : 0,
    )
    return (
        <Grid xs={12} id="advancedSearchViewTableContainer">
            <Table
                rowsCount={fakeResultsCount}
                onColumnReorderEndCallback={onColumnReorderEndCallback}
                onColumnResizeEndCallback={onColumnResizeEndCallback}
                isColumnReordering={false}
                isColumnResizing={false}
                width={tableWidth}
                height={600}
                rowHeight={30}
                headerHeight={55}
            >
                {hasActions ? (
                    <Column
                        key="start"
                        header={
                            <HeaderCheckboxCell
                                disabled={disableSelectAll}
                                {...{
                                    props,
                                    toggleAll,
                                    globalSelectionStatus,
                                }}
                            />
                        }
                        cell={({ rowIndex, ...props }) => {
                            const [resultStatus, result] = getResult(rowIndex)
                            return (
                                <CheckboxCell
                                    {...{
                                        props,
                                        toggleSingle,
                                        viewProps,
                                        result,
                                        resultStatus,
                                        searchState,
                                    }}
                                />
                            )
                        }}
                        fixedLeft={true}
                        fixed={true}
                        width={50}
                        height={30}
                        minWidth={40}
                        allowCellsRecycling
                    />
                ) : null}
                {activeColumns.map((columnId: any) => {
                    const column: any = columns[columnId]
                    return (
                        <Column
                            key={column.id}
                            columnKey={column.id}
                            header={
                                <HeaderCell
                                    updateSorting={updateSorting}
                                    orderBy={searchOptions.orderBy}
                                    column={column}
                                />
                            }
                            cell={({ rowIndex, ...props }) => {
                                const ColumnCell = column.cellType || DefaultCell
                                const [resultStatus, result] = getResult(rowIndex)
                                return (
                                    <ColumnCell
                                        key={column.id}
                                        {...{
                                            viewProps,
                                            result,
                                            resultStatus,
                                            column,
                                            language,
                                        }}
                                        {...props}
                                    />
                                )
                            }}
                            width={columnWidths[column.id] || DEFAULT_COLUMN_WIDTH}
                            minWidth={column.minWidth || 90}
                            allowCellsRecycling
                            isReorderable
                            isResizable
                        />
                    )
                })}
            </Table>
        </Grid>
    )
}

export default AdvancedViewTable
