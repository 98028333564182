import IconAttachment from '@mui/icons-material/Attachment'
import IconImage from '@mui/icons-material/Image'
import IconPdf from '@mui/icons-material/PictureAsPdf'

export const getMimeTypeIcon = (mimeType) => {
    return mimeType === 'application/pdf' ? (
        <IconPdf sx={{ width: 30, height: 30 }} color="action" />
    ) : mimeType && mimeType.includes('image/') ? (
        <IconImage sx={{ width: 30, height: 30 }} color="action" />
    ) : (
        <IconAttachment sx={{ width: 30, height: 30 }} color="action" />
    )
}
