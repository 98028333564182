import { servicesLoaders } from 'Common/environment'
import BasicSearchView from 'Common/search/view/BasicSearchView'

import BatchFailedViewHeader from './BatchFailedViewHeader'
import BatchFailedViewItem from './BatchFailedViewItem'

export const searchConfig = {
    id: 'batchProdutionExportRulesFailed',
    searchService: servicesLoaders.work('/v2/search/productions'),
    searchServiceExport: null,
    defaultSelectFields: ['id', 'workId', 'title', 'isbn', 'bindingCodeName'],
    resultsLabel: 'Failed to update editions',
    pagingSize: 50,
    staticControls: true,
    hideQuery: true,
    hideFilters: true,
    queryFields: [
        {
            id: 'id',
            label: 'ID, edition',
        },
    ],
    filters: [],
}
export const viewsConfig = {
    views: [
        {
            id: 'basic',
            label: 'Basic',
            Component: BasicSearchView,
            componentConfig: {
                HeaderComponent: BatchFailedViewHeader,
                ItemComponent: BatchFailedViewItem,
            },
        },
    ],
}
