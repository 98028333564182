import { requestStatus } from '../utils/net/statuses'
import {
    GET_CONTACT_FAILURE,
    GET_CONTACT_REQUEST,
    GET_CONTACT_SUCCESS,
    PATCH_CONTACT_FAILURE,
    PATCH_CONTACT_SUCCESS,
    POST_CONTACT_SUCCESS,
} from './ContactsActions'

const initialState = {
    active: {
        profiles: [
            {
                person: {
                    firstName: '',
                    lastName: '',
                },
                contactDetails: [],
                addresses: [],
            },
        ],
    },
    activeStatus: undefined,
    activeStatusText: undefined,
    list: [],
    searchOptions: {
        query: '',
        orderBy: 'result',
    },
}
export default (state: any = initialState, action) => {
    switch (action.type) {
        case GET_CONTACT_REQUEST:
            return {
                ...state,
                active: {
                    id: action.payload,
                },
                activeStatus: requestStatus.request,
            }

        case POST_CONTACT_SUCCESS:
        case PATCH_CONTACT_SUCCESS:
        case GET_CONTACT_SUCCESS: {
            const { json } = action.payload
            return {
                ...state,
                active: { ...state.active, profiles: [json] },
                activeStatus: requestStatus.success,
            }
        }

        case PATCH_CONTACT_FAILURE:
        case GET_CONTACT_FAILURE:
            return {
                ...state,
                status: action.payload.status,
                activeStatusText: action.payload.statusText,
                activeStatus: requestStatus.failure,
            }

        default:
            return state
    }
}
