import { services } from 'Common/environment'

import { buildQuery } from '../multiSearch/queryBuilder'
import request from '../utils/net/request'

export const SEARCH_THEMA = 'SEARCH_THEMA'
export const SEARCH_THEMA_REQUEST = 'SEARCH_THEMA_REQUEST'
export const SEARCH_THEMA_SUCCESS = 'SEARCH_THEMA_SUCCESS'
export const SEARCH_THEMA_FAILURE = 'SEARCH_THEMA_FAILURE'

export const CLEAN_SEARCH_THEMA = 'CLEAN_SEARCH_THEMA'
export const CLEAN_SEARCH_THEMA_REQUEST = 'CLEAN_SEARCH_THEMA_REQUEST'
export const CLEAN_SEARCH_THEMA_SUCCESS = 'CLEAN_SEARCH_THEMA_SUCCESS'
export const CLEAN_SEARCH_THEMA_FAILURE = 'CLEAN_SEARCH_THEMA_FAILURE'

export const CLEAR_THEMA_SEARCH = 'CLEAR_THEMA_SEARCH'

export const searchThema = (themaCodeSearchConfig: any = {}, clearStore = false) => {
    return (dispatch) => {
        const url = services.metadata(
            `/v2/search/thema${buildQuery({
                oDataQuery: themaCodeSearchConfig,
            })}`,
        )
        return dispatch({
            type: clearStore ? CLEAN_SEARCH_THEMA : SEARCH_THEMA,
            meta: {
                themaCodeSearchConfig,
            },
            payload: {
                promise: request({
                    url,
                    method: 'GET',
                }),
            },
        })
    }
}

export const clearThemaSearch = () => {
    return {
        type: CLEAR_THEMA_SEARCH,
    }
}
