import { services } from 'Common/environment'

import request from '../utils/net/request'

export const GET_SETTINGS = 'GET_SETTINGS'
export const GET_SETTINGS_REQUEST = 'GET_SETTINGS_REQUEST'
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS'
export const GET_SETTINGS_FAILURE = 'GET_SETTINGS_FAILURE'
export const GET_SETTING = 'GET_SETTING'
export const GET_SETTING_REQUEST = 'GET_SETTING_REQUEST'
export const GET_SETTING_SUCCESS = 'GET_SETTING_SUCCESS'
export const GET_SETTING_FAILURE = 'GET_SETTING_FAILURE'
export const POST_SETTINGS = 'POST_SETTINGS'
export const POST_SETTINGS_REQUEST = 'POST_SETTINGS_REQUEST'
export const POST_SETTINGS_SUCCESS = 'POST_SETTINGS_SUCCESS'
export const POST_SETTINGS_FAILURE = 'POST_SETTINGS_FAILURE'
export const POST_SETTING = 'POST_SETTING'
export const POST_SETTING_REQUEST = 'POST_SETTING_REQUEST'
export const POST_SETTING_SUCCESS = 'POST_SETTING_SUCCESS'
export const POST_SETTING_FAILURE = 'POST_SETTING_FAILURE'
export const getSettings = () => (dispatch) =>
    dispatch({
        type: GET_SETTINGS,
        payload: {
            promise: request({
                method: 'GET',
                url: services.users(`/v1/users/currentuser/settings`),
            }),
        },
    })
export const getSetting = (key) => (dispatch) =>
    dispatch({
        type: GET_SETTING,
        meta: {
            key,
        },
        payload: {
            promise: request({
                method: 'GET',
                url: services.users(`/v1/users/currentuser/settings/${key}`),
            }),
        },
    })
export const postSettings = (settings) => (dispatch) =>
    dispatch({
        type: POST_SETTINGS,
        payload: {
            data: settings,
            promise: request({
                url: services.users(`/v1/users/currentuser/settings`),
                method: 'POST',
                body: Object.keys(settings).reduce(
                    (acc, cur) => ({ ...acc, [cur]: JSON.stringify(settings[cur]) }),
                    {},
                ),
            }),
        },
    })
export const postSetting = (key, value) => (dispatch) =>
    dispatch({
        type: POST_SETTING,
        meta: {
            key,
        },
        payload: {
            data: {
                [key]: value,
            },
            promise: request({
                url: services.users(`/v1/users/currentuser/settings`),
                method: 'POST',
                body: {
                    [key]: JSON.stringify(value),
                },
            }),
        },
    })
