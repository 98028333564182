import { Environment } from './index'

export const SET_ENVIRONMENT = 'SET_ENVIRONMENT'
export type ActionType = 'SET_ENVIRONMENT'
export type Action = {
    type: ActionType
    envPayload: Environment
}
export const setEnvironment = (env: Environment): Action => {
    return {
        type: SET_ENVIRONMENT,
        envPayload: env,
    }
}
