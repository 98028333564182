import { SearchThemaCode } from 'Common/thema/types'

import { RequestType, requestStatus } from '../utils/net/statuses'
import {
    CLEAN_SEARCH_THEMA_FAILURE,
    CLEAN_SEARCH_THEMA_REQUEST,
    CLEAN_SEARCH_THEMA_SUCCESS,
    CLEAR_THEMA_SEARCH,
    SEARCH_THEMA_FAILURE,
    SEARCH_THEMA_REQUEST,
    SEARCH_THEMA_SUCCESS,
} from './themaActions'

const initialState = {
    data: [],
    status: {},
    pagination: {},
}

type SearchThemaCodeState = {
    data: SearchThemaCode[]
    status: {
        getStatus?: RequestType
        getError?: any
    }
    pagination: any
}
export default (state: SearchThemaCodeState = initialState, action) => {
    switch (action.type) {
        case SEARCH_THEMA_REQUEST:
        case CLEAN_SEARCH_THEMA_REQUEST:
            return { ...state, status: { ...state.status, getStatus: requestStatus.request } }

        case SEARCH_THEMA_SUCCESS:
            return {
                ...state,
                data: [...state.data, ...action.payload.json.results],
                pagination: action.payload.json.pagination,
                status: { ...state.status, getStatus: requestStatus.success },
            }

        case CLEAN_SEARCH_THEMA_SUCCESS:
            return {
                ...state,
                data: [...action.payload.json.results],
                pagination: action.payload.json.pagination,
                status: { getStatus: requestStatus.success },
            }

        case SEARCH_THEMA_FAILURE:
        case CLEAN_SEARCH_THEMA_FAILURE:
            return {
                ...state,
                status: {
                    ...state.status,
                    getStatus: requestStatus.failure,
                    getError: action.payload,
                },
            }
        case CLEAR_THEMA_SEARCH:
            return {
                ...initialState,
                status: { ...state.status },
            }
        default:
            return state
    }
}
