import { services } from 'Common/environment'
import createErrorMessages from 'Common/messages/createErrorMessages'
import request from 'Common/utils/net/request'

import messages from '../messages'

export const GET_CONTACT_RESELLER_INFO = 'GET_CONTACT_RESELLER_INFO'
export const GET_CONTACT_RESELLER_INFO_REQUEST = 'GET_CONTACT_RESELLER_INFO_REQUEST'
export const GET_CONTACT_RESELLER_INFO_SUCCESS = 'GET_CONTACT_RESELLER_INFO_SUCCESS'
export const GET_CONTACT_RESELLER_INFO_FAILURE = 'GET_CONTACT_RESELLER_INFO_FAILURE'
export const PATCH_CONTACT_RESELLER_INFO = 'PATCH_CONTACT_RESELLER_INFO'
export const PATCH_CONTACT_RESELLER_INFO_REQUEST = 'PATCH_CONTACT_RESELLER_INFO_REQUEST'
export const PATCH_CONTACT_RESELLER_INFO_SUCCESS = 'PATCH_CONTACT_RESELLER_INFO_SUCCESS'
export const PATCH_CONTACT_RESELLER_INFO_FAILURE = 'PATCH_CONTACT_RESELLER_INFO_FAILURE'
const GET_CONTACT_RESELLER_INFO_ERROR_MESSAGES = createErrorMessages(
    ['CONTACT_NOT_FOUND', 'CONTACT_FORBIDDEN'],
    messages,
)
export const getContactResellerInfo = (contactId) => (dispatch) =>
    dispatch({
        type: GET_CONTACT_RESELLER_INFO,
        meta: {
            contactId,
        },
        payload: {
            promise: request({
                method: 'GET',
                url: services.contact(`/v2/contacts/${contactId}/resellerinfo`),
                errorMessages: GET_CONTACT_RESELLER_INFO_ERROR_MESSAGES,
            }),
        },
    })
const PATCH_CONTACT_RESELLER_INFO_ERROR_MESSAGES = createErrorMessages(
    [
        'CONTACT_NOT_FOUND',
        'CONTACT_FORBIDDEN',
        'CONTACT_ERP_CUSTOMER_GROUP_NOT_FOUND',
        'CONTACT_ERP_CUSTOMER_SUB_GROUP_NOT_FOUND',
    ],
    messages,
)
export const patchContactResellerInfo = (contactId, resellerInfo) => (dispatch) =>
    dispatch({
        type: PATCH_CONTACT_RESELLER_INFO,
        meta: {
            contactId,
        },
        payload: {
            promise: request({
                url: services.contact(`/v2/contacts/${contactId}/resellerinfo`),
                method: 'PATCH',
                body: resellerInfo,
                errorMessages: PATCH_CONTACT_RESELLER_INFO_ERROR_MESSAGES,
            }),
        },
    })
