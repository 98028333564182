import { useSnackBar } from 'Common/components/snackbars/SnackbarToast'
import { ErrorResponse, ProblemDetails } from 'Common/types'

export const useHandleError = () => {
    const snackBar = useSnackBar()

    const handleError = (error: ProblemDetails & ErrorResponse) => {
        switch (error.status) {
            case 400:
                snackBar.show({
                    severity: 'error',
                    title: 'Invalid Request',
                    message: error?.reasons
                        ? error.reasons.map((reason) => reason).join('\n')
                        : error?.error?.message
                          ? error.error.message
                          : 'The request could not be processed. Please check your input and try again.',
                })
                break
            case 500:
            case 502:
            case 503:
            case 504:
                snackBar.show({
                    severity: 'error',
                    title: 'Server Error',
                    message: 'Something went wrong on our end. Please try again later.',
                })
                break
            default:
                snackBar.show({
                    severity: 'error',
                    title: error.title || 'Error',
                    message: error?.reasons
                        ? error.reasons.map((reason) => reason).join('\n')
                        : error?.error?.message
                          ? error.error.message
                          : 'An unexpected error occurred.',
                })

                break
        }
    }

    return handleError
}
