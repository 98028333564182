import { getOr } from 'lodash/fp'
import { Component } from 'react'
import { NavLink } from 'react-router-dom'

import { Button, Checkbox, FormControlLabel } from '@mui/material'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Unstable_Grid2'

import InfoNotice from 'Common/components/notice/InfoNotice'
import { Dialog } from 'Common/core'
import { MetaData } from 'Common/metadata'

import ErrorMessage from '../../components/error/ErrorMessage'
import Spinner from '../../components/loader/Spinner'
import request from '../../utils/net/request'
import { plainSubmit } from '../../utils/net/submit'
import { serializeSearchOutput } from '../searchOutputSerializers'

const MAX_EXPORT_COUNT = 100
const INITIAL_STATE = {
    showModal: false,
    exportTypes: [],
    submitting: false,
    showConfirmation: false,
    error: null,
}
const ERROR_MESSAGES = {
    NO_ASSETS_OF_THE_GIVEN_TYPES_FOUND:
        'None of the search results contains the selected asset type.',
    INVALID_ASSET_TYPE_FOR_EXPORT:
        'Export cannot be performed due to a misconfiguration, contact support (INVALID_ASSET_TYPE_FOR_EXPORT).',
    MISSING_SEARCH_CONFIGURATION:
        'Export cannot be performed due to a misconfiguration, contact support (PRODUCTION_MISSING_SEARCH_CONFIGURATION).',
}

type Props = {
    searchConfig: any
    searchState: Record<string, any>
    metaData: MetaData
    resultsCount: number
    searchMetaData?: any
}

type State = {
    exportTypes?: any
    submitting?: boolean
    showModal?: boolean
    showConfirmation?: boolean
    error?: any
}

class SearchAssetExport extends Component<Props, State> {
    state = { ...INITIAL_STATE }

    submit = () => {
        const { searchConfig, searchState } = this.props
        const selectedEditions = getOr([], 'selectedResult', searchState)
        const { exportTypes } = this.state
        if (selectedEditions.length > 0) {
            searchState.searchOptions.query = selectedEditions.join(' ')
            searchState.searchOptions.combinator = 'or'
        }
        const searchQuery = serializeSearchOutput(searchState.searchOptions, searchConfig, -1)
        this.setState(
            {
                submitting: true,
            },
            () =>
                plainSubmit(() =>
                    request({
                        method: 'POST',
                        url: `${searchConfig.searchService()}/exportassets?${searchQuery}`,
                        body: {
                            types: exportTypes,
                        },
                        errorMessages: ERROR_MESSAGES,
                    }),
                )
                    .then(() =>
                        this.setState({
                            error: null,
                            submitting: false,
                            showConfirmation: true,
                        }),
                    )
                    .catch((error) =>
                        this.setState({
                            error,
                            submitting: false,
                        }),
                    ),
        )
    }

    toggleModal = () =>
        this.setState((state) =>
            state.showModal
                ? { ...INITIAL_STATE }
                : {
                      showModal: true,
                  },
        )

    toggleExportType = (assetTypeId) =>
        this.setState((state) => ({
            exportTypes: state.exportTypes.includes(assetTypeId)
                ? state.exportTypes.filter((_) => _ !== assetTypeId)
                : [...state.exportTypes, assetTypeId],
        }))

    renderModalConfirmation() {
        return (
            <Grid container spacing={2}>
                <Grid xs={12}>
                    Your export is now being processed. It could take a while depending on how many
                    exports are being processed for the moment. When it is completed, you will
                    recieve an email with a link and later it will appear for download{' '}
                    <Link underline="none" component={NavLink} to="/mydownloads">
                        here
                    </Link>
                    .
                </Grid>
            </Grid>
        )
    }

    renderModal() {
        const { metaData, searchMetaData, resultsCount } = this.props
        const { showModal, exportTypes, error, submitting, showConfirmation } = this.state
        if (!showModal) return null
        return (
            <Dialog
                title="Export assets"
                actions={[
                    !showConfirmation && (
                        <Button
                            variant="outlined"
                            onClick={this.submit}
                            disabled={exportTypes.length === 0 || submitting}
                            color="primary"
                        >
                            Export
                        </Button>
                    ),
                ]}
                onClose={this.toggleModal}
            >
                {showConfirmation ? (
                    this.renderModalConfirmation()
                ) : (
                    <Grid container spacing={2} xs={12}>
                        {resultsCount > MAX_EXPORT_COUNT ? (
                            <Grid xs={12}>
                                <InfoNotice
                                    small
                                    title="Too many search results"
                                    content={`Your search retrieved more than ${MAX_EXPORT_COUNT} results. Only assets from the ${MAX_EXPORT_COUNT} first resultats will be exported if you perform an export.`}
                                />
                            </Grid>
                        ) : null}
                        <Grid xs={12}>
                            Choose the asset types you want to export, from the search results:
                        </Grid>
                        <Grid xs={12}>
                            {searchMetaData.customProperties.allowedAssetExports
                                .map((assetTypeId) =>
                                    metaData.assetTypes.find(
                                        (assetType) => assetType.id === assetTypeId,
                                    ),
                                )
                                .filter((_) => _)
                                .map((assetType) => (
                                    <FormControlLabel
                                        label={assetType.name}
                                        key={assetType.id}
                                        control={
                                            <Checkbox
                                                checked={exportTypes.includes(assetType.id)}
                                                onChange={() => this.toggleExportType(assetType.id)}
                                            />
                                        }
                                    />
                                ))}
                        </Grid>
                        {submitting ? (
                            <Grid
                                xs={12}
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                <Spinner />
                            </Grid>
                        ) : null}
                    </Grid>
                )}
                <ErrorMessage
                    {...{
                        error,
                    }}
                />
            </Dialog>
        )
    }

    render() {
        const { searchMetaData } = this.props
        const allowedAssetExport = getOr([], 'customProperties.allowedAssetExports', searchMetaData)
        if (allowedAssetExport.length === 0) return null
        return (
            <div
                style={{
                    display: 'inline-block',
                    margin: 5,
                }}
            >
                {this.renderModal()}
                <Button variant="outlined" onClick={this.toggleModal} color="primary">
                    Export assets
                </Button>
            </div>
        )
    }
}

export default SearchAssetExport
