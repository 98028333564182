import { Component } from 'react'

import IconComment from '@mui/icons-material/Comment'
import IconEmail from '@mui/icons-material/Email'
import IconError from '@mui/icons-material/Error'
import IconInfo from '@mui/icons-material/InfoOutlined'
import IconLocation from '@mui/icons-material/LocationOn'
import IconPerson from '@mui/icons-material/Person'
import IconPhone from '@mui/icons-material/Phone'
import {
    Avatar,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { getContactNames } from '../../utils/contacts'
import AssetThumbnail from '../assets/AssetThumbnail'
import Spinner, { SPINNER_SIZES } from '../loader/Spinner'

const addressTypes = {
    miscellaneous: 'Uncategorized address',
    postal: 'Postal address',
    visitation: 'Visiting address',
}
const phoneNumberTypes = {
    phoneNumber: 'Phone number',
    mobileNumber: 'Mobile number',
}

const getAddress = (contactAddress) => {
    const { address1, address2, zip, city } = contactAddress
    let address = address1 || ''
    if (address2) address += `, ${address2}`

    if (zip || city) {
        if (address) address += ', '
        const additional = []
        if (zip) additional.push(zip)
        if (city) additional.push(city)
        address += additional.join(' ')
    }

    return address
}

type Props = {
    contacts: any
}

class ContactDetails extends Component<Props> {
    renderEmailAddress(emailAddress, index) {
        const { value } = emailAddress
        return (
            <ListItem key={index}>
                <ListItemIcon>
                    <IconEmail />
                </ListItemIcon>
                <ListItemText primary={value} />
            </ListItem>
        )
    }

    renderAddress(address, index) {
        const { type } = address
        return (
            <ListItem key={index}>
                <ListItemIcon>
                    <IconLocation />
                </ListItemIcon>
                <ListItemText
                    primary={getAddress(address)}
                    secondary={addressTypes[type] || addressTypes.miscellaneous}
                />
            </ListItem>
        )
    }

    renderPhoneNumber(phoneNumber, index) {
        const { value, type } = phoneNumber
        return (
            <ListItem key={index}>
                <ListItemIcon>
                    <IconPhone />
                </ListItemIcon>
                <ListItemText
                    primary={value}
                    secondary={phoneNumberTypes[type] || phoneNumberTypes.phoneNumber}
                />
            </ListItem>
        )
    }

    render() {
        const { contacts } = this.props

        if (!contacts.active || !contacts.active.profiles || contacts.activeStatus === 'request') {
            return (
                <Grid container spacing={2}>
                    <Spinner size={SPINNER_SIZES.LARGE} />
                </Grid>
            )
        }

        return (
            <Grid xs={12}>
                {contacts.active.profiles.map((contact, index) => {
                    const { addresses, emailAddresses, phoneNumbers, imageAssetId } = contact
                    return (
                        <Grid
                            container
                            spacing={2}
                            rowSpacing={2}
                            xs={12}
                            key={index}
                            alignItems="center"
                        >
                            <Grid xs={12} sm={3}>
                                <Grid
                                    xs={12}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    {imageAssetId ? (
                                        <AssetThumbnail
                                            id={imageAssetId}
                                            maxWidth={100}
                                            defaultImage={false}
                                            alt="contact image"
                                        />
                                    ) : null}
                                    {!imageAssetId ? (
                                        <Avatar sx={{ width: 100, height: 100 }}>
                                            <IconPerson sx={{ fontSize: 96 }} />
                                        </Avatar>
                                    ) : null}
                                </Grid>
                                <Grid
                                    xs={12}
                                    sx={{
                                        color: contact.isSecret ? 'red' : '',
                                    }}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Typography variant="h5">
                                        {getContactNames(contact).fullName}
                                    </Typography>
                                    {contact.isSecret ? (
                                        <span>
                                            <IconError color="error" />
                                            <strong>Secret contact</strong>
                                        </span>
                                    ) : null}
                                </Grid>
                            </Grid>
                            <Grid xs={12} sm={9}>
                                <Paper>
                                    <Grid xs={12}>
                                        <List>
                                            <Grid xs={12}>
                                                <Typography fontWeight="bold">
                                                    Contact information
                                                </Typography>
                                            </Grid>
                                            <Divider />
                                            {addresses.length === 0 &&
                                            emailAddresses.length === 0 &&
                                            phoneNumbers.length === 0 ? (
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <IconInfo />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Unfortunately information is missing here" />
                                                </ListItem>
                                            ) : null}
                                            {phoneNumbers.map((item, detailIndex) =>
                                                this.renderPhoneNumber(item, detailIndex),
                                            )}
                                            {emailAddresses.map((item, detailIndex) =>
                                                this.renderEmailAddress(item, detailIndex),
                                            )}
                                            {addresses.map((item, addressIndex) =>
                                                this.renderAddress(item, addressIndex),
                                            )}
                                            <Grid xs={12}>
                                                <Typography fontWeight="bold">Other</Typography>
                                            </Grid>
                                            <Divider />
                                            {contact.notes ? (
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <IconComment />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={contact.notes}
                                                        secondary="Notes"
                                                    />
                                                </ListItem>
                                            ) : null}
                                        </List>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    )
                })}
                {contacts.active.profiles.length === 0 ? (
                    <p>This user is not connected to a contact</p>
                ) : null}
            </Grid>
        )
    }
}

export default ContactDetails
