import { isArray } from 'lodash/fp'

import { DialogActions, DialogContent, Divider, Dialog as MUIDialog } from '@mui/material'
import { styled } from '@mui/material/styles'

import DialogTitle from './DialogTitle'

const PREFIX = 'Dialog'

const classes = {
    dialogRoot: `${PREFIX}-dialogRoot`,
    dialogContentRoot: `${PREFIX}-dialogContentRoot`,
}

const StyledMUIDialog = styled(MUIDialog)(() => ({
    [`& .${classes.dialogRoot}`]: {
        overflowY: 'visible',
    },

    [`& .${classes.dialogContentRoot}`]: {
        overflowY: 'visible',
    },
}))

type Props = {
    children: React.ReactNode
    onClose?(..._args: unknown[]): unknown
    actions?: unknown[]
    maxWidth?: any
    open?: boolean
    title?: React.ReactNode
    submitting?: boolean
    disableBackdropClick?: boolean
    disableEscapeKeyDown?: boolean
}

const Dialog = (props: Props) => {
    const {
        children,
        actions = [],
        title,
        maxWidth = 'lg',
        onClose,
        submitting = false,
        ...otherProps
    } = props
    const actualActions = [...actions].filter((_) => _)

    return (
        <StyledMUIDialog
            maxWidth={maxWidth}
            fullWidth={true}
            scroll="body"
            open
            {...otherProps}
            classes={{
                paper: classes.dialogRoot,
            }}
        >
            {title ? (
                <DialogTitle
                    {...{
                        submitting,
                    }}
                    onClose={props.onClose ? props.onClose : null}
                >
                    {title}
                </DialogTitle>
            ) : null}
            <DialogContent
                classes={{
                    root: classes.dialogContentRoot,
                }}
            >
                {children}
            </DialogContent>
            {actualActions && isArray(actualActions) && actualActions.length > 0 ? (
                <>
                    <Divider />
                    <DialogActions>{actualActions}</DialogActions>
                </>
            ) : null}
        </StyledMUIDialog>
    )
}

export default Dialog
