import { postPublishProductions as postPublishProductionsAction } from 'Work/actions/productionActions'
import { searchConfig as productionsSearchConfig } from 'Work/config/productionsConfig'
import messages from 'Work/messages'
import { getOr } from 'lodash/fp'
import { Component } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { InjectedFormProps } from 'redux-form'

import { Button, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import Spinner from 'Common/components/loader/Spinner'
import InfoNotice from 'Common/components/notice/InfoNotice'
import { Dialog } from 'Common/core'
import { getSearch as getSearchAction } from 'Common/search/searchActions'
import { RootState } from 'Common/types'
import { plainSubmit } from 'Common/utils/net/submit'

type EditionPublishStatus = {
    id: string
    workId: string
    title: string
    isbn: string
    status: string
    warnings?: any[]
    isSuccess?: boolean
}
type Props = {
    closeModal: () => void
}

type State = {
    selectedEditions?: EditionPublishStatus[]
    submitting: boolean
    error: string | null
    warningAccepted: boolean
    successUpdating: boolean
    submitSucceeded: boolean
}
const mapState = (state: RootState) => {
    const { metaData, search } = state
    const productionsSearch = getOr([], productionsSearchConfig.id, search)
    const { itemsTotalCount } = productionsSearch.paging
    const editions = getOr([], 'productions.results', search)
    const editionsSearchResult = editions.map((p) => ({
        id: getOr(false, 'document.id', p),
        title: getOr('-', 'document.title', p),
        workId: getOr('-', 'document.workId', p),
        isbn: getOr('-', 'document.isbn', p),
        status: '-',
    }))
    return {
        metaData: metaData.data,
        editions,
        editionsSearchResult,
        itemsTotalCount,
    }
}

const connector = connect(mapState, {
    postPublishProductions: postPublishProductionsAction,
    getSearch: getSearchAction,
})

type PropsFromRedux = ConnectedProps<typeof connector>

class BatchPublishEditions extends Component<Props & InjectedFormProps & PropsFromRedux, State> {
    state = {
        warningAccepted: false,
        submitting: false,
        successUpdating: false,
        submitSucceeded: false,
        error: null,
        selectedEditions: this.props.editionsSearchResult,
    }

    acceptWarning = () =>
        this.setState({
            warningAccepted: true,
        })

    submit = () => {
        const { postPublishProductions } = this.props
        this.setState(
            {
                submitting: true,
            },
            () => {
                plainSubmit(() => postPublishProductions(productionsSearchConfig))
                    .then(() => {
                        this.setState({
                            successUpdating: true,
                            submitSucceeded: true,
                            submitting: false,
                        })
                    })
                    .catch((error) =>
                        this.setState({
                            error,
                            submitSucceeded: true,
                            submitting: false,
                        }),
                    )
            },
        )
    }

    render() {
        const { closeModal, getSearch, itemsTotalCount } = this.props
        const {
            submitting,
            error,
            selectedEditions,
            warningAccepted,
            successUpdating,
            submitSucceeded,
        } = this.state

        const closeAndSearchAction = () => {
            getSearch(productionsSearchConfig)
            closeModal()
        }

        const selectedCloseAction = () => {
            submitSucceeded ? closeAndSearchAction() : closeModal()
        }

        const actions = [
            <Button
                variant="outlined"
                key="cancel"
                onClick={selectedCloseAction}
                disabled={submitting}
            >
                {submitSucceeded ? messages.common.action.close : messages.common.action.cancel}
            </Button>,
            <Button
                variant="outlined"
                key="save"
                onClick={this.submit}
                disabled={
                    selectedEditions.length === 0 ||
                    submitting ||
                    submitSucceeded ||
                    !warningAccepted
                }
                color="secondary"
            >
                Make public
            </Button>,
        ]

        const title = (
            <Typography>
                <span>Publish {itemsTotalCount} editions</span>
            </Typography>
        )
        return (
            <Dialog
                {...{
                    actions,
                    title,
                }}
            >
                {!warningAccepted ? (
                    <InfoNotice
                        title="Are you sure you want to proceed with a batch operation?"
                        content={
                            <div>
                                <Typography variant="body2">
                                    {`You are about to perform a batch operation that will affect all entities in your search result (${itemsTotalCount} editions). The operation is irreversible.`}
                                </Typography>
                                <Button
                                    style={{
                                        float: 'left',
                                        marginTop: 20,
                                    }}
                                    color="primary"
                                    variant="outlined"
                                    key="accept-warning"
                                    onClick={this.acceptWarning}
                                >
                                    Continue
                                </Button>
                            </div>
                        }
                    />
                ) : null}
                {submitting && !error ? (
                    <Grid xs={12}>
                        <div
                            style={{
                                textAlign: 'center',
                            }}
                        >
                            <Spinner />
                            <span>Saving... </span>
                        </div>
                    </Grid>
                ) : null}
                {!submitting && successUpdating ? (
                    <Typography variant="body2">Success updating editions.</Typography>
                ) : null}
                {error ? (
                    <Grid xs={12}>
                        <div
                            style={{
                                color: '#f44336',
                                whiteSpace: 'pre-line',
                            }}
                        >
                            {error}
                        </div>
                    </Grid>
                ) : null}
            </Dialog>
        )
    }
}

export default connector(BatchPublishEditions)
