import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Paper, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

type Props = {
    title: string
    content?: any
    small?: boolean
}

export default function InfoNotice({ title, content = null, small = false }: Props) {
    return (
        <Grid xs={12}>
            <Paper
                sx={{
                    backgroundColor: 'warning.main',
                }}
            >
                <Grid xs={12}>
                    <Grid container spacing={2} xs={12}>
                        <Grid display="flex" alignItems="center">
                            <InfoOutlinedIcon sx={{ fontSize: 42 }} />
                        </Grid>
                        <Grid container spacing={0}>
                            <Grid xs={12}>
                                <Typography variant={small ? 'h6' : 'h5'} fontWeight="bold">
                                    {title}
                                </Typography>
                            </Grid>
                            <Grid xs={12}>
                                <Typography>{content}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}
