import { convertFactorsToPercentages } from 'Calculation/utils/calculation'

import { requestStatus } from 'Common/utils/net/statuses'

import { GET_PRODUCTION_REQUEST } from '../actions/productionActions'
import {
    GET_PRODUCTION_DEFAULT_ROYALTY_LEVELS_FAILURE,
    GET_PRODUCTION_DEFAULT_ROYALTY_LEVELS_REQUEST,
    GET_PRODUCTION_DEFAULT_ROYALTY_LEVELS_SUCCESS,
} from '../actions/productionRoyaltyLevelsActions'

const initialState = {
    status: {},
}
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCTION_REQUEST: {
            return { ...initialState }
        }

        case GET_PRODUCTION_DEFAULT_ROYALTY_LEVELS_REQUEST: {
            const { bindingCodeId, contractTypeId, isMainEdition, publishingHouseId } =
                action.payload
            return {
                ...state,
                bindingCodeId,
                contractTypeId,
                isMainEdition,
                publishingHouseId,
                status: { ...state.status, getStatus: requestStatus.request, getError: null },
            }
        }

        case GET_PRODUCTION_DEFAULT_ROYALTY_LEVELS_SUCCESS: {
            const converted = convertFactorsToPercentages(action.payload.json)
            return {
                ...state,
                data: converted,
                status: { ...state.status, getStatus: requestStatus.success },
            }
        }

        case GET_PRODUCTION_DEFAULT_ROYALTY_LEVELS_FAILURE: {
            return {
                ...state,
                status: {
                    ...state.status,
                    getStatus: requestStatus.failure,
                    getError: action.payload,
                },
            }
        }

        default: {
            return state
        }
    }
}
