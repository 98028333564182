import { reducer as form } from 'redux-form'

import contacts from 'Common/Contacts/ContactsReducer'
import assets from 'Common/assets/AssetsReducer'
import auth from 'Common/auth/authReducer'
import environment from 'Common/environment/environmentReducer'
import error from 'Common/error/errorReducer'
import productionHistory from 'Common/history/productionHistoryReducer'
import menu from 'Common/menu/menuReducer'
import metaData from 'Common/metadata/metaDataReducer'
import search from 'Common/search/searchReducer'
import settings from 'Common/settings/settingsReducer'
import themaCodes from 'Common/thema/themaReducer'

import metaDataType from '../../Admin/reducers/metaDataTypeReducer'
import metaDataTypes from '../../Admin/reducers/metaDataTypesReducer'
import user from '../../Admin/reducers/userReducer'
import concept from '../../Concept/reducers/ConceptReducer'
import concepts from '../../Concept/reducers/ConceptsReducer'
import calculation from '../../Concept/reducers/calculationReducer'
import preCalculation from '../../Concept/reducers/preCalculationReducer'
import contactList from '../../Contact/reducers/contactListReducer'
import contactLists from '../../Contact/reducers/contactListsReducer'
import contact from '../../Contact/reducers/contactReducer'
import retailers from '../../Imports/reducers/retailersReducer'
import myConcepts from '../../Root/reducers/myConceptsReducer'
import myRelatedConcepts from '../../Root/reducers/myRelatedConceptsReducer'
import activity from '../../Work/reducers/activityReducer'
import printCosts from '../../Work/reducers/printCostsReducer'
import productionAssets from '../../Work/reducers/productionAssetsReducer'
import productionBookClubs from '../../Work/reducers/productionBookClubsReducer'
import productionEstimatedSales from '../../Work/reducers/productionEstimatedSalesReducer'
import productionPrintChanges from '../../Work/reducers/productionPrintChangesReducer'
import productionPrintDeliverySpecifications from '../../Work/reducers/productionPrintDeliveryspecificationsReducer'
import production from '../../Work/reducers/productionReducer'
import productionRoyaltyLevelsDefault from '../../Work/reducers/productionRoyaltyLevelsDefaultReducer'
import productionRoyaltyLevels from '../../Work/reducers/productionRoyaltyLevelsReducer'
import productionSalesTarget from '../../Work/reducers/productionSalesTargetReducer'
import productionTexts from '../../Work/reducers/productionTextsReducer'
import workCalculation from '../../Work/reducers/workCalculationReducer'
import workCosts from '../../Work/reducers/workCostsReducer'
import work from '../../Work/reducers/workReducer'

const reducers = {
    activity,
    assets,
    auth,
    calculation,
    concept,
    concepts,
    contact,
    contactList,
    contactLists,
    contacts,
    environment,
    error,
    form,
    menu,
    metaData,
    metaDataType,
    metaDataTypes,
    myConcepts,
    myRelatedConcepts,
    preCalculation,
    printCosts,
    production,
    productionAssets,
    productionBookClubs,
    productionEstimatedSales,
    productionHistory,
    productionPrintChanges,
    productionPrintDeliverySpecifications,
    productionRoyaltyLevels,
    productionRoyaltyLevelsDefault,
    productionSalesTarget,
    productionTexts,
    retailers,
    search,
    settings,
    themaCodes,
    user,
    work,
    workCalculation,
    workCosts,
}

export default reducers
