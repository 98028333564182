import { map } from 'lodash/fp'

import { services } from 'Common/environment'
import request from 'Common/utils/net/request'
import {
    getMyContactIds,
    getMyPublishingHouseIdsApprover,
    getMyUserId,
} from 'Common/utils/user/auth'

export const GET_MY_CONCEPTS = 'GET_MY_CONCEPTS'
export const GET_MY_CONCEPTS_REQUEST = 'GET_MY_CONCEPTS_REQUEST'
export const GET_MY_CONCEPTS_SUCCESS = 'GET_MY_CONCEPTS_SUCCESS'
export const GET_MY_CONCEPTS_FAILURE = 'GET_MY_CONCEPTS_FAILURE'
export const GET_MY_RELATED_CONCEPTS = 'GET_MY_RELATED_CONCEPTS'
export const GET_MY_RELATED_CONCEPTS_REQUEST = 'GET_MY_RELATED_CONCEPTS_REQUEST'
export const GET_MY_RELATED_CONCEPTS_SUCCESS = 'GET_MY_RELATED_CONCEPTS_SUCCESS'
export const GET_MY_RELATED_CONCEPTS_FAILURE = 'GET_MY_RELATED_CONCEPTS_FAILURE'
export const getMyConcepts = (auth) => {
    const userId = getMyUserId(auth)
    const filter = `$filter=(status eq 'active' or status eq 'pendingApproval') and (owner eq '${userId}')`
    const select =
        'members,publishingHouse,accessLevel,contactRoles,title,roles,status,completion,id,owner'
    return (dispatch) => {
        return dispatch({
            type: GET_MY_CONCEPTS,
            payload: {
                promise: request({
                    method: 'GET',
                    url: services.concept(
                        `/v2/search/concepts?${filter}&$orderBy=updated desc&$select=${select}&limit=99`,
                    ),
                }),
            },
        })
    }
}

const getContactsFilter = (contactIds) =>
    map((contactId) => `trustedContacts/any(t: t eq '${contactId}')`)(contactIds).join(' or ')

const getApproverFilter = (approverIds) =>
    map((publishingHouseId) => `publishingHouse eq '${publishingHouseId}'`)(approverIds).join(
        ' or ',
    )

export const getMyRelatedConcepts = (auth) => {
    const contactIds = getMyContactIds(auth)
    const approverIds = getMyPublishingHouseIdsApprover(auth, 'opus_concept')
    let filter = "$filter=(status eq 'active' or status eq 'pendingApproval')"
    let additionalFilters = ''

    if (contactIds.length > 0) {
        const contactsFilter = getContactsFilter(contactIds)
        additionalFilters += contactsFilter
    }

    if (approverIds.length > 0) {
        const approverFilter = getApproverFilter(approverIds)
        additionalFilters += `${
            contactIds.length > 0 ? ' or ' : ''
        }((${approverFilter}) and status eq 'pendingApproval')`
    }

    if (additionalFilters.length > 0) {
        filter += ` and (${additionalFilters})`
    }

    const select =
        'members,publishingHouse,publishingHouseGroup,accessLevel,contactRoles,title,roles,status,completion,id,owner'
    return (dispatch) =>
        dispatch({
            type: GET_MY_RELATED_CONCEPTS,
            payload: {
                promise: request({
                    method: 'GET',
                    url: services.concept(
                        `/v2/search/concepts?${filter}&$orderBy=updated desc&$select=${select}&limit=99`,
                    ),
                }),
            },
        })
}
