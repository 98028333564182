import { fillOperation } from 'Calculation/utils/calculation'
import { get } from 'lodash/fp'

import { requestStatus } from 'Common/utils/net/statuses'

import {
    GET_CALCULATION_GROUP_FAILURE,
    GET_CALCULATION_GROUP_REQUEST,
    GET_CALCULATION_GROUP_SUCCESS,
    GET_CONCEPT_CALCULATION_FAILURE,
    GET_CONCEPT_CALCULATION_GROUP_FAILURE,
    GET_CONCEPT_CALCULATION_GROUP_REQUEST,
    GET_CONCEPT_CALCULATION_GROUP_SUCCESS,
    GET_CONCEPT_CALCULATION_REQUEST,
    GET_CONCEPT_CALCULATION_SUCCESS,
} from '../actions/conceptCalculationActions'

const fillOperations = (extendedFields) => {
    return extendedFields.map((extendedField) => {
        return {
            ...extendedField,
            operations: extendedField.operations
                ? extendedField.operations.map((operation) =>
                      fillOperation(operation, extendedFields),
                  )
                : null,
        }
    })
}

export const convertFieldsToExtendedFields = (fields) => {
    return fields
        ? Object.keys(fields).map((id) => {
              const { operations, ...field } = get(id, fields)
              return { ...field, id, operations }
          }, fields)
        : []
}
const initialState = {
    status: {
        getStatus: undefined,
        getError: undefined,
    },
    data: undefined,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_CONCEPT_CALCULATION_REQUEST:
        case GET_CALCULATION_GROUP_REQUEST:
        case GET_CONCEPT_CALCULATION_GROUP_REQUEST:
            return {
                ...state,
                data: undefined,
                status: { ...state.status, getStatus: requestStatus.request, getError: null },
            }

        case GET_CONCEPT_CALCULATION_SUCCESS:
        case GET_CALCULATION_GROUP_SUCCESS:
            return {
                ...state,
                data: {
                    ...action.payload.json,
                    extendedFields: fillOperations(
                        convertFieldsToExtendedFields(action.payload.json.fields),
                    ),
                },
                status: { ...state.status, getStatus: requestStatus.success },
            }

        case GET_CONCEPT_CALCULATION_GROUP_SUCCESS: {
            const [calculationGroup] = action.payload.json.items
            return {
                ...state,
                data: {
                    ...calculationGroup,
                    extendedFields: fillOperations(
                        convertFieldsToExtendedFields(calculationGroup.fields),
                    ),
                },
                status: { ...state.status, getStatus: requestStatus.success },
            }
        }

        case GET_CONCEPT_CALCULATION_FAILURE:
        case GET_CALCULATION_GROUP_FAILURE:
        case GET_CONCEPT_CALCULATION_GROUP_FAILURE:
            return {
                ...state,
                status: {
                    ...state.status,
                    getStatus: requestStatus.failure,
                    getError: action.payload,
                },
            }

        default:
            return state
    }
}
