const messages = {
    save: 'Save',
    create: 'Create',
    createNew: 'Create new',
    cancel: 'Cancel',
    add: 'Add',
    remove: 'Remove',
    clear: 'Clear',
    continue: 'Continue',
    saveAndContinue: 'Save and continue',
    ignoreAndContinue: 'Continue without saving',
    close: 'Close',
    showMore: 'Show more',
    select: 'Select',
    reset: 'Undo',
    repeat: 'Repeat',
    copy: 'Copy',
    connect: 'Connect',
    logout: 'Logout',
}

export default messages
