import action from './actions'
import errorcodes from './errorcodes'
import error from './errors'
import validation from './validation'

const messages = {
    action,
    error,
    validation,
    errorcodes,
}
export default messages
