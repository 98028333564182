import IconInfo from '@mui/icons-material/Info'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { sanitizer } from 'Common/utils/sanitizer'

type Props = {
    infoText?: string
}

const newLineToHtml = (text) =>
    `<p>${text
        .replace(/\r\n\r\n/g, '</p><p>')
        .replace(/\n\n/g, '</p><p>')
        .replace(/\n/g, '</p><p>')}</p>`

const FormFieldInfoText = ({ infoText = '' }: Props) => (
    <Grid
        xs={12}
        sx={{
            borderTop: '1px dashed black',
            backgroundColor: 'warning.main',
            marginBottom: 0.5,
        }}
    >
        <Grid container spacing={0} xs={12}>
            <Grid xs={2} display="flex" justifyContent="center">
                <IconInfo />
            </Grid>
            <Grid xs={10}>
                <Typography fontWeight="bold">EXPLANATION</Typography>
            </Grid>
            <Grid xsOffset={2} xs={10} display="flex" justifyContent="flex-start">
                <Grid container spacing={0} xs={12}>
                    {infoText.split('\n\n\n\n').map((text, index) => (
                        <Grid xs={12} key={index}>
                            <Typography
                                variant="body2"
                                dangerouslySetInnerHTML={{
                                    __html: sanitizer(newLineToHtml(text)),
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    </Grid>
)

export default FormFieldInfoText
