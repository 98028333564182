import { Component } from 'react'

import { Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

const getSortingDisplay = (field, orderBy = '') => {
    const [sortField = '', sortDirection = ''] = orderBy.split(' ')
    if (field !== sortField) return ''
    if (sortDirection === 'desc') return '\u2193'
    return '\u2191'
}

const getUpdatedSorting = (field, orderBy = '') => {
    const [sortField = '', sortDirection = ''] = orderBy.split(' ')
    if (field !== sortField) return field
    if (field === sortField && sortDirection === 'desc') return ''
    return `${field} desc`
}

type Props = {
    updateSorting(..._args: unknown[]): unknown
    size: any
    label: string
    field: string
    orderBy?: any
    className?: string
    style?: any
    sx?: any
}

class SortableSearchHeader extends Component<Props> {
    static defaultProps = {
        size: 12,
        orderBy: '',
        className: '',
        style: {},
        sx: {},
    }

    handleSort = () => {
        const { field, orderBy, updateSorting } = this.props
        updateSorting(getUpdatedSorting(field, orderBy))
    }

    render() {
        const { size, label, field, orderBy, className, style, sx } = this.props
        return (
            <Grid xs={size} className={className} style={style} sx={sx}>
                <Typography
                    className="linkPrimary"
                    onClick={this.handleSort}
                    style={{
                        userSelect: 'none',
                    }}
                    variant="subtitle1"
                >
                    {label} {getSortingDisplay(field, orderBy)}
                </Typography>
            </Grid>
        )
    }
}

export default SortableSearchHeader
