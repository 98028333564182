import { getOr, sortBy } from 'lodash/fp'
import { NavLink } from 'react-router-dom'

import Link from '@mui/material/Link'

import { servicesLoaders } from 'Common/environment'
import {
    booleanFilter,
    combinationOptionFilter,
    contactFilter,
    dateFilter,
    optionArrayFilter,
    optionFilter,
    themaCodeFilter,
} from 'Common/search/filter/filters'
import AdvancedSearchView from 'Common/search/view/AdvancedSearchView'
import BasicSearchView from 'Common/search/view/BasicSearchView'
import BooleanCell from 'Common/search/view/cells/BooleanCell'
import DateCell from 'Common/search/view/cells/DateCell'
import LinkCell from 'Common/search/view/cells/LinkCell'
import ListCell from 'Common/search/view/cells/ListCell'
import MemberCell from 'Common/search/view/cells/MemberCell'
import { getMembersColumns } from 'Common/utils/roles'
import { getTextColumns, texts } from 'Common/utils/texts'

import LaunchBriefViewItem from '../components/Works/LaunchBriefViewItem'
import WorksViewHeader from '../components/Works/WorksViewHeader'
import WorksViewItem from '../components/Works/WorksViewItem'

const ROLES_CATEGORY = 'Roles'
const LAUNCH_BRIEF_CATEGORY = 'Launch brief'
const MAIN_EDITION_CATEGORY = 'Main edition'
const TEXTS_CATEGORY = 'Texts'
export const DEFAULT_SEARCH_QUERY = '?queryField=all&orderBy=relevance&combinator=and'

const defaultSelectFields = [
    'id',
    'title',
    'publishingHouseName',
    'overallGoals',
    'salesArgument',
    'purpose',
    'members',
    'membersByRole',
]
export const searchConfig = {
    id: 'works',
    searchService: servicesLoaders.work('/v2/search/works'),
    searchServiceExport: servicesLoaders.work('/v2/search/works/export'),
    searchLocation: '/book/works',
    searchMetaDataId: 'works',
    accessModule: 'opus_work',
    savedSearchesId: 'savedsearches-work-works-v1',
    defaultSelectFields,
    defaultOrderBy: 'title desc',
    pagingSize: 50,
    queryFields: [
        {
            id: 'title',
            label: 'Title',
        },
        {
            id: 'id',
            label: 'ID, work',
        },
        {
            id: 'mainEditionCostCenterName',
            label: 'Main edition: Cost center',
        },
    ],
    //Needs to be alphabetical
    filters: [
        {
            type: contactFilter,
            id: 'filterContacts',
            name: 'Contact/role',
        },
        {
            type: optionFilter,
            id: 'filterCostCenter',
            searchId: 'costCenter',
            metaDataId: 'costCenters',
            name: 'Cost center',
        },
        {
            type: booleanFilter,
            id: 'filterIsAuthorAvailableForEvents',
            searchId: 'isAuthorAvailableForEvents',
            name: 'Is author available for events',
        },
        {
            type: booleanFilter,
            id: 'filterisAuthorAvailableForPress',
            searchId: 'isAuthorAvailableForPress',
            name: 'Is author available for press',
        },
        {
            type: booleanFilter,
            id: 'filterIsCancelled',
            searchId: 'isCancelled',
            name: 'Is Cancelled',
        },
        {
            type: optionFilter,
            id: 'filterMainEditionInterestAges',
            searchId: 'mainEditionInterestAge',
            metaDataId: 'interestAges',
            name: 'Main edition: Age group',
        },
        {
            type: optionFilter,
            id: 'filterMainEditionInternalCategories',
            searchId: 'mainEditionInternalCategoryId',
            metaDataId: 'internalCategories',
            typeConfig: {
                disableSelectAll: true,
            },
            name: 'Main edition: Internal category',
        },
        {
            type: optionFilter,
            id: 'filterMainEditionEffortType',
            searchId: 'mainEditionEffortTypeId',
            metaDataId: 'effortTypes',
            name: 'Main edition: List category',
        },
        {
            type: optionFilter,
            id: 'filterMainEditionMainGroupId',
            searchId: 'mainEditionMainGroupId',
            metaDataId: 'mainGroups',
            name: 'Main edition: Main group',
        },
        {
            type: dateFilter,
            id: 'filtermMainEditionPubDates',
            searchId: 'mainEditionPublishingDate',
            name: 'Main edition: Publication date',
        },
        {
            type: dateFilter,
            id: 'filtermMainEditionRevDates',
            searchId: 'mainEditionReviewDate',
            name: 'Main edition: Review date',
        },
        {
            type: combinationOptionFilter,
            id: 'filterMainEditionSeasons',
            searchId: 'mainEditionSeasonName',
            typeConfig: {
                keys: ['year', 'season'],
            },
            options: [
                {
                    id: 'year',
                    name: 'Season, year',
                    defaultValue: `${new Date().getFullYear()}`,
                    metaDataId: 'seasonYears',
                },
                {
                    id: 'season',
                    name: 'Season, period',
                    defaultValue: 'Spring',
                    metaDataId: 'seasonPeriods',
                },
            ],
            name: 'Main edition: Season',
        },
        {
            type: optionFilter,
            id: 'filterMainEditionSubGroupId',
            searchId: 'mainEditionSubGroupId',
            metaDataId: 'subGroups',
            name: 'Main edition: Sub group',
        },
        {
            type: optionArrayFilter,
            id: 'originalLanguageIds',
            searchId: 'originalLanguageIds',
            metaDataId: 'productionLanguages',
            name: 'Original language',
        },
        {
            type: optionFilter,
            id: 'filterPublishingHouses',
            searchId: 'publishingHouseId',
            metaDataId: 'publishingHouses',
            typeConfig: {
                filterByPublishingHouse: true,
            },
            name: 'Publishing house',
        },
        {
            type: optionFilter,
            id: 'filterPublishingHouseGroups',
            searchId: 'publishingHouseGroup',
            metaDataId: 'publishingHouseGroups',
            name: 'Publishing house group',
        },
        {
            type: optionFilter,
            id: 'filterPublishingLists',
            searchId: 'publishingList',
            metaDataId: 'publishingLists',
            name: 'Publishing list',
        },
        {
            type: themaCodeFilter,
            id: 'filterThemaCode',
            name: 'Thema code',
        },
        {
            type: booleanFilter,
            id: 'filterWillAuthorParticipate',
            searchId: 'willAuthorParticipate',
            name: 'Will author participate',
        },
    ],
}
export const viewsConfig = {
    views: [
        {
            id: 'basic',
            label: 'Basic',
            Component: BasicSearchView,
            componentConfig: {
                HeaderComponent: WorksViewHeader,
                ItemComponent: WorksViewItem,
            },
        },
        {
            id: 'advanced',
            label: 'Advanced',
            Component: AdvancedSearchView,
            savedViewsId: 'savedviews-work-works-v1',
            defaultColumns: ['title', 'publishingHouseName'],
            defaultMetaDataColumns: [
                {
                    metaDataId: 'contactRoles',
                    filterMetaDataBy: (role) => getOr(false, 'customProperties.isAuthor', role),
                    mapMetaDataToColumns: (role) => `membersByRole_${role.id}`,
                },
            ],
            metaDataColumns: [
                {
                    metaDataId: 'contactRoles',
                    mapMetaDataToColumns: getMembersColumns(ROLES_CATEGORY, MemberCell),
                },
                {
                    metaDataId: 'textTypes',
                    mapMetaDataToColumns: getTextColumns(TEXTS_CATEGORY, 'mainEdition_'),
                },
            ],
            columns: {
                id: {
                    id: 'id',
                    selectField: 'id',
                    label: 'Work id',
                    sortable: true,
                },
                title: {
                    id: 'title',
                    selectField: 'title',
                    label: 'Title',
                    width: 300,
                    sortable: true,
                    cellType: LinkCell,
                    cellConfig: {
                        createLink: (work) => `/book/${work.id}`,
                    },
                },
                originalTitle: {
                    id: 'originalTitle',
                    selectField: 'originalTitle',
                    label: 'Original title',
                    width: 200,
                    sortable: true,
                    category: LAUNCH_BRIEF_CATEGORY,
                },
                firstPublishedYear: {
                    id: 'firstPublishedYear',
                    selectField: 'firstPublishedYear',
                    label: 'Year of publication of the original edition',
                    width: 180,
                    sortable: true,
                    category: LAUNCH_BRIEF_CATEGORY,
                },
                publishingHouseName: {
                    id: 'publishingHouseName',
                    selectField: 'publishingHouseName',
                    label: 'Publishing house',
                    width: 200,
                    sortable: true,
                },
                overallGoals: {
                    id: 'overallGoals',
                    selectField: 'overallGoals',
                    label: 'General communication goals',
                    category: LAUNCH_BRIEF_CATEGORY,
                    width: 250,
                    sortable: true,
                },
                salesArgument: {
                    id: 'salesArgument',
                    selectField: 'salesArgument',
                    label: 'USP',
                    category: LAUNCH_BRIEF_CATEGORY,
                    width: 200,
                    sortable: true,
                },
                effortTypeName: {
                    id: 'effortTypeName',
                    selectField: 'effortTypeName',
                    label: 'Category',
                    category: LAUNCH_BRIEF_CATEGORY,
                    width: 200,
                    sortable: true,
                },
                targetGroupNames: {
                    id: 'targetGroupNames',
                    selectField: 'targetGroupNames',
                    label: 'Target personas',
                    sortable: false,
                    category: LAUNCH_BRIEF_CATEGORY,
                    width: 400,
                },
                targetGroupText: {
                    id: 'targetGroupText',
                    selectField: 'targetGroupText',
                    label: 'Target personas, detailed description',
                    category: LAUNCH_BRIEF_CATEGORY,
                    width: 400,
                    sortable: true,
                },
                socialMedia: {
                    id: 'socialMedia',
                    selectField: 'socialMedia',
                    label: 'Activity/followers on social media',
                    category: LAUNCH_BRIEF_CATEGORY,
                    width: 400,
                    sortable: true,
                },
                willAuthorParticipate: {
                    id: 'willAuthorParticipate',
                    selectField: 'willAuthorParticipate',
                    label: 'Will author participate',
                    category: LAUNCH_BRIEF_CATEGORY,
                    sortable: true,
                    cellType: BooleanCell,
                },
                isAuthorAvailableForEvents: {
                    id: 'isAuthorAvailableForEvents',
                    selectField: 'isAuthorAvailableForEvents',
                    label: 'Is author available for events',
                    category: LAUNCH_BRIEF_CATEGORY,
                    sortable: true,
                    cellType: BooleanCell,
                },
                isAuthorAvailableForPress: {
                    id: 'isAuthorAvailableForPress',
                    selectField: 'isAuthorAvailableForPress',
                    label: 'Is author available for press',
                    category: LAUNCH_BRIEF_CATEGORY,
                    sortable: true,
                    cellType: BooleanCell,
                },
                description: {
                    id: 'description',
                    selectField: 'description',
                    label: 'About the book',
                    category: LAUNCH_BRIEF_CATEGORY,
                    sortable: true,
                },
                challenges: {
                    id: 'challenges',
                    selectField: 'challenges',
                    label: 'Challenges',
                    category: LAUNCH_BRIEF_CATEGORY,
                    sortable: true,
                },
                opportunities: {
                    id: 'opportunities',
                    selectField: 'opportunities',
                    label: 'Opportunities',
                    category: LAUNCH_BRIEF_CATEGORY,
                    sortable: true,
                },
                budget: {
                    id: 'budget',
                    selectField: 'budget',
                    label: 'Launch budget',
                    category: LAUNCH_BRIEF_CATEGORY,
                    sortable: true,
                },
                authorText: {
                    id: 'authorText',
                    selectField: 'authorText',
                    label: 'About the creator',
                    category: LAUNCH_BRIEF_CATEGORY,
                    sortable: true,
                },
                communicationEffortFocus: {
                    id: 'communicationEffortFocus',
                    selectField: 'communicationEffortFocus',
                    label: 'Proposal for launch activities',
                    category: LAUNCH_BRIEF_CATEGORY,
                    sortable: true,
                },
                editorialWorkEffortHours: {
                    id: 'editorialWorkEffortHours',
                    selectField: 'editorialWorkEffortHours',
                    label: 'Editorial work effort hours',
                    category: LAUNCH_BRIEF_CATEGORY,
                    sortable: true,
                },
                purpose: {
                    id: 'purpose',
                    selectField: 'purpose',
                    label: 'Reasons for publishing the book',
                    category: LAUNCH_BRIEF_CATEGORY,
                    width: 300,
                    sortable: true,
                },
                originalLanguageNames: {
                    id: 'originalLanguageNames',
                    selectField: 'originalLanguageNames',
                    label: 'Original language',
                    sortable: false,
                    width: 200,
                    cellType: ListCell,
                },
                publishingListName: {
                    id: 'publishingListName',
                    selectField: 'publishingListName',
                    label: 'Publishing list',
                    width: 200,
                    sortable: true,
                },
                mainEditionSeasonName: {
                    id: 'mainEditionSeasonName',
                    selectField: 'mainEditionSeasonName',
                    label: 'Main edition: Season',
                    width: 110,
                    sortable: true,
                    category: MAIN_EDITION_CATEGORY,
                },
                mainEditionEffortTypeName: {
                    id: 'mainEditionEffortTypeName',
                    selectField: 'mainEditionEffortTypeName',
                    label: 'Main edition: List category',
                    width: 140,
                    sortable: true,
                    category: MAIN_EDITION_CATEGORY,
                },
                mainEditionPublishingDate: {
                    id: 'mainEditionPublishingDate',
                    selectField: 'mainEditionPublishingDate',
                    label: 'Main edition: Publ. date',
                    width: 100,
                    sortable: true,
                    category: MAIN_EDITION_CATEGORY,
                    cellType: DateCell,
                },
                mainEditionReviewDate: {
                    id: 'mainEditionReviewDate',
                    selectField: 'mainEditionReviewDate',
                    label: 'Main edition: Rev. date',
                    width: 100,
                    sortable: true,
                    category: MAIN_EDITION_CATEGORY,
                    cellType: DateCell,
                },
                mainEditionEstimatedNumberOfPages: {
                    id: 'mainEditionEstimatedNumberOfPages',
                    selectField: 'mainEditionEstimatedNumberOfPages',
                    label: 'Main edition: Estimated number of pages',
                    width: 65,
                    sortable: true,
                    category: MAIN_EDITION_CATEGORY,
                },
                mainEditionPages: {
                    id: 'mainEditionPages',
                    selectField: 'mainEditionPages',
                    label: 'Main edition: Last numbered page',
                    width: 110,
                    sortable: true,
                    category: MAIN_EDITION_CATEGORY,
                },
                mainEditionIsbn: {
                    id: 'mainEditionIsbn',
                    selectField: 'mainEditionIsbn',
                    label: 'Main edition: Isbn',
                    width: 110,
                    sortable: true,
                    category: MAIN_EDITION_CATEGORY,
                },
                themas: {
                    id: 'themas',
                    selectField: 'themas',
                    label: 'Thema: Description',
                    width: 250,
                    sortable: false,
                    category: MAIN_EDITION_CATEGORY,
                    cellType: ListCell,
                    cellConfig: {
                        transform: (themas) => {
                            const sortedThemas = sortBy(['themaCodeTypeId', 'sortOrder'], themas)
                            return sortedThemas.map((thema) => thema.codeDescription)
                        },
                        filter: (languageCode) => (thema) => thema.languageCode === languageCode,
                    },
                },
                themasCodes: {
                    id: 'themasCodes',
                    selectField: 'themas',
                    label: 'Thema: Code',
                    width: 250,
                    sortable: false,
                    category: MAIN_EDITION_CATEGORY,
                    cellType: ListCell,
                    cellConfig: {
                        transform: (themas) => {
                            const sortedThemas = sortBy(['themaCodeTypeId', 'sortOrder'], themas)
                            return sortedThemas.map((thema) => thema.codeValue)
                        },
                        filter: (languageCode) => (thema) => thema.languageCode === languageCode,
                    },
                },
                themasFull: {
                    id: 'themasFull',
                    selectField: 'themas',
                    label: 'Thema: Code & Description',
                    width: 250,
                    sortable: false,
                    category: MAIN_EDITION_CATEGORY,
                    cellType: ListCell,
                    cellConfig: {
                        transform: (themas) => {
                            const sortedThemas = sortBy(['themaCodeTypeId', 'sortOrder'], themas)
                            return sortedThemas.map(
                                (thema) => `${thema.codeValue}: ${thema.codeDescription}`,
                            )
                        },
                        filter: (languageCode) => (thema) => thema.languageCode === languageCode,
                    },
                },
                mainEditionInterestAgeName: {
                    id: 'mainEditionInterestAgeName',
                    selectField: 'mainEditionInterestAgeName',
                    label: 'Main edition: Age group',
                    width: 100,
                    sortable: true,
                    category: MAIN_EDITION_CATEGORY,
                },
                mainEditionSeriesName: {
                    id: 'mainEditionSeriesName',
                    selectField: 'mainEditionSeriesName',
                    label: 'Main edition: Series, name',
                    width: 160,
                    sortable: true,
                    category: MAIN_EDITION_CATEGORY,
                },
                mainEditionNumberInSeries: {
                    id: 'mainEditionNumberInSeries',
                    selectField: 'mainEditionNumberInSeries',
                    label: 'Main edition: Series, position',
                    width: 80,
                    sortable: true,
                    category: MAIN_EDITION_CATEGORY,
                },
                mainEditionMainGroupName: {
                    id: 'mainEditionMainGroupName',
                    selectField: 'mainEditionMainGroupName',
                    label: 'Main edition: Main group',
                    width: 120,
                    sortable: true,
                },
                mainEditionSubGroupName: {
                    id: 'mainEditionSubGroupName',
                    selectField: 'mainEditionSubGroupName',
                    label: 'Main edition: Sub group',
                    width: 65,
                    sortable: true,
                },
                mainEditionLibraryCodeName: {
                    id: 'mainEditionLibraryCodeName',
                    selectField: 'mainEditionLibraryCodeName',
                    label: 'YKL Library code',
                    width: 180,
                    sortable: true,
                },
                mainEditionLibraryCodePrefixName: {
                    id: 'mainEditionLibraryCodePrefixName',
                    selectField: 'mainEditionLibraryCodePrefixName',
                    label: 'Main edition: YKL Library code prefix',
                    width: 120,
                    sortable: true,
                },
                mainEditionInternalCategoryName: {
                    id: 'mainEditionInternalCategoryName',
                    selectField: 'mainEditionInternalCategoryName',
                    label: 'Main edition: Internal category',
                    width: 200,
                    sortable: true,
                    category: MAIN_EDITION_CATEGORY,
                },
                mainEditionCostCenterName: {
                    id: 'mainEditionCostCenterName',
                    selectField: 'mainEditionCostCenterName',
                    label: 'Main edition: Cost center',
                    width: 200,
                    sortable: true,
                    category: MAIN_EDITION_CATEGORY,
                },
                productions: {
                    id: 'productions',
                    selectField: 'productions',
                    label: 'Editions',
                    width: 200,
                    sortable: false,
                    cellType: ListCell,
                    cellConfig: {
                        transform: (editions) =>
                            editions.map((edition) => (
                                <Link
                                    key={`/book/${edition.workId}/edition/${edition.id}`}
                                    component={NavLink}
                                    to={`/book/${edition.workId}/edition/${edition.id}`}
                                    style={{
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {edition.bindingCodeName}
                                </Link>
                            )),
                    },
                },
                reviewQuotes: {
                    id: 'reviewQuotes',
                    selectField: 'reviewQuotes',
                    label: 'Review Quotes (work)',
                    width: 250,
                    sortable: false,
                    cellType: ListCell,
                    cellConfig: {
                        transform: (reviewQuotes) => {
                            const sortedreviewQuotes = sortBy(['sortOrder'], reviewQuotes)
                            return sortedreviewQuotes.map(
                                (reviewQuote) => `${reviewQuote.source}: ${reviewQuote.quote}`,
                            )
                        },
                    },
                },
            },
        },
        {
            id: 'launchbrief',
            label: 'Launch brief',
            Component: BasicSearchView,
            requiredSelectFields: [
                'challenges',
                'communicationEffortFocus',
                'editorialWorkEffortHours',
                'description',
                'authorText',
                'id',
                'isAuthorAvailableForEvents',
                'isAuthorAvailableForPress',
                'mainEditionBrand',
                'mainEditionBrandName',
                'mainEditionCoverImageAssetId',
                'mainEditionEffortTypeName',
                'mainEditionEstimatedNumberOfPages',
                'mainEditionInterestAgeName',
                'mainEditionNumberInSeries',
                'mainEditionPages',
                'mainEditionPublishingDate',
                'mainEditionReviewDate',
                'mainEditionSeasonName',
                'mainEditionSeriesName',
                'members',
                'opportunities',
                'overallGoals',
                'productions',
                'publishingHouseName',
                'purpose',
                'salesArgument',
                'targetGroupNames',
                'targetGroups',
                'title',
                'themas',
                'willAuthorParticipate',
                `mainEdition_textFullByTypes_${texts.descriptionCore}`,
                `mainEdition_textFullByTypes_${texts.internalSalesPitch}`,
                `mainEdition_textFullByTypes_1`, // isNfg
                'mainEditionPublishingHouseId',
            ],
            componentConfig: {
                ItemComponent: LaunchBriefViewItem,
            },
        },
    ],
}
