import { flow, get, getOr, map, range, sortBy, toLower, uniq } from 'lodash/fp'
import { DateTime } from 'luxon'

import { servicesLoaders } from 'Common/environment'
import {
    booleanFilter,
    combinationOptionFilter,
    contactFilter,
    customOptionFilter,
    dateFilter,
    optionArrayFilter,
    optionFilter,
    optionIdNameArrayFilter,
    themaCodeFilter,
    timeplanFilter,
} from 'Common/search/filter/filters'
import { SearchConfig, ViewConfig } from 'Common/search/searchConfig'
import AdvancedSearchView from 'Common/search/view/AdvancedSearchView'
import BasicSearchView from 'Common/search/view/BasicSearchView'
import BooleanCell from 'Common/search/view/cells/BooleanCell'
import DateCell from 'Common/search/view/cells/DateCell'
import LinkCell from 'Common/search/view/cells/LinkCell'
import ListCell from 'Common/search/view/cells/ListCell'
import MemberCell from 'Common/search/view/cells/MemberCell'
import { IdName } from 'Common/types'
import { getMembersColumns } from 'Common/utils/roles'
import { getTextColumns, texts } from 'Common/utils/texts'
import { getTimePlanActualColumns, getTimePlanPlannedColumns } from 'Common/utils/timeplan'

import LaunchViewHeader from '../components/Productions/Search/LaunchViewHeader'
import LaunchViewItem from '../components/Productions/Search/LaunchViewItem'
import MediaViewItem from '../components/Productions/Search/MediaViewItem'
import ProductionSearchOperations from '../components/Productions/Search/ProductionSearchOperations'
import ProductionsViewHeader from '../components/Productions/Search/ProductionsViewHeader'
import ProductionsViewItem from '../components/Productions/Search/ProductionsViewItem'
import PublishingListSearchView from '../components/Productions/Search/PublishingListSearchView'
import PublishingListViewHeader from '../components/Productions/Search/PublishingListViewHeader'
import PublishingListViewItem from '../components/Productions/Search/PublishingListViewItem'
import SalesPitchViewItem from '../components/Productions/Search/SalesPitchViewItem'

const ROLES_CATEGORY = 'Roles'
const EDITION_CATEGORY = 'Edition'
const EDITION_EXPORT = 'Export'
const TEXTS_CATEGORY = 'Texts'
const TIMEPLAN_CATEGORY = 'Production plan'
const PRINT_CATEGORY = 'Print'

const SALES_END_YEAR: number = parseInt(
    DateTime.fromISO(new Date().toISOString())
        .plus({
            years: 5,
        })
        .toFormat('yyyy'),
)

const getYearInterval = (from = 1950, to: number = SALES_END_YEAR): IdName[] =>
    range(from, to)
        .reverse()
        .map((year) => ({
            name: `${year}`,
            id: `${year}`,
            isActive: true,
        }))
const bindingCodeTypes = ['Printed', 'Audio', 'Ebook']
const getBindingCodeTypeOptions = (): IdName[] =>
    bindingCodeTypes.map((bindingCodeType) => ({
        name: `${bindingCodeType}`,
        id: toLower(`${bindingCodeType}`),
        isActive: true,
    }))

const defaultSelectFields = [
    'id',
    'workId',
    'title',
    'isbn',
    'bindingCodeName',
    'publishingDate',
    'publishingHouseName',
    'publishingHouseGroup',
    'seasonName',
    'members',
    'membersByRole',
    'exportRulesRightsSalesChannels',
    'exportRulesDistributionSalesChannels',
    'exportRulesBlockedSalesChannels',
]
export const searchConfig: SearchConfig = {
    id: 'productions',
    searchService: servicesLoaders.work('/v2/search/productions'),
    searchServiceExport: servicesLoaders.work('/v2/search/productions/export'),
    searchLocation: '/book/editions',
    savedSearchesId: 'savedsearches-work-productionssearch-v5',
    searchMetaDataId: 'productions',
    accessModule: 'opus_work',
    defaultSelectFields,
    pagingSize: 50,
    queryFields: [
        {
            id: 'title',
            label: 'Title',
        },
        {
            id: 'isbn',
            label: 'ISBN',
        },
        {
            id: 'seriesName',
            label: 'Series',
        },
        {
            id: 'id',
            label: 'ID, edition',
        },
        {
            id: 'costCenterName',
            label: 'Cost center',
        },
        {
            id: 'workId',
            label: 'ID, work',
        },
        {
            id: 'activePrintTimeplanComment',
            label: 'Comments, production plan',
        },
        {
            id: 'erpPurchaseOrderNumbers',
            label: 'Order number',
        },
    ],
    //Needs to be alphabetical
    filters: [
        {
            type: booleanFilter,
            id: 'filterEbookIsAccessibleReadingEpub',
            searchId: 'activePrintEbookIsAccessibleReadingEpub',
            name: 'Accessible Reading Epub',
        },
        {
            type: dateFilter,
            id: 'activeWebPeriodEndDate',
            searchId: 'activeWebPeriodEndDate',
            name: 'Active web period end',
        },
        {
            type: dateFilter,
            id: 'activeWebPeriodStartDate',
            searchId: 'activeWebPeriodStartDate',
            name: 'Active web period start',
        },
        {
            type: optionFilter,
            id: 'filterInterestAges',
            searchId: 'interestAge',
            metaDataId: 'interestAges',
            name: 'Age group',
        },
        {
            type: optionArrayFilter,
            id: 'filterAssetTypes',
            searchId: 'assetTypeIds',
            metaDataId: 'assetTypes',
            name: 'Asset type',
        },
        {
            type: optionArrayFilter,
            id: 'filterAssetContractTypes',
            searchId: 'contractTypeIds',
            metaDataId: 'assetContractTypes',
            name: 'Contract type',
        },
        {
            type: booleanFilter,
            id: 'filterHasAudioFile',
            searchId: 'activePrintEbookHasAudioFile',
            name: 'Audio file',
        },
        {
            type: combinationOptionFilter,
            id: 'filterBacklistSeasons',
            searchId: 'backlistSeasonName',
            typeConfig: {
                keys: ['year', 'season'],
            },
            name: 'Backlist season',
            options: [
                {
                    id: 'year',
                    name: 'Backlist season, year',
                    defaultValue: `${new Date().getFullYear()}`,
                    metaDataId: 'backlistSeasonYears',
                },
                {
                    id: 'season',
                    name: 'Backlist season, period',
                    defaultValue: 'Spring',
                    metaDataId: 'backlistSeasonPeriods',
                },
            ],
        },
        {
            type: optionFilter,
            id: 'filterBindingId',
            searchId: 'bindingId',
            metaDataId: 'bookBindingBindings',
            name: 'Binding',
        },
        {
            type: optionFilter,
            id: 'filterBindingCodes',
            searchId: 'bindingCode',
            metaDataId: 'bindingCodes',
            name: 'Binding code',
        },
        {
            type: customOptionFilter,
            id: 'filterBindingCodeType',
            searchId: 'bindingCodeType',
            options: getBindingCodeTypeOptions(),
            name: 'Binding code type',
        },
        {
            type: optionFilter,
            id: 'filterBrands',
            searchId: 'brand',
            metaDataId: 'brands',
            name: 'Brand',
            typeConfig: {
                filterByBrands: true,
            },
        },
        {
            type: booleanFilter,
            id: 'filterHasCeLogo',
            searchId: 'hasCeLogo',
            name: 'Carries CE-Logo',
        },
        {
            type: booleanFilter,
            id: 'filterIsConnectedToERP',
            searchId: 'isConnectedToERP',
            name: 'Connected to ERP',
        },
        {
            type: contactFilter,
            id: 'filterContacts',
            name: 'Contact/role',
        },
        {
            type: optionFilter,
            id: 'filterCostCenter',
            searchId: 'costCenter',
            metaDataId: 'costCenters',
            name: 'Cost center',
        },
        {
            type: dateFilter,
            id: 'filterCreatedDate',
            searchId: 'createdDate',
            name: 'Created',
        },
        {
            type: booleanFilter,
            id: 'filterIsCancelled',
            searchId: 'isCancelled',
            name: 'Deactivated',
        },
        {
            type: optionIdNameArrayFilter,
            id: 'filterExportDistribution',
            searchId: 'exportRulesDistributionSalesChannels',
            metaDataId: 'salesChannels',
            name: 'Export distribution',
        },
        {
            type: optionIdNameArrayFilter,
            id: 'filterExportBlocked',
            searchId: 'exportRulesBlockedSalesChannels',
            metaDataId: 'salesChannels',
            name: 'Export is blocked',
        },
        {
            type: optionIdNameArrayFilter,
            id: 'filterExportRights',
            searchId: 'exportRulesRightsSalesChannels',
            metaDataId: 'salesChannels',
            name: 'Export rights',
        },
        {
            type: booleanFilter,
            id: 'filterHasFscPaper',
            searchId: 'activePrintHasFscPaper',
            name: 'FSC Paper',
        },
        {
            type: optionFilter,
            id: 'filterListingCodes',
            searchId: 'listingCodeId',
            metaDataId: 'listingCodes',
            name: 'Governing code',
        },
        {
            type: optionFilter,
            id: 'filterInsideFormats',
            searchId: 'insideFormatId',
            metaDataId: 'contractFormats',
            name: 'Inside format',
        },
        {
            type: optionFilter,
            id: 'filterInternalCategories',
            searchId: 'internalCategoryId',
            metaDataId: 'internalCategories',
            typeConfig: {
                disableSelectAll: true,
            },
            name: 'Internal category',
        },
        {
            type: booleanFilter,
            id: 'filterhasIsbn',
            searchId: 'hasIsbn',
            name: 'Isbn',
        },
        {
            type: booleanFilter,
            id: 'filterIsBgaActive',
            searchId: 'isBgaActive',
            name: 'Is agency title',
        },
        {
            type: optionArrayFilter,
            id: 'languageIds',
            searchId: 'languageIds',
            metaDataId: 'productionLanguages',
            name: 'Language',
        },
        {
            type: optionFilter,
            id: 'filterEffortType',
            searchId: 'effortTypeId',
            metaDataId: 'effortTypes',
            name: 'List category',
        },
        {
            type: booleanFilter,
            id: 'filterIsMainEdition',
            searchId: 'isMainEdition',
            name: 'Main edition',
        },
        {
            type: optionFilter,
            id: 'filterMainGroupId',
            searchId: 'mainGroupId',
            metaDataId: 'mainGroups',
            name: 'Main group',
        },
        {
            type: optionArrayFilter,
            id: 'filterMissingAssetTypes',
            searchId: 'missingAssetTypeIds',
            metaDataId: 'assetTypes',
            name: 'Missing asset type',
        },
        {
            type: booleanFilter,
            id: 'filterIsPrintOnDemand',
            searchId: 'isPrintOnDemand',
            name: 'Print On Demand',
        },
        {
            type: optionFilter,
            id: 'filterArticleGroup',
            searchId: 'articleGroup',
            metaDataId: 'articleGroups',
            name: 'Product group',
        },
        {
            type: timeplanFilter,
            id: 'filterTimeplan',
            name: 'Production plan',
        },
        {
            type: booleanFilter,
            id: 'filterIsPublished',
            searchId: 'isPublished',
            name: 'Public',
        },
        {
            type: dateFilter,
            id: 'filterPubDates',
            searchId: 'publishingDate',
            name: 'Publication date',
        },
        {
            type: optionFilter,
            id: 'filterPublishingHouses',
            searchId: 'publishingHouse',
            metaDataId: 'publishingHouses',
            typeConfig: {
                filterByPublishingHouse: true,
            },
            name: 'Publishing house',
        },
        {
            type: optionFilter,
            id: 'filterPublishingHouseGroups',
            searchId: 'publishingHouseGroup',
            metaDataId: 'publishingHouseGroups',
            name: 'Publishing house group',
        },
        {
            type: optionFilter,
            id: 'filterPublishingLists',
            searchId: 'publishingList',
            metaDataId: 'publishingLists',
            name: 'Publishing list',
        },
        {
            type: dateFilter,
            id: 'filterRevDates',
            searchId: 'reviewDate',
            name: 'Review date',
        },
        {
            type: booleanFilter,
            id: 'filterRoyalty',
            searchId: 'royalty',
            name: 'Royalty',
        },
        {
            type: optionFilter,
            id: 'filterContractTypes',
            searchId: 'contractTypeId',
            metaDataId: 'contractTypes',
            name: 'Royalty model',
        },
        {
            type: optionFilter,
            id: 'filterSalesStatus',
            searchId: 'salesStatusId',
            metaDataId: 'salesStatus',
            name: 'Sales status',
        },
        {
            type: customOptionFilter,
            id: 'filterSalesYear',
            searchId: 'salesYear',
            typeConfig: {
                disableSelectAll: true,
            },
            options: getYearInterval(1950, SALES_END_YEAR),
            name: 'Sales year',
        },
        {
            type: combinationOptionFilter,
            id: 'filterSeasons',
            searchId: 'seasonName',
            typeConfig: {
                keys: ['year', 'season'],
            },
            name: 'Season',
            options: [
                {
                    id: 'year',
                    name: 'Season, year',
                    defaultValue: `${new Date().getFullYear()}`,
                    metaDataId: 'seasonYears',
                },
                {
                    id: 'season',
                    name: 'Season, period',
                    defaultValue: 'Spring',
                    metaDataId: 'seasonPeriods',
                },
            ],
        },
        {
            type: booleanFilter,
            id: 'filterBgaShowOnSite',
            searchId: 'bgaShowOnSite',
            name: 'Show on agency website',
        },
        {
            type: optionFilter,
            id: 'filterStatuses',
            searchId: 'productionStatusId',
            metaDataId: 'productionStatuses',
            name: 'Status',
        },
        {
            type: optionFilter,
            id: 'filterSubGroupId',
            searchId: 'subGroupId',
            metaDataId: 'subGroups',
            name: 'Sub group',
        },
        {
            type: optionFilter,
            id: 'filterTechnicalProductionTypes',
            searchId: 'technicalProductionTypeId',
            metaDataId: 'technicalProductionTypes',
            name: 'Technical production type',
        },
        {
            type: themaCodeFilter,
            id: 'filterThemaCode',
            name: 'Thema code',
        },
        {
            type: optionArrayFilter,
            id: 'filterTextTypes',
            searchId: 'textTypeIds',
            metaDataId: 'textTypes',
            name: 'Text type',
        },
        {
            type: dateFilter,
            id: 'filterUpdatedDate',
            searchId: 'updatedDate',
            name: 'Updated',
        },
    ],
}
export const viewsConfig: ViewConfig = {
    views: [
        {
            id: 'basic',
            label: 'Basic',
            Component: BasicSearchView,
            componentConfig: {
                HeaderComponent: ProductionsViewHeader,
                ItemComponent: ProductionsViewItem,
            },
        },
        {
            id: 'advanced',
            label: 'Advanced',
            Component: AdvancedSearchView,
            ActionsComponent: ProductionSearchOperations,
            savedViewsId: 'savedviews-work-productionssearch-v2',
            defaultColumns: [
                'title',
                'bindingCodeName',
                'publishingHouseName',
                'publishingDate',
                'seasonName',
            ],
            defaultMetaDataColumns: [
                {
                    metaDataId: 'contactRoles',
                    filterMetaDataBy: (role) => getOr(false, 'customProperties.isAuthor', role),
                    mapMetaDataToColumns: (role) => `membersByRole_${role.id}`,
                },
            ],
            metaDataColumns: [
                {
                    metaDataId: 'contactRoles',
                    mapMetaDataToColumns: getMembersColumns(ROLES_CATEGORY, MemberCell),
                },
                {
                    metaDataId: 'timePlanEntryTypes',
                    mapMetaDataToColumns: getTimePlanPlannedColumns(TIMEPLAN_CATEGORY, DateCell),
                },
                {
                    metaDataId: 'timePlanEntryTypes',
                    mapMetaDataToColumns: getTimePlanActualColumns(TIMEPLAN_CATEGORY, DateCell),
                },
                {
                    metaDataId: 'textTypes',
                    mapMetaDataToColumns: getTextColumns(TEXTS_CATEGORY),
                },
            ],
            columns: {
                id: {
                    id: 'id',
                    selectField: 'id',
                    label: 'ID, Edition',
                    width: 120,
                    sortable: true,
                    category: EDITION_CATEGORY,
                    cellType: LinkCell,
                    cellConfig: {
                        createLink: ({ workId, id }) => `/book/${workId}/edition/${id}`,
                    },
                },
                workId: {
                    id: 'workId',
                    selectField: 'workId',
                    label: 'ID, Work',
                    width: 100,
                    sortable: true,
                    category: EDITION_CATEGORY,
                    cellType: LinkCell,
                    cellConfig: {
                        createLink: ({ workId }) => `/book/${workId}`,
                    },
                },
                title: {
                    id: 'title',
                    selectField: 'title',
                    label: 'Title',
                    width: 300,
                    sortable: true,
                    category: EDITION_CATEGORY,
                    cellType: LinkCell,
                    cellConfig: {
                        createLink: ({ workId, id }) => `/book/${workId}/edition/${id}`,
                    },
                },
                bindingCodeName: {
                    id: 'bindingCodeName',
                    selectField: 'bindingCodeName',
                    label: 'Binding code',
                    width: 135,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                bindingCodeType: {
                    id: 'bindingCodeType',
                    selectField: 'bindingCodeType',
                    label: 'Binding code type',
                    width: 135,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                publishingHouseName: {
                    id: 'publishingHouseName',
                    selectField: 'publishingHouseName',
                    label: 'Publishing house',
                    width: 160,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                isbn: {
                    id: 'isbn',
                    selectField: 'isbn',
                    label: 'ISBN',
                    width: 140,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                ukIsbn: {
                    id: 'ukIsbn',
                    selectField: 'ukIsbn',
                    label: 'UK ISBN',
                    width: 115,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                seasonName: {
                    id: 'seasonName',
                    selectField: 'seasonName',
                    label: 'Season',
                    width: 115,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                backlistSeasonName: {
                    id: 'backlistSeasonName',
                    selectField: 'backlistSeasonName',
                    label: 'Backlist season',
                    width: 115,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                publishingDate: {
                    id: 'publishingDate',
                    selectField: 'publishingDate',
                    label: 'Publ. date',
                    width: 105,
                    sortable: true,
                    category: EDITION_CATEGORY,
                    cellType: DateCell,
                },
                reviewDate: {
                    id: 'reviewDate',
                    selectField: 'reviewDate',
                    label: 'Rev. date',
                    width: 105,
                    sortable: true,
                    category: EDITION_CATEGORY,
                    cellType: DateCell,
                },
                articleGroupName: {
                    id: 'articleGroupName',
                    selectField: 'articleGroupName',
                    label: 'Product group',
                    width: 235,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                costCenterName: {
                    id: 'costCenterName',
                    selectField: 'costCenterName',
                    label: 'Cost center',
                    width: 205,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                productionStatusName: {
                    id: 'productionStatusName',
                    selectField: 'productionStatusName',
                    label: 'Status',
                    width: 155,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                interestAgeName: {
                    id: 'interestAgeName',
                    selectField: 'interestAgeName',
                    label: 'Age group',
                    width: 105,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                brandName: {
                    id: 'brandName',
                    selectField: 'brandName',
                    label: 'Brand',
                    width: 165,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                printsTotal: {
                    id: 'printsTotal',
                    selectField: 'printsTotal',
                    label: 'Print total',
                    width: 90,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                maxPrintNumber: {
                    id: 'maxPrintNumber',
                    selectField: 'maxPrintNumber',
                    label: 'Max print number',
                    width: 90,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                pages: {
                    id: 'pages',
                    selectField: 'pages',
                    label: 'Last numbered page',
                    width: 115,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                resellerPrice: {
                    id: 'resellerPrice',
                    selectField: 'resellerPrice',
                    label: 'Price excl. VAT',
                    width: 75,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                resellerPriceIncludingVat: {
                    id: 'resellerPriceIncludingVat',
                    selectField: 'resellerPriceIncludingVat',
                    label: 'Price incl. VAT',
                    width: 75,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                publisherRetailPrice: {
                    id: 'publisherRetailPrice',
                    selectField: 'publisherRetailPrice',
                    label: 'Publisher retail price',
                    width: 75,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                publisherRetailPriceIncludingVat: {
                    id: 'publisherRetailPriceIncludingVat',
                    selectField: 'publisherRetailPriceIncludingVat',
                    label: 'Publisher retail price incl. VAT',
                    width: 75,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                priceGroupPocketName: {
                    id: 'priceGroupPocketName',
                    selectField: 'priceGroupPocketName',
                    label: 'Price group pocket',
                    width: 75,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                seriesName: {
                    id: 'seriesName',
                    selectField: 'seriesName',
                    label: 'Series, name',
                    width: 165,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                marketingSerie: {
                    id: 'marketingSerie',
                    selectField: 'marketingSerie',
                    label: 'Marketing series, name',
                    width: 165,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                numberInSeries: {
                    id: 'numberInSeries',
                    selectField: 'numberInSeries',
                    label: 'Series, position',
                    width: 85,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                isPublished: {
                    id: 'isPublished',
                    selectField: 'isPublished',
                    label: 'Public',
                    width: 90,
                    sortable: true,
                    category: EDITION_CATEGORY,
                    cellType: BooleanCell,
                },
                isConnectedToERP: {
                    id: 'isConnectedToERP',
                    selectField: 'isConnectedToERP',
                    label: 'Connected to ERP',
                    width: 90,
                    sortable: true,
                    category: EDITION_CATEGORY,
                    cellType: BooleanCell,
                },
                isCancelled: {
                    id: 'isCancelled',
                    selectField: 'isCancelled',
                    label: 'Cancelled',
                    width: 90,
                    sortable: true,
                    category: EDITION_CATEGORY,
                    cellType: BooleanCell,
                },
                isMainEdition: {
                    id: 'isMainEdition',
                    selectField: 'isMainEdition',
                    label: 'Main edition',
                    width: 120,
                    sortable: true,
                    category: EDITION_CATEGORY,
                    cellType: BooleanCell,
                },
                audioPlaytimeHours: {
                    id: 'audioPlaytimeHours',
                    selectField: 'audioPlaytimeHours',
                    label: 'Running time, hours',
                    width: 105,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                audioPlaytimeMinutes: {
                    id: 'audioPlaytimeMinutes',
                    selectField: 'audioPlaytimeMinutes',
                    label: 'Running time, minutes',
                    width: 105,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                internalCategoryName: {
                    id: 'internalCategoryName',
                    selectField: 'internalCategoryName',
                    label: 'Internal category',
                    width: 200,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                listingCodeName: {
                    id: 'listingCodeName',
                    selectField: 'listingCodeName',
                    label: 'Governing code',
                    width: 140,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                audioIfcd: {
                    id: 'audioIfcd',
                    selectField: 'audioIfcd',
                    label: 'IFCD',
                    width: 70,
                    sortable: false,
                    category: EDITION_CATEGORY,
                },
                technicalProductionTypeName: {
                    id: 'technicalProductionTypeName',
                    selectField: 'technicalProductionTypeName',
                    label: 'Technical production type',
                    width: 180,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                contractTypeName: {
                    id: 'contractTypeName',
                    selectField: 'contractTypeName',
                    label: 'Royalty model',
                    width: 100,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                activePrintInsidePages: {
                    id: 'activePrintInsidePages',
                    selectField: 'activePrintInsidePages',
                    label: 'Size (pages)',
                    width: 130,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                insideFormatName: {
                    id: 'insideFormatName',
                    selectField: 'insideFormatName',
                    label: 'Inside format',
                    width: 180,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                activePrintInsideTrimmedFormatWidth: {
                    id: 'activePrintInsideTrimmedFormatWidth',
                    selectField: 'activePrintInsideTrimmedFormatWidth',
                    label: 'Width (inside)',
                    width: 120,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                activePrintInsideTrimmedFormatHeight: {
                    id: 'activePrintInsideTrimmedFormatHeight',
                    selectField: 'activePrintInsideTrimmedFormatHeight',
                    label: 'Height (inside)',
                    width: 120,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                activePrintInsidePaperName: {
                    id: 'activePrintInsidePaperName',
                    selectField: 'activePrintInsidePaperName',
                    label: 'Paper (inside)',
                    width: 130,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                activePrintInsideName: {
                    id: 'activePrintInsideName',
                    selectField: 'activePrintInsideName',
                    label: 'Paper name (inside)',
                    width: 130,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                activePrintQuantityPlanned: {
                    id: 'activePrintQuantityPlanned',
                    selectField: 'activePrintQuantityPlanned',
                    label: 'Preliminary print run',
                    width: 150,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                activePrintQuantityOrdered: {
                    id: 'activePrintQuantityOrdered',
                    selectField: 'activePrintQuantityOrdered',
                    label: 'Definitive print run',
                    width: 150,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                activePrintDefinitiveSpineWidth: {
                    id: 'activePrintDefinitiveSpineWidth',
                    selectField: 'activePrintDefinitiveSpineWidth',
                    label: 'Spine width in mm',
                    width: 120,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                quantityCumulative: {
                    id: 'quantityCumulative',
                    selectField: 'quantityCumulative',
                    label: 'Accumulated print run',
                    width: 150,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                deliveryNoteTitle: {
                    id: 'deliveryNoteTitle',
                    selectField: 'deliveryNoteTitle',
                    label: 'Article text',
                    width: 200,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                originalTitle: {
                    id: 'originalTitle',
                    selectField: 'originalTitle',
                    label: 'Original title',
                    width: 200,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                subtitle: {
                    id: 'subtitle',
                    selectField: 'subtitle',
                    label: 'Subtitle',
                    width: 200,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                assets: {
                    id: 'assets',
                    selectField: 'assets',
                    label: 'Asset types',
                    width: 200,
                    sortable: false,
                    category: EDITION_CATEGORY,
                    cellType: ListCell,
                    cellConfig: {
                        transform: (assets) =>
                            flow(
                                map((asset) => get('assetType.name', asset)),
                                uniq,
                                sortBy((_) => _),
                            )(assets),
                    },
                },
                contractTypeNames: {
                    id: 'contractTypeNames',
                    selectField: 'contractTypeNames',
                    label: 'Contract types',
                    width: 200,
                    sortable: false,
                    category: EDITION_CATEGORY,
                    cellType: ListCell,
                },
                texts: {
                    id: 'texts',
                    selectField: 'texts',
                    label: 'Text types',
                    width: 200,
                    sortable: false,
                    category: EDITION_CATEGORY,
                    cellType: ListCell,
                    cellConfig: {
                        transform: (textTypes) =>
                            flow(
                                map((text) => get('textType.name', text)),
                                uniq,
                                sortBy((_) => _),
                            )(textTypes),
                    },
                },
                themas: {
                    id: 'themas',
                    selectField: 'themas',
                    label: 'Thema',
                    sortable: false,
                    width: 250,
                    category: EDITION_CATEGORY,
                    cellType: ListCell,
                    cellConfig: {
                        transform: (themas) => {
                            const sortedThemas = sortBy(['themaCodeTypeId', 'sortOrder'], themas)
                            return sortedThemas.map((thema) => thema.codeDescription)
                        },
                        filter: (languageCode) => (thema) => thema.languageCode === languageCode,
                    },
                },
                effortTypeName: {
                    id: 'effortTypeName',
                    selectField: 'effortTypeName',
                    label: 'List category',
                    width: 140,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                activePrintTimeplanComment: {
                    id: 'activePrintTimeplanComment',
                    selectField: 'activePrintTimeplanComment',
                    label: 'Comments, production plan',
                    width: 200,
                    sortable: false,
                    category: TIMEPLAN_CATEGORY,
                },
                isPrintOnDemand: {
                    id: 'isPrintOnDemand',
                    selectField: 'isPrintOnDemand',
                    label: 'Print On Demand',
                    width: 140,
                    sortable: true,
                    category: EDITION_CATEGORY,
                    cellType: BooleanCell,
                },
                isBgaActive: {
                    id: 'isBgaActive',
                    selectField: 'isBgaActive',
                    label: 'Is agency title',
                    width: 140,
                    sortable: true,
                    category: EDITION_CATEGORY,
                    cellType: BooleanCell,
                },
                bgaShowOnSite: {
                    id: 'bgaShowOnSite',
                    selectField: 'bgaShowOnSite',
                    label: 'Show on agency website',
                    width: 140,
                    sortable: true,
                    category: EDITION_CATEGORY,
                    cellType: BooleanCell,
                },
                firstPublishedYear: {
                    id: 'firstPublishedYear',
                    selectField: 'firstPublishedYear',
                    label: 'Year of publication of the original edition',
                    width: 180,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                keywords: {
                    id: 'keywords',
                    selectField: 'keywords',
                    label: 'Subject matter',
                    width: 140,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                bookTypes: {
                    id: 'bookTypes',
                    selectField: 'bookTypes',
                    label: 'Book type',
                    width: 140,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                timePeriods: {
                    id: 'timePeriods',
                    selectField: 'timePeriods',
                    label: 'Time',
                    width: 140,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                places: {
                    id: 'places',
                    selectField: 'places',
                    label: 'Place',
                    width: 140,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                authorGroups: {
                    id: 'authorGroups',
                    selectField: 'authorGroups',
                    label: 'Author grouping',
                    width: 140,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                authorSortOrders: {
                    id: 'authorSortOrders',
                    selectField: 'authorSortOrders',
                    label: 'Author sort orders',
                    width: 140,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                bibliographicCharacters: {
                    id: 'bibliographicCharacters',
                    selectField: 'bibliographicCharacters',
                    label: 'Non-fictional person present in the book',
                    width: 140,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                characterTypes: {
                    id: 'characterTypes',
                    selectField: 'characterTypes',
                    label: 'Character types',
                    width: 140,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                nominationNames: {
                    id: 'nominationNames',
                    selectField: 'nominationNames',
                    label: 'Award nominations',
                    width: 140,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                awardNames: {
                    id: 'awardNames',
                    selectField: 'awardNames',
                    label: 'Awards',
                    width: 140,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                targetGroups: {
                    id: 'targetGroups',
                    selectField: 'targetGroups',
                    label: 'Target groups',
                    width: 140,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                webpage: {
                    id: 'webpage',
                    selectField: 'webpage',
                    label: 'Webpage',
                    width: 140,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                screenVersions: {
                    id: 'screenVersions',
                    selectField: 'screenVersions',
                    label: 'Screen versions',
                    width: 140,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                webKeywords: {
                    id: 'webKeywords',
                    selectField: 'webKeywords',
                    label: 'Keywords',
                    width: 140,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                activePrintInsidePrintingName: {
                    id: 'activePrintInsidePrintingName',
                    selectField: 'activePrintInsidePrintingName',
                    label: 'Print',
                    width: 140,
                    sortable: true,
                    category: PRINT_CATEGORY,
                },
                activePrintInsideWeight: {
                    id: 'activePrintInsideWeight',
                    selectField: 'activePrintInsideWeight',
                    label: 'Inside weight (grams)',
                    width: 65,
                    sortable: true,
                    category: PRINT_CATEGORY,
                },
                activePrintCoverPrintingName: {
                    id: 'activePrintCoverPrintingName',
                    selectField: 'activePrintCoverPrintingName',
                    label: 'Cover: Printing',
                    width: 140,
                    sortable: true,
                    category: PRINT_CATEGORY,
                },
                activePrintPrintedCoverPrintingName: {
                    id: 'activePrintPrintedCoverPrintingName',
                    selectField: 'activePrintPrintedCoverPrintingName',
                    label: 'Printed cover: Printing',
                    width: 140,
                    sortable: true,
                    category: PRINT_CATEGORY,
                },
                activePrintSoftCoverPrintingName: {
                    id: 'activePrintSoftCoverPrintingName',
                    selectField: 'activePrintSoftCoverPrintingName',
                    label: 'Softcover: Printing',
                    width: 140,
                    sortable: true,
                    category: PRINT_CATEGORY,
                },
                activePrintJacketPrintingName: {
                    id: 'activePrintJacketPrintingName',
                    selectField: 'activePrintJacketPrintingName',
                    label: 'Jacket: Printing',
                    width: 140,
                    sortable: true,
                    category: PRINT_CATEGORY,
                },
                activePrintCoverLaminationName: {
                    id: 'activePrintCoverLaminationName',
                    selectField: 'activePrintCoverLaminationName',
                    label: 'Cover: Lamination',
                    width: 140,
                    sortable: true,
                    category: PRINT_CATEGORY,
                },
                activePrintPrintedCoverLaminationName: {
                    id: 'activePrintPrintedCoverLaminationName',
                    selectField: 'activePrintPrintedCoverLaminationName',
                    label: 'Printed cover: Lamination',
                    width: 140,
                    sortable: true,
                    category: PRINT_CATEGORY,
                },
                activePrintSoftCoverLaminationName: {
                    id: 'activePrintSoftCoverLaminationName',
                    selectField: 'activePrintSoftCoverLaminationName',
                    label: 'SoftCover: Lamination',
                    width: 140,
                    sortable: true,
                    category: PRINT_CATEGORY,
                },
                stockBalance: {
                    id: 'stockBalance',
                    selectField: 'stockBalance',
                    label: 'Stock balance',
                    width: 100,
                    sortable: true,
                    category: PRINT_CATEGORY,
                },
                activePrintJacketLaminationName: {
                    id: 'activePrintJacketLaminationName',
                    selectField: 'activePrintJacketLaminationName',
                    label: 'Jacket: Lamination',
                    width: 140,
                    sortable: true,
                    category: PRINT_CATEGORY,
                },
                activePrintEbookHasAudioFile: {
                    id: 'activePrintEbookHasAudioFile',
                    selectField: 'activePrintEbookHasAudioFile',
                    label: 'Audio file',
                    width: 140,
                    sortable: true,
                    category: PRINT_CATEGORY,
                    cellType: BooleanCell,
                },
                accessPrice: {
                    id: 'accessPrice',
                    selectField: 'accessPrice',
                    label: 'Access price',
                    width: 140,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                salesYear: {
                    id: 'salesYear',
                    selectField: 'salesYear',
                    label: 'Sales year',
                    width: 140,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                salesStatusName: {
                    id: 'salesStatusName',
                    selectField: 'salesStatusName',
                    label: 'Sales status',
                    width: 140,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                licensePrice: {
                    id: 'licensePrice',
                    selectField: 'licensePrice',
                    label: 'License price',
                    width: 140,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                salesPrice: {
                    id: 'salesPrice',
                    selectField: 'salesPrice',
                    label: 'Sales price',
                    width: 65,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                libraryCodeName: {
                    id: 'libraryCodeName',
                    selectField: 'libraryCodeName',
                    label: 'YKL Library code',
                    width: 180,
                    sortable: true,
                },
                libraryCodePrefixName: {
                    id: 'libraryCodePrefixName',
                    selectField: 'libraryCodePrefixName',
                    label: 'YKL Library code prefix',
                    width: 120,
                    sortable: true,
                },
                mainGroupName: {
                    id: 'mainGroupName',
                    selectField: 'mainGroupName',
                    label: 'Main group',
                    width: 120,
                    sortable: true,
                },
                subGroupName: {
                    id: 'subGroupName',
                    selectField: 'subGroupName',
                    label: 'Sub group',
                    width: 65,
                    sortable: true,
                },
                activeWebPeriodStartDate: {
                    id: 'activeWebPeriodStartDate',
                    selectField: 'activeWebPeriodStartDate',
                    label: 'Active web period start',
                    width: 100,
                    sortable: true,
                    cellType: DateCell,
                },
                activeWebPeriodEndDate: {
                    id: 'activeWebPeriodEndDate',
                    selectField: 'activeWebPeriodEndDate',
                    label: 'Active web period end',
                    width: 100,
                    sortable: true,
                    cellType: DateCell,
                },
                firstSellingDay: {
                    id: 'firstSellingDay',
                    selectField: 'firstSellingDay',
                    label: 'First permitted day of sale',
                    width: 100,
                    sortable: true,
                    cellType: DateCell,
                },
                createdDate: {
                    id: 'createdDate',
                    selectField: 'createdDate',
                    label: 'Created',
                    width: 100,
                    sortable: true,
                    cellType: DateCell,
                },
                updatedDate: {
                    id: 'updatedDate',
                    selectField: 'updatedDate',
                    label: 'Updated',
                    width: 100,
                    sortable: true,
                    cellType: DateCell,
                },
                taxCodeName: {
                    id: 'taxCodeName',
                    selectField: 'taxCodeName',
                    label: 'Tax code',
                    width: 140,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                weight: {
                    id: 'weight',
                    selectField: 'weight',
                    label: 'Weight (kg)',
                    width: 65,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                estimatedWeight: {
                    id: 'estimatedWeight',
                    selectField: 'estimatedWeight',
                    label: 'Estimated Weight',
                    width: 65,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                estimatedNumberOfPages: {
                    id: 'estimatedNumberOfPages',
                    selectField: 'estimatedNumberOfPages',
                    label: 'Estimated number of pages',
                    width: 65,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                imageSheetExists: {
                    id: 'imageSheetExists',
                    selectField: 'imageSheetExists',
                    label: 'Image sheet exists',
                    width: 85,
                    sortable: true,
                    cellType: BooleanCell,
                    category: EDITION_CATEGORY,
                },
                activePrintPrintedCoverPaperOther: {
                    id: 'activePrintPrintedCoverPaperOther',
                    selectField: 'activePrintPrintedCoverPaperOther',
                    label: 'Printed cover paper',
                    width: 140,
                    sortable: true,
                    category: PRINT_CATEGORY,
                },
                activePrintSoftCoverPaperOther: {
                    id: 'activePrintSoftCoverPaperOther',
                    selectField: 'activePrintSoftCoverPaperOther',
                    label: 'Soft cover paper',
                    width: 140,
                    sortable: true,
                    category: PRINT_CATEGORY,
                },
                activePrintJacketPaperOther: {
                    id: 'activePrintJacketPaperOther',
                    selectField: 'activePrintJacketPaperOther',
                    label: 'Jacket cover paper',
                    width: 140,
                    sortable: true,
                    category: PRINT_CATEGORY,
                },
                activePrintRightsComment: {
                    id: 'activePrintRightsComment',
                    selectField: 'activePrintRightsComment',
                    label: 'Rights comments, edition',
                    width: 240,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                insidePaperBulk: {
                    id: 'insidePaperBulk',
                    selectField: 'insidePaperBulk',
                    label: 'Inside paper bulk',
                    width: 65,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                depth: {
                    id: 'depth',
                    selectField: 'depth',
                    label: 'Thickness (m)',
                    width: 65,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                estimatedDepth: {
                    id: 'estimatedDepth',
                    selectField: 'estimatedDepth',
                    label: 'Estimated Thickness',
                    width: 65,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                width: {
                    id: 'width',
                    selectField: 'width',
                    label: 'Width (m)',
                    width: 65,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                estimatedWidth: {
                    id: 'estimatedWidth',
                    selectField: 'estimatedWidth',
                    label: 'Estimated Width',
                    width: 65,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                calculatedOuterMeasurement: {
                    id: 'calculatedOuterMeasurement',
                    selectField: 'calculatedOuterMeasurement',
                    label: 'Calculated outer measurement (mm)',
                    width: 65,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                estimatedOuterMeasurement: {
                    id: 'estimatedOuterMeasurement',
                    selectField: 'estimatedOuterMeasurement',
                    label: 'Estimated outer measurement',
                    width: 65,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                height: {
                    id: 'height',
                    selectField: 'height',
                    label: 'Height (m)',
                    width: 65,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                estimatedHeight: {
                    id: 'estimatedHeight',
                    selectField: 'estimatedHeight',
                    label: 'Estimated Height',
                    width: 65,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                bindingName: {
                    id: 'bindingName',
                    selectField: 'bindingName',
                    label: 'Binding',
                    width: 200,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                comment: {
                    id: 'comment',
                    selectField: 'comment',
                    label: 'General comments',
                    width: 200,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                languageNames: {
                    id: 'languageNames',
                    selectField: 'languageNames',
                    label: 'Language',
                    width: 200,
                    sortable: false,
                    category: EDITION_CATEGORY,
                    cellType: ListCell,
                },
                erpPurchaseOrderNumbers: {
                    id: 'erpPurchaseOrderNumbers',
                    selectField: 'erpPurchaseOrderNumbers',
                    label: 'Order number',
                    width: 140,
                    sortable: false,
                    category: EDITION_CATEGORY,
                    cellType: ListCell,
                    cellConfig: {
                        transform: (erpPurchaseOrderNumbers) =>
                            flow(
                                uniq,
                                sortBy((_) => _),
                            )(erpPurchaseOrderNumbers),
                    },
                },
                calculatedNumberOfCharacters: {
                    id: 'calculatedNumberOfCharacters',
                    selectField: 'calculatedNumberOfCharacters',
                    label: 'Calculated number of characters',
                    width: 110,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                calculatedRunningTimeSeconds: {
                    id: 'calculatedRunningTimeSeconds',
                    selectField: 'calculatedRunningTimeSeconds',
                    label: 'Calculated running time (seconds)',
                    width: 110,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                publishingListName: {
                    id: 'publishingListName',
                    selectField: 'publishingListName',
                    label: 'Publishing list',
                    width: 200,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                exportRulesRightsSalesChannels: {
                    id: 'exportRulesRightsSalesChannels',
                    selectField: 'exportRulesRightsSalesChannels',
                    label: 'Export rights',
                    width: 200,
                    sortable: false,
                    category: EDITION_EXPORT,
                    cellType: ListCell,
                    cellConfig: {
                        transform: (salesChannels) =>
                            salesChannels.sort(({ name }) => name).map(({ name }) => name),
                    },
                },
                exportRulesDistributionSalesChannels: {
                    id: 'exportRulesDistributionSalesChannels',
                    selectField: 'exportRulesDistributionSalesChannels',
                    label: 'Export distributions',
                    width: 200,
                    sortable: false,
                    category: EDITION_EXPORT,
                    cellType: ListCell,
                    cellConfig: {
                        transform: (salesChannels) =>
                            salesChannels.sort(({ name }) => name).map(({ name }) => name),
                    },
                },
                exportRulesBlockedSalesChannels: {
                    id: 'exportRulesBlockedSalesChannels',
                    selectField: 'exportRulesBlockedSalesChannels',
                    label: 'Blocked in sales channels',
                    width: 200,
                    sortable: false,
                    category: EDITION_EXPORT,
                    cellType: ListCell,
                    cellConfig: {
                        transform: (salesChannels) =>
                            salesChannels.sort(({ name }) => name).map(({ name }) => name),
                    },
                },
                activePrintHasFscPaper: {
                    id: 'activePrintHasFscPaper',
                    selectField: 'activePrintHasFscPaper',
                    label: 'FSC Paper',
                    width: 65,
                    sortable: false,
                    category: PRINT_CATEGORY,
                    cellType: BooleanCell,
                },
                hasCeLogo: {
                    id: 'hasCeLogo',
                    selectField: 'hasCeLogo',
                    label: 'CE-Logo',
                    width: 65,
                    sortable: false,
                    category: EDITION_CATEGORY,
                    cellType: BooleanCell,
                },
                activePrintEbookIsAccessibleReadingEpub: {
                    id: 'activePrintEbookIsAccessibleReadingEpub',
                    selectField: 'activePrintEbookIsAccessibleReadingEpub',
                    label: 'Accessible Reading Epub',
                    width: 105,
                    sortable: false,
                    category: PRINT_CATEGORY,
                    cellType: BooleanCell,
                },
                mainCharacters: {
                    id: 'mainCharacters',
                    selectField: 'mainCharacters',
                    label: 'Main Characters',
                    width: 100,
                    sortable: true,
                    category: EDITION_CATEGORY,
                },
                editionNumber: {
                    id: 'editionNumber',
                    selectField: 'editionNumber',
                    label: 'Edition Number',
                    width: 65,
                    sortable: false,
                    category: EDITION_CATEGORY,
                },
                rightsStartDate: {
                    id: 'rightsStartDate',
                    selectField: 'rightsStartDate',
                    label: 'Contract rights start date',
                    width: 105,
                    sortable: false,
                    category: EDITION_CATEGORY,
                    cellType: DateCell,
                },
                rightsEndDate: {
                    id: 'rightsEndDate',
                    selectField: 'rightsEndDate',
                    label: 'Contract rights end date',
                    width: 105,
                    sortable: false,
                    category: EDITION_CATEGORY,
                    cellType: DateCell,
                },
            },
        },
        {
            id: 'publish',
            label: 'Publication',
            Component: PublishingListSearchView,
            requiredSelectFields: [
                ...defaultSelectFields,
                'members',
                'activePrint',
                'reviewDate',
                'isbn',
                'pages',
                'coverImageAssetId',
            ],
            componentConfig: {
                HeaderComponent: PublishingListViewHeader,
                ItemComponent: PublishingListViewItem,
            },
        },
        {
            id: 'salespitchretailer',
            label: 'Sales sheet - retailer',
            Component: BasicSearchView,
            requiredSelectFields: [
                ...defaultSelectFields,
                'activePrint',
                'audioPlaytimeHours',
                'audioPlaytimeMinutes',
                'bindingCode',
                'bindingCodeType',
                'brand',
                'brandName',
                'coverImageAssetId',
                'estimatedNumberOfPages',
                'interestAgeName',
                'isbn',
                'numberInSeries',
                'originalTitle',
                'pages',
                'publishingHouse',
                'resellerPrice',
                'reviewDate',
                'seasonName',
                'seriesName',
                'subtitle',
                'themas',
                'members',
                `textFullByTypes_${texts.authorPortrait}`,
                `textFullByTypes_${texts.headline}`,
                `textFullByTypes_${texts.illustratorPortrait}`,
                `textFullByTypes_${texts.internalSalesPitch}`,
                `textFullByTypes_${texts.internalSalesPitchFlyer}`,
                `textFullByTypes_${texts.descriptionCore}`,
                'textFullByTypes_1', // isNfg
            ],
            componentConfig: {
                ItemComponent: SalesPitchViewItem,
            },
        },
        {
            id: 'salespitchmedia',
            label: 'Sales sheet - media',
            Component: BasicSearchView,
            requiredSelectFields: [
                ...defaultSelectFields,
                'activePrint',
                'audioPlaytimeHours',
                'audioPlaytimeMinutes',
                'bindingCode',
                'bindingCodeType',
                'brand',
                'brandName',
                'coverImageAssetId',
                'estimatedNumberOfPages',
                'interestAgeName',
                'isbn',
                'numberInSeries',
                'originalTitle',
                'pages',
                'publishingHouse',
                'resellerPrice',
                'reviewDate',
                'seasonName',
                'seriesName',
                'subtitle',
                'themas',
                'members',
                `textFullByTypes_${texts.authorPortrait}`,
                `textFullByTypes_${texts.headline}`,
                `textFullByTypes_${texts.illustratorPortrait}`,
                `textFullByTypes_${texts.descriptionCore}`,
                'textFullByTypes_1', // isNfg
            ],
            componentConfig: {
                ItemComponent: MediaViewItem,
            },
        },
        {
            id: 'launch',
            label: 'Launch',
            Component: BasicSearchView,
            componentConfig: {
                HeaderComponent: LaunchViewHeader,
                ItemComponent: LaunchViewItem,
            },
        },
    ],
}
