const messages = {
    CONTACT_NOT_FOUND: 'Contact not found.',
    CONTACT_FORBIDDEN: 'You do not have the access rights required to create/edit this contact.',
    CONTACT_MISSING_PUBLISHING_HOUSE_GROUP:
        'A contact needs to be connected to a publishing house group.',
    CONTACT_DELETE_STILL_CONNECTED_TO_WORK:
        'The contact cannot be deleted because it is still connected to one or more works.',
    CONTACT_DELETE_STILL_CONNECTED_TO_ASSET:
        'The contact cannot be deleted because it is still connected to one or more assets.',
    CONTACT_DELETE_STILL_CONNECTED_TO_PRODUCTION:
        'The contact cannot be deleted because it is still connected to one or more editions.',
    CONTACT_DELETE_STILL_CONNECTED_TO_CONCEPT:
        'The contact cannot be deleted because it is still connected to one or more concepts.',
    CONTACT_DELETE_STILL_CONNECTED_TO_LISTS:
        'The contact cannot be deleted because it is still connected to one or more select lists.',
    USER_NOT_FOUND: 'The user was not found.',
    ASSET_NOT_FOUND: 'The asset was not found.',
    ASSET_FORBIDDEN: 'You do not have access rights to this asset.',
    ASSET_MISSING_DATA: 'Demanded information about the access is missing.',
    ASSET_MISSING_CONTRACT_TYPE: 'Type of contract is missing for the asset.',
    ASSET_MISSING_CHANGED_FILES: 'Something went wrong while saving the chosen file.',
    ASSET_FILE_NOT_FOUND: 'The file connected to the asset was not found.',
    ASSET_FILE_TYPE_NOT_ALLOWED: 'The file type is not allowed for this asset type.',
    ASSET_IMAGE_HEIGHT_TO_LOW: 'The height of the image is too small for the access type.',
    ASSET_IMAGE_WIDTH_TO_LOW: 'The width of the image is too small for the access type.',
    UNABLE_TO_DETERMINE_FILETYPE: 'Could not determine file type. Is the file extension correct?',
}
export default messages
