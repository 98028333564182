import FormFieldHistory from './FormFieldHistory'
import FormFieldInfoReadOnly from './FormFieldInfoReadOnly'
import FormFieldInfoSharedTo from './FormFieldInfoSharedTo'
import FormFieldInfoText from './FormFieldInfoText'

type Props = {
    fieldInfo: Record<string, unknown>
    propertyRules: any
    infoText?: string
    history?: string
    clickedInfoButton?: boolean
    showInfoTextDialog?: boolean
}

const FormFieldInfo = (
    props: Props = {
        fieldInfo: {},
        propertyRules: {},
        clickedInfoButton: false,
    },
) => {
    const { fieldInfo, propertyRules, infoText, history, clickedInfoButton } = props

    if (!fieldInfo.showInfoButton) return null
    return clickedInfoButton && fieldInfo.showInfoButton ? (
        <>
            {fieldInfo.isReadOnly ? (
                <FormFieldInfoReadOnly readOnlyReasons={propertyRules.isReadOnly} />
            ) : null}
            {fieldInfo.isSharedTo ? <FormFieldInfoSharedTo /> : null}
            {fieldInfo.hasInfoText ? (
                <FormFieldInfoText
                    {...{
                        infoText,
                    }}
                />
            ) : null}
            {fieldInfo.hasHistory ? (
                <FormFieldHistory
                    {...{
                        history,
                    }}
                />
            ) : null}
        </>
    ) : null
}

export default FormFieldInfo
