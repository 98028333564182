import { services } from 'Common/environment'
import createErrorMessages from 'Common/messages/createErrorMessages'
import request from 'Common/utils/net/request'

import messages from '../messages'

export const GET_PRODUCTION_ASSETS = 'GET_PRODUCTION_ASSETS'
export const GET_PRODUCTION_ASSETS_REQUEST = 'GET_PRODUCTION_ASSETS_REQUEST'
export const GET_PRODUCTION_ASSETS_SUCCESS = 'GET_PRODUCTION_ASSETS_SUCCESS'
export const GET_PRODUCTION_ASSETS_FAILURE = 'GET_PRODUCTION_ASSETS_FAILURE'
export const CONNECT_ASSET_PRODUCTION = 'CONNECT_ASSET_PRODUCTION'
export const CONNECT_ASSET_PRODUCTION_REQUEST = 'CONNECT_ASSET_PRODUCTION_REQUEST'
export const CONNECT_ASSET_PRODUCTION_SUCCESS = 'CONNECT_ASSET_PRODUCTION_SUCCESS'
export const CONNECT_ASSET_PRODUCTION_FAILURE = 'CONNECT_ASSET_PRODUCTION_FAILURE'
export const DISCONNECT_ASSET_PRODUCTION = 'DISCONNECT_ASSET_PRODUCTION'
export const DISCONNECT_ASSET_PRODUCTION_REQUEST = 'DISCONNECT_ASSET_PRODUCTION_REQUEST'
export const DISCONNECT_ASSET_PRODUCTION_SUCCESS = 'DISCONNECT_ASSET_PRODUCTION_SUCCESS'
export const DISCONNECT_ASSET_PRODUCTION_FAILURE = 'DISCONNECT_ASSET_PRODUCTION_FAILURE'
const GET_PRODUCTION_ASSETS_ERROR_MESSAGES = createErrorMessages(
    ['PRODUCTION_NOT_FOUND', 'PRODUCTION_FORBIDDEN'],
    messages,
)
export const getProductionAssets = (workId, productionId) => (dispatch) =>
    dispatch({
        type: GET_PRODUCTION_ASSETS,
        payload: {
            data: {
                workId,
                productionId,
            },
            promise: request({
                method: 'GET',
                url: services.work(`/v1/works/${workId}/productions/${productionId}/assets`),
                errorMessages: GET_PRODUCTION_ASSETS_ERROR_MESSAGES,
            }),
        },
    })
const CONNECT_ASSET_PRODUCTION_ERROR_MESSAGES = createErrorMessages(
    [
        'PRODUCTION_NOT_FOUND',
        'PRODUCTION_FORBIDDEN',
        'PRODUCTION_MUST_HAVE_ISBN_TO_ADD_ASSET_TYPE',
        'PRODUCTION_ALREADY_HAS_ASSET_OF_TYPE',
        'ASSET_NOT_FOUND',
        'ASSET_ALREADY_EXISTS',
    ],
    messages,
)
export const connectAssetToProduction = (production, asset) => (dispatch) => {
    const { workId, productionId } = production
    return dispatch({
        type: CONNECT_ASSET_PRODUCTION,
        meta: {
            productionId,
            asset,
        },
        payload: {
            data: productionId,
            promise: request({
                url: services.work(`/v1/works/${workId}/productions/${productionId}/assets`),
                method: 'POST',
                body: asset,
                errorMessages: CONNECT_ASSET_PRODUCTION_ERROR_MESSAGES,
            }),
        },
    })
}
const DISCONNECT_ASSET_PRODUCTION_ERROR_MESSAGES = createErrorMessages(
    ['PRODUCTION_NOT_FOUND', 'PRODUCTION_FORBIDDEN', 'ASSET_NOT_FOUND'],
    messages,
)
export const disconnectAssetFromProduction = (production, asset) => (dispatch) => {
    const { workId, productionId } = production
    const assetId = asset.id
    return dispatch({
        type: DISCONNECT_ASSET_PRODUCTION,
        meta: {
            productionId,
            asset: {
                id: 0,
            },
        },
        payload: {
            data: productionId,
            promise: request({
                url: services.work(
                    `/v1/works/${workId}/productions/${productionId}/assets/${assetId}`,
                ),
                method: 'DELETE',
                errorMessages: DISCONNECT_ASSET_PRODUCTION_ERROR_MESSAGES,
            }),
        },
    })
}
