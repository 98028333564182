import { requestStatus } from 'Common/utils/net/statuses'

import { GET_PRODUCTION_REQUEST } from '../actions/productionActions'
import {
    DELETE_PRODUCTION_BOOK_CLUB_FAILURE,
    DELETE_PRODUCTION_BOOK_CLUB_REQUEST,
    DELETE_PRODUCTION_BOOK_CLUB_SUCCESS,
    GET_PRODUCTION_BOOK_CLUBS_FAILURE,
    GET_PRODUCTION_BOOK_CLUBS_REQUEST,
    GET_PRODUCTION_BOOK_CLUBS_SUCCESS,
    PATCH_PRODUCTION_BOOK_CLUB_FAILURE,
    PATCH_PRODUCTION_BOOK_CLUB_REQUEST,
    PATCH_PRODUCTION_BOOK_CLUB_SUCCESS,
    POST_PRODUCTION_BOOK_CLUB_FAILURE,
    POST_PRODUCTION_BOOK_CLUB_REQUEST,
    POST_PRODUCTION_BOOK_CLUB_SUCCESS,
} from '../actions/productionBookClubActions'

const initialState = {
    status: {
        getStatus: undefined,
        getError: undefined,
    },
    data: undefined,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCTION_REQUEST:
            return { ...initialState }

        case GET_PRODUCTION_BOOK_CLUBS_REQUEST:
            return {
                ...state,
                workId: action.meta.workId,
                productionId: action.meta.productionId,
                data: null,
                status: { ...state.status, getStatus: requestStatus.request, getError: null },
            }

        case GET_PRODUCTION_BOOK_CLUBS_SUCCESS:
            return {
                ...state,
                data: action.payload.json,
                status: { ...state.status, getStatus: requestStatus.success },
            }

        case GET_PRODUCTION_BOOK_CLUBS_FAILURE:
            return {
                ...state,
                status: {
                    ...state.status,
                    getStatus: requestStatus.failure,
                    getError: action.payload,
                },
            }

        case POST_PRODUCTION_BOOK_CLUB_REQUEST:
            return { ...state, status: { ...state.status, postStatus: requestStatus.request } }

        case POST_PRODUCTION_BOOK_CLUB_SUCCESS:
            return {
                ...state,
                data: action.payload.json,
                status: { ...state.status, postStatus: requestStatus.success },
            }

        case POST_PRODUCTION_BOOK_CLUB_FAILURE:
            return {
                ...state,
                status: {
                    ...state.status,
                    postStatus: requestStatus.failure,
                    postError: action.payload,
                },
            }

        case PATCH_PRODUCTION_BOOK_CLUB_REQUEST:
            return { ...state, status: { ...state.status, patchStatus: requestStatus.request } }

        case PATCH_PRODUCTION_BOOK_CLUB_SUCCESS:
            return { ...state, status: { ...state.status, patchStatus: requestStatus.success } }

        case PATCH_PRODUCTION_BOOK_CLUB_FAILURE:
            return {
                ...state,
                status: {
                    ...state.status,
                    patchStatus: requestStatus.failure,
                    patchError: action.payload,
                },
            }

        case DELETE_PRODUCTION_BOOK_CLUB_REQUEST:
            return { ...state, status: { ...state.status, deleteStatus: requestStatus.request } }

        case DELETE_PRODUCTION_BOOK_CLUB_SUCCESS:
            return { ...state, status: { ...state.status, deleteStatus: requestStatus.success } }

        case DELETE_PRODUCTION_BOOK_CLUB_FAILURE:
            return {
                ...state,
                status: {
                    ...state.status,
                    deleteStatus: requestStatus.failure,
                    deleteError: action.payload,
                },
            }

        default:
            return state
    }
}
