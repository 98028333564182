import { isObject as _isObject, getOr, isEmpty, isNil, isString } from 'lodash/fp'

import messages from '../messages'

/* eslint no-useless-escape: "off" */
const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
const phoneRegex = /0([-\s]?\d){6,10}/i
const integerRegex = /^[\+\-]?\d+$/i
const decimalRegex = /^[0-9,.]+$/i
const positiveNumberOrZero = /^[+]?([.,]\d+|\d+[.,]?\d*)$/i
const dateRegex = /^(\d{4})-(0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-9]|3[0-1])$/i
const webSiteUrlRegex =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i
const finnishSocialSecurityNumberRegex =
    /^([\d]{2}|NN)([\d]{2}|NN)([\d]{2})([-|+|A])([A-Z|\d]{4})$/i

const valueIsEmpty = (value: Value) => isNil(value) || (isString(value) && value.trim() === '')

type Value = any

type Message = string

export const isObject = (message?: Message) => (value: Value) => {
    const validatorErrorMessage = message ? message : messages.validation.needsToBeAnObject
    return valueIsEmpty(value) || _isObject(value) ? null : validatorErrorMessage
}
export const isFinnishSocialSecurityNumber = (message?: Message) => (value: Value) => {
    const validatorErrorMessage = message
        ? message
        : messages.validation.needsToBeAFinnishSocialSecurityNumber
    return valueIsEmpty(value) || finnishSocialSecurityNumberRegex.test(value)
        ? null
        : validatorErrorMessage
}
export const isPhoneNumber = (message?: Message) => (value: Value) => {
    const validatorErrorMessage = message ? message : messages.validation.needsToBeAPhoneNumber
    return valueIsEmpty(value) || phoneRegex.test(value) ? null : validatorErrorMessage
}
export const isEmailAddress = (message?: Message) => (value: Value) => {
    const validatorErrorMessage = message ? message : messages.validation.needsToBeAnEmail
    return valueIsEmpty(value) || emailRegex.test(value) ? null : validatorErrorMessage
}
export const notEmpty = (message?: Message) => (value: Value) => {
    const validatorErrorMessage = message ? message : messages.validation.needsToBeEntered
    return valueIsEmpty(value) ? validatorErrorMessage : null
}
export const isTrue = (message?: Message) => (value: Value) => {
    const validatorErrorMessage = message ? message : messages.validation.needsToBeTrue
    return value === true ? null : validatorErrorMessage
}
export const isInteger = (message?: Message) => (value: Value) => {
    const validatorErrorMessage = message ? message : messages.validation.needsToBeInteger
    return valueIsEmpty(value) || integerRegex.test(value) ? null : validatorErrorMessage
}
export const isIntegerOrDecimal = (message?: Message) => (value: Value) => {
    const validatorErrorMessage = message ? message : messages.validation.needsToBeIntegerOrDecimal
    return valueIsEmpty(value) || decimalRegex.test(value) ? null : validatorErrorMessage
}
export const isInRange = (message, min, max) => (value: Value) => {
    if (valueIsEmpty(value)) {
        return null
    }

    const validatorErrorMessage = message ? message : messages.validation.needsToBeInRange
    const num = typeof value === 'number' ? value : parseInt(value, 10)
    return num >= min && num <= max ? null : validatorErrorMessage
}
export const isExactLength = (message, length) => (value: Value) => {
    const validatorErrorMessage = message ? message : messages.validation.needsToBeASpecificLength
    return !valueIsEmpty(value) && value.length !== length ? validatorErrorMessage : null
}
export const hasMinLength = (message, minLength) => (value: Value) => {
    const validatorErrorMessage = message
        ? message
        : messages.validation.needsToBeAtLeastASpecificLength
    return !valueIsEmpty(value) && value.length < minLength ? validatorErrorMessage : null
}
export const hasMaxLength = (message, maxLength) => (value: Value) => {
    const validatorErrorMessage = message
        ? message
        : messages.validation.needsToBeLesserThenASpecificLength
    return !valueIsEmpty(value) && value.length > maxLength ? validatorErrorMessage : null
}
export const isDate = (message?: Message) => (value: Value) => {
    const validatorErrorMessage = message ? message : messages.validation.needsToBeDate
    return valueIsEmpty(value) || dateRegex.test(value) ? null : validatorErrorMessage
}
export const isPositiveNumber = (message?: Message) => (value: Value) => {
    const validatorErrorMessage = message ? message : messages.validation.needsToBePositiveNumber
    return valueIsEmpty(value) || positiveNumberOrZero.test(value) ? null : validatorErrorMessage
}
export const isWebsiteUrl = (message?: Message) => (value: Value) => {
    const validatorErrorMessage = message ? message : messages.validation.needsToBeAnUrl
    return valueIsEmpty(value) || webSiteUrlRegex.test(value) ? null : validatorErrorMessage
}
// FIXME does this need to be changed? Shouldn't it check valueIsEmpty also?
export const isEqual = (message, otherValue) => (value: Value) => {
    const validatorErrorMessage = message ? message : messages.validation.needsToBeEqual
    return value !== otherValue ? validatorErrorMessage : null
}
export const runValidators = (currentRule, value) =>
    currentRule
        .map((validator) => validator(value))
        .filter((_) => _)
        .reduce((acc, cur) => acc || cur, null)

const isArrayWithEmptyObjects = (value) =>
    Array.isArray(value) && value.filter((_) => !isEmpty(_)).length === 0 ? true : false

const isArrayOfUndefined = (value: Value) =>
    Array.isArray(value) && value.filter((r) => !!r).length === 0

const validationBase =
    (rules) =>
    (values = {}) =>
        Object.keys(rules)
            .filter((_) => _)
            .map((key) => {
                const currentRule = getOr(null, key, rules)
                if (!currentRule || isArrayOfUndefined(currentRule)) return null
                const fieldValue = getOr(null, key, values)
                const value = Array.isArray(fieldValue)
                    ? fieldValue.map((testValue) => validationBase(currentRule)(testValue))
                    : Array.isArray(currentRule)
                      ? runValidators(currentRule, fieldValue)
                      : validationBase(currentRule)(fieldValue)
                return {
                    key,
                    value,
                }
            })
            .filter((_) => _)
            .filter(({ value }) => !isArrayWithEmptyObjects(value))
            .reduce(
                (acc, cur) =>
                    !cur || acc[cur.key] || isEmpty(cur.value)
                        ? acc
                        : { ...acc, [cur.key]: cur.value },
                {},
            )

const validation = (rules, values) => validationBase(rules)(values)

export const quickValidation = validationBase
export default validation
