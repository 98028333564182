import React from 'react'
import { WrappedFieldArrayProps } from 'redux-form'

import { Chip, FormControl, InputAdornment, MenuItem } from '@mui/material'
import { Checkbox, Input, ListItemText, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { styled } from '@mui/material/styles'

import MetaDataTypeAdminDialog from '../../Admin/components/metadata/type/MetaDataTypeAdminDialog'
import { FormFieldInfo, FormFieldInfoToggler } from './fieldinfo'
import { getErrorText, getFieldInfo, getHasError } from './formFieldHelpers'

const PREFIX = 'FormChipSelectionArray'

const classes = {
    chips: `${PREFIX}-chips`,
    chip: `${PREFIX}-chip`,
    selectIcon: `${PREFIX}-selectIcon`,
    selectRoot: `${PREFIX}-selectRoot`,
}

const StyledGrid = styled(Grid)(({ theme }) => ({
    [`& .${classes.chips}`]: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    [`& .${classes.chip}`]: {
        margin: 2,
    },

    [`& .${classes.selectIcon}`]: {
        left: 0,
    },

    [`& .${classes.selectRoot}`]: {
        paddingLeft: theme.spacing(3),
    },
}))

const ITEM_HEIGHT = 36
const ITEM_PADDING_TOP = 6
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

type Props = {
    metaData: any
    label: string
    meta?: any
    required?: boolean
    disabled?: boolean
    showMetaDataAdmin?: boolean
    metaDataType?: string
    chipSecondaryColor?: boolean
    infoText?: string
    propertyRules?: any
    style?: any
    SelectionComponent?(..._args: unknown[]): unknown
    id?: string
    dispatch?: any
}

export class Component extends React.Component<Props & WrappedFieldArrayProps> {
    static defaultProps = {
        required: false,
        disabled: false,
        propertyRules: {},
    }

    state = {
        dataSource: [],
        clickedInfoButton: false,
        showInfoTextDialog: false,
    }

    toggleSelection = (event) => {
        const { fields, metaData } = this.props
        const selectedItem = event.target.value
        const fieldValues = fields.getAll() || []
        const foundObjectInMetaData = metaData.find((m) => m.id === selectedItem.id)
        const foundObjectInFields = fieldValues.find((m) => m.id === selectedItem.id)

        if (foundObjectInMetaData) {
            if (!foundObjectInFields) {
                fields.push(foundObjectInMetaData)
            } else {
                fieldValues.forEach((field, index) => {
                    if (field.id === selectedItem.id) {
                        fields.remove(index)
                    }
                })
            }
        } else {
            if (foundObjectInFields) {
                fields.push({
                    id: selectedItem.id,
                })
            } else {
                fieldValues.forEach((field, index) => {
                    if (field.id === selectedItem.id) {
                        fields.remove(index)
                    }
                })
            }
        }
    }

    clickIcon = () => {
        this.setState({
            clickedInfoButton: !this.state.clickedInfoButton,
        })
    }

    render() {
        const {
            fields,
            meta,
            metaData,
            infoText,
            propertyRules,
            SelectionComponent,
            chipSecondaryColor,
            id,
            label,
            showMetaDataAdmin,
            metaDataType,
            dispatch,
            ...otherProps
        } = this.props
        const { required, style, disabled } = this.props
        const { name } = fields
        const fieldInfo = getFieldInfo(propertyRules, infoText)
        const isDisabled = disabled || fieldInfo.isReadOnly
        const { showInfoTextDialog, clickedInfoButton } = this.state
        const { error, touched } = meta
        const fieldValues = fields.getAll() || []
        const notSelectedMetaData = metaData.map(({ id, name }) => ({
            id,
            name,
        }))
        const isSelectFieldDisabled = isDisabled || notSelectedMetaData.length === 0
        const containerStyle = {
            float: 'left',
            width: '100%',
        }
        const nestedProps = {
            ...otherProps,
            required,
            label: label,
            helperText: getErrorText(touched, error),
            error: getHasError(touched, error),
            style: { ...style },
            disabled: isSelectFieldDisabled,
            multiple: true,
            value: fieldValues.map(({ id, name }) => ({
                id,
                name,
            })),
            onChange: this.toggleSelection,
            input: <Input />,
            SelectProps: {
                classes: {
                    select: classes.selectRoot,
                    icon: classes.selectIcon,
                },
                renderValue: (selected) => {
                    return (
                        <div className={classes.chips}>
                            {selected.map((value) => (
                                <Chip
                                    color="primary"
                                    variant="outlined"
                                    key={value.id}
                                    label={value.name}
                                    className={classes.chip}
                                />
                            ))}
                        </div>
                    )
                },
                MenuProps: MenuProps,
            },
            InputProps: {
                endAdornment: (
                    <InputAdornment position="end">
                        <FormFieldInfoToggler
                            clickIcon={this.clickIcon}
                            showInfoButton={fieldInfo.showInfoButton}
                        />
                        <MetaDataTypeAdminDialog
                            {...{
                                metaDataType,
                                showMetaDataAdmin,
                            }}
                        />
                    </InputAdornment>
                ),
            },
            InputLabelProps: {
                shrink: true,
            },
        }
        return (
            <StyledGrid xs={12} sx={{ paddingTop: 0 }}>
                <FormControl variant="standard" fullWidth>
                    <TextField
                        variant="standard"
                        fullWidth
                        select
                        id={
                            id ||
                            `FormChipSelectionArray-${name}-${label}`.replace(/[^A-Za-z0-9-]/gi, '')
                        }
                        {...nestedProps}
                    >
                        <MenuItem disabled key="empty-value" value=""></MenuItem>
                        {metaData
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((m) => (
                                <MenuItem key={m.id} value={m}>
                                    <Checkbox
                                        color="primary"
                                        checked={fieldValues.map((fv) => fv.id).indexOf(m.id) > -1}
                                    />
                                    <ListItemText primary={m.name} />
                                </MenuItem>
                            ))}
                    </TextField>
                </FormControl>

                <FormFieldInfo
                    {...{
                        fieldInfo,
                        propertyRules,
                        infoText,
                        containerStyle,
                        showInfoTextDialog,
                        clickedInfoButton,
                    }}
                />
            </StyledGrid>
        )
    }
}
export default Component
