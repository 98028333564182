const messages = {
    WORK_NOT_FOUND: 'The work was not found.',
    WORK_FORBIDDEN: 'You do not have the required access rights to edit this work.',
    WORK_FORBIDDEN_CONNECT_TO_ERP: 'You do not have ERP-rights for this work.',
    WORK_MISSING_MAIN_EDITION: 'The work has no main edition. Set main edition and try again.',
    WORK_MISSING_PUBLISHING_DATE:
        'The work is missing publication date. Set publication date in the main edition and try again.',
    WORK_MISSING_COST_CENTER: 'The work is missing a cost center',
    WORK_INVALID_ASSET_TYPE: 'This access type cannot be connected to work',
    WORK_ALREADY_CONNECTED_TO_ERP: 'The work is already connected to the ERP system',
    WORK_MAIN_EDITION_CANNOT_BE_CANCELLED: 'You cannot select a cancelled edition as main edition.',
    WORK_EXTERNAL_PRIMARY_EDITION_CANNOT_BE_CANCELLED:
        'You cannot select a cancelled edition for web info.',
    PRODUCTION_NOT_FOUND: 'The edition was not found.',
    PRODUCTION_FORBIDDEN: 'You do not have the required access rights to edit the edition.',
    PRODUCTION_FORBIDDEN_NEW_PRINT:
        'You do not have the required access rights to create new prints.',
    PRODUCTION_FORBIDDEN_ISBN: 'You do not have the required access rights to assign ISBN.',
    PRODUCTION_FORBIDDEN_CONNECT_TO_ERP: 'You do not have ERP-rights for this edition.',
    PRODUCTION_ALREADY_CONNECTED_TO_ERP: 'The edition is already conncted to ERP.',
    PRODUCTION_ALREADY_HAS_ISBN: 'The edition already has an ISBN.',
    PRODUCTION_BAD_ERP_STATUS_CODE: 'The statistics code is invalid.',
    PRODUCTION_MISSING_WORK: 'The edition is not connected to a work.',
    PRODUCTION_MISSING_REVIEW_DATE: 'The edition is missing a review date.',
    PRODUCTION_MISSING_THEMA: 'The edition is missing a thema code.',
    PRODUCTION_MISSING_RESELLER_PRICE: 'The edition is missing a retailer price.',
    PRODUCTION_MISSING_ISBN: 'The edition is missing ISBN.',
    PRODUCTION_MISSING_ISBN_ID: 'The edition is missin ISBN-ID.',
    PRODUCTION_MISSING_BRAND: 'The edition is missing a brand.',
    PRODUCTION_MISSING_PUBLISHING_DATE: 'The edition is missing a publication date.',
    PRODUCTION_MISSING_WRITE_OFF_RULE: 'The edition is missing a depreciation rule.',
    PRODUCTION_MISSING_BINDING_CODE: 'The edition is missing a binding code.',
    PRODUCTION_MISSING_TAX_CODE: 'The edition is missing VAT',
    PRODUCTION_MISSING_ARTICLE_GROUP: 'The edition is missing an product group.',
    PRODUCTION_MISSING_PUBLISHING_DATE_OR_REVIEW_DATE: 'The date must be specified.',
    PRODUCTION_ALREADY_CANCELLED: 'The edition is already cancelled.',
    PRODUCTION_INVALID_BRAND: 'The edition has an invalid brand.',
    PRODUCTION_INVALID_TEXT_TYPE: 'Invalid text type.',
    PRODUCTION_NOT_CONNECTED_TO_ERP: 'The edition is not connected to the ERP system.',
    PRODUCTION_NOT_CANCELLED: 'The edition is not cancelled.',
    PRODUCTION_CANNOT_PUBLISH_ALREADY_PUBLISHED_PRODUCTION: 'The edition is already public.',
    PRODUCTION_CANNOT_UNPUBLISH_NOT_PUBLISHED_PRODUCTION: 'The edition is not public.',
    PRODUCTION_FORBIDDEN_PUBLISH: 'You do not have the required access rights to make this public.',
    PRODUCTION_BINDINGCODE_NOT_MATCHING_TECHNICALPRODUCTIONTYPE:
        'The technical production type of the edition is not valid for the binding code.',
    PRODUCTION_DISCOUNT_NUMBER_NOT_FOUND: 'Discount number was not found.',
    PRODUCTION_ARTICLEGROUP_COSTCENTER_MISMATCH:
        'The product group of the edition is not valid for the cost center.',
    PRODUCTION_BR_CATEGORY_SUBCATEGORY_MISMATCH:
        'The Agency rights subgroup of the edtion is not valid for the category.',
    PRODUCTION_COULD_NOT_FIND_NEW_IFCD: 'Out of IFCD numbers. Contact support to add more.',
    PRODUCTION_PUBLISHING_DATE_MUST_BE_WORKDAY: 'Publication date must be a workday.',
    PRODUCTION_MUST_HAVE_ISBN_TO_ADD_ASSET_TYPE:
        'The edition must have an ISBN before this asset type can be connected.',
    PRODUCTION_ALREADY_HAS_ASSET_OF_TYPE: 'The edition already has a connected asset of this type.',
    PRODUCTION_CANNOT_UPDATE_DATES_MULTIPLE_PRINTS:
        'The date cannot be changed since there exists multiple prints.',
    PRODUCTION_CANNOT_UPDATE_PUBLISHING_DATE_AND_REVIEW_DATE_SIMULTANEOUSLY:
        'Multiple dates cannot be changed at the same time.',
    PRODUCTION_CANNOT_UPDATE_PUBLISHING_DATE_ON_SHAREDINFO_COPY:
        'The publication date cannot be changed on an edition that recieves information from another edition.',
    PRODUCTION_CANNOT_FIND_CULTUREINFO:
        'The chosen country settings could not be found. Contact support.',
    PRODUCTION_SHARED_INFO_COPY_IS_ALREADY_MASTER:
        'The edition chosen to recieve information already shares information to another edition',
    PRODUCTION_SHARED_INFO_MASTER_IS_ALREADY_COPY:
        'The edition chosen to share information already recieves information from another edition.',
    PRODUCTION_CANNOT_CHANGE_TO_SAME_BINDINGCODE:
        'You cannot switch to the same binding code as the edition already has.',
    PRODUCTION_CANNOT_CHANGE_TO_BINDING_CODE_OF_DIFFERENT_TYPE:
        'You cannot switch to a binding code that is included in another group than the current, that is no switch from e.g. a printed to a digital.',
    CANT_COPY_FROM_SAME_PRODUCTION:
        'You cannot copy a technical production from the same edition that you are copying to.',
    FROM_PRODUCTION_MISSING_TECHNICAL_PRODUCTION_TYPE:
        'The edition you are trying to copy is missing a technical production type. Fix this before copying or copy from another edition.',
    INSIDE_FORMAT_MISMATCH:
        'The edition you are trying to copy is missing a format/standard format. This is already set on the one you are trying to copy to. This case is not supported yet, but will be fixed asap.',
    INVALID_SERIES_FOR_SELECTED_PUBLISHINGHOUSE:
        'The series is not available for the chosen publishing house. Contact support to add the series or create a concept from another work.',
    MEMBERS_CREATOR_MUST_BE_ADDED_TO_WORK:
        'The selected role cannot be added to an edition but must be added to the work.',
    ASSET_NOT_FOUND: 'The asset was not found.',
    ASSET_ALREADY_EXISTS: 'The asset already exists.',
    MEMBERS_NOT_FOUND: 'The contact was not found.',
    MEMBERS_CONTACT_NOT_FOUND: 'The contact was not found.',
    MEMBERS_CONTACT_ROLE_NOT_FOUND: 'The selected role was not found.',
    ACTIVITY_NOT_FOUND: 'The activity was not found.',
    ACTIVITY_FORBIDDEN: 'You do not have the required access rights to change this activity.',
    ACTIVITY_TEMPLATE_PACKAGE_NOT_FOUND: 'The activity package was not found.',
    ACTIVITY_CANNOT_PATCH_OLD_ACTIVITY:
        'Older activity types cannot be edited. They only exist for history.',
    ACTIVITY_MISSING_NAME: 'You must enter a name to create an activity.',
    ACTIVITY_CANNOT_CREATE_MISSING_WORK: 'You must enter a work to create an activity.',
    ACTIVITY_CANNOT_DELETE_LAST_WORK: 'An activity must be connected to at least one work.',
    PRINT_NOT_FOUND: 'The print was not found.',
    PUBLISHINGHOUSE_NOT_FOUND: 'The publishing house was not found.',
    DELIVERYTYPE_IS_MISSING_DELIVERY_ADDRESS: 'The order type is missing a delivery address.',
    DELIVERYSPECIFICATION_IS_MISSING_DELIVERYITEMS: 'The order is missing delivery items.',
    DELIVERYSPECIFICATION_HAS_INVALID_TYPE: 'The order has an invalid type.',
    DELIVERYITEM_HAS_INVALID_CATEGORY:
        'The order has an invalid category on the delivery item type.',
    DELIVERYITEM_HAS_FORBIDDEN_CATEGORY: 'The order has forbidden delivery item types.',
    DELIVERYITEM_MAX_ALLOWED_QUANTITY_EXCEEDED: 'Maximal number has been exceeded.',
    DELIVERYSPECIFICATION_PRINTER_IS_MISSING_SCHILLING_LEVNO:
        'The printer is missing supplier number in ERP.',
    DELIVERYSPECIFICATION_PRINTER_IS_NOT_PRODUCTION_MEMBER:
        'The printer is not a contact on the edition.',
    DELIVERYSPECIFICATION_ALREADY_EXISTS: 'An order of the same type already exists on the print.',
    DELIVERYSPECIFICATION_HAS_MULTIPLE_DELIVERYITEMS_WITH_SAME_CATEGORY:
        'Maximum number of delivery items has been exceeded.',
    DELIVERYSPECIFICATION_MISSING_DELIVERY_ADDRESS: 'Delivery address missing',
    DELIVERYSPECIFICATION_IS_MISSING_INTERNAL_CONTACT: 'The order is missing an internal client',
    DELIVERYSPECIFICATION_HAS_INVALID_INTERNAL_CONTACT:
        'The internal client of the order is not valid. ',
    DELIVERYITEM_HAS_INVALID_PRELIMINARY_COST_PRICE: 'The preliminary cost center is not valid.',
    DELIVERYITEM_BOOKED_COST_PRICE_IS_READONLY: 'Booked cost price is not editable.',
    DELIVERYITEM_ACTUAL_DELIVERYDATE_IS_READONLY: 'Actual delivery date is not editable.',
    DELIVERYITEM_HAS_INVALID_PLANNED_DELIVERYDATE: 'You have selected an invalid delivery date.',
    DELIVERYADDRESS_HAS_INVALID_POSTALCODE: 'The postal number is not valid.',
    DELIVERYITEM_HAS_INVALID_LEGAL_DEPOSIT:
        'Legal deposit has been selected for an invalid delivery item.',
    DELIVERYSPECIFICATION_IS_MISSING_DELIVERY_ADDRESS: 'The order is missing a delivery address.',
    DELIVERYITEM_NOT_FOUND: 'The delivery item could not be found.',
    DELIVERYSPECIFICATION_NOT_FOUND: 'No orders were found.',
    DELIVERYITEM_MISMATCH: 'A delivery item i faulty, try again or contact support.',
    DELIVERYSPECIFICATION_PRINTER_IS_READONLY: 'It is not possible to change the printer.',
    ERP_COVER_NOT_CONNECTED: 'The cover was not found in ERP.',
    PRINT_INVALID: 'Technical error, please contact support.',
    COSTPRICE_IS_READONLY_FOR_POSTED_DELIVERYSPECIFICATIONS:
        'The cost price cannot be changed when there exists orders with status Booked.',
    PRINT_HAS_INVALID_QUANTITY_ORDERED: 'The print run has an invalid value.',
    CANNOT_DELETE_SELECTED_PRINT: 'The print cannot be deleted.',
}
export default messages
