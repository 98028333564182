import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { debounce, find, getOr } from 'lodash/fp'
import { CSSProperties, Component } from 'react'
import Autosuggest from 'react-autosuggest'
import { ConnectedProps, connect } from 'react-redux'

import IconBusiness from '@mui/icons-material/Business'
import IconPerson from '@mui/icons-material/Person'
import { FormControl, ListItemIcon, ListItemText, MenuItem, Paper, TextField } from '@mui/material'

import { servicesLoaders } from 'Common/environment'
import { RootState } from 'Common/store/createStore'

import {
    clearSearch as clearSearchAction,
    getSearch as getSearchAction,
    updateSearchOptions as updateSearchOptionsAction,
} from '../../search/searchActions'
import { requestStatus } from '../../utils/net/statuses'
import Spinner from '../loader/Spinner'

const styles = {
    container: {
        position: 'relative',
    } as CSSProperties,
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1,
        marginTop: '8px',
        left: 0,
        right: 0,
    } as CSSProperties,
    suggestion: {
        display: 'block',
    } as CSSProperties,
    suggestionsList: {
        listStyleType: 'none',
        maxHeight: 300,
        overflowY: 'auto',
    } as CSSProperties,
}

const defaultSelectFields = ['id', 'firstName', 'lastName', 'isUser', 'isCompanyContact']

function renderInputComponent(inputProps) {
    const {
        classes,
        inputRef = () => {},
        ref,
        label,
        ...other
    }: {
        classes: any
        inputRef: (..._args: Array<any>) => any
        label: string
        ref: (..._args: Array<any>) => any
    } = inputProps
    return (
        <FormControl variant="standard" fullWidth>
            <TextField
                variant="standard"
                autoFocus
                fullWidth
                label={label}
                InputProps={{
                    inputRef: (node) => {
                        ref(node)
                        inputRef()
                    },
                }}
                {...other}
            />
        </FormControl>
    )
}

function renderSuggestionsContainer(options) {
    return (
        <Paper {...options.containerProps} square>
            {options.children}
        </Paper>
    )
}

const getSuggestionValue = (suggestion) => suggestion

function DisplayEmail({
    email,
    query,
}: {
    email: {
        value: string
    }
    query: string
}) {
    if (!email) return null
    const matchesEmail = match(email.value, query)
    const partsEmail = parse(email.value, matchesEmail)
    return (
        <>
            {partsEmail.map((partEmail, index) =>
                partEmail.highlight ? (
                    <span
                        key={String(index)}
                        style={{
                            fontWeight: 600,
                        }}
                    >
                        {partEmail.text}
                    </span>
                ) : (
                    <strong
                        key={String(index)}
                        style={{
                            fontWeight: 300,
                        }}
                    >
                        {partEmail.text}
                    </strong>
                ),
            )}
        </>
    )
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
    const { Icon, email, disabled } = suggestion
    const matchesName = match(suggestion.name, query)
    const partsName = parse(suggestion.name, matchesName)
    return (
        <MenuItem selected={isHighlighted} component="div" disabled={disabled}>
            <ListItemIcon>
                <Icon />
            </ListItemIcon>
            <ListItemText
                primary={partsName.map((part, index) =>
                    part.highlight ? (
                        <span
                            key={String(index)}
                            style={{
                                fontWeight: 500,
                            }}
                        >
                            {part.text}
                        </span>
                    ) : (
                        <strong
                            key={String(index)}
                            style={{
                                fontWeight: 300,
                            }}
                        >
                            {part.text}
                        </strong>
                    ),
                )}
                secondary={
                    <DisplayEmail
                        {...{
                            email,
                            query,
                        }}
                    />
                }
            />
        </MenuItem>
    )
}

type Props = {
    onChange: (..._args: Array<any>) => any
    searchId: string
    contactLabel?: string
    searchFilters?: any[]
    searchOptions?: any
    clearOnSelect?: boolean
}

export type State = {
    searchConfig: any
    activeQuery: any
    value: string | null | undefined
}

const mapState = (state: RootState, props: Props) => {
    const { searchId } = props
    const { search } = state
    return {
        contacts: search[searchId] || {},
    }
}

const mapDisp = {
    getSearch: getSearchAction,
    updateSearchOptions: updateSearchOptionsAction,
    clearSearch: clearSearchAction,
}

const connector = connect(mapState, mapDisp)

type PropsFromRedux = ConnectedProps<typeof connector>

class ContactPicker extends Component<Props & PropsFromRedux, State> {
    static defaultProps = {
        searchOptions: {},
        clearOnSelect: false,
    }

    debouncedGetSearch: (..._args: Array<any>) => any = () => {}

    constructor(props: Props & PropsFromRedux) {
        super(props)
        const { searchId, getSearch, searchFilters } = props
        this.debouncedGetSearch = debounce(300, getSearch)
        this.state = {
            activeQuery: null,
            searchConfig: {
                id: searchId,
                searchService: servicesLoaders.contact(`/v3/search/contacts`),
                searchServiceExport: null,
                defaultSelectFields,
                filters: searchFilters,
            },
            value: '',
        }
    }

    onUpdateInput = (event, change) => {
        const { clearSearch, searchOptions, updateSearchOptions, onChange } = this.props
        const { searchConfig } = this.state

        if (change.method === 'type') {
            const { newValue } = change
            this.setState(
                {
                    value: newValue,
                },
                () => {
                    if (newValue.length < 2) {
                        clearSearch(searchConfig)
                        onChange({})
                    } else {
                        updateSearchOptions(searchConfig, {
                            ...searchOptions,
                            query: newValue,
                            selectFields: searchConfig.defaultSelectFields,
                        })
                        this.debouncedGetSearch(searchConfig)
                        this.setState({
                            activeQuery: newValue,
                        })
                    }
                },
            )
        }

        if (change.method === 'click' || change.method === 'enter') {
            this.onNewRequest(change.newValue)
        }
    }

    onNewRequest = (value) => {
        if (value.disabled) {
            return
        }

        const { contacts, onChange } = this.props
        const selection = getOr(
            {},
            `[0].document`,
            contacts.results.filter((c) => c.document.id === value.value),
        )
        const { firstName, lastName, ...others } = selection
        const { isCompanyContact } = selection
        const contact = {
            ...others,
            [isCompanyContact ? 'company' : 'person']: {
                [isCompanyContact ? 'name1' : 'firstName']: firstName,
                [isCompanyContact ? 'name2' : 'lastName']: lastName,
            },
        }
        this.setState({
            value: value.name,
        })
        onChange(contact)
    }

    getSuggestions = () => {
        const { contacts } = this.props
        return [
            contacts.status === requestStatus.request && {
                name: 'Searching...',
                value: 'LOADING',
                Icon: Spinner,
                disabled: true,
            },
            ...getOr([], 'results', this.props.contacts).map((contactHolder) => {
                const contact = contactHolder.document
                const { isCompanyContact } = contact
                const emailAddresses = getOr([], 'emailAddresses', contact)
                const name =
                    `${getOr('', 'firstName', contact)} ${getOr('', 'lastName', contact)}`.trim()
                const email = find(({ type }) => type === 'workEmail', emailAddresses)
                return {
                    name: name,
                    email: email,
                    value: contact.id,
                    Icon: isCompanyContact ? IconBusiness : IconPerson,
                    disabled: false,
                }
            }),
        ].filter(Boolean)
    }

    render() {
        const { contactLabel } = this.props
        const { value } = this.state
        const inputProps = {
            label: 'Search contacts',
            autoFocus: true,
            value,
            onChange: this.onUpdateInput,
        }
        const autosuggestProps = {
            renderInputComponent,
            label: `Search for ${contactLabel ? contactLabel : 'contact'}`,
            suggestions: this.getSuggestions(),
            getSuggestionValue: getSuggestionValue,
            renderSuggestion: renderSuggestion,
            renderSuggestionsContainer: renderSuggestionsContainer,
            onSuggestionsClearRequested: () => {},
            onSuggestionsFetchRequested: () => {},
        }
        return (
            <Autosuggest
                {...autosuggestProps}
                inputProps={inputProps}
                theme={{
                    container: styles.container,
                    suggestionsContainerOpen: styles.suggestionsContainerOpen,
                    suggestionsList: styles.suggestionsList,
                    suggestion: styles.suggestion,
                }}
            />
        )
    }
}
export default connector(ContactPicker)
