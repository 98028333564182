import { requestStatus } from 'Common/utils/net/statuses'

import {
    GET_CALCULATION_GROUP_FAILURE,
    GET_CALCULATION_GROUP_REQUEST,
    GET_CALCULATION_GROUP_SUCCESS,
    GET_CONCEPT_PRE_CALCULATION_FAILURE,
    GET_CONCEPT_PRE_CALCULATION_REQUEST,
    GET_CONCEPT_PRE_CALCULATION_SUCCESS,
} from '../actions/conceptCalculationActions'

const initialState = {
    status: {},
}
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_CONCEPT_PRE_CALCULATION_REQUEST:
        case GET_CALCULATION_GROUP_REQUEST:
            return {
                ...state,
                data: null,
                status: { ...state.status, getStatus: requestStatus.request, getError: null },
            }

        case GET_CONCEPT_PRE_CALCULATION_SUCCESS:
        case GET_CALCULATION_GROUP_SUCCESS: {
            return {
                ...state,
                data: action.payload.json,
                status: { ...state.status, getStatus: requestStatus.success },
            }
        }

        case GET_CONCEPT_PRE_CALCULATION_FAILURE:
        case GET_CALCULATION_GROUP_FAILURE:
            return {
                ...state,
                status: {
                    ...state.status,
                    getStatus: requestStatus.failure,
                    getError: action.payload,
                },
            }

        default:
            return state
    }
}
