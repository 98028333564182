import { getOr, sortBy } from 'lodash/fp'

import IconDelete from '@mui/icons-material/Delete'
import IconReplay from '@mui/icons-material/Replay'
import IconSave from '@mui/icons-material/Save'
import {
    Divider,
    FormControl,
    IconButton,
    InputAdornment,
    MenuItem,
    TextField,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { RESET, UNSAVED_VIEW_NAME } from './AdvancedSearchView'

type Props = {
    activateSavedView(..._args: unknown[]): unknown
    toggleRemoveViewModal(..._args: unknown[]): unknown
    toggleSaveViewModal(..._args: unknown[]): unknown
    isInitial: boolean
    isUnchangedSavedView: boolean
    hideUnsavedView: boolean
    workingView?: Record<string, unknown>
    savedViewsId?: string
    savedViews?: any[]
    currentViewName?: string
}

function AdvancedViewToolbar(props: Props) {
    const {
        workingView,
        savedViewsId,
        savedViews,
        isInitial,
        isUnchangedSavedView,
        hideUnsavedView,
        currentViewName,
        activateSavedView,
        toggleRemoveViewModal,
        toggleSaveViewModal,
    } = props
    if (!savedViewsId) return null
    return (
        <Grid container spacing={2}>
            <Grid xs={12}>
                <FormControl variant="standard" fullWidth>
                    <TextField
                        variant="standard"
                        select
                        label="My views"
                        onChange={(event) => activateSavedView(event.target.value)}
                        value={currentViewName || ''}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton
                                        onClick={
                                            isUnchangedSavedView
                                                ? toggleRemoveViewModal
                                                : toggleSaveViewModal
                                        }
                                        title={isUnchangedSavedView ? 'Remove' : 'Save'}
                                        disabled={isInitial}
                                        size="large"
                                    >
                                        {isUnchangedSavedView ? (
                                            <IconDelete />
                                        ) : (
                                            <IconSave color={isInitial ? 'disabled' : 'primary'} />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    >
                        <MenuItem value={RESET}>
                            <IconReplay />
                            Clear
                        </MenuItem>
                        <Divider />
                        {!hideUnsavedView ? (
                            <MenuItem value={UNSAVED_VIEW_NAME} disabled>{`${getOr(
                                'Not named',
                                'name',
                                workingView,
                            )} (not saved)`}</MenuItem>
                        ) : null}
                        {sortBy((view) => view.name, savedViews).map((view) => (
                            <MenuItem key={view.name} value={view.name}>
                                {view.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default AdvancedViewToolbar
