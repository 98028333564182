import { Component } from 'react'

import Grid from '@mui/material/Unstable_Grid2'

import SortableSearchHeader from 'Common/search/view/SortableSearchHeader'

type Props = {
    searchOptions: Record<string, unknown>
    updateSorting(..._args: unknown[]): unknown
}

class LaunchViewHeader extends Component<Props> {
    render() {
        const { searchOptions, updateSorting } = this.props
        const { orderBy } = searchOptions
        return (
            <Grid xs={12}>
                <Grid container spacing={2} xs={12} sx={{ display: { xs: 'none', md: 'flex' } }}>
                    <SortableSearchHeader
                        size={4}
                        label="Title"
                        field="title"
                        {...{
                            orderBy,
                            updateSorting,
                        }}
                    />
                    <SortableSearchHeader
                        size={2}
                        label="Author"
                        field="authors"
                        {...{
                            orderBy,
                            updateSorting,
                        }}
                    />
                    <SortableSearchHeader
                        size={2}
                        label="Publication date"
                        field="publishingDate"
                        {...{
                            orderBy,
                            updateSorting,
                        }}
                    />
                    <Grid xs={2} sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
                        <strong>Launch brief</strong>
                    </Grid>
                    <Grid xs={2} sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
                        <strong>Launch</strong>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default LaunchViewHeader
