import { Component } from 'react'

import Grid from '@mui/material/Unstable_Grid2'

import SortableSearchHeader from 'Common/search/view/SortableSearchHeader'

type Props = {
    searchOptions: Record<string, unknown>
    updateSorting(..._args: unknown[]): unknown
}

class ActivityViewHeader extends Component<Props> {
    render() {
        const { searchOptions, updateSorting } = this.props
        const { orderBy } = searchOptions
        return (
            <Grid xs={12}>
                <Grid container spacing={2} xs={12} sx={{ display: { xs: 'none', md: 'flex' } }}>
                    <SortableSearchHeader
                        size={6}
                        label="Activity"
                        field="name"
                        {...{
                            orderBy,
                            updateSorting,
                        }}
                    />
                    <SortableSearchHeader
                        size={2}
                        label="Category"
                        field="categoryName"
                        {...{
                            orderBy,
                            updateSorting,
                        }}
                    />
                    <SortableSearchHeader
                        size={2}
                        label="Date made public"
                        field="publishingDate"
                        {...{
                            orderBy,
                            updateSorting,
                        }}
                    />
                    <SortableSearchHeader
                        size={2}
                        label="Status"
                        field="statusName"
                        {...{
                            orderBy,
                            updateSorting,
                        }}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default ActivityViewHeader
