import { Link, Typography } from '@mui/material'
import { Container } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

const GlobalError = () => (
    <Container sx={{ marginTop: 4, marginBottom: 2 }} maxWidth={false} className="shrink-print">
        <Grid
            xs={12}
            container
            spacing={2}
            display="flex"
            justifyContent="stretch"
            sx={{
                backgroundColor: 'primary.main',
            }}
        >
            <Grid xs={12} display="flex" justifyContent="center">
                <Typography>
                    <span role="img" aria-label="sad face" style={{ fontSize: 72 }}>
                        😭
                    </span>
                </Typography>
            </Grid>
            <Grid xs={12} display="flex" justifyContent="center">
                <Typography variant="h2" color="white">
                    An error occured
                </Typography>
            </Grid>
            <Grid xs={12} display="flex" justifyContent="center">
                <Typography variant="h5" color="white">
                    This part of Mockingbird could not be loaded. Please inform support or try
                    again.
                </Typography>
            </Grid>
            <Grid xs={12} display="flex" justifyContent="center">
                <Link href="/" variant="h6" underline="always" sx={{ color: 'white' }}>
                    Click here to return to the start page.
                </Link>
            </Grid>
        </Grid>
        <Grid xs={12} />
    </Container>
)

export default GlobalError
