import { getOr, slice } from 'lodash/fp'
import { Component, Fragment } from 'react'

import Grid from '@mui/material/Unstable_Grid2'

import { MetaData } from 'Common/metadata'

import Spinner, { SPINNER_SIZES } from '../../components/loader/Spinner'
import { requestStatus } from '../../utils/net/statuses'
import BasicPagingControl from './BasicPagingControl'

type Props = {
    updateSelectFields(..._args: unknown[]): unknown
    loadNextPage(..._args: unknown[]): unknown
    searchConfig: {
        pagingSize?: number
    }
    viewConfig: {
        componentConfig: {
            ItemComponent(..._args: unknown[]): unknown
        }
    }
    searchState: {
        searchOptions: {
            selectFields?: string[]
        }
        results: unknown[]
        status?: string
        paging?: {
            nextOffset?: number
        }
    }
    updateSorting?(..._args: unknown[]): unknown
    viewProps?: Record<string, unknown>
    language?: string
    metaData: MetaData
}

type State = {
    offsetToShow: number
}

class BasicSearchView extends Component<Props, State> {
    state = {
        offsetToShow: 0,
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const offset = getOr(0, 'searchState.searchOptions.offset', this.props)
        const nextOffset = getOr(0, 'searchState.searchOptions.offset', nextProps)

        if (offset !== nextOffset) {
            this.setState({
                offsetToShow: nextOffset,
            })
        }
    }

    showPreviousResults = () => {
        const { searchConfig } = this.props
        const pagingSize = getOr(25, 'pagingSize', searchConfig)
        this.setState((state) => ({
            offsetToShow: Math.max(0, state.offsetToShow - pagingSize),
        }))
    }

    showNextResults = () => {
        const { searchConfig, searchState, loadNextPage } = this.props
        const { offsetToShow } = this.state
        const { results, paging } = searchState
        const pagingSize = getOr(25, 'pagingSize', searchConfig)

        if (offsetToShow + pagingSize < results.length) {
            this.setState((state) => ({
                offsetToShow: state.offsetToShow + pagingSize,
            }))
        } else if (paging.nextOffset) {
            loadNextPage()
        }
    }

    renderErrorOrSpinner(spinner = true, reason?: any) {
        return (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 30,
                }}
            >
                {spinner ? <Spinner size={SPINNER_SIZES.LARGE} /> : reason}
            </div>
        )
    }

    renderResults(pendingResults, pagingSize) {
        const { viewConfig, searchState, viewProps, updateSorting, language, metaData } = this.props
        const { searchOptions } = searchState
        const { offsetToShow } = this.state
        const { results } = searchState
        const HeaderComponent = getOr(null, 'componentConfig.HeaderComponent', viewConfig)
        const ItemComponent = getOr(null, 'componentConfig.ItemComponent', viewConfig)
        const resultsToShow: any = slice(offsetToShow, offsetToShow + pagingSize, results)
        if (pendingResults) return this.renderErrorOrSpinner(true)
        return (
            <Grid xs={12}>
                {HeaderComponent ? (
                    <HeaderComponent
                        {...{
                            searchOptions,
                            viewProps,
                            updateSorting,
                        }}
                    />
                ) : null}
                {resultsToShow.map((item) => (
                    <ItemComponent
                        key={item.document.id}
                        result={item.document}
                        viewProps={viewProps}
                        {...{
                            searchOptions,
                            updateSorting,
                            language,
                            metaData: metaData,
                        }}
                    />
                ))}
            </Grid>
        )
    }

    render() {
        const { searchConfig, viewConfig, searchState } = this.props
        const { searchOptions } = searchState
        const { offsetToShow } = this.state
        const { status, results } = searchState
        const ItemComponent = getOr(null, 'componentConfig.ItemComponent', viewConfig)
        if (!ItemComponent) return null
        const pagingSize = getOr(25, 'pagingSize', searchConfig)
        if (status === requestStatus.failure)
            return this.renderErrorOrSpinner(false, 'An error occured')
        if (status === requestStatus.request && results.length === 0)
            return this.renderErrorOrSpinner(true)
        if (results.length === 0) return this.renderErrorOrSpinner(false, 'No results')
        const offset = getOr(0, 'offset', searchOptions)
        const pendingResults =
            status === requestStatus.request && results.length < offset + pagingSize
        return (
            <Fragment>
                <Grid container spacing={0} rowSpacing={2} xs={12}>
                    <Grid xs={12}>
                        <BasicPagingControl
                            showPreviousResults={this.showPreviousResults}
                            showNextResults={this.showNextResults}
                            disableControls={pendingResults}
                            {...{
                                searchState,
                                offsetToShow,
                                pagingSize,
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} rowSpacing={2} xs={12}>
                    <Grid xs={12}>{this.renderResults(pendingResults, pagingSize)}</Grid>
                </Grid>
                <Grid container spacing={0} rowSpacing={2} xs={12}>
                    <Grid xs={12}>
                        <BasicPagingControl
                            showPreviousResults={this.showPreviousResults}
                            showNextResults={this.showNextResults}
                            disableControls={pendingResults}
                            {...{
                                searchState,
                                offsetToShow,
                                pagingSize,
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid xs={12} />
            </Fragment>
        )
    }
}

export default BasicSearchView
