import { Cell } from 'fixed-data-table-2'
import { PureComponent } from 'react'

const getSortingDisplay = (isActiveSort, orderDirection) => {
    if (!isActiveSort) return ''
    if (orderDirection === 'desc') return '\u2193'
    return '\u2191'
}

type Props = {
    column: {
        selectField: string
        label: string
        sortField?: string
        sortable?: boolean
    }
    updateSorting?(..._args: unknown[]): unknown
    orderBy?: string
    viewProps?: Record<string, unknown>
}

class HeaderCell extends PureComponent<Props> {
    handleSort = (isActiveSort, orderDirection) => {
        const { column, updateSorting } = this.props
        const newSorting = `${column.sortField || column.selectField}${
            !orderDirection && isActiveSort ? ' desc' : ''
        }`
        updateSorting(newSorting)
    }

    render() {
        const { column, orderBy, updateSorting, viewProps, ...otherProps } = this.props
        const [orderField, orderDirection] = (orderBy || '').split(' ')
        const isActiveSort = (column.sortField || column.selectField) === orderField
        const isSortable = updateSorting && column.sortable
        return (
            <Cell {...otherProps}>
                {!isSortable && column.label}
                {isSortable ? (
                    <span
                        className="linkPrimary"
                        onClick={() => this.handleSort(isActiveSort, orderDirection)}
                    >
                        {`${column.label} ${getSortingDisplay(isActiveSort, orderDirection)}`}
                    </span>
                ) : null}
            </Cell>
        )
    }
}

export default HeaderCell
