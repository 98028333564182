/* serializes a searchOptions object to a string for use in the url for the client */
import { Base64 } from 'js-base64'
import { isEmpty } from 'lodash/fp'

export const getQueryInputSerializer =
    (defaultQuery = '') =>
    (searchOptions) =>
        window.encodeURIComponent((searchOptions.query || defaultQuery).trim())
export const getQueryFieldInputSerializer =
    (defaultField = 'all') =>
    (searchOptions) =>
        searchOptions.queryField || defaultField
export const getOrderByInputSerializer =
    (defaultOrderBy = 'relevance') =>
    (searchOptions) =>
        searchOptions.orderBy || defaultOrderBy
export const getCombinatorInputSerializer =
    (defaultCombinator = 'and') =>
    (searchOptions) =>
        searchOptions.combinator || defaultCombinator
export const getViewInputSerializer =
    (defaultView = '') =>
    (searchOptions) =>
        searchOptions.view || defaultView
export const getOptionsInputSerializer = (inputId) => (searchOptions) =>
    (searchOptions[inputId] || []).join(',')
export const getCombinationsInputSerializer = (inputId, typeConfig) => (searchOptions) =>
    (searchOptions[inputId] || [])
        .map(
            (option) =>
                `${typeConfig.keys
                    .map((key) => option[key])
                    .filter((_) => _)
                    .join(':::')}`,
        )
        .join(',')
export const getDatesInputSerializer = (inputId) =>
    getCombinationsInputSerializer(inputId, {
        keys: ['min', 'max'],
    })
export const getObjectsInputSerializer = (inputId) => (searchOptions) =>
    (searchOptions[inputId] || []).map((option) => Base64.encode(JSON.stringify(option))).join(',')
export const serializeSearchInput = (searchOptions = {}, searchConfig) => {
    const serializers = [
        ['query', searchConfig.queryInputSerializer || getQueryInputSerializer()],
        ['queryField', searchConfig.queryFieldInputSerializer || getQueryFieldInputSerializer()],
        ['orderBy', searchConfig.orderByInputSerializer || getOrderByInputSerializer()],
        ['combinator', searchConfig.combinatorInputSerializer || getCombinatorInputSerializer()],
        ['view', searchConfig.viewInputSerializer || getViewInputSerializer()],
    ]
        .concat(
            (searchConfig.filters || []).map((filter) => [
                filter.id,
                filter.inputSerializer ||
                    filter.type.getInputSerializer(filter.id, {
                        ...filter.type.typeConfig,
                        ...filter.typeConfig,
                    }),
            ]),
        )
        .filter((serializer) => serializer && serializer[1])
    return serializers
        .map((serializer) => {
            const serializerResult = serializer[1](searchOptions, searchConfig)
            return isEmpty(serializerResult) ? null : `${serializer[0]}=${serializerResult}`
        })
        .filter((_) => _)
        .join('&')
}
