import { getOr } from 'lodash/fp'

export type Pagination = {
    itemsPerPage: number
    itemsTotalCount: string
    pageNumber: number
    pagesTotalCount: number
}
export const handlePaging = (searchConfig: any, pagination: Pagination, currentOffset = 0) => {
    const itemsPerPage = getOr(25, 'pagingSize', searchConfig)
    const itemsTotalCount = parseInt(pagination.itemsTotalCount, 10)
    const nextOffset =
        currentOffset + itemsPerPage > itemsTotalCount ? null : currentOffset + itemsPerPage
    return { ...pagination, itemsPerPage, itemsTotalCount, currentOffset, nextOffset }
}
