import Grid from '@mui/material/Unstable_Grid2'

import SortableSearchHeader from 'Common/search/view/SortableSearchHeader'

type Props = {
    searchOptions: Record<string, any>
    updateSorting: (..._args: Array<any>) => any
}

const BatchFailedViewHeader = (props: Props) => {
    const { searchOptions, updateSorting } = props
    const { orderBy } = searchOptions
    return (
        <Grid container spacing={2} xs={12}>
            <Grid xs={2}>
                <SortableSearchHeader
                    label="ISBN"
                    field="isbn"
                    {...{
                        orderBy,
                        updateSorting,
                    }}
                />
            </Grid>
            <Grid xs={3}>
                <SortableSearchHeader
                    label="Title"
                    field="title"
                    {...{
                        orderBy,
                        updateSorting,
                    }}
                />
            </Grid>
            <Grid xs={7}>Warning</Grid>
        </Grid>
    )
}

export default BatchFailedViewHeader
