import fileSaver from 'file-saver'
import { isString } from 'lodash/fp'
import { Component, Fragment } from 'react'

import IconDownload from '@mui/icons-material/CloudDownload'
import { Button } from '@mui/material'

import { services } from 'Common/environment'
import { AppDispatch } from 'Common/store/createStore'

import messages from '../../messages'
import createErrorMessages from '../../messages/createErrorMessages'
import request from '../../utils/net/request'
import { plainSubmit } from '../../utils/net/submit'
import GenericErrorDialog from '../error/GenericErrorDialog'
import Spinner, { SPINNER_SIZES } from '../loader/Spinner'

export const getFileName = (res, id) => {
    const contentDisposition = res.headers.get('content-disposition')
    if (!contentDisposition || !contentDisposition.includes('filename')) return id
    const result = contentDisposition
        .split(';')
        .filter((str) => str.includes('filename='))
        .map((str) => str.trim().replace('filename=', ''))
    return result.length > 0 ? result[0] : id
}

type Props = {
    id: number
    dispatch?: AppDispatch
    label?: string
    color?: any
    fullWidth?: any
}

class AssetDownload extends Component<Props> {
    static defaultProps = {
        label: 'Download',
    }

    state = {
        downloading: false,
        error: null,
    }

    download = () => {
        const { id } = this.props
        this.setState(
            {
                downloading: true,
            },
            () =>
                plainSubmit(
                    () =>
                        request({
                            method: 'GET',
                            url: services.assets(`/v1/assets/${id}/file`),
                        }).then(({ response }) =>
                            response
                                .blob()
                                .then((blob) => {
                                    fileSaver.saveAs(blob, getFileName(response, id))
                                })
                                .then(() =>
                                    this.setState({
                                        downloading: false,
                                    }),
                                ),
                        ),
                    createErrorMessages(
                        ['ASSET_FORBIDDEN', 'ASSET_NOT_FOUND', 'ASSET_FILE_NOT_FOUND'],
                        messages,
                    ),
                ).catch((error) =>
                    this.setState({
                        error: isString(error)
                            ? error
                            : 'An error occured while downloading the asset.',
                        downloading: false,
                    }),
                ),
        )
    }

    closeErrorDialog = () =>
        this.setState({
            error: null,
        })

    render() {
        const { id, dispatch, label, ...otherProps } = this.props
        const { downloading, error } = this.state
        return (
            <Fragment>
                <Button
                    variant="outlined"
                    disabled={downloading}
                    onClick={this.download}
                    {...otherProps}
                    endIcon={
                        downloading ? <Spinner size={SPINNER_SIZES.BUTTON} /> : <IconDownload />
                    }
                >
                    {label}
                </Button>
                <GenericErrorDialog
                    handleClose={this.closeErrorDialog}
                    {...{
                        error,
                    }}
                />
            </Fragment>
        )
    }
}

export default AssetDownload
