import * as React from 'react'
import { ConnectedProps, connect } from 'react-redux'

import { loadEnvironment } from 'Common/environment'
import { setEnvironment as setEnvironmentAction } from 'Common/environment/environmentActions'
import { getMetaData as getMetaDataAction } from 'Common/metadata/metaDataActions'
import { getSettings as getSettingsAction } from 'Common/settings/settingsActions'
import { RootState } from 'Common/store/createStore'

const mapState = (state: RootState) => {
    const { settings } = state
    return {
        settings: settings.data,
    }
}

const mapDisp = {
    setEnvironment: setEnvironmentAction,
    getMetaData: getMetaDataAction,
    getSettings: getSettingsAction,
}

const connector = connect(mapState, mapDisp)

type PropsFromRedux = ConnectedProps<typeof connector>

export const baseLoader = (Component, metadataFields, handleError) => {
    class BaseLoader extends React.Component<PropsFromRedux> {
        state = {
            metaDataLoaded: false,
            settingsLoaded: false,
        }

        componentDidMount() {
            const { getMetaData, getSettings, setEnvironment } = this.props
            loadEnvironment().then((env) => {
                setEnvironment(env)

                if (metadataFields && metadataFields.length > 0) {
                    getMetaData(metadataFields)
                        .then(() =>
                            this.setState({
                                metaDataLoaded: true,
                            }),
                        )
                        .catch(handleError)
                } else {
                    this.setState({
                        metaDataLoaded: true,
                    })
                }

                getSettings()
                    .then(() => {
                        this.setState({
                            settingsLoaded: true,
                        })
                    })
                    .catch(handleError)
            })
        }

        render() {
            const { getMetaData, getSettings, ...otherProps } = this.props
            const { metaDataLoaded, settingsLoaded } = this.state

            if (!metaDataLoaded || !settingsLoaded) {
                return null
            }

            return <Component {...otherProps} />
        }
    }

    return connector(BaseLoader)
}
