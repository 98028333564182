import { MetaData } from 'Common/metadata'

import { requestStatus } from '../utils/net/statuses'
import { GET_METADATA_FAILURE, GET_METADATA_REQUEST, GET_METADATA_SUCCESS } from './metaDataActions'

const addMissingFields = (metaData: MetaData, metaDataFields: any) => ({
    ...metaDataFields.reduce((acc, cur) => ({ ...acc, [cur]: [] }), {}),
    ...metaData,
})

const initialState = {
    data: {},
    status: {
        getStatus: undefined,
        getError: undefined,
    },
}
type State = {
    status: {
        getStatus: any
        getError: any
    }
    data?: MetaData
}

export default (state: State = initialState, action) => {
    switch (action.type) {
        case GET_METADATA_REQUEST:
            return {
                ...state,
                status: {
                    ...state.status,
                    getStatus: action.meta.silent ? state.status.getStatus : requestStatus.request,
                },
            }

        case GET_METADATA_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...addMissingFields(action.payload.json, action.meta.metaDataFields),
                },
                status: {
                    ...state.status,
                    getStatus: action.meta.silent ? state.status.getStatus : requestStatus.success,
                },
            }

        case GET_METADATA_FAILURE:
            return {
                ...state,
                status: {
                    ...state.status,
                    getStatus: requestStatus.failure,
                    getError: action.meta.silent ? state.status.getStatus : action.payload,
                },
            }

        default:
            return state
    }
}
