import { postActivityStatusActivities as postActivityStatusActivitiesAction } from 'Work/actions/activityActions'
import { searchConfig } from 'Work/config/activitiesConfig'
import messages from 'Work/messages'
import { first, getOr, uniq } from 'lodash/fp'
import { Component, Fragment } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'

import IconCheck from '@mui/icons-material/Check'
import IconError from '@mui/icons-material/Error'
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import Spinner from 'Common/components/loader/Spinner'
import { Dialog, SaveButton } from 'Common/core'
import FormSelectField from 'Common/form/FormSelectField'
import { notEmpty, quickValidation } from 'Common/form/formvalidation'
import { getSearch as getSearchAction } from 'Common/search/searchActions'
import { RootState } from 'Common/types'
import { formSubmit } from 'Common/utils/net/submit'

type ResultTableProps = {
    editions: {
        title: string
        value: string
        status: any
    }[]
}

const ResultTable = ({ editions }: ResultTableProps) => (
    <Table
        style={{
            tableLayout: 'auto',
        }}
    >
        <TableHead>
            <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Activity status</TableCell>
                <TableCell
                    style={{
                        textAlign: 'left',
                    }}
                >
                    Action status
                </TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {editions.map(({ title, status, value }, index) => (
                <TableRow key={index}>
                    <TableCell>{title}</TableCell>
                    <TableCell title={value}>{value}</TableCell>
                    <TableCell
                        style={{
                            textAlign: 'left',
                        }}
                    >
                        {status}
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>
)

type Props = {
    closeModal: () => void
    error: string
}
type State = {
    selectedContact?: Record<string, unknown> | null
    selectedRole?: Record<string, unknown> | null
    isNewContact: boolean
    saveActivities: any[]
}

const styles = {
    icon: {},
}

const FORM_NAME = 'add_listing_code_to_editions'

const mapState = (state: RootState) => {
    const { metaData, search } = state
    const currentProductions = getOr([], 'activities.results', search)
    const selectedActivityIds = getOr([], 'activities.selectedResult', search)
    const selectedActivities = selectedActivityIds.map((selectedProductionId) =>
        currentProductions.find(
            (currentProduction) => currentProduction.document.id === selectedProductionId,
        ),
    )
    const selectedPublishingHouseGroups = uniq(
        selectedActivities.map((edition) => edition.document.publishingHouseGroupId),
    )
    const hasMultiplePublishingHouseGroups =
        (selectedPublishingHouseGroups && selectedPublishingHouseGroups.length !== 1) || false
    const initialValues = {
        activityIds: getOr([], 'activities.selectedResult', search),
    }
    return {
        initialValues,
        hasMultiplePublishingHouseGroups,
        selectedPublishingHouseGroups,
        metaData: metaData.data,
        selectedActivities,
    }
}

const connector = connect(mapState, {
    postActivityStatusActivities: postActivityStatusActivitiesAction,
    getSearch: getSearchAction,
})
const form = reduxForm<any, any>({
    form: FORM_NAME,
    validate: quickValidation({
        activityStatus: [notEmpty()],
        activityIds: [notEmpty()],
    }),
})
type PropsFromRedux = ConnectedProps<typeof connector>

class UpdateActivityStatus extends Component<Props & InjectedFormProps & PropsFromRedux, State> {
    state = {
        selectedContact: null,
        selectedRole: null,
        isNewContact: false,
        saveActivities: [],
    }

    static defaultProps = {
        selectedActivities: [],
        selectedPublishingHouseGroups: [],
        hasMultiplePublishingHouseGroups: false,
    }

    submit = (values) => {
        const { postActivityStatusActivities, selectedActivities } = this.props
        const { activityStatus, activityIds } = values
        return formSubmit(() => postActivityStatusActivities(activityIds, activityStatus))
            .then(({ value }) => value.json)
            .then((saveActivities) => {
                this.setState({
                    saveActivities: saveActivities.map((savedActivity) => ({
                        ...savedActivity,
                        ...selectedActivities.find(
                            (selectedActivity) =>
                                selectedActivity.document.id === `${savedActivity.id}`,
                        ),
                    })),
                })
            })
    }

    render() {
        const {
            closeModal,
            metaData,
            getSearch,
            selectedActivities,
            error,
            handleSubmit,
            submitting,
            valid,
            submitSucceeded,
            hasMultiplePublishingHouseGroups,
            selectedPublishingHouseGroups,
        } = this.props
        const { saveActivities } = this.state

        const closeAndSearchAction = () => {
            getSearch(searchConfig)
            closeModal()
        }

        const selectedCloseAction = () => {
            submitSucceeded ? closeAndSearchAction() : closeModal()
        }

        const doSubmit = handleSubmit(this.submit)
        const actions = [
            <Button key="cancel" onClick={selectedCloseAction} disabled={submitting}>
                {submitSucceeded ? messages.common.action.close : messages.common.action.cancel}
            </Button>,
            <SaveButton
                key="save"
                onClick={doSubmit}
                disabled={
                    selectedActivities.length === 0 ||
                    !valid ||
                    submitting ||
                    submitSucceeded ||
                    hasMultiplePublishingHouseGroups
                }
            />,
        ]
        const selectedPublishingHouseGroup = selectedPublishingHouseGroups
            ? first(selectedPublishingHouseGroups)
            : []
        const title =
            selectedActivities.length === 0
                ? 'No activities selected'
                : hasMultiplePublishingHouseGroups
                  ? 'Activities from multiple publishing house groups selected'
                  : submitting
                    ? 'Saving...'
                    : saveActivities.length !== 0
                      ? 'Activity status successfully added to activities'
                      : 'Edit activity status'
        return (
            <Dialog
                {...{
                    actions,
                    title,
                }}
                onClose={closeModal}
            >
                {selectedActivities.length !== 0 && !hasMultiplePublishingHouseGroups ? (
                    <Grid container spacing={2} xs={12}>
                        <form onSubmit={doSubmit}>
                            <Grid xs={12}>
                                {!submitting && saveActivities.length === 0 ? (
                                    <Fragment>
                                        <Grid xs={12}>
                                            <Field
                                                name="activityStatus"
                                                component={FormSelectField}
                                                label="Activity status"
                                                metaData={metaData.activityStatus.filter(
                                                    (activityStatus) =>
                                                        activityStatus.publishingHouseGroupIds.includes(
                                                            selectedPublishingHouseGroup,
                                                        ),
                                                )}
                                            />
                                        </Grid>
                                    </Fragment>
                                ) : null}
                                {submitting ? (
                                    <Grid xs={12}>
                                        <div
                                            style={{
                                                textAlign: 'center',
                                            }}
                                        >
                                            <Spinner />
                                            <span>Loading... </span>
                                        </div>
                                    </Grid>
                                ) : null}
                                {error ? (
                                    <Grid xs={12}>
                                        <div
                                            style={{
                                                textAlign: 'center',
                                                color: '#f44336',
                                            }}
                                        >
                                            {error}
                                        </div>
                                    </Grid>
                                ) : null}
                                <Grid xs={12}>
                                    <Typography variant="h6">Activities</Typography>
                                    {saveActivities.length !== 0 ? (
                                        <ResultTable
                                            editions={saveActivities.map((a) => ({
                                                title: getOr('-', 'document.name', a),
                                                value: getOr('-', 'value.name', a),
                                                status: a.isSuccess ? (
                                                    a.warnings.length === 0 ? (
                                                        <IconCheck style={styles.icon} />
                                                    ) : (
                                                        <span
                                                            title={a.warnings
                                                                .map(
                                                                    ({
                                                                        message,
                                                                    }: {
                                                                        message: string
                                                                    }) => message,
                                                                )
                                                                .join(', ')}
                                                        >
                                                            {a.warnings
                                                                .map(
                                                                    ({
                                                                        message,
                                                                    }: {
                                                                        message: string
                                                                    }) => message,
                                                                )
                                                                .join(', ')}
                                                        </span>
                                                    )
                                                ) : (
                                                    <span
                                                        title={a.warnings
                                                            .map(
                                                                ({
                                                                    message,
                                                                }: {
                                                                    message: string
                                                                }) => message,
                                                            )
                                                            .join(', ')}
                                                    >
                                                        <IconError />
                                                        <span>
                                                            {a.warnings
                                                                .map(
                                                                    ({
                                                                        message,
                                                                    }: {
                                                                        message: string
                                                                    }) => message,
                                                                )
                                                                .join(', ')}
                                                        </span>
                                                    </span>
                                                ),
                                            }))}
                                        />
                                    ) : (
                                        <ResultTable
                                            editions={selectedActivities.map((a) => ({
                                                title: a.document.name,
                                                value: getOr('-', 'document.statusName', a),
                                                status: <span>-</span>,
                                            }))}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                ) : null}
            </Dialog>
        )
    }
}

export default connector(form(UpdateActivityStatus))
