import { get, isEmpty } from 'lodash/fp'
import { Component } from 'react'
import { ConnectedProps, connect } from 'react-redux'

import { Collections as CollectionsIcon } from '@mui/icons-material/'

import { services } from 'Common/environment'
import { AppDispatch, RootState } from 'Common/store/createStore'

import { isInBrowser } from '../../utils/browser'

const inBrowser = isInBrowser()

const getUrl = (id, maxWidth) => {
    const params = [maxWidth ? `maxWidth=${maxWidth}` : null].filter((_) => _).join('&')
    return `${!inBrowser ? 'http://localhost:3000' : services.assets('')}/v1/thumbs/${id}${
        isEmpty(params) ? '' : '?'
    }${params}`
}

const maxFetchRetries = 1

type Props = {
    dispatch: AppDispatch
    id?: number
    maxWidth?: number
    style?: Record<string, unknown>
    enforceDimensions?: boolean
    reload?: boolean
    defaultImage?: boolean
    onClick?: any
    alt?: string
}

const mapState = (state: RootState) => {
    const { auth } = state
    return {
        auth,
    }
}

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

class AssetThumbnail extends Component<Props & PropsFromRedux> {
    _isMounted: boolean

    image: string

    static defaultProps = {
        maxWidth: 0,
        reload: false,
        enforceDimensions: true,
        defaultImage: true,
        style: {},
    }

    state = {
        imageFetched: false,
    }

    componentDidMount() {
        this._isMounted = true
        this.getFile()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { reload } = this.props
        const shouldLoadImage =
            (nextProps.reload !== reload && nextProps.reload) || this.props.id !== nextProps.id

        if (shouldLoadImage) {
            this.setState({
                imageFetched: false,
            })
            this.getFile()
        }
    }

    componentDidUpdate(prevProps) {
        const shouldLoadImage = this.props.id !== prevProps.id

        if (shouldLoadImage) {
            this.setState({
                imageFetched: false,
            })
            this.getFile()
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    getFile(attempt = 0) {
        if (attempt > maxFetchRetries) {
            this.setState({
                imageFetched: true,
            })
            return
        }

        const { id, maxWidth, auth } = this.props
        const token = get('data.token', auth)
        if (!token || !id) return
        fetch(getUrl(id, maxWidth), {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                const success = res.status >= 200 && res.status < 300
                if (!success) throw new Error()
                return res.blob()
            })
            .then((blob) => {
                if (!this._isMounted) return

                if (blob.size === 0) {
                    this.getFile(++attempt)
                    return
                }

                this.image = URL.createObjectURL(blob)
                this.setState({
                    imageFetched: true,
                })
            })
            .catch(() => {})
    }

    render() {
        const {
            id,
            maxWidth,
            enforceDimensions,
            defaultImage,
            style,
            auth,
            dispatch,
            reload,
            ...otherProps
        } = this.props
        return (
            <div
                style={{
                    width: maxWidth > 0 && enforceDimensions ? maxWidth : undefined,
                }}
            >
                {this.image ? (
                    <img
                        alt="thumbnail"
                        {...otherProps}
                        src={this.image}
                        style={{
                            maxWidth: '100%',
                        }}
                    />
                ) : (
                    <CollectionsIcon
                        color="disabled"
                        sx={{
                            fontSize: maxWidth,
                        }}
                    />
                )}
            </div>
        )
    }
}

export default connector(AssetThumbnail)
