import { convertFactorsToPercentages } from 'Calculation/utils/calculation'

import { requestStatus } from 'Common/utils/net/statuses'

import { GET_PRODUCTION_REQUEST } from '../actions/productionActions'
import {
    GET_PRODUCTION_ROYALTY_LEVELS_FAILURE,
    GET_PRODUCTION_ROYALTY_LEVELS_REQUEST,
    GET_PRODUCTION_ROYALTY_LEVELS_SUCCESS,
    PUT_PRODUCTION_ROYALTY_LEVELS_FAILURE,
    PUT_PRODUCTION_ROYALTY_LEVELS_REQUEST,
    PUT_PRODUCTION_ROYALTY_LEVELS_SUCCESS,
} from '../actions/productionRoyaltyLevelsActions'

const initialState = {
    status: {},
}
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCTION_REQUEST: {
            return { ...initialState }
        }

        case GET_PRODUCTION_ROYALTY_LEVELS_REQUEST: {
            return {
                ...state,
                workId: action.payload.workId,
                productionId: action.payload.productionId,
                status: { ...state.status, getStatus: requestStatus.request, getError: null },
            }
        }

        case GET_PRODUCTION_ROYALTY_LEVELS_SUCCESS: {
            return {
                ...state,
                data: convertFactorsToPercentages(action.payload.json),
                status: { ...state.status, getStatus: requestStatus.success },
            }
        }

        case GET_PRODUCTION_ROYALTY_LEVELS_FAILURE: {
            return {
                ...state,
                status: {
                    ...state.status,
                    getStatus: requestStatus.failure,
                    getError: action.payload,
                },
            }
        }

        case PUT_PRODUCTION_ROYALTY_LEVELS_REQUEST: {
            return {
                ...state,
                status: { ...state.status, putStatus: requestStatus.request, putError: null },
            }
        }

        case PUT_PRODUCTION_ROYALTY_LEVELS_SUCCESS: {
            return {
                ...state,
                data: convertFactorsToPercentages(action.payload.json),
                status: { ...state.status, putStatus: requestStatus.success },
            }
        }

        case PUT_PRODUCTION_ROYALTY_LEVELS_FAILURE: {
            return {
                ...state,
                status: {
                    ...state.status,
                    putStatus: requestStatus.failure,
                    putError: action.payload,
                },
            }
        }

        default: {
            return state
        }
    }
}
