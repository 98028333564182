import IconDone from '@mui/icons-material/Done'
import { green } from '@mui/material/colors'

type Props = {
    completion: number
}

const SectionCompletion = ({ completion }: Props) => {
    if (completion === 0) {
        return (
            <span>
                <IconDone
                    style={{
                        height: 16,
                        width: 16,
                        marginBottom: -2,
                        marginRight: 5,
                        color: green[700],
                    }}
                />
                Everything is filled out
            </span>
        )
    }

    return <span>{`${completion}% left to fill out`}</span>
}

export default SectionCompletion
