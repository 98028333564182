import { requestStatus } from '../utils/net/statuses'
import {
    GET_SETTINGS_FAILURE,
    GET_SETTINGS_REQUEST,
    GET_SETTINGS_SUCCESS,
    GET_SETTING_FAILURE,
    GET_SETTING_REQUEST,
    GET_SETTING_SUCCESS,
    POST_SETTINGS_FAILURE,
    POST_SETTINGS_REQUEST,
    POST_SETTINGS_SUCCESS,
    POST_SETTING_FAILURE,
    POST_SETTING_REQUEST,
    POST_SETTING_SUCCESS,
} from './settingsActions'

const convertValuesFromBackend = (jsonPayload = {}) =>
    Object.keys(jsonPayload)
        .map((key) => {
            try {
                return {
                    key,
                    value: JSON.parse(jsonPayload[key]),
                }
            } catch (e) {
                return null
            }
        })
        .filter((_) => _)
        .reduce((acc, cur) => ({ ...acc, [cur.key]: cur.value }), {})

const initialState = {
    data: {},
    status: {},
}
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SETTINGS_REQUEST:
        case GET_SETTING_REQUEST:
            return { ...state, status: { ...state.status, getStatus: requestStatus.request } }

        case GET_SETTINGS_SUCCESS:
        case GET_SETTING_SUCCESS:
            return {
                ...state,
                data: { ...state.data, ...convertValuesFromBackend(action.payload.json) },
                status: { ...state.status, getStatus: requestStatus.success },
            }

        case GET_SETTINGS_FAILURE:
        case GET_SETTING_FAILURE:
            return {
                ...state,
                status: {
                    ...state.status,
                    getStatus: requestStatus.failure,
                    getError: action.payload,
                },
            }

        case POST_SETTINGS_REQUEST:
        case POST_SETTING_REQUEST:
            return {
                ...state,
                data: { ...state.data, ...action.payload },
                status: { ...state.status, postStatus: requestStatus.request },
            }

        case POST_SETTINGS_SUCCESS:
        case POST_SETTING_SUCCESS:
            return { ...state, status: { ...state.status, postStatus: requestStatus.success } }

        case POST_SETTINGS_FAILURE:
        case POST_SETTING_FAILURE:
            return { ...state, status: { ...state.status, postStatus: requestStatus.failure } }

        default:
            return state
    }
}
