/* serializes a searchOptions object to a search string for sending to the search backend */
import { flow, getOr, groupBy, identity, isEmpty, isNil, isString } from 'lodash/fp'

export const getQueryOutputSerializer =
    (defaultQuery = '') =>
    (searchOptions) =>
        window.encodeURIComponent((searchOptions.query || defaultQuery).trim())

export const getQueryFieldOutputSerializer =
    (defaultField = 'all') =>
    (searchOptions) => {
        const queryField = searchOptions.queryField || defaultField
        if (isEmpty(queryField) || queryField === 'all') return null
        return queryField
    }

export const getOrderByOutputSerializer =
    (defaultOrderBy = 'relevance') =>
    (searchOptions) => {
        const orderBy = searchOptions.orderBy || defaultOrderBy
        if (isEmpty(orderBy) || orderBy === 'relevance') return null
        return orderBy
    }

export const getCombinatorOutputSerializer =
    (defaultCombinator = 'and') =>
    (searchOptions) => {
        const combinator = searchOptions.combinator || defaultCombinator
        if (isEmpty(combinator) || combinator === 'and') return null
        return combinator
    }

export const getOffsetOutputSerializer =
    (defaultOffset = 0) =>
    (searchOptions) => {
        const offset = searchOptions.offset || defaultOffset
        return offset === 0 ? null : offset
    }

export const getOptionsOutputSerializer = (inputId?: any, outputId?: any) => (searchOptions) =>
    (searchOptions[inputId] || [])
        .map((option) => `${outputId} eq ${isString(option) ? `'${option}'` : option}`)
        .join(' or ')

export const getMissingOutputSerializer = (inputId, outputId) => (searchOptions) =>
    (searchOptions[inputId] || [])
        .map((option) => `${outputId} ${option ? 'ne' : 'eq'} null`)
        .join(' or ')

export const getEmptyArrayOutputSerializer = (inputId, outputId) => (searchOptions) =>
    (searchOptions[inputId] || [])
        .map((option) => `${option ? '' : 'not'} ${outputId}/any()`.trim())
        .join(' or ')

export const getCombinationsOutputSerializer =
    (inputId, outputId, typeConfig?: any) => (searchOptions) =>
        (searchOptions[inputId] || [])
            .map(
                (option) =>
                    `${outputId} eq '${typeConfig.keys.map((key) => option[key]).join(' ')}'`,
            )
            .join(' or ')

export const getArrayOutputSerializer = (inputId, outputId, typeConfig?: any) => (searchOptions) =>
    (searchOptions[inputId] || [])
        .map(
            (option) =>
                `${outputId}/any(t: t eq '${getOr(identity, 'objectToValue', typeConfig)(option)}')`,
        )
        .join(' or ')

export const getIdNameArrayOutputSerializer =
    (inputId, outputId, typeConfig?: any) => (searchOptions) =>
        (searchOptions[inputId] || [])
            .map(
                (option) =>
                    `${outputId}/any(t: t/id eq '${getOr(identity, 'objectToValue', typeConfig)(option)}')`,
            )
            .join(' or ')

export const getDatesOutputSerializer = (inputId, outputId, typeConfig?: any) => (searchOptions) =>
    (searchOptions[inputId] || [])
        .map((option) =>
            option.max
                ? `${outputId} ge ${option.min + 'T00:00:00.000Z'} and ${outputId} le ${
                      option.max + 'T23:59:59.999Z'
                  }`
                : getFilter(typeConfig, outputId, option.min),
        )
        .join(' or ')

export const getFilter = (typeConfig: any, outputId: any, date: any) => {
    if (getOr(null, 'singleDateComparator', typeConfig) === null) {
        return `${outputId} ge ${date + 'T00:00:00.000Z'} and ${outputId} le ${date + 'T23:59:59.999Z'}`
    } else {
        return `${outputId} ${getOr('eq', 'singleDateComparator', typeConfig)} ${date}`
    }
}

export const getObjectsOutputSerializer = (inputId, outputId) => (searchOptions) =>
    (searchOptions[inputId] || [])
        .map((option) => option.id)
        .map((optionId) => `${outputId} eq ${isString(optionId) ? `'${optionId}'` : optionId}`)
        .join(' or ')

export const getContactsOutputSerializer =
    (inputId, outputId, typeConfig?: any) => (searchOptions) =>
        (searchOptions[inputId] || [])
            .filter((option) => !isNil(option.contactId) || !isNil(option.roleId))
            .map((option) => {
                if (!isNil(option.contactId) && !isNil(option.roleId)) {
                    return `${typeConfig.contactAndRoleOutputId}/any(t: t eq '${option.contactId}|${option.roleId}')`
                } else if (!isNil(option.roleId)) {
                    return `${typeConfig.roleOutputId}/any(t: t eq '${option.roleId}')`
                }

                return `${typeConfig.contactOutputId}/any(t: t eq '${option.contactId}')`
            })
            .join(' or ')

export const getThemaCodesOutputSerializer = (inputId) => (searchOptions) =>
    (searchOptions[inputId] || [])
        .map((option) => `themas/any(t: t/codeValue eq '${option.codeValue}')`)
        .join(' or ')

export const getObjectsToListOutputSerializer =
    (inputId, outputId, typeConfig?: any) => (searchOptions) => {
        const result = (searchOptions[inputId] || [])
            .map((option) => option[typeConfig.propertyId])
            .filter((_) => _)
            .join(',')
        return isEmpty(result) ? '' : `${outputId}:(${result})`
    }

export const getTimeplanOutputSerializer =
    (inputId, outputId, typeConfig?: any) => (searchOptions) =>
        (searchOptions[inputId] || [])
            .map((option) => {
                const realOutputId = typeConfig.getOutputId(option)
                return option.max
                    ? `${realOutputId} ge ${option.min + 'T00:00:00.000Z'} and ${realOutputId} le ${
                          option.max + 'T23:59:59.999Z'
                      }`
                    : `${realOutputId} ${getOr('eq', 'singleDateComparator', typeConfig)} ${
                          option.min + 'T00:00:00.000Z'
                      }`
            })
            .join(' or ')

const getQueryFieldAppender =
    (searchOptions, searchConfig) =>
    (searchString = '') => {
        const queryFieldOutputSerializer =
            searchConfig.queryOutputSerializer || getQueryFieldOutputSerializer()
        const queryField = queryFieldOutputSerializer(searchOptions, searchConfig)
        return `${searchString}${queryField ? `&searchFields=${queryField}` : ''}`
    }

const getOffsetAppender =
    (searchOptions, searchConfig) =>
    (searchString = '') => {
        const offsetOutputSerializer =
            searchConfig.offsetOutputSerializer || getOffsetOutputSerializer()
        const offset = offsetOutputSerializer(searchOptions, searchConfig)
        return `${searchString}${offset ? `&offset=${offset}` : ''}`
    }

const getOrderByAppender =
    (searchOptions, searchConfig) =>
    (searchString = '') => {
        const orderByOutputSerializer =
            searchConfig.orderByOutputSerializer || getOrderByOutputSerializer()
        const orderBy = orderByOutputSerializer(searchOptions, searchConfig)
        return `${searchString}${orderBy ? `&$orderBy=${orderBy}` : ''}`
    }

const getCombinatorAppender =
    (searchOptions, searchConfig) =>
    (searchString = '') => {
        const combinatorOutputSerializer =
            searchConfig.combinatorOutputSerializer || getCombinatorOutputSerializer()
        const combinator = combinatorOutputSerializer(searchOptions, searchConfig)
        return `${searchString}${
            combinator ? `&searchMode=${combinator === 'or' ? 'any' : 'all'}` : ''
        }`
    }

const DEFAULT_FILTER_GROUP = '$filter'

const filterGroupCreator = (filterGroupId, filters = [], searchOptions, searchConfig) => {
    const filterGroupOutput = filters
        .map((filter) => {
            const outputSerializer =
                filter.outputSerializer ||
                filter.type.getOutputSerializer(filter.id, filter.searchId, {
                    ...filter.type.typeConfig,
                    ...filter.typeConfig,
                })
            const output = outputSerializer(searchOptions, searchConfig)
            return isEmpty(output) ? null : `(${output})`
        })
        .filter((_) => _)
        .join(' and ')
    return isEmpty(filterGroupOutput) ? null : `${filterGroupId}=${filterGroupOutput}`
}

const getFiltersAppender =
    (searchOptions, searchConfig) =>
    (searchString = '') => {
        const groupedFilters = groupBy(
            (filter) => filter.externalFilterGroup || DEFAULT_FILTER_GROUP,
            searchConfig.filters,
        )
        const filtersOutput = Object.keys(groupedFilters)
            .map((filterGroupId) =>
                filterGroupCreator(
                    filterGroupId,
                    groupedFilters[filterGroupId],
                    searchOptions,
                    searchConfig,
                ),
            )
            .filter((_) => _)
            .join('&')
        return `${searchString}${filtersOutput ? `&${filtersOutput}` : ''}`
    }

const getSelectFieldsAppender =
    (searchOptions) =>
    (searchString = '') => {
        const selectOutput = (searchOptions.selectFields || []).join(',')
        return `${searchString}${!isEmpty(selectOutput) ? `&$select=${selectOutput}` : ''}`
    }

export const serializeSearchOutput = (searchOptions = {}, searchConfig, limit = 25) => {
    const queryOutputSerializer = searchConfig.queryOutputSerializer || getQueryOutputSerializer()
    const baseSearchString = `q=${queryOutputSerializer(searchOptions, searchConfig)}&limit=${limit}`
    return flow([
        getQueryFieldAppender(searchOptions, searchConfig),
        getOffsetAppender(searchOptions, searchConfig),
        getOrderByAppender(searchOptions, searchConfig),
        getCombinatorAppender(searchOptions, searchConfig),
        getFiltersAppender(searchOptions, searchConfig),
        getSelectFieldsAppender(searchOptions),
    ])(baseSearchString)
}
