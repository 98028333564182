import { SET_ENVIRONMENT } from './environmentActions'
import { Action } from './environmentActions'

const initialState = {}
export type State = any
export default (state: State = initialState, action: Action) => {
    switch (action.type) {
        case SET_ENVIRONMENT:
            return { ...state, ...action.envPayload }

        default:
            return state
    }
}
