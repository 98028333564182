import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { createPromise } from 'redux-promise-middleware'
import thunk from 'redux-thunk'

import reducers from '../reducers'

const configureStore = () => {
    const middlewares = [
        thunk,
        createPromise({
            promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'FAILURE'],
        }),
    ]
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : compose
    return createStore(
        combineReducers({ ...reducers }),
        composeEnhancers(applyMiddleware(...middlewares)),
    )
}

const store = configureStore()

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
