import {
    debounce,
    defer,
    difference,
    fromPairs,
    getOr,
    isEqual,
    memoize,
    omit,
    pick,
    slice,
    union,
    uniq,
} from 'lodash/fp'
import { Component, Fragment } from 'react'

import { Button, Tooltip } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { EditButton } from 'Common/components/buttons'
import { MetaData } from 'Common/metadata'

import { requestStatus } from '../../utils/net/statuses'
import AdvancedViewMissingColumns from './AdvancedViewMissingColumns'
import AdvancedViewSelection from './AdvancedViewSelection'
import AdvancedViewTable from './AdvancedViewTable'
import AdvancedViewToolbar from './AdvancedViewToolbar'
import SavedViewRemoval from './SavedViewRemoval'
import SearchAssetExport from './SearchAssetExport'
import SearchExportCsv from './SearchExportCsv'
import ViewSaving from './ViewSaving'
import {
    getSavedViews,
    getWorkingView,
    isWorkingViewUnchangedSavedView,
    removeSavedView,
    saveView,
    saveWorkingView,
} from './savedViews'

export const DEFAULT_COLUMN_WIDTH = 210
export const RESET = '::##RESET##::'
export const UNSAVED_VIEW_NAME = '::##UNSAVED_VIEW_NAME##::'
const getInitialColumns = memoize((viewConfig) =>
    viewConfig.defaultColumns
        .map((colId) => viewConfig.columns[colId])
        .filter((_) => _)
        .map((col) => col.id),
)
const getInitialColumnWidths = memoize((viewConfig) =>
    fromPairs(
        viewConfig.defaultColumns
            .map((colId) => viewConfig.columns[colId])
            .filter((_) => _)
            .map((col) => col.id)
            .map((col) => [col.id, col.width || DEFAULT_COLUMN_WIDTH]),
    ),
)

const isInitialView = (view, viewConfig) =>
    view.name
        ? false
        : isEqual(
              {
                  activeColumns: getInitialColumns(viewConfig),
                  columnWidths: getInitialColumnWidths(viewConfig),
              },
              pick(['activeColumns', 'columnWidths'], view),
          )

const reorderColumnOrder = (columnOrder, columnBefore, columnAfter, reorderColumn) => {
    const cleanedColumnOrder = columnOrder.filter((colId) => colId !== reorderColumn)
    const initialEnd = !columnBefore ? 0 : cleanedColumnOrder.indexOf(columnBefore) + 1
    const finalEnd = !columnAfter ? initialEnd - 1 : cleanedColumnOrder.length
    const wrappedSlice: any = slice
    return [
        ...wrappedSlice(0, initialEnd, cleanedColumnOrder),
        reorderColumn,
        ...wrappedSlice(initialEnd, finalEnd, cleanedColumnOrder),
    ]
}

type Props = {
    updateSelectFields(..._args: unknown[]): unknown
    loadNextPage(..._args: unknown[]): unknown
    postSetting(..._args: unknown[]): unknown
    searchConfig: {
        defaultSelectFields: string[]
        id: string
        searchService(..._args: unknown[]): unknown
        searchServiceExport(..._args: unknown[]): unknown
    }
    viewConfig: {
        defaultColumns: unknown[]
        columns: any
        savedViewsId?: string
        ActionsComponent?: any
    }
    searchState: {
        searchOptions: {
            selectFields: string[]
            orderBy: any
        }
        paging?: Record<string, unknown>
        results: unknown[]
    }
    metaData: MetaData
    settings: Record<string, unknown>
    viewProps?: Record<string, unknown>
    updateSorting?(..._args: unknown[]): unknown
    toggleSingle?(..._args: unknown[]): unknown
    toggleAll?(..._args: unknown[]): unknown
    searchMetaData?: Record<string, unknown>
    hasActions?: boolean

    language?: string
}

type State = {
    workingView: any
    savedViews: any
    showViewConfiguration: boolean
    showRemoveViewModal: boolean
    showSaveViewModal: boolean
    tableWidth: number
}

class AdvancedSearchView extends Component<Props, State> {
    static defaultProps = {
        results: [],
        hasActions: false,
    }

    debouncedOnEnsureTableWidth: any

    private _isMounted: boolean

    pending: boolean

    constructor(props) {
        super(props)
        this.debouncedOnEnsureTableWidth = debounce(250, this.onEnsureTableWidth)
        const { viewConfig, settings } = this.props
        const workingView = getWorkingView(viewConfig) || {
            activeColumns: getInitialColumns(viewConfig),
            columnWidths: getInitialColumnWidths(viewConfig),
        }
        this.state = {
            workingView,
            savedViews: viewConfig.savedViewsId ? getSavedViews(viewConfig, settings) : [],
            showViewConfiguration: false,
            showRemoveViewModal: false,
            showSaveViewModal: false,
            tableWidth: 1008,
        }
    }

    UNSAFE_componentWillMount() {
        const { updateSelectFields, viewConfig, searchState } = this.props
        const { workingView } = this.state
        const { columns } = viewConfig
        const { searchOptions } = searchState
        const { selectFields } = searchOptions
        const neededSelectFields = uniq(
            workingView.activeColumns
                .map((colId) => columns[colId])
                .filter((_) => _)
                .map((col) => col.selectField),
        )
        const missingSelectFields = difference(neededSelectFields, selectFields)
        if (missingSelectFields.length === 0) return
        updateSelectFields(union(missingSelectFields, selectFields))
    }

    componentDidMount() {
        this._isMounted = true
        this.onEnsureTableWidth()
        window.addEventListener('resize', this.debouncedOnEnsureTableWidth)
        window.addEventListener('opusToggleDrawerMenu', this.debouncedOnEnsureTableWidth)
    }

    componentWillUnmount() {
        this._isMounted = false
        window.removeEventListener('resize', this.debouncedOnEnsureTableWidth)
    }

    getTableWidth = () => {
        const el: any = document.querySelector('#advancedSearchViewTableContainer')
        return el ? el.offsetWidth - 16 : 0
    }

    onEnsureTableWidth = () => {
        const tableWidth = this.getTableWidth()

        if (tableWidth > 0 && tableWidth !== this.state.tableWidth) {
            this.setState({
                tableWidth,
            })
        }
    }

    onColumnResizeEndCallback = (newColumnWidth, columnKey) => {
        this.setState(
            (state) => ({
                workingView: {
                    ...state.workingView,
                    columnWidths: {
                        ...state.workingView.columnWidths,
                        [columnKey]: newColumnWidth,
                    },
                },
            }),
            () => saveWorkingView(this.props.viewConfig.savedViewsId, this.state.workingView),
        )
    }

    onColumnReorderEndCallback = (event) =>
        this.setState(
            (state) => ({
                workingView: {
                    ...state.workingView,
                    activeColumns: reorderColumnOrder(
                        state.workingView.activeColumns,
                        event.columnBefore,
                        event.columnAfter,
                        event.reorderColumn,
                    ),
                },
            }),
            () => saveWorkingView(this.props.viewConfig.savedViewsId, this.state.workingView),
        )

    getResult = (rowIndex) => {
        const {
            loadNextPage,
            searchState: { results, paging },
        } = this.props

        if (requestStatus.request && results.length === 0) {
            return ['pending', null]
        }

        const resultsCount = getOr(results.length, 'itemsTotalCount', paging)

        if (rowIndex >= results.length && rowIndex < resultsCount) {
            if (!this.pending) {
                this.pending = true
                defer(loadNextPage, () => {
                    this.pending = false
                })
            }

            return ['pending', null]
        }

        return ['ok', results[rowIndex]]
    }

    handleUpdateActiveColumns = (updatedActiveColumns) => {
        const { searchConfig, viewConfig, searchState, updateSelectFields } = this.props
        const { defaultSelectFields } = searchConfig
        const { savedViewsId, columns } = viewConfig
        const {
            searchOptions: { selectFields },
        } = searchState
        const { workingView } = this.state
        const added: any = difference(updatedActiveColumns, workingView.activeColumns)
        const removed: any = difference(workingView.activeColumns, updatedActiveColumns)
        const addedNotLoaded = difference(added, selectFields)
        const removedNotDefault = difference(removed, defaultSelectFields)
        this.setState(
            (state) => ({
                workingView: {
                    ...state.workingView,
                    activeColumns: updatedActiveColumns,
                    columnWidths: fromPairs(
                        updatedActiveColumns.map((id) => [
                            id,
                            state.workingView.columnWidths[id] ||
                                columns[id].width ||
                                DEFAULT_COLUMN_WIDTH,
                        ]),
                    ),
                },
            }),
            () => saveWorkingView(savedViewsId, this.state.workingView),
        )
        if (addedNotLoaded.length === 0 && removedNotDefault.length === 0) return
        const updatedSelectFields = union(
            defaultSelectFields,
            updatedActiveColumns.map((_) => columns[_].selectField),
        )
        updateSelectFields(updatedSelectFields)
    }

    activateSavedView = (viewName) => {
        const { searchConfig, viewConfig, updateSelectFields } = this.props
        const { savedViews } = this.state
        const { defaultSelectFields } = searchConfig
        const { savedViewsId, columns } = viewConfig

        if (viewName === RESET) {
            this.resetView()
            return
        }

        const savedView = savedViews.find((view) => view.name === viewName)
        if (!savedView) return
        this.setState(
            {
                workingView: { ...savedView },
            },
            () => saveWorkingView(savedViewsId, this.state.workingView),
        )
        const updatedSelectFields = union(
            defaultSelectFields,
            savedView.activeColumns.map((_) => columns[_].selectField),
        )
        updateSelectFields(updatedSelectFields)
    }

    resetView = () => {
        const { searchConfig, viewConfig, updateSelectFields } = this.props
        const { defaultSelectFields } = searchConfig
        this.setState(
            {
                workingView: {
                    activeColumns: getInitialColumns(viewConfig),
                    columnWidths: getInitialColumnWidths(viewConfig),
                },
            },
            () => saveWorkingView(viewConfig.savedViewsId, this.state.workingView),
        )
        updateSelectFields(defaultSelectFields)
    }

    handleSaveView = (name) => {
        const { viewConfig, settings, postSetting } = this.props
        const { savedViewsId } = viewConfig
        const { workingView } = this.state
        const updatedWorkingView = { ...workingView, name }
        const updatedViews = saveView(
            viewConfig,
            settings,
            name,
            updatedWorkingView.activeColumns,
            updatedWorkingView.columnWidths,
            postSetting,
        )
        this.setState(
            {
                workingView: updatedWorkingView,
                savedViews: updatedViews,
            },
            () => {
                saveWorkingView(savedViewsId, updatedWorkingView)
                this.toggleSaveViewModal()
            },
        )
    }

    handleRemoveSavedView = (name) => {
        const { viewConfig, settings, postSetting } = this.props
        const { savedViewsId } = viewConfig
        const { workingView } = this.state
        const updatedWorkingView = omit(['name'], workingView)
        const updatedViews = removeSavedView(viewConfig, settings, name, postSetting)
        this.setState(
            {
                workingView: updatedWorkingView,
                savedViews: updatedViews,
            },
            () => {
                saveWorkingView(savedViewsId, updatedWorkingView)
                this.toggleRemoveViewModal()
            },
        )
    }

    toggleViewConfiguration = () =>
        this.setState((state) => ({
            showViewConfiguration: !state.showViewConfiguration,
        }))

    toggleRemoveViewModal = () =>
        this.setState((state) => ({
            showRemoveViewModal: !state.showRemoveViewModal,
        }))

    toggleSaveViewModal = () =>
        this.setState((state) => ({
            showSaveViewModal: !state.showSaveViewModal,
        }))

    render() {
        const {
            searchConfig,
            viewConfig,
            searchState,
            updateSorting,
            viewProps,
            metaData,
            searchMetaData,
            toggleSingle,
            toggleAll,
            hasActions,
            language,
        } = this.props
        const { columns, savedViewsId } = viewConfig
        const { results, paging } = searchState
        const {
            workingView,
            showViewConfiguration,
            savedViews,
            showRemoveViewModal,
            showSaveViewModal,
            tableWidth,
        } = this.state
        const { activeColumns } = workingView
        const resultsCount: any = getOr(results.length, 'itemsTotalCount', paging)
        const selectedCount = getOr(0, 'selectedResult.length', searchState)
        const ActionsComponent = viewConfig.ActionsComponent
        const isInitial = isInitialView(workingView, viewConfig)
        const isUnchangedSavedView = isWorkingViewUnchangedSavedView(workingView, savedViews)
        const hideUnsavedView = isInitial || isUnchangedSavedView
        const currentViewName = hideUnsavedView ? workingView?.name : UNSAVED_VIEW_NAME
        const isProductionView = searchConfig.id === 'productions'
        return (
            <Fragment>
                {savedViewsId && showRemoveViewModal ? (
                    <SavedViewRemoval
                        viewName={currentViewName}
                        removeView={this.handleRemoveSavedView}
                        closeModal={this.toggleRemoveViewModal}
                    />
                ) : null}
                {savedViewsId && showSaveViewModal ? (
                    <ViewSaving
                        viewName={workingView.name}
                        saveView={this.handleSaveView}
                        closeModal={this.toggleSaveViewModal}
                        {...{
                            savedViews,
                        }}
                    />
                ) : null}
                {showViewConfiguration ? (
                    <AdvancedViewSelection
                        handleSave={this.handleUpdateActiveColumns}
                        handleClose={this.toggleViewConfiguration}
                        {...{
                            columns,
                            activeColumns,
                        }}
                    />
                ) : (
                    <Grid container spacing={0} rowSpacing={2} xs={12}>
                        <Grid container spacing={0} xs={12}>
                            <Grid container spacing={0} xs={5}>
                                <Grid xs={4} display="flex" alignItems="flex-end">
                                    <EditButton onClick={this.toggleViewConfiguration}>
                                        Columns
                                    </EditButton>
                                </Grid>
                                <Grid xs={3} display="flex" alignItems="flex-end">
                                    {selectedCount > 0 && isProductionView ? (
                                        <Tooltip
                                            title="Deselect checkboxes to enable actions"
                                            placement="right"
                                        >
                                            <span>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={true}
                                                >
                                                    Actions
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        <>{ActionsComponent ? <ActionsComponent /> : null}</>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid xs={7}>
                                <AdvancedViewToolbar
                                    activateSavedView={this.activateSavedView}
                                    toggleRemoveViewModal={this.toggleRemoveViewModal}
                                    toggleSaveViewModal={this.toggleSaveViewModal}
                                    {...{
                                        workingView,
                                        savedViewsId,
                                        savedViews,
                                        isInitial,
                                        isUnchangedSavedView,
                                        hideUnsavedView,
                                        currentViewName,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid xs={12}>
                            <AdvancedViewMissingColumns
                                {...{
                                    viewConfig,
                                    workingView,
                                }}
                            />
                        </Grid>
                        <AdvancedViewTable
                            getResult={this.getResult}
                            onColumnReorderEndCallback={this.onColumnReorderEndCallback}
                            onColumnResizeEndCallback={this.onColumnResizeEndCallback}
                            {...{
                                viewConfig,
                                searchState,
                                viewProps,
                                workingView,
                                resultsCount,
                                tableWidth,
                                updateSorting,
                                toggleSingle,
                                toggleAll,
                                hasActions,
                                language,
                            }}
                        />
                        <Grid xs={11.5} display="flex" justifyContent="flex-end">
                            <SearchAssetExport
                                {...{
                                    searchConfig,
                                    searchState,
                                    metaData,
                                    searchMetaData,
                                    resultsCount,
                                }}
                            />
                            <SearchExportCsv
                                {...{
                                    searchConfig,
                                    viewConfig,
                                    searchState,
                                    searchMetaData,
                                    activeColumns,
                                    workingView,
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
            </Fragment>
        )
    }
}

export default AdvancedSearchView
