import { Component } from 'react'

import Grid from '@mui/material/Unstable_Grid2'

import SortableSearchHeader from 'Common/search/view/SortableSearchHeader'

type Props = {
    searchOptions: Record<string, unknown>
    updateSorting(..._args: unknown[]): unknown
}

class WorksViewHeader extends Component<Props> {
    render() {
        const { searchOptions, updateSorting } = this.props
        const { orderBy } = searchOptions
        return (
            <Grid xs={12}>
                <Grid
                    container
                    spacing={2}
                    rowSpacing={2}
                    xs={12}
                    sx={{ display: { xs: 'none', md: 'flex' } }}
                >
                    <SortableSearchHeader
                        size={4}
                        label="Title"
                        field="title"
                        {...{
                            orderBy,
                            updateSorting,
                        }}
                    />
                    <SortableSearchHeader
                        size={4}
                        label="Author"
                        field="authors"
                        {...{
                            orderBy,
                            updateSorting,
                        }}
                    />
                    <SortableSearchHeader
                        size={4}
                        label="Publishing house"
                        field="publishingHouseName"
                        {...{
                            orderBy,
                            updateSorting,
                        }}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default WorksViewHeader
