import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router'

import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material/styles'
import { LicenseInfo } from '@mui/x-license'

import { browserHistory } from 'Common/base/history'
import { SnackBarProvider } from 'Common/components/snackbars/SnackbarToast'

import { requireAuthentication } from '../auth/AuthenticatedComponent'
import ErrorBoundary from '../components/error/ErrorBoundary'
import GlobalError from '../error/GlobalError'
import metaDataFields from '../metaDataFields'
import store from '../store/createStore'
import mockingbirdTheme from '../styles/themes/mockingbirdBaseTheme'
import { baseAppInit } from './BaseApp'
import { baseLoader } from './BaseLoader'

const initApp = () => {
    const theme = createTheme(mockingbirdTheme)
    const rootElement = document.getElementById('root')

    const handleError = (error) => {
        render(<GlobalError />, rootElement)
        throw error
    }

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    })

    LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_LICENSE_KEY)
    const App = requireAuthentication(baseLoader(baseAppInit(), metaDataFields, handleError))

    try {
        render(
            <ErrorBoundary point="initApp.render">
                <SnackBarProvider>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            <Provider store={store}>
                                <Router history={browserHistory}>
                                    <QueryClientProvider client={queryClient}>
                                        <App />
                                    </QueryClientProvider>
                                </Router>
                            </Provider>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </SnackBarProvider>
            </ErrorBoundary>,
            rootElement,
        )
    } catch (error) {
        handleError(error)
    }
}

export default initApp
