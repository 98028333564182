import { services } from 'Common/environment'
import request from 'Common/utils/net/request'

export const GET_METADATA_TYPES = 'GET_METADATA_TYPES'
export const GET_METADATA_TYPES_REQUEST = 'GET_METADATA_TYPES_REQUEST'
export const GET_METADATA_TYPES_SUCCESS = 'GET_METADATA_TYPES_SUCCESS'
export const GET_METADATA_TYPES_FAILURE = 'GET_METADATA_TYPES_FAILURE'

export const getMetaDataTypes = () => (dispatch) =>
    dispatch({
        type: GET_METADATA_TYPES,
        payload: {
            promise: request({
                url: services.metadata('/v2/types'),
                method: 'GET',
            }),
        },
    })
