import { services } from 'Common/environment'
import createErrorMessages from 'Common/messages/createErrorMessages'
import request from 'Common/utils/net/request'

import messages from '../messages'

export const GET_CONTACT_LISTS = 'GET_CONTACT_LISTS'
export const GET_CONTACT_LISTS_REQUEST = 'GET_CONTACT_LISTS_REQUEST'
export const GET_CONTACT_LISTS_SUCCESS = 'GET_CONTACT_LISTS_SUCCESS'
export const GET_CONTACT_LISTS_FAILURE = 'GET_CONTACT_LISTS_FAILURE'
export const GET_ACTIVE_CONTACT_LISTS = 'GET_ACTIVE_CONTACT_LISTS'
export const GET_ACTIVE_CONTACT_LISTS_REQUEST = 'GET_ACTIVE_CONTACT_LISTS_REQUEST'
export const GET_ACTIVE_CONTACT_LISTS_SUCCESS = 'GET_ACTIVE_CONTACT_LISTS_SUCCESS'
export const GET_ACTIVE_CONTACT_LISTS_FAILURE = 'GET_ACTIVE_CONTACT_LISTS_FAILURE'
export const PATCH_ACTIVE_CONTACT_LISTS = 'PATCH_ACTIVE_CONTACT_LISTS'
export const PATCH_ACTIVE_CONTACT_LISTS_REQUEST = 'PATCH_ACTIVE_CONTACT_LISTS_REQUEST'
export const PATCH_ACTIVE_CONTACT_LISTS_SUCCESS = 'PATCH_ACTIVE_CONTACT_LISTS_SUCCESS'
export const PATCH_ACTIVE_CONTACT_LISTS_FAILURE = 'PATCH_ACTIVE_CONTACT_LISTS_FAILURE'
export const getContactLists = () => (dispatch) =>
    dispatch({
        type: GET_CONTACT_LISTS,
        payload: {
            promise: request({
                method: 'GET',
                url: services.contact(`/v2/lists`),
            }),
        },
    })
const GET_ACTIVE_CONTACT_LISTS_ERROR_MESSAGES = createErrorMessages(
    ['CONTACT_NOT_FOUND', 'CONTACT_FORBIDDEN'],
    messages,
)
export const getActiveContactLists = (contactId) => (dispatch) =>
    dispatch({
        type: GET_ACTIVE_CONTACT_LISTS,
        meta: {
            contactId,
        },
        payload: {
            promise: request({
                method: 'GET',
                url: services.contact(`/v2/contacts/${contactId}/lists`),
                errorMessages: GET_ACTIVE_CONTACT_LISTS_ERROR_MESSAGES,
            }),
        },
    })
const PATCH_ACTIVE_CONTACT_LISTS_ERROR_MESSAGES = createErrorMessages(
    ['CONTACT_NOT_FOUND', 'CONTACT_FORBIDDEN'],
    messages,
)
export const patchActiveContactLists = (contactId, groups) => (dispatch) =>
    dispatch({
        type: PATCH_ACTIVE_CONTACT_LISTS,
        meta: {
            contactId,
        },
        payload: {
            promise: request({
                method: 'PATCH',
                body: groups,
                url: services.contact(`/v2/contacts/${contactId}/lists`),
                errorMessages: PATCH_ACTIVE_CONTACT_LISTS_ERROR_MESSAGES,
            }),
        },
    })
