import { services } from 'Common/environment'
import createErrorMessages from 'Common/messages/createErrorMessages'
import request from 'Common/utils/net/request'

import messages from '../messages'

export const GET_CONTACT_LIST = 'GET_CONTACT_LIST'
export const GET_CONTACT_LIST_REQUEST = 'GET_CONTACT_LIST_REQUEST'
export const GET_CONTACT_LIST_SUCCESS = 'GET_CONTACT_LIST_SUCCESS'
export const GET_CONTACT_LIST_FAILURE = 'GET_CONTACT_LIST_FAILURE'
export const POST_CONTACT_LIST = 'POST_CONTACT_LIST'
export const POST_CONTACT_LIST_REQUEST = 'POST_CONTACT_LIST_REQUEST'
export const POST_CONTACT_LIST_SUCCESS = 'POST_CONTACT_LIST_SUCCESS'
export const POST_CONTACT_LIST_FAILURE = 'POST_CONTACT_LIST_FAILURE'
export const PATCH_CONTACT_LIST = 'PATCH_CONTACT_LIST'
export const PATCH_CONTACT_LIST_REQUEST = 'PATCH_CONTACT_LIST_REQUEST'
export const PATCH_CONTACT_LIST_SUCCESS = 'PATCH_CONTACT_LIST_SUCCESS'
export const PATCH_CONTACT_LIST_FAILURE = 'PATCH_CONTACT_LIST_FAILURE'
export const DELETE_CONTACT_LIST_MEMBER = 'DELETE_CONTACT_LIST_MEMBER'
export const DELETE_CONTACT_LIST_MEMBER_REQUEST = 'DELETE_CONTACT_LIST_MEMBER_REQUEST'
export const DELETE_CONTACT_LIST_MEMBER_SUCCESS = 'DELETE_CONTACT_LIST_MEMBER_SUCCESS'
export const DELETE_CONTACT_LIST_MEMBER_FAILURE = 'DELETE_CONTACT_LIST_FAILURE'
export const POST_CONTACT_LIST_MEMBER = 'POST_CONTACT_LIST_MEMBER'
export const POST_CONTACT_LIST_MEMBER_REQUEST = 'POST_CONTACT_LIST_MEMBER_REQUEST'
export const POST_CONTACT_LIST_MEMBER_SUCCESS = 'POST_CONTACT_LIST_MEMBER_SUCCESS'
export const POST_CONTACT_LIST_MEMBER_FAILURE = 'POST_CONTACT_LIST_FAILURE'
const GET_CONTACT_LIST_ERROR_MESSAGES = createErrorMessages(['LIST_NOT_FOUND'], messages)
export const getContactList = (id) => (dispatch) =>
    dispatch({
        type: GET_CONTACT_LIST,
        payload: {
            promise: request({
                method: 'GET',
                url: services.contact(`/v2/lists/${id}`),
                errorMessages: GET_CONTACT_LIST_ERROR_MESSAGES,
            }),
        },
    })
const POST_CONTACT_LIST_ERROR_MESSAGES = createErrorMessages(['USER_MISSING_AD_USERNAME'], messages)
export const postContactList = (values) => (dispatch) =>
    dispatch({
        type: POST_CONTACT_LIST,
        payload: {
            promise: request({
                method: 'POST',
                url: services.contact(`/v2/lists`),
                body: values,
                errorMessages: POST_CONTACT_LIST_ERROR_MESSAGES,
            }),
        },
    })
const PATCH_CONTACT_LIST_ERROR_MESSAGES = createErrorMessages(
    ['LIST_NOT_FOUND', 'USER_NOT_FOUND', 'LIST_IS_NOT_SHARED_AND_USER_IS_NOT_OWNER'],
    messages,
)
export const patchContactList = (id, values) => (dispatch) =>
    dispatch({
        type: PATCH_CONTACT_LIST,
        meta: {
            id,
        },
        payload: {
            promise: request({
                url: services.contact(`/v2/lists/${id}`),
                method: 'PATCH',
                body: values,
                errorMessages: PATCH_CONTACT_LIST_ERROR_MESSAGES,
            }),
        },
    })
const POST_CONTACT_LIST_MEMBER_ERROR_MESSAGES = createErrorMessages(
    ['LIST_NOT_FOUND', 'CONTACT_NOT_FOUND'],
    messages,
)
export const postContactListMember = (listId, contactId) => (dispatch) =>
    dispatch({
        type: POST_CONTACT_LIST_MEMBER,
        meta: {
            listId,
            contactId,
        },
        payload: {
            promise: request({
                method: 'POST',
                url: services.contact(`/v2/lists/${listId}/members`),
                body: {
                    id: contactId,
                },
                errorMessages: POST_CONTACT_LIST_MEMBER_ERROR_MESSAGES,
            }),
        },
    })
const DELETE_CONTACT_LIST_MEMBER_ERROR_MESSAGES = createErrorMessages(
    ['LIST_NOT_FOUND', 'LIST_CONTACT_DOES_NOT_EXIST_IN_LIST'],
    messages,
)
export const deleteContactListMember = (listId, memberId) => (dispatch) =>
    dispatch({
        type: DELETE_CONTACT_LIST_MEMBER,
        payload: {
            promise: request({
                method: 'DELETE',
                url: services.contact(`/v2/lists/${listId}/members/${memberId}`),
                errorMessages: DELETE_CONTACT_LIST_MEMBER_ERROR_MESSAGES,
            }),
        },
    })
