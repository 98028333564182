import { get, getOr } from 'lodash/fp'
import { PureComponent } from 'react'

import { Checkbox } from '@mui/material'

type Props = {
    result?: Record<string, unknown>
    viewProps?: Record<string, unknown>
    searchState?: any
    toggleSingle?(..._args: unknown[]): unknown
}

class CheckboxCell extends PureComponent<Props> {
    render() {
        const { result, toggleSingle, searchState } = this.props
        const value = get(`document.id`, result)
        const { selectedResult = [] } = searchState
        const checked = selectedResult.includes(value)
        const selectedCount = getOr(0, 'selectedResult.length', searchState)
        const disabled = (selectedResult.includes(value) || selectedCount >= 50) && !checked
        return (
            <Checkbox
                {...{
                    checked,
                    disabled,
                }}
                color="primary"
                style={{
                    padding: 0,
                    margin: '3px 0px 3px 12px',
                }}
                onClick={() => toggleSingle(value)}
            />
        )
    }
}

export default CheckboxCell
