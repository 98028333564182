import { services } from 'Common/environment'
import { AppDispatch } from 'Common/types'
import request from 'Common/utils/net/request'

export const GET_RETAILERS = 'GET_RETAILERS'
export const GET_RETAILERS_REQUEST = 'GET_RETAILERS_REQUEST'
export const GET_RETAILERS_SUCCESS = 'GET_RETAILERS_SUCCESS'
export const GET_RETAILERS_FAILURE = 'GET_RETAILERS_FAILURE'

export const getRetailers = () => (dispatch: AppDispatch) =>
    dispatch({
        type: GET_RETAILERS,
        payload: {
            promise: request({
                url: services.schilling('/v1/retailers'),
                method: 'GET',
            }),
        },
    })
